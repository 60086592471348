/* Css For Docpro */
/************ TABLE OF CONTENTS ***************
1. Fonts
2. Reset
3. Global
4. Main Header/style-one/style-two
5. Main Slider/style-one/style-two
6. Intro Section
7. Welcome Section
9. Cta Section
8. Research Fields
10. Testimonial Section
11. Researches Section
12. Team Section
14. Video
15. Fact Counter
16. News Section
19. Clients Section
20. Main Footer
21. Footer Bottom
22. Research Style Two
23. Innovative Solution
24. Discover Section
25. Testimonial Section
26. Chooseus Section
27. News Style Two
28. Page Title
29. Research Page Section
30. Research Details
31. Professor Page Section
32. Professor Details
33. About Section
34. Error Section
35. Blog Page Section
36. Blog Details
37. Blog Sidebar
38. Contact Section
39. Google Map


**********************************************/
/*** 

====================================================================
  Reset
====================================================================

 ***/
/*** 

====================================================================
  Global Settings
====================================================================

 ***/
/* Preloader */
/** button **/
/*** 

====================================================================
                        Home-Page-One
====================================================================

***/
/** main-header **/
/** header-top **/
/** main-menu **/
/** megamenu-style **/
/** mobile-menu **/
/** banner-section **/
/** clients-section **/
/** feature-section **/
/** about-section **/
/** process-section **/
/** testimonial-section **/
/** team-section **/
/** search-doctors **/
/** cta-section **/
/** news-section **/
/** agent-section **/
/** main-footer **/
/*** 

====================================================================
                        Home-Page-Two
====================================================================

***/
/** header-style-two **/
/** banner-style-two **/
/** search-doctors-two **/
/** about-style-two **/
/** process-style-two **/
/** team-style-two **/
/*** 

====================================================================
                        Home-Page-Three
====================================================================

***/
/** banner-style-three **/
/** category-section **/
/** team-style-three **/
/** faq-section **/
/** testimonial-style-two **/
/*** 

====================================================================
                        Home-Page-Four
====================================================================

***/
/** banner-style-four **/
/** about-style-three **/
@-webkit-keyframes "shine" {
    100% {
        left: 125%;
    }
}

@keyframes "shine" {
    100% {
        left: 125%;
    }
}

/*** 

====================================================================
                        Home-Page-Five
====================================================================

***/
/** banner-style-five **/
/** funfact-section **/
/*** 

====================================================================
                        About-Page
====================================================================

***/
/** page-title **/
/** page-title-two **/
/** questions-section **/
/*** 

====================================================================
                        Pricing-Page
====================================================================

***/
/*** 

====================================================================
                        Error-Page
====================================================================

***/
/*** 

====================================================================
                        Clinic-Page
====================================================================

***/
/** clinic-section **/
/** clinic-details **/
/** appointment-section **/
/*** 

====================================================================
                        Docotrs-Page
====================================================================

***/
/*** 

====================================================================
                        Blog-Page
====================================================================

***/
/*** 

====================================================================
                        Blog-Page
====================================================================

***/
/** information-section **/
/** contact-section **/
* {
    margin: 0px;
    padding: 0px;
    border: none;
    outline: none;
}

body {
    font-size: 15px;
    color: #808080;
    line-height: 28px;
    font-weight: 400;
    background: #ffffff;
    font-family: 'Poppins', sans-serif;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center top;
    -webkit-font-smoothing: antialiased;
}

.large-container {
    max-width: 1500px;
    padding: 0px 15px;
    margin: 0 auto;
}

.container-fluid {
    padding: 0px;
}

.auto-container {
    position: static;
    max-width: 1200px;
    padding: 0px 15px;
    margin: 0 auto;
}

.small-container {
    max-width: 680px;
    margin: 0 auto;
}

.boxed_wrapper {
    position: relative;
    margin: 0 auto;
    overflow: hidden !important;
    width: 100%;
    min-width: 300px;
}

a {
    text-decoration: none;
    transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -webkit-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;

    &:hover {
        text-decoration: none;
        outline: none;
    }
}

input {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 16px;
    transition: all 500ms ease;

    &:focus {
        outline: none;
        box-shadow: none;
        transition: all 500ms ease;
    }
}

button {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 16px;

    &:focus {
        outline: none;
        box-shadow: none;
        transition: all 500ms ease;
    }
}

select {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 16px;
}

textarea {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 16px;

    &:focus {
        outline: none;
        box-shadow: none;
        transition: all 500ms ease;
    }
}

ul {
    list-style: none;
    padding: 0px;
    margin: 0px;
}

li {
    list-style: none;
    padding: 0px;
    margin: 0px;
}

p {
    position: relative;
    font-family: 'Poppins', sans-serif;
    color: #808080;
    font-weight: 400;
    margin: 0px;
    transition: all 500ms ease;
}

h1 {
    position: relative;
    font-family: 'Infer', sans-serif;
    font-weight: 400;
    color: #3C5A7D;
    margin: 0px;
    transition: all 500ms ease;
}

h2 {
    position: relative;
    font-family: 'Infer', sans-serif;
    font-weight: 400;
    color: #3C5A7D;
    margin: 0px;
    transition: all 500ms ease;
}

h3 {
    position: relative;
    font-family: 'Infer', sans-serif;
    font-weight: 400;
    color: #3C5A7D;
    margin: 0px;
    transition: all 500ms ease;
}

h4 {
    position: relative;
    font-family: 'Infer', sans-serif;
    font-weight: 400;
    color: #3C5A7D;
    margin: 0px;
    transition: all 500ms ease;
}

h5 {
    position: relative;
    font-family: 'Infer', sans-serif;
    font-weight: 600;
    color: #3C5A7D;
    margin: 0px;
    transition: all 500ms ease;
}

h6 {
    position: relative;
    font-family: 'Infer', sans-serif;
    font-weight: 600;
    color: #3C5A7D;
    margin: 0px;
    transition: all 500ms ease;
}

.preloader {
    position: fixed;
    left: 0px;
    top: 0px;
    height: 100%;
    width: 100%;
    z-index: 999999;
    background-color: #fff;
    background-position: center center;
    background-repeat: no-repeat;
    background-image: url(/assets/images/icons/preloader.svg);
}

.centred {
    text-align: center;
}

.pull-left {
    float: left;
}

.pull-right {
    float: right;
}

figure {
    margin: 0px;
}

img {
    display: inline-block;
    max-width: 100%;
    height: auto;
}

.theme-btn-one {
    position: relative;
    display: inline-block;
    font-size: 15px;
    line-height: 26px;
    font-weight: 600;
    color: #fff !important;
    text-align: center;
    padding: 17px 40px;
    border-radius: 30px;
    z-index: 1;
    box-shadow: 0 20px 30px #d5edea;
    transition: all 500ms ease;

    i {
        position: relative;
        font-size: 13px;
        margin-left: 10px;
    }

    &:before {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        border-radius: 30px;
        left: 0px;
        top: 0px;
        z-index: -1;
        transform: scale(0, 0);
        transition: all 500ms ease;
    }

    &:hover {
        &:before {
            transform: scale(1, 1);
        }
    }

    &.small-scale {
        font-size: 14px;
        padding: 14px 24px;
        font-weight: 400;
        line-height: 16.94px;
        box-shadow: none;
    }

    &.x-small-scale {
        font-size: 12px;
        padding: 5px 10px;
        font-weight: 400;
        line-height: 16.94px;
        box-shadow: none;
    }
}

.theme-btn-two {
    position: relative;
    display: inline-block;
    font-size: 15px;
    line-height: 26px;
    font-weight: 600;
    text-align: center;
    padding: 12px 40px;
    border-radius: 30px;
    z-index: 1;
    border: 2px solid #ebeef1;
    transition: all 500ms ease;

    &:hover {
        color: #fff;
    }
}

.pagination {
    position: relative;
    display: block;

    .page-link {
        padding: 0;
    }

    a[aria-label='Previous'] {
        span {
            display: none !important;
        }
    
        &::before {
            content: '\e902' !important;
        }
     }

     a[aria-label='Next'] {
        span {
            display: none !important;
        }
    
        &::before {
            content: '\e905' !important;
        }
     }

    .page-item {
        &:first-child {
            .page-link {
              border-radius: 50%;
            }
          }
      
          &:last-child {
            .page-link {
                border-radius: 50%;
            }
          }
    }
    li, .page-item, .page-link {
        display: inline-block;
        margin: 0px 3px;

        a {
            position: relative;
            display: inline-block;
            font-size: 17px;
            font-weight: 500;
            height: 50px;
            width: 50px;
            line-height: 50px;
            background: transparent;
            border: 1px solid #e6e8ec;
            border-radius: 50%;
            text-align: center;
            color: #3C5A7D;
            z-index: 1;
            transition: all 500ms ease;

            &:hover {
                color: #fff;
                box-shadow: 0 10px 10px #d5edea;
            }
        }

        a.current, a.active {
            color: #fff;
            box-shadow: 0 10px 10px #d5edea;
        }

        &.disabled {
            display: none;
        }
    }
}

.sec-pad {
    padding: 115px 0px 120px 0px;
}

.mr-0 {
    margin: 0px;
}

.scroll-top {
    width: 55px;
    height: 55px;
    line-height: 55px;
    position: fixed;
    bottom: 105%;
    right: 30px;
    font-size: 24px;
    z-index: 99;
    color: #ffffff;
    text-align: center;
    cursor: pointer;
    transition: 1s ease;

    span {
        color: #fff;
    }
}

.scroll-top.open {
    bottom: 50px;
}

.sec-title {
    position: relative;
    display: block;
    margin-bottom: 50px;

    p {
        position: relative;
        display: inline-block;
        font-size: 17px;
        line-height: 26px;
        font-weight: 600;
        text-transform: uppercase;
        padding-bottom: 17px;
    }

    h2 {
        position: relative;
        display: block;
        font-size: 50px;
        line-height: 55px;
        font-weight: 900;
    }
}

.sec-title.centred {
    text-align: center !important;
}

.sec-title.light {
    h2 {
        color: #3C5A7D;
    }
}

.main-header {
    position: relative;
    left: 0px;
    top: 0px;
    z-index: 999;
    width: 100%;
    transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -webkit-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;

    .logo-box {
        .logo {
            position: relative;
            max-width: 194px;
            width: 100%;
            z-index: 1;

            img {
                position: relative;
                width: 100%;
                height: 40px;
            }
        }

        .pattern {
            position: absolute;
            top: -66px;
            right: 60px;
            width: 604px;
            height: 166px;
            background-repeat: no-repeat;
            z-index: 1;
        }

        .bg-color {
            position: absolute;
            top: -66px;
            right: 0px;
            width: 5000px;
            height: 166px;
            clip-path: polygon(0% 0%, 100% 0%, 99% 100%, 0% 100%, 0% 0%);
        }
    }

    .btn-box {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        flex-wrap: wrap;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;

        .theme-btn-one {
            padding: 12px 30px;
            font-size: 17px;
            font-weight: 500;
            box-shadow: 0 10px 10px #d5edea;

            i {
                font-size: 18px;
                margin-right: 10px;
                top: 3px;
                margin-left: 0px;
            }
        }
    }

    .outer-box {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
    }

    .sticky-header {
        .logo-box {
            padding: 19.5px 0px !important;
        }

        .main-menu {
            .navigation {
                >li {
                    padding: 30px 0px !important;
                }
            }
        }
    }

    .outer-container {
        position: relative;
        padding: 0px 70px;
    }
}

.sticky-header {
    position: fixed;
    opacity: 0;
    visibility: hidden;
    background: #fff;
    left: 0px;
    top: 0px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    width: 100%;
    z-index: 0;
    transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -webkit-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
}

.fixed-header {
    .sticky-header {
        z-index: 999;
        opacity: 1;
        visibility: visible;
        -ms-animation-name: fadeInDown;
        -moz-animation-name: fadeInDown;
        -op-animation-name: fadeInDown;
        -webkit-animation-name: fadeInDown;
        animation-name: fadeInDown;
        -ms-animation-duration: 500ms;
        -moz-animation-duration: 500ms;
        -op-animation-duration: 500ms;
        -webkit-animation-duration: 500ms;
        animation-duration: 500ms;
        -ms-animation-timing-function: linear;
        -moz-animation-timing-function: linear;
        -op-animation-timing-function: linear;
        -webkit-animation-timing-function: linear;
        animation-timing-function: linear;
        -ms-animation-iteration-count: 1;
        -moz-animation-iteration-count: 1;
        -op-animation-iteration-count: 1;
        -webkit-animation-iteration-count: 1;
        animation-iteration-count: 1;
    }
}

.header-top {
    position: relative;
    padding-top: 30px;

    .info {
        li {
            position: relative;
            display: inline-block;
            margin: 0px 15px;

            a {
                position: relative;
                display: inline-block;
                color: #3C5A7D;
            }

            &:last-child {
                margin-right: 0px;
            }
        }

        li.language {
            position: relative;
            cursor: pointer;

            &:before {
                position: absolute;
                content: '';
                width: 30px;
                height: 30px;
                left: 0px;
                top: 3px;
            }

            &:after {
                position: absolute;
                top: 2px;
                right: 0px;
                content: "\f107";
                font-family: 'Font Awesome 5 Pro';
                font-size: 15px;
                font-weight: 500;
                color: #3C5A7D;
            }

            a {
                padding-right: 13px;
            }

            .language-dropdown {
                position: absolute;
                right: 0;
                top: 100%;
                margin-top: 4px;
                width: 150px;
                padding: 5px 0px;
                opacity: 0;
                visibility: hidden;
                z-index: 2;
                -webkit-transform: translateY(20px);
                -ms-transform: translateY(20px);
                transform: translateY(20px);
                -webkit-transition: all 300ms linear;
                -ms-transition: all 300ms linear;
                transition: all 300ms linear;

                li {
                    position: relative;
                    display: block;
                    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
                    margin: 0px 15px !important;

                    &:last-child {
                        border: none;
                    }

                    a {
                        position: relative;
                        display: block;
                        padding: 5px 0px;
                        font-size: 14px;
                        line-height: 24px;
                        color: #fff;
                        text-align: left;
                    }
                }
            }

            &:hover {
                .language-dropdown {
                    opacity: 1;
                    visibility: visible;
                    -webkit-transform: translateY(0px);
                    -ms-transform: translateY(0px);
                    transform: translateY(0px);
                }
            }
        }
    }
}

.main-header.style-one {
    .header-top {
        .info {
            position: relative;
            display: inline-block;
            background: #ebf7f6;
            border-radius: 5px;
            padding: 4px 12px;
        }
    }

    .logo-box {
        position: relative;
        padding: 19px 100px 30px 0px;
    }
}

.main-menu {
    float: left;

    .navbar-collapse {
        padding: 0px;
        display: block !important;
    }

    .navigation {
        margin: 0px;

        >li {
            position: inherit;
            float: left;
            z-index: 2;
            padding: 35px 0px;
            margin: 0px 3px;
            -webkit-transition: all 300ms ease;
            -moz-transition: all 300ms ease;
            -ms-transition: all 300ms ease;
            -o-transition: all 300ms ease;
            transition: all 300ms ease;

            &:last-child {
                margin-right: 0px !important;
            }

            &:first-child {
                margin-left: 0px !important;
            }

            >a {
                position: relative;
                display: block;
                text-align: center;
                font-size: 14px;
                line-height: 30px;
                font-weight: 500;
                padding: 0px 19px;
                opacity: 1;
                color: #3C5A7D;
                z-index: 1;
                -webkit-transition: all 500ms ease;
                -moz-transition: all 500ms ease;
                -ms-transition: all 500ms ease;
                -o-transition: all 500ms ease;
                transition: all 500ms ease;

                &:before {
                    position: absolute;
                    content: '';
                    width: 100%;
                    height: 3px;
                    left: 0px;
                    bottom: -12px;
                    transform: scale(0, 0);
                    transition: all 500ms ease;
                }
            }

            &:hover {
                >a {
                    &:before {
                        transform: scale(1, 1);
                    }
                }

                >ul {
                    >li {
                        opacity: 1;
                        visibility: visible;
                        -webkit-transform: translateY(0);
                        transform: translateY(0);
                    }
                }
            }

            >ul {
                position: absolute;
                left: inherit;
                top: 100%;
                width: 250px;
                z-index: 100;
                display: none;
                opacity: 0;
                visibility: hidden;
                padding: 10px 0px;
                background: #3C5A7D;
                filter: alpha(opacity=0);
                -webkit-transition: all 0.2s ease-out;
                transition: all 0.2s ease-out;
                -moz-transition: all 0.2s ease-out;
                -ms-transition: all 0.2s ease-out;
                -webkit-box-shadow: 0px 4px 4px 1px rgba(0, 0, 0, 0.2);
                box-shadow: 0px 4px 4px 1px rgba(0, 0, 0, 0.2);
                -webkit-transform: rotateX(-90deg);
                transform: rotateX(-90deg);
                -webkit-transform-origin: 0 0;
                transform-origin: 0 0;

                >li {
                    position: relative;
                    width: 100%;
                    padding: 0px 30px;
                    -webkit-transition: all 0.2s cubic-bezier(0.4, 0.28, 0.31, 1.28) 0s;
                    transition: all 0.2s cubic-bezier(0.4, 0.28, 0.31, 1.28) 0s;
                    opacity: 0;
                    visibility: hidden;
                    -webkit-transform: translateY(5px);
                    transform: translateY(5px);

                    &:nth-child(2n+1) {
                        -webkit-transition-delay: 0.1s;
                        transition-delay: 0.1s;
                    }

                    &:nth-child(2n+2) {
                        -webkit-transition-delay: 0.15s;
                        transition-delay: 0.15s;
                    }

                    &:nth-child(2n+3) {
                        -webkit-transition-delay: 0.2s;
                        transition-delay: 0.2s;
                    }

                    &:nth-child(2n+4) {
                        -webkit-transition-delay: 0.25s;
                        transition-delay: 0.25s;
                    }

                    &:nth-child(2n+5) {
                        -webkit-transition-delay: 0.3s;
                        transition-delay: 0.3s;
                    }

                    &:nth-child(2n+6) {
                        -webkit-transition-delay: 0.35s;
                        transition-delay: 0.35s;
                    }

                    &:nth-child(2n+7) {
                        -webkit-transition-delay: 0.4s;
                        transition-delay: 0.4s;
                    }

                    &:nth-child(2n+8) {
                        -webkit-transition-delay: 0.45s;
                        transition-delay: 0.45s;
                    }

                    &:last-child {
                        border-bottom: none;

                        >a {
                            border-bottom: none;
                        }
                    }

                    >a {
                        position: relative;
                        display: block;
                        padding: 14px 0px;
                        line-height: 24px;
                        font-weight: 400;
                        font-size: 16px;
                        text-transform: capitalize;
                        color: #fff;
                        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
                        text-align: left;
                        transition: all 500ms ease;
                        -moz-transition: all 500ms ease;
                        -webkit-transition: all 500ms ease;
                        -ms-transition: all 500ms ease;
                        -o-transition: all 500ms ease;
                    }

                    >ul {
                        position: absolute;
                        right: 100%;
                        top: 0%;
                        width: 230px;
                        z-index: 100;
                        display: none;
                        padding: 10px 0px;
                        background: #3C5A7D;
                        filter: alpha(opacity=0);
                        -webkit-transition: all 0.2s ease-out;
                        transition: all 0.2s ease-out;
                        -moz-transition: all 0.2s ease-out;
                        -ms-transition: all 0.2s ease-out;
                        -webkit-box-shadow: 0px 4px 4px 1px rgba(0, 0, 0, 0.2);
                        box-shadow: 0px 4px 4px 1px rgba(0, 0, 0, 0.2);
                        -webkit-transform: rotateX(-90deg);
                        transform: rotateX(-90deg);
                        -webkit-transform-origin: 0 0;
                        transform-origin: 0 0;

                        >li {
                            position: relative;
                            width: 100%;
                            padding: 0px 30px;
                            position: relative;
                            width: 100%;
                            padding: 0px 30px;
                            -webkit-transition: all 0.2s cubic-bezier(0.4, 0.28, 0.31, 1.28) 0s;
                            transition: all 0.2s cubic-bezier(0.4, 0.28, 0.31, 1.28) 0s;
                            opacity: 0;
                            visibility: hidden;
                            -webkit-transform: translateY(5px);
                            transform: translateY(5px);

                            &:nth-child(2n+1) {
                                -webkit-transition-delay: 0.1s;
                                transition-delay: 0.1s;
                            }

                            &:nth-child(2n+2) {
                                -webkit-transition-delay: 0.15s;
                                transition-delay: 0.15s;
                            }

                            &:nth-child(2n+3) {
                                -webkit-transition-delay: 0.2s;
                                transition-delay: 0.2s;
                            }

                            &:nth-child(2n+4) {
                                -webkit-transition-delay: 0.25s;
                                transition-delay: 0.25s;
                            }

                            &:last-child {
                                border-bottom: none;
                                border-bottom: none;

                                >a {
                                    border-bottom: none;
                                }
                            }

                            >a {
                                position: relative;
                                display: block;
                                padding: 14px 0px;
                                line-height: 24px;
                                font-weight: 400;
                                font-size: 16px;
                                text-transform: capitalize;
                                color: #fff;
                                border-bottom: 1px solid rgba(255, 255, 255, 0.1);
                                text-align: left;
                                transition: all 500ms ease;
                                -moz-transition: all 500ms ease;
                                -webkit-transition: all 500ms ease;
                                -ms-transition: all 500ms ease;
                                -o-transition: all 500ms ease;
                            }
                        }

                        >li.dropdown {
                            >a {
                                &:after {
                                    font-family: 'Font Awesome 5 Pro';
                                    content: "\f105";
                                    position: absolute;
                                    right: 20px;
                                    top: 12px;
                                    display: block;
                                    line-height: 24px;
                                    font-size: 16px;
                                    font-weight: 900;
                                    z-index: 5;
                                }
                            }
                        }
                    }

                    >ul.from-right {
                        left: auto;
                        right: 0px;
                    }

                    &:hover {
                        >ul {
                            >li {
                                opacity: 1;
                                visibility: visible;
                                -webkit-transform: translateY(0);
                                transform: translateY(0);
                            }
                        }
                    }
                }

                >li.dropdown {
                    >a {
                        &:after {
                            font-family: 'Font Awesome 5 Pro';
                            content: "\f105";
                            position: absolute;
                            right: 0px;
                            top: 14px;
                            display: block;
                            line-height: 24px;
                            font-size: 16px;
                            font-weight: 800;
                            text-align: center;
                            z-index: 5;
                        }
                    }
                }
            }

            >.megamenu {
                position: absolute;
                left: inherit;
                top: 100%;
                width: 250px;
                z-index: 100;
                display: none;
                opacity: 0;
                visibility: hidden;
                padding: 10px 0px;
                background: #3C5A7D;
                filter: alpha(opacity=0);
                -webkit-transition: all 0.2s ease-out;
                transition: all 0.2s ease-out;
                -moz-transition: all 0.2s ease-out;
                -ms-transition: all 0.2s ease-out;
                -webkit-box-shadow: 0px 4px 4px 1px rgba(0, 0, 0, 0.2);
                box-shadow: 0px 4px 4px 1px rgba(0, 0, 0, 0.2);
                -webkit-transform: rotateX(-90deg);
                transform: rotateX(-90deg);
                -webkit-transform-origin: 0 0;
                transform-origin: 0 0;

                li {
                    >a {
                        position: relative;
                        display: block;
                        padding: 14px 0px;
                        line-height: 24px;
                        font-weight: 400;
                        font-size: 16px;
                        text-transform: capitalize;
                        color: #fff;
                        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
                        text-align: left;
                        transition: all 500ms ease;
                        -moz-transition: all 500ms ease;
                        -webkit-transition: all 500ms ease;
                        -ms-transition: all 500ms ease;
                        -o-transition: all 500ms ease;
                    }

                    &:last-child {
                        >a {
                            border-bottom: none;
                        }
                    }
                }
            }

            >ul.from-right {
                left: auto;
                right: 0px;
            }
        }

        >li.current {
            >a {
                &:before {
                    transform: scale(1, 1);
                }
            }
        }

        >li.dropdown {
            >a {
                &:after {
                    position: absolute;
                    top: 2px;
                    right: 0px;
                    content: "\f107";
                    font-family: 'Font Awesome 5 Pro';
                }
            }

            &:hover {
                >ul {
                    visibility: visible;
                    opacity: 1;
                    filter: alpha(opacity=100);
                    top: 99%;
                    -webkit-transform: rotateX(0);
                    transform: rotateX(0);
                }

                >.megamenu {
                    visibility: visible;
                    opacity: 1;
                    filter: alpha(opacity=100);
                    top: 99%;
                    -webkit-transform: rotateX(0);
                    transform: rotateX(0);
                }
            }

            >.megamenu {
                position: absolute;
                width: 100%;
                padding: 30px 50px;
                left: 0px;
            }
        }

        li {
            >ul {
                >li.dropdown {
                    &:hover {
                        >ul {
                            visibility: visible;
                            opacity: 1;
                            filter: alpha(opacity=100);
                            top: 0%;
                            -webkit-transform: rotateX(0);
                            transform: rotateX(0);
                        }
                    }
                }
            }
        }

        li.dropdown {
            .dropdown-btn {
                position: absolute;
                right: -32px;
                top: 66px;
                width: 34px;
                height: 30px;
                text-align: center;
                font-size: 18px;
                line-height: 26px;
                color: #3b3b3b;
                cursor: pointer;
                display: none;
                z-index: 5;
                transition: all 500ms ease;
            }

            ul {
                li.dropdown {
                    .dropdown-btn {
                        display: none;
                    }
                }
            }

            .megamenu {
                li {
                    h4 {
                        margin-bottom: 15px;
                        color: #fff;
                    }
                }
            }
        }
    }
}

.menu-area {
    .mobile-nav-toggler {
        position: relative;
        float: right;
        font-size: 40px;
        line-height: 50px;
        cursor: pointer;
        color: #3786ff;
        display: none;

        .icon-bar {
            position: relative;
            height: 2px;
            width: 30px;
            display: block;
            margin-bottom: 5px;
            background-color: #3b3b3b;
            -webkit-transition: all 300ms ease;
            -moz-transition: all 300ms ease;
            -ms-transition: all 300ms ease;
            -o-transition: all 300ms ease;
            transition: all 300ms ease;

            &:last-child {
                margin-bottom: 0px;
            }
        }
    }

    z-index: 1;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.nav-outer {
    .mobile-nav-toggler {
        position: relative;
        float: right;
        font-size: 40px;
        line-height: 50px;
        cursor: pointer;
        color: #3786ff;
        display: none;
    }
}

.mobile-menu {
    position: fixed;
    right: 0;
    top: 0;
    width: 300px;
    padding-right: 30px;
    max-width: 100%;
    height: 100%;
    opacity: 0;
    visibility: hidden;
    z-index: 999999;

    .navbar-collapse {
        display: block !important;
    }

    .nav-logo {
        position: relative;
        padding: 50px 25px;
        text-align: left;
        padding-bottom: 100px;
    }

    .menu-backdrop {
        position: fixed;
        right: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        background: rgba(0, 0, 0, 0.5);
        -webkit-transform: translateX(101%);
        -ms-transform: translateX(101%);
        transform: translateX(101%);
        transition: all 900ms ease;
        -moz-transition: all 900ms ease;
        -webkit-transition: all 900ms ease;
        -ms-transition: all 900ms ease;
        -o-transition: all 900ms ease;
    }

    .menu-box {
        position: absolute;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
        max-height: 100%;
        overflow-y: auto;
        background: #202020;
        padding: 0px 0px;
        z-index: 5;
        opacity: 0;
        visibility: hidden;
        border-radius: 0px;
        -webkit-transform: translateX(101%);
        -ms-transform: translateX(101%);
        transform: translateX(101%);
    }

    .close-btn {
        position: absolute;
        right: 25px;
        top: 10px;
        line-height: 30px;
        width: 24px;
        text-align: center;
        font-size: 16px;
        color: #ffffff;
        cursor: pointer;
        z-index: 10;
        -webkit-transition: all 0.9s ease;
        -moz-transition: all 0.9s ease;
        -ms-transition: all 0.9s ease;
        -o-transition: all 0.9s ease;
        transition: all 0.9s ease;

        &:hover {
            -webkit-transform: rotate(90deg);
            -ms-transform: rotate(90deg);
            transform: rotate(90deg);
        }
    }

    .navigation {
        position: relative;
        display: block;
        width: 100%;
        float: none;

        li {
            position: relative;
            display: block;
            border-top: 1px solid rgba(255, 255, 255, 0.10);

            >ul {
                >li {
                    &:first-child {
                        border-top: 1px solid rgba(255, 255, 255, 0.10);
                    }

                    >ul {
                        display: none;
                    }
                }

                display: none;
            }

            >a {
                position: relative;
                display: block;
                line-height: 24px;
                padding: 10px 25px;
                font-size: 15px;
                font-weight: 500;
                color: #ffffff;
                text-transform: uppercase;
                -webkit-transition: all 500ms ease;
                -moz-transition: all 500ms ease;
                -ms-transition: all 500ms ease;
                -o-transition: all 500ms ease;
                transition: all 500ms ease;

                &:before {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 0;
                    height: 0;
                    border-left: 5px solid #fff;
                    -webkit-transition: all 500ms ease;
                    -moz-transition: all 500ms ease;
                    -ms-transition: all 500ms ease;
                    -o-transition: all 500ms ease;
                    transition: all 500ms ease;
                }
            }

            ul {
                li {
                    >a {
                        font-size: 16px;
                        margin-left: 20px;
                        text-transform: capitalize;
                    }
                }
            }
        }

        &:last-child {
            border-bottom: 1px solid rgba(255, 255, 255, 0.10);
        }

        li.current {
            >a {
                &:before {
                    height: 100%;
                }
            }
        }

        li.dropdown {
            .dropdown-btn {
                position: absolute;
                right: 6px;
                top: 6px;
                width: 32px;
                height: 32px;
                text-align: center;
                font-size: 16px;
                line-height: 32px;
                color: #ffffff;
                background: rgba(255, 255, 255, 0.10);
                cursor: pointer;
                border-radius: 2px;
                -webkit-transition: all 500ms ease;
                -moz-transition: all 500ms ease;
                -ms-transition: all 500ms ease;
                -o-transition: all 500ms ease;
                transition: all 500ms ease;
                z-index: 5;
            }

            .dropdown-btn.open {
                background: #fff;
                color: #3b3b3b;
                -webkit-transform: rotate(90deg);
                -ms-transform: rotate(90deg);
                transform: rotate(90deg);
            }
        }

        >li.dropdown {
            >.megamenu {
                display: none;
            }
        }
    }

    .social-links {
        position: relative;
        padding: 0px 25px;

        li {
            position: relative;
            display: inline-block;
            margin: 0px 10px 10px;

            a {
                position: relative;
                line-height: 32px;
                font-size: 16px;
                color: #ffffff;
                -webkit-transition: all 500ms ease;
                -moz-transition: all 500ms ease;
                -ms-transition: all 500ms ease;
                -o-transition: all 500ms ease;
                transition: all 500ms ease;
            }
        }
    }

    .contact-info {
        position: relative;
        padding: 120px 30px 20px 30px;

        h4 {
            position: relative;
            font-size: 20px;
            color: #ffffff;
            font-weight: 700;
            margin-bottom: 20px;
        }

        ul {
            li {
                position: relative;
                display: block;
                font-size: 15px;
                color: rgba(255, 255, 255, 0.80);
                margin-bottom: 3px;

                a {
                    color: rgba(255, 255, 255, 0.80);
                }

                &:last-child {
                    margin-bottom: 0px;
                }
            }
        }
    }
}

.mobile-menu-visible {
    overflow: hidden;

    .mobile-menu {
        opacity: 1;
        visibility: visible;

        .menu-backdrop {
            opacity: 0.70;
            visibility: visible;
            -webkit-transition: all 0.7s ease;
            -moz-transition: all 0.7s ease;
            -ms-transition: all 0.7s ease;
            -o-transition: all 0.7s ease;
            transition: all 0.7s ease;
            -webkit-transform: translateX(0%);
            -ms-transform: translateX(0%);
            transform: translateX(0%);
        }

        .menu-box {
            opacity: 1;
            visibility: visible;
            -webkit-transition: all 0.7s ease;
            -moz-transition: all 0.7s ease;
            -ms-transition: all 0.7s ease;
            -o-transition: all 0.7s ease;
            transition: all 0.7s ease;
            -webkit-transform: translateX(0%);
            -ms-transform: translateX(0%);
            transform: translateX(0%);
        }

        .close-btn {
            -webkit-transform: rotate(360deg);
            -ms-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }
}

div#mCSB_1_container {
    top: 0px !important;
}

.banner-section {
    position: relative;
    overflow: hidden;
    padding: 65px 0px 70px 0px;

    .content-box {
        h1 {
            display: block;
            font-size: 75px;
            line-height: 80px;
            margin-bottom: 31px;
            font-weight: 900;
        }

        p {
            font-size: 20px;
            line-height: 28px;
            color: #3C5A7D;
            margin-bottom: 43px;
        }

        form {
            .form-group {
                position: relative;
                margin-bottom: 21px;
                margin-right: 100px;

                input[type='text'] {
                    position: relative;
                    width: 100%;
                    height: 70px;
                    //background: #fff;
                    //border-radius: 40px;
                    padding: 0px 90px 10px 30px;
                    font-size: 16px;
                    color: #848484;
                    border: 1px solid #fff;
                    box-shadow: 0 10px 30px rgb(57 202 187 / 15%);
                    transition: all 500ms ease;
                }

                input {
                    &:focus {
                        border-color: #3C5A7D;

                        + {
                            button {
                                background: #3C5A7D;
                            }
                        }
                    }
                }

                button {
                    position: absolute;
                    top: 5px;
                    right: 5px;
                    width: 60px;
                    height: 60px;
                    line-height: 60px;
                    text-align: center;
                    //border-radius: 50%;
                    font-size: 18px;
                    color: #fff;
                    cursor: pointer;
                    transition: all 500ms ease;

                    &:hover {
                        background: #3C5A7D;
                    }
                }
            }
        }
    }

    .image-box {
        position: relative;
        display: block;
        margin-left: -40px;

        .image {
            img {
                max-width: none;
                float: left;
            }
        }
    }

    .pattern-layer {
        position: absolute;
        top: 0px;
        right: 0px;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-repeat: no-repeat;
    }
}

.select-box {
    li {
        position: relative;
        display: inline-block;
        float: left;
        margin-right: 24px;

        &:last-child {
            margin: 0px !important;
        }
    }

    .single-checkbox {
        position: relative;
        display: block;
    }

    label {
        position: relative;
        display: block;
        padding-left: 20px;
        margin-right: 0px;
        margin-bottom: 0;
        color: #3C5A7D;
        font-size: 17px;
        line-height: 30px;
        font-weight: 500;
        cursor: pointer;

        span {
            &:before {
                position: absolute;
                content: '';
                width: 10px;
                height: 10px;
                left: -2px;
                top: -2px;
                border-radius: 50%;
                opacity: 0;
                transform: scale(0, 0);
                transition: all 500ms ease;
            }
        }
    }

    input[type="radio"] {
        display: none;

        + {
            label {
                span {
                    position: absolute;
                    top: 10px;
                    left: 0;
                    width: 10px;
                    height: 10px;
                    vertical-align: middle;
                    background-color: transparent;
                    border: 2px solid #3C5A7D;
                    border-radius: 50%;
                    cursor: pointer;
                    transition: all 300ms ease;
                }
            }
        }

        &:checked {
            + {
                label {
                    span {
                        &:before {
                            opacity: 1;
                            transform: scale(1, 1);
                        }
                    }
                }
            }
        }
    }
}

.owl-nav-none {
    .owl-nav {
        display: none !important;
    }
}

.owl-dots-none {
    .owl-dots {
        display: none !important;
    }
}

.clients-section {
    position: relative;
    padding: 95px 0px;

    .clients-logo-box {
        position: relative;
        display: block;

        img {
            display: block;
            max-width: 100%;
            width: auto;
            margin: 0 auto;
            filter: grayscale(100%);
            -webkit-filter: grayscale(100%);
            -moz-filter: grayscale(100%);
            -o-filter: grayscale(100%);
            -ms-filter: grayscale(100%);
            -webkit-transition: all 500ms ease;
            -moz-transition: all 500ms ease;
            -ms-transition: all 500ms ease;
            -o-transition: all 500ms ease;
            transition: all 500ms ease;

            &:hover {
                filter: grayscale(0%);
                -webkit-filter: grayscale(0%);
                -moz-filter: grayscale(0%);
                -o-filter: grayscale(0%);
            }
        }
    }

    .pattern-layer {
        .pattern-1 {
            position: absolute;
            left: 0px;
            top: 0px;
            width: 382px;
            height: 234px;
            background-repeat: no-repeat;
        }

        .pattern-2 {
            position: absolute;
            right: 0px;
            top: 0px;
            width: 405px;
            height: 211px;
            background-repeat: no-repeat;
        }
    }
}

.feature-section {
    position: relative;
    padding: 120px 0px;

    .pattern-layer {
        .pattern-1 {
            position: absolute;
            left: 80px;
            bottom: 0px;
            width: 299px;
            height: 142px;
            background-repeat: no-repeat;
        }

        .pattern-2 {
            position: absolute;
            right: 80px;
            top: 0px;
            width: 299px;
            height: 142px;
            background-repeat: no-repeat;
        }
    }
}

.feature-block-one {
    .inner-box {
        position: relative;
        display: block;
        background: #fff;
        padding: 30px 30px 45px 30px;
        border-radius: 10px;
        overflow: hidden;
        //box-shadow: 0 10px 30px #eaf2f1;
        transition: all 500ms ease;

        &:hover {
            box-shadow: 0 20px 30px #cee4e2;

            .link {
                a {
                    transform: scale(0, 0);
                }
            }

            .btn-box {
                .theme-btn-one {
                    transform: scale(1, 1);
                }
            }

            .pattern {
                .pattern-1 {
                    left: 0px;
                    opacity: 1;
                }

                .pattern-2 {
                    opacity: 1;
                }
            }
        }

        .icon-box {
            position: relative;
            min-height: 100px;
            margin-bottom: 5px;

            img {
                display: inline-block;
            }
        }

        p {
            color: #3C5A7D;
            margin-bottom: 0px;
        }

        h3 {
            font-size: 24px;
            line-height: 26px;
            font-weight: 900;
            margin-bottom: 25px;
        }

        .link {
            a {
                position: relative;
                display: inline-block;
                width: 50px;
                height: 50px;
                line-height: 54px;
                text-align: center;
                font-size: 16px;
                background: #e6f5f3;
                border-radius: 50%;
                transform: scale(1, 1);
            }
        }

        .btn-box {
            position: absolute;
            left: 0px;
            bottom: 45px;
            width: 100%;
            text-align: center;

            .theme-btn-one {
                padding: 12px 30px;
                font-size: 15px;
                font-weight: 600;
                transform: scale(0, 0);
            }
        }

        .pattern {
            .pattern-1 {
                position: absolute;
                left: -30px;
                top: 0px;
                width: 229px;
                height: 103px;
                background-repeat: no-repeat;
                opacity: 0;
                transition: all 500ms ease;
            }

            .pattern-2 {
                position: absolute;
                right: 0px;
                bottom: 0px;
                width: 149px;
                height: 87px;
                opacity: 0;
                background-repeat: no-repeat;
                transition: all 500ms ease;
            }
        }
    }
}

.about-section {
    position: relative;
    padding: 110px 0px 120px 0px;

    .content_block_1 {
        .content-box {
            margin-left: 50px;
        }
    }
}

.image_block_1 {
    .image-box {
        position: relative;
        display: block;
        max-width: 570px;

        .image {
            img {
                position: relative;
                max-width: none;
                float: right;
            }
        }

        &:before {
            position: absolute;
            content: '';
            background: #b7e9e1;
            width: 400px;
            height: 400px;
            border-radius: 50%;
            left: 5px;
            top: 150px;
        }

        .pattern {
            .pattern-1 {
                position: absolute;
                right: 120px;
                top: 105px;
                width: 560px;
                height: 491px;
                z-index: -1;
                background-repeat: no-repeat;
            }

            .pattern-2 {
                position: absolute;
                left: 0px;
                top: 170px;
                width: 80px;
                height: 80px;
                border-radius: 50%;
                opacity: 0.7;
                -webkit-animation: zoom-fade 3s infinite linear;
                animation: zoom-fade 3s infinite linear;
            }

            .pattern-3 {
                position: absolute;
                top: 167px;
                right: 168px;
                width: 124px;
                height: 144px;
                z-index: 1;
                background-repeat: no-repeat;
            }
        }
    }
}

.content_block_1 {
    .content-box {
        .text {
            margin-bottom: 24px;
        }

        .list-style-one {
            margin-bottom: 34px;
        }

        .sec-title {
            margin-bottom: 24px;
        }
    }
}

.list-style-one {
    position: relative;
    display: block;

    li {
        position: relative;
        display: block;
        font-size: 18px;
        line-height: 26px;
        font-family: 'Jost', sans-serif;
        color: #3C5A7D;
        font-weight: 500;
        margin-bottom: 8px;
        padding-left: 30px;

        &:last-child {
            margin-bottom: 0px;
        }

        &:before {
            position: absolute;
            content: '';
            width: 14px;
            height: 14px;
            left: 0px;
            top: 6px;
            border-radius: 50%;
        }
    }
}

.process-section {
    position: relative;
    padding: 115px 0px 120px 0px;

    .sec-title {
        margin-bottom: 50px;
    }

    .inner-content {
        position: relative;
        background: #fff;
        border-radius: 10px;
        overflow: hidden;
    }

    .pattern-layer {
        .pattern-1 {
            position: absolute;
            left: 70px;
            bottom: 0px;
            width: 299px;
            height: 142px;
            background-repeat: no-repeat;
        }

        .pattern-2 {
            position: absolute;
            left: 150px;
            top: 110px;
            width: 460px;
            height: 387px;
            background-repeat: no-repeat;
        }

        .pattern-3 {
            position: absolute;
            right: 70px;
            top: 0px;
            width: 382px;
            height: 285px;
            background-repeat: no-repeat;
        }

        .pattern-4 {
            position: absolute;
            right: 135px;
            bottom: 30px;
            width: 460px;
            height: 387px;
            background-repeat: no-repeat;
        }
    }
}

.processing-block-one {
    .inner-box {
        position: relative;
        display: block;
        padding: 47px 40px 46px 40px;

        &:after {
            position: absolute;
            content: '';
            width: 145px;
            height: 6px;
            bottom: 0px;
            right: 40px;
            transition: all 500ms ease;
        }

        &:hover {
            &:after {
                width: 100%;
                right: 0px;
            }
        }

        &:before {
            position: absolute;
            content: '';
            background: #e4f2f1;
            width: 1px;
            height: 100%;
            top: 0px;
            right: -15px;
        }

        .icon-box {
            position: relative;
            display: inline-block;
            min-height: 97px;
            margin-bottom: 10px;

            span {
                position: absolute;
                top: -2px;
                right: -35px;
                font-size: 24px;
                font-family: 'Jost', sans-serif;
                color: #fff;
                background: #3C5A7D;
                width: 50px;
                height: 50px;
                line-height: 50px;
                text-align: center;
                border-radius: 50%;
                box-shadow: 0 10px 20px rgba(0, 24, 73, 0.3);
            }
        }

        h3 {
            position: relative;
            display: block;
            font-size: 26px;
            line-height: 32px;
            font-weight: 700;
            margin-bottom: 22px;

            a {
                display: inline-block;
                color: #3C5A7D;
            }
        }
    }
}

.processing-block {
    &:last-child {
        .processing-block-one {
            .inner-box {
                &:before {
                    display: none;
                }
            }
        }
    }
}

.testimonial-section {
    position: relative;
    padding: 120px 0px;

    .owl-stage-outer {
        overflow: visible;
    }

    .owl-item {
        opacity: 0;
        transition: all 500ms ease;
    }

    .owl-item.active {
        opacity: 1;
    }

    .bg-layer {
        position: absolute;
        content: '';
        width: 50%;
        height: 100%;
        top: 0px;
        right: 0px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }

    .testimonial-inner {
        position: relative;
        background: #fff;
        padding: 63px 150px 70px 70px;
        border-radius: 10px;
        overflow: hidden;
        box-shadow: 0 0px 30px rgba(0, 24, 73, 0.1);

        .pattern {
            position: absolute;
            right: 0px;
            bottom: 0px;
            width: 202px;
            height: 250px;
            background-repeat: no-repeat;
        }
    }

    .nav-style-one {
        .owl-prev {
            display: block;
            margin-bottom: 10px;
        }

        .owl-next {
            display: block;
        }
    }

    .owl-nav {
        position: absolute;
        right: -90px;
        bottom: -25px;
    }

    .pattern-layer {
        .pattern-1 {
            position: absolute;
            left: 0px;
            top: 0px;
            width: 239px;
            height: 376px;
            background-repeat: no-repeat;
        }

        .pattern-2 {
            position: absolute;
            left: 280px;
            bottom: 150px;
            width: 174px;
            height: 174px;
            background-repeat: no-repeat;
        }

        .pattern-3 {
            position: absolute;
            left: 0px;
            top: 0px;
            right: 0px;
            width: 100%;
            height: 100%;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
        }
    }

    .image-layer {
        position: absolute;
        right: 290px;
        bottom: 0px;
    }
}

.testimonial-block-one {
    .inner-box {
        position: relative;
        display: block;

        .text {
            position: relative;
            margin-bottom: 30px;

            p {
                font-size: 18px;
                line-height: 30px;
                color: #3C5A7D;
            }
        }

        .author-info {
            position: relative;
            display: block;
            padding: 10px 0px 6px 90px;

            .author-thumb {
                position: absolute;
                left: 0px;
                top: 0px;
                width: 70px;
                height: 70px;
                border-radius: 50%;
                box-shadow: 0 10px 20px rgba(0, 24, 73, 0.1);

                img {
                    width: 100%;
                    border-radius: 50%;
                }
            }

            h3 {
                position: relative;
                font-size: 22px;
                line-height: 26px;
                font-weight: 700;
                margin-bottom: 0px;
            }

            .designaion {
                position: relative;
                display: block;
                font-size: 16px;
                line-height: 24px;
            }
        }
    }
}

.nav-style-one {
    .owl-prev {
        position: relative;
        display: inline-block;
        width: 60px;
        height: 60px;
        line-height: 60px;
        font-size: 20px;
        text-align: center;
        border-radius: 50%;
        background: #fff;
        color: #3C5A7D;
        box-shadow: 0 0px 50px #d5edea;
        cursor: pointer;
        transition: all 500ms ease;

        &:hover {
            color: #fff;
        }
    }

    .owl-next {
        position: relative;
        display: inline-block;
        width: 60px;
        height: 60px;
        line-height: 60px;
        font-size: 20px;
        text-align: center;
        border-radius: 50%;
        background: #fff;
        color: #3C5A7D;
        box-shadow: 0 0px 50px #d5edea;
        cursor: pointer;
        transition: all 500ms ease;

        &:hover {
            color: #fff;
        }
    }
}

.bg-color-3 {
    background: #e6faf5;
}

.bg-color-4 {
    background: #3C5A7D;
}


.team-section {
    position: relative;
    padding: 115px 0px 120px 0px;

    .more-btn {
        position: relative;
        margin-top: 30px;
    }

    .sec-title {
        margin-bottom: 50px;
    }
}

.team-block-one {
    .inner-box {
        position: relative;
        display: block;
        overflow: hidden;
        background: #fff;
        border-radius: 10px;
        padding: 35px 40px 31px 170px;
        margin-bottom: 30px;
        box-shadow: 0 20px 50px rgba(0, 24, 73, 0.07);

        .image-box {
            position: absolute;
            left: 40px;
            top: 40px;
            width: 100px;
            height: 100px;
            border-radius: 20px;
            box-shadow: 0 10px 40px rgba(0, 24, 73, 0.2);

            img {
                width: 100%;
                border-radius: 20px;
            }
        }

        .content-box {
            .like-box {
                position: absolute;
                top: 20px;
                right: 20px;

                a {
                    position: relative;
                    display: inline-block;
                    font-size: 15px;
                    width: 40px;
                    height: 40px;
                    line-height: 40px;
                    text-align: center;
                    background: #eaf8f6;
                    border-radius: 50%;
                    z-index: 1;

                    &:hover {
                        color: #fff;
                    }
                }
            }

            .name-box {
                li {
                    position: relative;
                    display: inline-block;
                    margin-right: 6px;
                    font-size: 20px;

                    &:nth-child(2) {
                        top: 2px;
                    }

                    &:last-child {
                        top: 2px;
                        margin: 0px !important;
                    }

                    h3 {
                        position: relative;
                        display: block;
                        font-size: 22px;
                        line-height: 26px;
                        font-weight: 700;

                        a {
                            display: inline-block;
                            color: #3C5A7D;
                        }
                    }
                }
            }

            .designation {
                position: relative;
                display: block;
                font-size: 15px;
                line-height: 24px;
                margin-bottom: 8px;
            }

            .text {
                position: relative;
                margin-bottom: 9px;

                p {
                    line-height: 26px;
                }
            }

            .rating {
                li {
                    position: relative;
                    display: inline-block;
                    float: left;
                    font-size: 14px;
                    margin-right: 3px;

                    &:last-child {
                        margin-right: 0px !important;
                        margin-left: 5px !important;
                    }

                    a {
                        display: inline-block;
                        font-size: 15px;
                        position: relative;
                        color: #808080;
                    }
                }

                position: relative;
                display: inline-block;
                float: left;
            }

            .link {
                position: relative;
                display: inline-block;
                float: left;
                padding-left: 15px;
                margin-left: 15px;

                a {
                    font-size: 15px;

                    &:hover {
                        color: #3C5A7D !important;
                    }
                }

                &:before {
                    position: absolute;
                    content: '';
                    background: #e5e5e5;
                    width: 1px;
                    height: 18px;
                    left: 0px;
                    top: 4px;
                }
            }

            .rating-box {
                position: relative;
                margin-bottom: 5px;
            }

            .location-box {
                position: relative;
                display: block;

                p {
                    font-size: 15px;
                    color: #808080;

                    i {
                        margin-right: 8px;
                    }
                }
            }
        }

        .pattern {
            .pattern-1 {
                position: absolute;
                left: -30px;
                top: 0px;
                width: 254px;
                height: 115px;
                background-repeat: no-repeat;
                opacity: 0;
                transition: all 500ms ease;
            }

            .pattern-2 {
                position: absolute;
                right: 0px;
                bottom: 0px;
                width: 208px;
                height: 104px;
                background-repeat: no-repeat;
                opacity: 0;
                transition: all 500ms ease;
            }
        }

        &:hover {
            .pattern {
                .pattern-1 {
                    left: 0px;
                    opacity: 1;
                }

                .pattern-2 {
                    opacity: 1;
                }
            }
        }
    }
}

.search-doctors {
    position: relative;
    padding: 115px 0px 120px 0px;

    .pattern-layer {
        .pattern-1 {
            position: absolute;
            right: 70px;
            top: 0px;
            width: 348px;
            height: 227px;
            background-repeat: no-repeat;
        }

        .pattern-2 {
            position: absolute;
            left: 190px;
            top: 120px;
            width: 460px;
            height: 387px;
            background-repeat: no-repeat;
        }

        .pattern-3 {
            position: absolute;
            right: 115px;
            bottom: 60px;
            width: 460px;
            height: 387px;
            background-repeat: no-repeat;
        }
    }
}

.doctors-block-one {
    .inner-box {
        position: relative;
        display: block;
        background: #fff;
        padding: 60px 40px;
        border-radius: 10px;

        h3 {
            position: relative;
            display: block;
            font-size: 26px;
            line-height: 32px;
            font-weight: 700;
            margin-bottom: 27px;
        }

        .doctors-list {
            li {
                position: relative;
                display: block;
                font-size: 16px;
                line-height: 26px;
                color: #3C5A7D;
                margin-bottom: 10px;

                &:last-child {
                    margin-bottom: 0px;
                }

                a {
                    display: inline-block;
                    color: #3C5A7D;
                }
            }

            position: relative;
            margin-bottom: 32px;
        }

        .btn-box {
            position: absolute;
            left: 40px;
            bottom: 60px;
            transform: scale(0, 0);
            z-index: 1;
            transition: all 500ms ease;

            .theme-btn-one {
                padding: 12px 30px;
            }
        }

        &:hover {
            .btn-box {
                transform: scale(1, 1);
            }

            .pattern {
                opacity: 1;
            }

            .link {
                a {
                    opacity: 0;
                }
            }
        }

        .pattern {
            position: absolute;
            right: 0px;
            bottom: 0px;
            width: 193px;
            height: 99px;
            background-repeat: no-repeat;
            opacity: 0;
            transition: all 500ms ease;
        }

        .link {
            a {
                position: relative;
                display: inline-block;
                width: 50px;
                height: 50px;
                line-height: 54px;
                text-align: center;
                font-size: 16px;
                background: #e6f5f3;
                border-radius: 50%;
                opacity: 1;
                transition: all 500ms ease;
            }
        }
    }
}

.cta-section {
    position: relative;
    padding-top: 80px;

    .image-box {
        position: relative;
        z-index: 1;

        .image {
            position: relative;
            margin-bottom: -95px;
        }
    }

    .content_block_2 {
        .content-box {
            position: relative;
            padding-bottom: 120px;
            margin-top: 35px;
        }
    }

    .pattern-layer {
        .pattern-1 {
            position: absolute;
            left: 70px;
            bottom: 0px;
            width: 299px;
            height: 142px;
            background-repeat: no-repeat;
        }

        .pattern-2 {
            position: absolute;
            left: 240px;
            top: 15px;
            width: 460px;
            height: 387px;
            background-repeat: no-repeat;
        }

        .pattern-3 {
            position: absolute;
            right: 70px;
            top: 0px;
            width: 349px;
            height: 228px;
            background-repeat: no-repeat;
        }
    }
}

.content_block_2 {
    .content-box {
        .text {
            position: relative;
            margin-bottom: 40px;

            p {
                color: #3C5A7D;
            }
        }

        .btn-box {
            .download-btn {
                position: relative;
                display: inline-block;
                float: left;
                font-size: 15px;
                line-height: 26px;
                font-weight: 600;
                color: #fff;
                padding: 16px 50px 15px 90px;
                border-radius: 40px;
                z-index: 1;
                box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);

                i {
                    position: absolute;
                    left: 37px;
                    top: 16px;
                    font-size: 45px;
                }

                span {
                    position: relative;
                    display: block;
                    font-size: 14px;
                    line-height: 18px;
                    font-family: 'Jost', sans-serif;
                    font-weight: 500;
                }

                h3 {
                    position: relative;
                    display: block;
                    font-size: 24px;
                    line-height: 26px;
                    color: #fff;
                    font-weight: 600;
                    transition: all 500ms ease;
                }

                &:hover {
                    background: #3C5A7D !important;

                    h3 {
                        color: #fff;
                    }
                }
            }

            .download-btn.app-store {
                margin-right: 30px;
            }
        }

        .sec-title {
            margin-bottom: 32px;
        }
    }
}

.news-section {
    position: relative;
    padding: 165px 0px 120px 0px;
}

.news-block-one {
    .inner-box {
        position: relative;
        display: block;
        overflow: hidden;
        border-radius: 10px;
        box-shadow: 0 10px 30px rgba(0, 24, 73, 0.1);

        .image-box {
            position: relative;
            display: block;
            overflow: hidden;

            &:before {
                position: absolute;
                content: '';
                width: 100%;
                height: 0%;
                left: 0px;
                top: 0px;
                opacity: 0.9;
                z-index: 1;
                transition: all 500ms ease;
            }

            img {
                width: 100%;
                transition: all 500ms ease;
            }

            .link {
                position: absolute;
                left: 0px;
                top: 0px;
                width: 100%;
                height: 100%;
                font-size: 0px;
                color: #fff;
                z-index: 2;
            }

            .category {
                position: absolute;
                left: 30px;
                bottom: 20px;
                font-size: 13px;
                line-height: 26px;
                font-weight: 500;
                color: #fff;
                padding: 0px 15px;
                border-radius: 3px;
                z-index: 2;
                text-transform: uppercase;
            }
        }

        &:hover {
            .image-box {
                &:before {
                    height: 100%;
                }

                img {
                    transform: scale(1.1);
                }
            }

            .lower-content {
                .link {
                    a {
                        opacity: 0;
                    }
                }

                .btn-box {
                    transform: scale(1, 1);
                }
            }
        }

        .lower-content {
            position: relative;
            padding: 25px 30px 35px 30px;

            h3 {
                position: relative;
                display: block;
                font-size: 26px;
                line-height: 30px;
                font-weight: 700;
                margin-bottom: 20px;

                a {
                    display: inline-block;
                    color: #3C5A7D;
                }
            }

            .post-info {
                li {
                    position: relative;
                    display: inline-block;
                    font-size: 15px;
                    margin-right: 25px;

                    &:last-child {
                        margin: 0px !important;

                        &:before {
                            display: none;
                        }
                    }

                    a {
                        font-weight: 600;
                        color: #2d2929;
                    }

                    img {
                        margin-right: 10px;
                    }

                    &:before {
                        position: absolute;
                        content: '';
                        background: #e5e7ec;
                        width: 1px;
                        height: 18px;
                        top: 10px;
                        right: -13px;
                    }
                }

                position: relative;
                margin-bottom: 21px;
            }

            .link {
                a {
                    position: relative;
                    display: inline-block;
                    width: 50px;
                    height: 50px;
                    line-height: 54px;
                    text-align: center;
                    font-size: 16px;
                    background: #e6f5f3;
                    border-radius: 50%;
                    opacity: 1;
                    transition: all 500ms ease;
                }
            }

            .btn-box {
                position: absolute;
                left: 30px;
                bottom: 35px;
                transform: scale(0, 0);
                transition: all 500ms ease;

                .theme-btn-one {
                    padding: 12px 28px;
                }
            }

            p {
                margin-bottom: 20px;
            }
        }
    }
}

.agent-section {
    position: relative;

    .inner-container {
        position: relative;
        padding: 50px 50px;
        border-radius: 10px;
        margin-bottom: -140px;
        z-index: 1;
    }
}

.content_block_3 {
    .content-box {
        position: relative;
        display: block;
        border: 1px dashed rgba(255, 255, 255, 0.3);
        border-radius: 10px;
        padding: 27px 40px 36px 40px;

        h3 {
            display: block;
            font-size: 30px;
            line-height: 38px;
            font-weight: 900;
            color: #3C5A7D;
            margin-bottom: 14px;
        }

        .support-box {
            position: relative;
            padding-left: 65px;

            .icon-box {
                position: absolute;
                left: 0px;
                top: 12px;
                font-size: 26px;
                line-height: 30px;

                &:before {
                    position: absolute;
                    content: '';
                    height: 44px;
                    width: 36px;
                    top: -5px;
                    left: 0px;
                    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%, 60% 0%);
                    opacity: 0.1;
                }
            }

            span {
                font-size: 15px;
                line-height: 26px;
                font-weight: 500;
                color: #fff;
                margin-bottom: 0px;
            }

            h3 {
                display: block;
                font-size: 24px;
                line-height: 32px;
                font-family: 'Poppins', sans-serif;
                font-weight: 700;
                margin-bottom: 0px;

                a {
                    display: inline-block;

                    &:hover {
                        color: #fff;
                    }
                }
            }
        }

        &:before {
            position: absolute;
            content: '';
            left: 40px;
            top: -1px;
            width: 145px;
            height: 5px;
        }
    }
}

.content_block_4 {
    .content-box {
        position: relative;
        display: block;
        border: 1px dashed rgba(255, 255, 255, 0.3);
        border-radius: 10px;
        padding: 29px 40px 37px 40px;

        h3 {
            display: block;
            font-size: 30px;
            line-height: 38px;
            font-weight: 900;
            color: #fff;
            margin-bottom: 21px;
        }

        .subscribe-form {
            .form-group {
                position: relative;
                margin: 0px;
                padding-right: 175px;
                border-radius: 30px;
                overflow: hidden;

                input[type='email'] {
                    position: relative;
                    display: block;
                    width: 100%;
                    height: 50px;
                    border: 1px solid rgba(255, 255, 255, 0.1);
                    background: rgba(255, 255, 255, 0.1);
                    font-size: 15px;
                    color: #fff;
                    padding: 10px 20px;
                    border-radius: 30px 0px 0px 30px;
                    transition: all 500ms ease;
                }

                button {
                    position: absolute;
                    top: 0px;
                    right: 0px;
                    border-radius: 0px;
                    padding: 12px 30px;
                    box-shadow: none;
                    transition: all 500ms ease;

                    &:before {
                        border-radius: 0px;
                        background: #fff;
                    }
                }

                input {
                    &::-webkit-input-placeholder {
                        color: #fff;
                    }
                }
            }
        }

        &:before {
            position: absolute;
            content: '';
            left: 40px;
            top: -1px;
            width: 145px;
            height: 5px;
        }
    }
}

.main-footer {
    position: relative;
}

.footer-top {
    position: relative;
    background: #1a2332;
    padding: 245px 0px 90px 0px;

    .pattern-layer {
        .pattern-1 {
            position: absolute;
            left: 0px;
            top: 0px;
            width: 363px;
            height: 376px;
            background-repeat: no-repeat;
        }

        .pattern-2 {
            position: absolute;
            right: 0px;
            top: 0px;
            width: 470px;
            height: 365px;
            background-repeat: no-repeat;
        }
    }

    .logo-widget {
        .footer-logo {
            position: relative;
            max-width: 194px;
            width: 100%;
            margin-bottom: 24px;

            img {
                width: 100%;
            }
        }

        .text {
            p {
                color: #fff;
                margin: 0px;
            }
        }

        margin-right: 50px;
        margin-top: -6px;
    }

    .widget-title {
        position: relative;
        display: block;
        margin-bottom: 35px;

        h3 {
            font-size: 26px;
            line-height: 34px;
            color: #fff;
            font-weight: 700;
        }
    }

    .links-widget {
        .links {
            li {
                position: relative;
                display: block;
                margin-bottom: 10px;

                &:last-child {
                    margin-bottom: 0px;
                }

                a {
                    position: relative;
                    display: inline-block;
                    font-size: 15px;
                    line-height: 22px;
                    color: #fff;

                    &:before {
                        position: absolute;
                        content: '';
                        width: 0%;
                        height: 1px;
                        left: 0px;
                        bottom: 0px;
                        transition: all 500ms ease;
                    }

                    &:hover {
                        &:before {
                            width: 100%;
                        }
                    }
                }
            }
        }

        margin-left: 30px;
    }

    .contact-widget {
        .widget-content {
            li {
                position: relative;
                display: block;
                font-size: 15px;
                line-height: 26px;
                color: #fff;
                margin-bottom: 20px;
                padding-left: 36px;

                a {
                    color: #fff;
                }

                &:last-child {
                    margin-bottom: 0px;
                }

                i {
                    position: absolute;
                    left: 0px;
                    top: 2px;
                    font-size: 20px;
                }

                &:first-child {
                    i {
                        top: 7px;
                    }
                }
            }
        }
    }
}

.footer-bottom {
    position: relative;
    width: 100%;
    background: #272f3e;
    padding: 36px 0px;

    .copyright {
        p {
            color: #fff;

            a {
                color: #fff;
            }
        }
    }

    .footer-nav {
        li {
            position: relative;
            display: inline-block;
            color: #fff;
            margin-right: 25px;

            &:last-child {
                margin: 0px !important;

                &:before {
                    display: none;
                }
            }

            a {
                color: #fff;
            }

            &:before {
                position: absolute;
                content: '';
                background: #fff;
                width: 1px;
                height: 13px;
                top: 8px;
                right: -15px;
            }
        }
    }
}

.main-header.style-two {
    .header-top {
        background: #1a2332;
        padding: 11px 0px;

        .info {
            li {
                a {
                    color: #fff;
                }
            }

            li.language {
                &:after {
                    color: #fff;
                }
            }
        }

        .top-left {
            li {
                position: relative;
                display: inline-block;
                margin: 0px 40px 0px 0px;
                font-size: 15px;
                color: #fff;
                padding-left: 22px;

                &:last-child {
                    margin: 0px !important;

                    &:before {
                        display: none;
                    }
                }

                a {
                    color: #fff;
                }

                i {
                    position: absolute;
                    left: 0px;
                    top: 6px;
                    font-size: 15px;
                }

                &:before {
                    position: absolute;
                    content: '';
                    background: rgba(255, 255, 255, 0.2);
                    width: 1px;
                    height: 16px;
                    top: 5px;
                    right: -22px;
                }
            }
        }
    }

    .logo-box {
        padding: 30px 0px;
    }

    .main-menu {
        .navigation {
            >li {
                padding: 40px 0px;
            }
        }
    }

    box-shadow: 0 10px 30px rgb(57 202 187 / 10%);
}

.banner-section.style-two {
    position: relative;
    padding: 110px 0px 245px 0px;

    .content-box {
        h1 {
            margin-bottom: 21px;
        }

        p {
            margin-bottom: 33px;
        }
    }

    .bg-layer {
        position: absolute;
        top: 0px;
        right: 0px;
        width: 50%;
        height: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 10% 100%, 0% 0%);
    }

    .pattern {
        .pattern-1 {
            position: absolute;
            left: 40px;
            top: 0px;
            width: 366px;
            height: 257px;
            background-repeat: no-repeat;
        }

        .pattern-2 {
            position: absolute;
            left: 0px;
            top: 0px;
            width: 296px;
            height: 458px;
            background-repeat: no-repeat;
        }

        .pattern-3 {
            position: absolute;
            left: 40px;
            bottom: 0px;
            width: 295px;
            height: 140px;
            background-repeat: no-repeat;
        }

        .pattern-4 {
            position: absolute;
            top: 0px;
            right: 0px;
            width: 337px;
            height: 100%;
            background-repeat: no-repeat;
        }
    }
}

.search-doctors-two {
    .doctors-block-one {
        .inner-box {
            overflow: hidden;
            box-shadow: 0 10px 30px rgba(0, 24, 73, 0.1);

            .pattern-2 {
                position: absolute;
                top: 0px;
                right: 0px;
                width: 144px;
                height: 161px;
                background-repeat: no-repeat;
            }
        }
    }

    .inner-container {
        position: relative;
        margin-top: -140px;
        z-index: 1;
    }
}

.about-style-two {
    position: relative;
    padding: 60px 0px;

    .image_block_2 {
        .image-box {
            margin-right: 40px;
        }
    }

    .image_block_3 {
        .image-box {
            margin-left: 40px;
        }
    }
}

.image_block_2 {
    .image-box {
        position: relative;
        padding: 0px 100px 95px 60px;

        .image {
            border-radius: 10px;
            box-shadow: 0 10px 40px rgba(0, 24, 73, 0.4);

            img {
                width: 100%;
                border-radius: 10px;
            }
        }

        .image-2 {
            position: absolute;
            left: 0px;
            bottom: 0px;
        }

        .image-content {
            position: absolute;
            top: 105px;
            right: 0px;
            background: #fff;
            max-width: 240px;
            width: 100%;
            border-radius: 10px;
            padding: 32px 20px 32px 90px;
            box-shadow: 17px 20px 80px rgba(0, 24, 73, 0.30);

            .icon-box {
                position: absolute;
                left: 23px;
                top: 23px;
            }

            span {
                position: relative;
                display: block;
                font-size: 14px;
                line-height: 20px;
                color: #3C5A7D;
            }

            h4 {
                position: relative;
                display: block;
                font-size: 20px;
                line-height: 26px;
                font-weight: 900;
            }
        }

        .pattern {
            .pattern-1 {
                position: absolute;
                left: 0px;
                top: 35px;
                width: 490px;
                height: 506px;
                background-repeat: no-repeat;
                z-index: -1;
            }

            .pattern-2 {
                position: absolute;
                left: 60px;
                top: 0px;
                width: 123px;
                height: 144px;
                z-index: 1;
                background-repeat: no-repeat;
            }

            .pattern-3 {
                position: absolute;
                left: 0px;
                top: 50px;
                width: 90px;
                height: 90px;
                border-radius: 50%;
                opacity: 0.8;
                z-index: 1;
                transition: all 500ms ease;
                -webkit-animation: zoom-fade 3s infinite linear;
                animation: zoom-fade 3s infinite linear;
            }
        }
    }
}

.process-style-two {
    position: relative;
    padding: 115px 0px 120px 0px;

    .inner-content {
        position: relative;

        .arrow {
            position: absolute;
            left: 270px;
            top: 65px;
            width: 650px;
            height: 51px;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
        }
    }

    .pattern-layer {
        .pattern-1 {
            position: absolute;
            left: 150px;
            top: 110px;
            width: 460px;
            height: 387px;
            background-repeat: no-repeat;
        }

        .pattern-2 {
            position: absolute;
            left: 70px;
            bottom: 0px;
            width: 299px;
            height: 142px;
            background-repeat: no-repeat;
        }

        .pattern-3 {
            position: absolute;
            right: 70px;
            top: 0px;
            width: 382px;
            height: 285px;
            background-repeat: no-repeat;
        }

        .pattern-4 {
            position: absolute;
            right: 140px;
            bottom: 30px;
            width: 460px;
            height: 387px;
            background-repeat: no-repeat;
        }

        .pattern-5 {
            position: absolute;
            left: 0px;
            bottom: 0px;
            width: 100%;
            height: 860px;
            background-repeat: no-repeat;
            background-size: cover;
            z-index: -1;
        }
    }
}

.processing-block-two {
    .inner-box {
        position: relative;
        display: block;
        padding: 0px 50px;

        .icon-box {
            position: relative;
            display: inline-block;
            width: 200px;
            height: 200px;
            background: #fff;
            border-radius: 70px;
            line-height: 200px;
            text-align: center;
            margin-bottom: 33px;
            box-shadow: 0px 20px 40px rgba(0, 24, 73, 0.10);

            img {
                display: inline-block;
            }
        }

        h3 {
            position: relative;
            display: block;
            font-size: 22px;
            line-height: 30px;
            font-weight: 900;

            a {
                display: inline-block;
                color: #012550;
            }
        }
    }
}

.team-style-two {
    position: relative;
    padding: 110px 0px 120px 0px;

    .more-btn {
        margin-top: 30px;
    }
}

.team-block-two {
    .inner-box {
        position: relative;
        display: block;
        border-radius: 10px;
        overflow: hidden;
        background: #fff;
        box-shadow: 0 10px 30px rgba(0, 24, 73, 0.1);
        margin-bottom: 30px;

        .image-box {
            position: relative;
            display: block;
            overflow: hidden;

            a {
                position: absolute;
                top: 15px;
                right: 15px;
                width: 40px;
                height: 40px;
                line-height: 40px;
                background: #eaf8f6;
                text-align: center;
                border-radius: 50%;
                font-size: 15px;
                z-index: 2;

                &:hover {
                    color: #fff;
                    background: #3C5A7D;
                }
            }

            img {
                width: 100%;
                transition: all 500ms ease;
            }
        }

        &:hover {
            .image-box {
                img {
                    transform: scale(1.1);
                }
            }

            .pattern {
                opacity: 1;
            }
        }

        .lower-content {
            position: relative;
            padding: 23px 30px 37px 30px;

            h3 {
                position: relative;
                display: block;
                font-size: 22px;
                line-height: 30px;
                font-weight: 700;
                margin-bottom: 0px;

                a {
                    display: inline-block;
                    color: #3C5A7D;
                }
            }

            .designation {
                position: relative;
                display: block;
                font-size: 15px;
                margin-bottom: 5px;
            }

            .rating {
                li {
                    position: relative;
                    display: inline-block;
                    float: left;
                    font-size: 14px;
                    margin-right: 3px;

                    &:last-child {
                        margin-right: 0px;
                    }

                    a {
                        display: inline-block;
                        font-size: 15px;
                        position: relative;
                        color: #808080;
                        margin-left: 6px;
                    }
                }

                position: relative;
                margin-bottom: 9px;
            }

            .location-box {
                p {
                    position: relative;
                    padding-left: 20px;
                    font-size: 15px;
                    line-height: 20px;

                    i {
                        position: absolute;
                        left: 0px;
                        top: 2px;
                        color: #c7c7c7;
                    }
                }
            }
        }

        .pattern {
            position: absolute;
            right: 0px;
            bottom: 0px;
            width: 153px;
            height: 82px;
            background-repeat: no-repeat;
            opacity: 0;
            transition: all 500ms ease;
        }
    }
}

.banner-section.style-three {
    position: relative;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 115px 0px 315px 0px;

    &:before {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        left: 0px;
        top: 0px;
        opacity: 0.95;
    }

    .pattern-layer {
        position: absolute;
        right: 0px;
        bottom: 0px;
        top: inherit;
        width: 100%;
        height: 162px;
        background-size: cover;
        background-repeat: no-repeat;
    }

    .content-box {
        form {
            .form-group {
                input[type='text'] {
                    box-shadow: 0 20px 30px rgba(57, 202, 187, 0.2);
                }

                margin-right: 0px;
            }
        }

        max-width: 770px;
        margin: 0 auto;

        h1 {
            color: #fff;
            margin-bottom: 23px;
        }

        p {
            color: #fff;
            margin-bottom: 33px;
        }
    }

    .select-box {
        li {
            float: none;
            margin: 0px 12px;
        }

        label {
            color: #fff;
        }

        input[type="radio"] {
            + {
                label {
                    span {
                        border-color: #fff;
                    }
                }
            }
        }
    }

    .anim-icon {
        .icon-1 {
            width: 77px;
            height: 96px;
            left: 30px;
            top: 180px;
        }

        .icon-2 {
            width: 338px;
            height: 226px;
            left: 150px;
            bottom: 100px;
        }

        .icon-3 {
            width: 51px;
            height: 51px;
            left: 600px;
            bottom: 90px;
        }

        .icon-4 {
            width: 76px;
            height: 77px;
            left: 50%;
            bottom: 150px;
        }

        .icon-5 {
            width: 116px;
            height: 126px;
            left: 60%;
            bottom: 80px;
        }

        .icon-6 {
            width: 84px;
            height: 73px;
            right: 275px;
            bottom: 230px;
        }

        .icon-7 {
            width: 96px;
            height: 125px;
            right: 0px;
            bottom: 100px;
        }

        .icon-8 {
            width: 79px;
            height: 76px;
            right: 60px;
            top: 200px;
        }
    }
}

.anim-icon {
    position: absolute;
    left: 0px;
    top: 0px;
    right: 0px;
    max-width: 1920px;
    width: 100%;
    height: 100%;
    margin: 0 auto;

    .icon {
        position: absolute;
        background-repeat: no-repeat;
    }
}

.float-bob-y {
    animation-name: float-bob-y;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    -webkit-animation-name: float-bob-y;
    -webkit-animation-duration: 2s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -moz-animation-name: float-bob-y;
    -moz-animation-duration: 2s;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    -ms-animation-name: float-bob-y;
    -ms-animation-duration: 2s;
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: linear;
    -o-animation-name: float-bob-y;
    -o-animation-duration: 2s;
    -o-animation-iteration-count: infinite;
    -o-animation-timing-function: linear;
}

.float-bob-x {
    animation-name: float-bob-x;
    animation-duration: 15s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    -webkit-animation-name: float-bob-x;
    -webkit-animation-duration: 15s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -moz-animation-name: float-bob-x;
    -moz-animation-duration: 15s;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    -ms-animation-name: float-bob-x;
    -ms-animation-duration: 15s;
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: linear;
    -o-animation-name: float-bob-x;
    -o-animation-duration: 15s;
    -o-animation-iteration-count: infinite;
    -o-animation-timing-function: linear;
}

.rotate-me {
    animation-name: rotateme;
    animation-duration: 30s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    -webkit-animation-name: rotateme;
    -webkit-animation-duration: 30s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -moz-animation-name: rotateme;
    -moz-animation-duration: 30s;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    -ms-animation-name: rotateme;
    -ms-animation-duration: 30s;
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: linear;
    -o-animation-name: rotateme;
    -o-animation-duration: 30s;
    -o-animation-iteration-count: infinite;
    -o-animation-timing-function: linear;
}

.category-section {
    position: relative;
    padding: 115px 0px 120px 0px;

    .more-btn {
        position: relative;
        margin-top: 30px;
    }

    .pattern-layer {
        .pattern-1 {
            position: absolute;
            left: 0px;
            bottom: 0px;
            width: 430px;
            height: 375px;
            background-repeat: no-repeat;
        }

        .pattern-2 {
            position: absolute;
            right: 250px;
            top: 110px;
            width: 380px;
            height: 290px;
            background-repeat: no-repeat;
        }
    }
}

.category-block-one {
    .inner-box {
        position: relative;
        display: block;
        background: #fff;
        border-radius: 70px;
        padding: 40px 15px;
        margin-bottom: 30px;
        overflow: hidden;
        transition: all 500ms ease;

        &:hover {
            box-shadow: 0 20px 30px rgba(57, 202, 187, 0.15);

            .pattern {
                .pattern-1 {
                    left: 0px;
                    opacity: 1;
                }

                .pattern-2 {
                    opacity: 1;
                }
            }

            .icon-box {
                img {
                    transform: scale(1.1);
                }
            }

            .link {
                a {
                    transform: scale(0, 0);
                }
            }

            .btn-box {
                a {
                    transform: scale(1, 1);
                }
            }
        }

        .pattern {
            .pattern-1 {
                position: absolute;
                left: 0px;
                top: 0px;
                width: 229px;
                height: 103px;
                opacity: 0;
                left: -30px;
                transition: all 500ms ease;
            }

            .pattern-2 {
                position: absolute;
                right: 0px;
                bottom: 0px;
                width: 149px;
                height: 87px;
                opacity: 0;
                transition: all 500ms ease;
            }
        }

        .icon-box {
            position: relative;
            min-height: 70px;
            margin-bottom: 15px;

            img {
                transition: all 500ms ease;
                display: inline-block;
            }
        }

        h3 {
            position: relative;
            display: block;
            font-size: 24px;
            line-height: 30px;
            font-weight: 500;
            margin-bottom: 25px;

            a {
                display: inline-block;
                color: #3C5A7D;
            }
        }

        .link {
            a {
                position: relative;
                display: inline-block;
                width: 50px;
                height: 50px;
                line-height: 54px;
                text-align: center;
                font-size: 16px;
                background: #e6f5f3;
                border-radius: 50%;
                transform: scale(1, 1);
                transition: all 500ms ease;
            }
        }

        .btn-box {
            position: absolute;
            left: 0px;
            bottom: 40px;
            width: 100%;
            text-align: center;

            a {
                padding: 12px 30px;
                transform: scale(0, 0);
            }
        }
    }
}

.mr-50 {
    margin-right: 50px;
}

.image_block_3 {
    .image-box {
        position: relative;
        padding: 0px 60px 95px 100px;

        //margin-right: ;
        .image {
            border-radius: 15px;
            box-shadow: 0 10px 40px rgba(0, 24, 73, 0.4);

            img {
                width: 100%;
                border-radius: 15px;
            }
        }

        .image-2 {
            position: absolute;
            right: 0px;
            bottom: 0px;
        }

        .image-content {
            position: absolute;
            top: 105px;
            left: 0px;
            background: #fff;
            max-width: 240px;
            width: 100%;
            border-radius: 10px;
            padding: 32px 20px 32px 90px;
            box-shadow: 17px 20px 80px rgba(0, 24, 73, 0.30);

            .icon-box {
                position: absolute;
                left: 23px;
                top: 23px;
            }

            span {
                position: relative;
                display: block;
                font-size: 14px;
                line-height: 20px;
                color: #3C5A7D;
            }

            h4 {
                position: relative;
                display: block;
                font-size: 20px;
                line-height: 26px;
                font-weight: 900;
            }
        }

        .pattern {
            .pattern-1 {
                position: absolute;
                left: 40px;
                top: 35px;
                width: 490px;
                height: 506px;
                background-repeat: no-repeat;
            }

            .pattern-2 {
                position: absolute;
                top: 0px;
                right: 60px;
                width: 124px;
                height: 144px;
                background-repeat: no-repeat;
                z-index: 1;
            }

            .pattern-3 {
                position: absolute;
                top: 70px;
                right: 0px;
                width: 90px;
                height: 90px;
                border-radius: 50%;
                z-index: 1;
                opacity: 0.5;
            }
        }
    }
}

.team-style-three {
    position: relative;
    padding: 115px 0px 120px 0px;

    .more-btn {
        position: absolute;
        top: 30px;
        right: 0px;
    }

    &:before {
        position: absolute;
        content: '';
        width: calc(100% - 675px);
        height: 430px;
        top: 0px;
        right: 675px;
    }

    .pattern-layer {
        .pattern-1 {
            position: absolute;
            left: 0px;
            top: 0px;
            width: 469px;
            height: 361px;
            background-repeat: no-repeat;
        }

        .pattern-2 {
            position: absolute;
            right: 675px;
            top: 0px;
            width: 159px;
            height: 181px;
            background-repeat: no-repeat;
        }

        .pattern-3 {
            position: absolute;
            top: 0px;
            right: 0px;
            width: 85px;
            height: 430px;
            background-repeat: no-repeat;
        }
    }
}

.team-block-three {
    .inner-box {
        position: relative;
        display: block;
        overflow: hidden;
        background: #fff;
        box-shadow: 0 20px 30px rgba(0, 24, 73, 0.1);
        border-radius: 20px;

        .image-box {
            position: relative;
            display: block;
            overflow: hidden;

            img {
                width: 100%;
                transition: all 500ms ease;
            }

            a {
                position: absolute;
                top: 20px;
                right: 20px;
                display: inline-block;
                font-size: 15px;
                width: 40px;
                height: 40px;
                line-height: 40px;
                text-align: center;
                background: #eaf8f6;
                border-radius: 50%;
                z-index: 1;

                &:hover {
                    background: #3C5A7D;
                }
            }
        }

        &:hover {
            .image-box {
                img {
                    transform: scale(1.1);
                }
            }
        }

        .lower-content {
            position: relative;
            padding: 25px 30px 30px 30px;

            .name-box {
                li {
                    position: relative;
                    display: inline-block;
                    margin-right: 6px;
                    font-size: 20px;

                    &:nth-child(2) {
                        top: 2px;
                    }

                    &:last-child {
                        top: 2px;
                        margin: 0px !important;
                    }

                    h3 {
                        position: relative;
                        display: block;
                        font-size: 22px;
                        line-height: 26px;
                        font-weight: 700;

                        a {
                            display: inline-block;
                            color: #3C5A7D;
                        }
                    }
                }
            }

            .designation {
                position: relative;
                display: block;
                font-size: 15px;
                line-height: 24px;
                margin-bottom: 12px;
            }

            .rating {
                li {
                    position: relative;
                    display: inline-block;
                    float: left;
                    font-size: 14px;
                    margin-right: 3px;

                    &:last-child {
                        margin-right: 0px !important;
                        margin-left: 5px !important;
                    }

                    a {
                        display: inline-block;
                        font-size: 15px;
                        position: relative;
                        color: #808080;
                    }
                }
            }

            .rating-box {
                position: relative;
                margin-bottom: 0px;
            }

            .location-box {
                position: relative;
                display: block;
                margin-bottom: 22px;

                p {
                    font-size: 15px;
                    color: #808080;

                    i {
                        margin-right: 8px;
                    }
                }
            }

            .lower-box {
                .text {
                    position: relative;
                    float: left;
                    font-size: 15px;
                    line-height: 44px;
                }

                a {
                    position: relative;
                    display: inline-block;
                    float: right;
                    font-size: 15px;
                    line-height: 26px;
                    font-weight: 600;
                    border: 2px solid #ebeef1;
                    border-radius: 30px;
                    text-align: center;
                    padding: 7px 27px;
                    text-align: center;

                    &:hover {
                        color: #fff;
                    }
                }
            }
        }
    }
}

.faq-section {
    position: relative;

    .content_block_5 {
        .content-box {
            margin-left: 40px;
            padding-bottom: 120px;
            margin-top: 30px;
        }
    }
}

.image_block_4 {
    .image-box {
        position: relative;
        display: block;
        margin-right: 30px;

        .pattern {
            position: absolute;
            left: 0px;
            top: 120px;
            width: 558px;
            height: 449px;
            background-repeat: no-repeat;
            -webkit-animation: zoom-fade 5s infinite linear;
            animation: zoom-fade 5s infinite linear;
        }

        img {
            position: relative;
            width: 100%;
        }
    }
}

.accordion-box {
    .block {
        position: relative;
        display: block;
        background: #fff;
        border-radius: 10px;
        margin-bottom: 20px;
        box-shadow: 0 10px 30px #e3eae9;
        transition: all 500ms ease;

        &:last-child {
            margin-bottom: 0px;
        }

        .acc-content {
            position: relative;
            display: none;
            padding: 0px 35px 33px 30px;
        }

        .acc-content.current {
            display: block;
        }

        .acc-btn {
            .icon-outer {
                position: absolute;
                width: 60px;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                top: 0px;
                right: 6px;
                text-align: center;
                cursor: pointer;
                transition: all 500ms ease;
                z-index: 1;

                &:after {
                    position: absolute;
                    content: "\f107";
                    font-size: 24px;
                    color: #3C5A7D;
                    font-family: 'Font Awesome 5 Pro';
                    font-weight: 400;
                }
            }

            position: relative;
            display: block;
            overflow: hidden;
            padding: 28px 80px 24px 30px;
            cursor: pointer;
            border-radius: 10px;
            transition: all 500ms ease;

            h4 {
                position: relative;
                display: block;
                font-size: 20px;
                line-height: 28px;
                font-weight: 600;
                margin: 0px;
            }
        }

        .acc-btn.active {
            .icon-outer {
                &:after {
                    opacity: 0;
                }

                &:before {
                    position: relative;
                    font-size: 24px;
                    font-family: 'Font Awesome 5 Pro';
                    font-weight: 400;
                    margin: 0px;
                    content: "\f106";
                    color: #3C5A7D;
                }
            }
        }
    }
}

.testimonial-style-two {
    position: relative;
    padding: 115px 0px 120px 0px;

    .owl-stage-outer {
        overflow: visible;
    }

    .owl-item {
        opacity: 0;
        transition: all 500ms ease;
    }

    .owl-item.active {
        opacity: 1;
    }

    .owl-dots {
        position: relative;
        display: block;
        text-align: center;
        margin-top: 50px;
    }

    .owl-theme {
        .owl-dots {
            .owl-dot {
                span {
                    position: relative;
                    display: inline-block;
                    width: 10px;
                    height: 10px;
                    background: transparent;
                    border: 2px solid #bfd5d3;
                    border-radius: 50%;
                    margin: 0px 10px;
                    cursor: pointer;
                    transition: all 500ms ease;
                }
            }
        }
    }

    .pattern-layer {
        .pattern-1 {
            position: absolute;
            left: 0px;
            top: 0px;
            width: 239px;
            height: 376px;
            background-repeat: no-repeat;
        }

        .pattern-2 {
            position: absolute;
            left: 0px;
            bottom: 0px;
            width: 129px;
            height: 226px;
            background-repeat: no-repeat;
        }

        .pattern-3 {
            position: absolute;
            left: 300px;
            bottom: 160px;
            width: 121px;
            height: 121px;
            background-repeat: no-repeat;
        }

        .pattern-4 {
            position: absolute;
            top: 0px;
            right: 50px;
            width: 321px;
            height: 179px;
            background-repeat: no-repeat;
        }

        .pattern-5 {
            position: absolute;
            right: 170px;
            bottom: 0px;
            width: 370px;
            height: 311px;
            background-repeat: no-repeat;
        }
    }
}

.testimonial-block-two {
    .inner-box {
        position: relative;
        display: block;
        background: #fff;
        padding: 49px 30px 50px 40px;
        border-radius: 10px;
        box-shadow: 0 10px 30px rgba(0, 0, 0, 0.08);

        .text {
            margin-bottom: 21px;

            p {
                font-size: 18px;
                line-height: 30px;
                color: #3C5A7D;
            }
        }

        .author-info {
            position: relative;
            padding: 7px 0px 1px 80px;

            .author-thumb {
                position: absolute;
                left: 0px;
                top: 0px;
                width: 60px;
                height: 60px;
                border-radius: 50%;

                img {
                    width: 100%;
                    border-radius: 50%;
                }
            }

            h4 {
                font-size: 20px;
                line-height: 26px;
                font-weight: 700;
                margin-bottom: 0px;
            }

            .designation {
                position: relative;
                display: block;
                font-size: 15px;
                line-height: 26px;
            }
        }
    }
}

.pt-115 {
    padding-top: 115px;
}

.banner-section.style-four {
    //padding: 190px 0px 310px 0px;

    form {
        .form-group {
            margin-right: 0px;
            background: #fff;
            border-radius: 40px;
            border-color: green;

            button {
                top: inherit;
                bottom: 5px;
            }

            input {
                padding: 10px 90px 10px 30px;
                background-color: transparent;

                &:focus {
                    border-color: #3C5A7D;

                    + {
                        button {
                            background: #3C5A7D;
                        }
                    }
                }
            }
            .ng-select-container {
                border-width: 1px;
                //border-top-right-radius: 40px;
                //border-top-left-radius: 40px;
                height: 70px;

                .ng-placeholder {
                    margin-left:30px;
                    margin-top: 15px;
                    z-index: 1;
                }

                .ng-value {
                    margin-left: 25px;
                }

                &:focus {
                    border-radius: green;
                }
            }

            .border-concave-down {
                .ng-select-container, input {
                    border-top-left-radius: 40px;
                    border-top-right-radius: 40px;
                    border-bottom-left-radius: 0px;
                    border-bottom-right-radius: 0px;
                    background-color: transparent;
                }
            }

            .border-concave-up {
                .ng-select-container, input {
                    border-top-left-radius: 0px;
                    border-top-right-radius: 0px;
                    border-bottom-left-radius: 40px;
                    border-bottom-right-radius: 40px;
                    background-color: transparent;

                    &:focus {
                        border-color: #3C5A7D;
                        border-width: 2px;
                        + {
                            button {
                                background: #3C5A7D;
                            }
                        }
                    }
                }
            }
        }
    }

    .image-box {
        .anim-icon {
            .icon-1 {
                position: absolute;
                right: 150px;
                top: 0px;
                width: 12px;
                height: 13px;
            }

            .icon-2 {
                position: absolute;
                left: 170px;
                top: 100px;
                width: 21px;
                height: 22px;
            }

            .icon-3 {
                position: absolute;
                left: 150px;
                top: 155px;
                width: 12px;
                height: 13px;
            }

            .icon-4 {
                position: absolute;
                left: 220px;
                top: 155px;
                width: 91px;
                height: 91px;
                border-radius: 50%;
                opacity: 0.8;
                z-index: 1;
            }

            .icon-5 {
                position: absolute;
                left: 175px;
                top: 230px;
                width: 115px;
                height: 115px;
                z-index: -1;
            }
        }

        position: absolute;
        right: 165px;
        top: 64px;
        margin: 0px;
    }

    .pattern-layer {
        .pattern-1 {
            position: absolute;
            top: 0px;
            right: 0px;
            width: 640px;
            height: 100%;
            background-repeat: no-repeat;
        }

        .pattern-2 {
            position: absolute;
            left: 0px;
            top: 0px;
            width: 239px;
            height: 376px;
            background-repeat: no-repeat;
        }

        .pattern-3 {
            position: absolute;
            left: 70px;
            top: 0px;
            width: 353px;
            height: 235px;
            background-repeat: no-repeat;
        }

        .pattern-4 {
            position: absolute;
            left: 0px;
            bottom: 0px;
            width: 246px;
            height: 387px;
            background-repeat: no-repeat;
        }

        .pattern-5 {
            position: absolute;
            left: 90px;
            bottom: 190px;
            width: 115px;
            height: 115px;
            background-repeat: no-repeat;
        }
    }
}

.feature-section.alternat-2 {
    padding: 0px;
}

.about-style-three {
    position: relative;

    .image-box {
        position: relative;

        .image {
            position: relative;
            float: left;
            border-radius: 10px;

            img {
                width: 100%;
                border-radius: 10px;
            }
        }

        .image-1 {
            margin-right: 10px;
        }

        &:before {
            position: absolute;
            top: 0;
            left: -75%;
            display: block;
            content: '';
            width: 50%;
            height: 100%;
            background: -o-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 100%);
            background: -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, 0)), to(rgba(255, 255, 255, .3)));
            background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 100%);
            -webkit-transform: skewX(-25deg);
            -ms-transform: skewX(-25deg);
            transform: skewX(-25deg);
            z-index: 1;
        }

        &:hover {
            &:before {
                -webkit-animation: shine 2s;
                animation: shine 2s;
            }
        }

        .pattern {
            .pattern-1 {
                position: absolute;
                left: -50px;
                top: 215px;
                width: 115px;
                height: 115px;
            }

            .pattern-2 {
                position: absolute;
                right: -35px;
                top: 40px;
                width: 115px;
                height: 115px;
            }

            .pattern-3 {
                position: absolute;
                top: -125px;
                right: -165px;
                width: 460px;
                height: 387px;
                background-repeat: no-repeat;
            }
        }
    }

    .sec-title {
        margin-bottom: 24px;
    }

    .title-inner {
        position: relative;
        margin-bottom: 52px;
    }
}

.banner-section.style-five {
    position: relative;
    padding-top: 110px;
    padding-bottom: 0px;
    overflow: visible;

    .map-layer {
        position: absolute;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-repeat: no-repeat;
    }

    .content-inner {
        position: relative;
        min-height: 295px;
        display: block;
        width: 100%;
        margin-bottom: 100px;

        .doctors-box {
            position: absolute;
            top: 0px;
            left: 80px;
            width: 240px;
            background: #fff;
            padding: 30px 30px 36px 30px;
            border-radius: 10px;
            opacity: 0;
            visibility: hidden;
            transition: all 500ms ease;

            .shape {
                position: absolute;
                left: 0px;
                top: 0px;
                width: 100%;
                height: 90px;
                background-repeat: no-repeat;
            }

            &:before {
                position: absolute;
                content: '';
                background: #fff;
                width: 15px;
                height: 15px;
                left: -7px;
                top: 50%;
                transform: translateY(-50%) rotate(45deg);
            }

            .image {
                position: relative;
                display: inline-block;
                width: 90px;
                height: 90px;
                border-radius: 50%;
                margin-bottom: 16px;
                z-index: 1;

                img {
                    width: 100%;
                    border-radius: 50%;
                }
            }

            h3 {
                font-size: 22px;
                line-height: 30px;
                font-weight: 700;
                margin-bottom: 2px;

                a {
                    display: inline-block;
                    color: #3C5A7D;
                }
            }

            .designation {
                position: relative;
                display: block;
                font-size: 14px;
                line-height: 24px;
                margin-bottom: 13px;
            }

            .info {
                li {
                    position: relative;
                    display: block;
                    font-size: 14px;
                    line-height: 24px;
                    margin-bottom: 5px;

                    &:last-child {
                        margin-bottom: 0px;
                    }

                    a {
                        display: inline-block;
                        color: #808080;
                    }

                    i {
                        margin-right: 10px;
                    }
                }
            }
        }

        .single-item {
            &:hover {
                .doctors-box {
                    left: 65px;
                    opacity: 1;
                    visibility: visible;
                }
            }
        }

        .location-pin {
            position: absolute;
            left: 0px;
            top: 125px;
            cursor: pointer;
            width: 45px;
            height: 57px;
        }

        .single-item.box-1 {
            position: absolute;
            left: 90px;
            top: 0px;
        }

        .single-item.box-2 {
            position: absolute;
            top: 0px;
            right: 300px;
        }

        .single-item.box-3 {
            position: absolute;
            top: -100px;
            right: 415px;
        }
    }

    .content-box {
        position: relative;
        margin-bottom: -130px;
        border-radius: 10px;
        padding: 43px 380px 41px 70px;
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
        z-index: 1;

        .image-box {
            position: absolute;
            right: -65px;
            bottom: -79px;
        }

        h2 {
            position: relative;
            display: block;
            font-size: 40px;
            line-height: 50px;
            color: #fff;
            font-weight: 900;
            margin-bottom: 21px;
        }

        form {
            .form-group {
                margin-right: 0px;
                background: #fff;
                border-radius: 40px;

                .wide {
                    position: relative;
                    float: left;
                    max-width: 240px;
                    width: 100%;

                    &:before {
                        position: absolute;
                        content: '';
                        background: #e5e5e5;
                        width: 1px;
                        height: 40px;
                        top: 15px;
                        right: 0px;
                    }
                }

                input {
                    float: left;
                    width: 415px;
                    border: none;
                }
            }
        }

        .select-box {
            label {
                color: #fff;
            }

            input[type="radio"] {
                + {
                    label {
                        span {
                            border-color: #fff;
                        }
                    }
                }
            }
        }
    }
}

.nice-select {
    &:after {
        position: absolute;
        content: "\f107";
        font-family: 'Font Awesome 5 Pro';
        font-size: 16px;
        top: 20px;
        right: 30px;
        color: #848484;
        font-weight: 400;
        border: none !important;
        transform: rotate(0deg) !important;
    }

    position: relative;
    display: block;
    width: 100%;
    height: 70px;
    border: none !important;
    background: transparent;
    font-size: 16px;
    color: #848484;
    padding: 17px 40px;

    .option {
        float: none;
        display: block;
        margin: 0px;
    }
}

.process-style-two.alternat-2 {
    background: #fff;
    padding-top: 240px;
}

.team-style-four {
    position: relative;
    padding: 115px 0px 90px 0px;

    .team-block-three {
        .inner-box {
            margin-bottom: 30px;
        }
    }
}

.funfact-section {
    position: relative;
    padding: 76px 0px 78px 0px;

    .pattern-layer {
        .pattern-1 {
            position: absolute;
            left: 0px;
            top: 0px;
            width: 382px;
            height: 100%;
            background-repeat: no-repeat;
        }

        .pattern-2 {
            position: absolute;
            right: 0px;
            top: 0px;
            width: 405px;
            height: 211px;
            background-repeat: no-repeat;
        }
    }
}

.counter-block-one {
    position: relative;
    display: inline-block;
    text-align: center;

    .inner-box {
        position: relative;
        display: block;
        text-align: left;

        .count-outer {
            position: relative;
            display: block;
            font-size: 42px;
            line-height: 42px;
            font-family: 'Infer', sans-serif;
            font-weight: 700;
            margin-bottom: 10px;
        }

        h4 {
            position: relative;
            display: block;
            font-size: 20px;
            line-height: 26px;
            font-weight: 600;
        }
    }
}

.pt-80 {
    padding-top: 80px;
}

.cta-section.alternat-2 {
    .image-box {
        margin-left: 30px;
    }

    .pattern-layer {
        .pattern-2 {
            left: inherit;
            right: 480px;
            top: 40px;
        }
    }
}

.page-title {
    position: relative;
    padding: 38px 0px 35px 0px;

    .title {
        position: relative;
        display: block;
        margin-bottom: 4px;

        h1 {
            font-size: 35px;
            line-height: 45px;
            font-weight: 900;
        }
    }

    .bread-crumb {
        li {
            position: relative;
            display: inline-block;
            font-size: 18px;
            line-height: 26px;
            color: #54717a;
            padding-right: 10px;
            margin-right: 2px;

            &:last-child {
                padding: 0px !important;
                margin: 0px !important;

                &:before {
                    display: none;
                }
            }

            a {
                color: #3C5A7D;

                &:hover {
                    text-decoration: underline;
                }
            }

            &:before {
                position: absolute;
                content: "\f105";
                font-family: 'Font Awesome 5 Pro';
                font-size: 16px;
                top: 1px;
                right: 0px;
                color: #3C5A7D;
                font-weight: 400;
            }
        }
    }

    .pattern-layer {
        .pattern-1 {
            position: absolute;
            left: 100px;
            top: 0px;
            width: 281px;
            height: 111px;
            background-repeat: no-repeat;
        }

        .pattern-2 {
            position: absolute;
            right: 80px;
            bottom: 0px;
            width: 279px;
            height: 111px;
            background-repeat: no-repeat;
        }
    }
}

.pb-90 {
    padding-bottom: 90px;
}

.pt-125 {
    padding-top: 125px;
}

.page-title-two {
    position: relative;
    border-bottom: 1px solid #efefef;

    .title-box {
        position: relative;
        //padding: 52px 0px 48px 0px;

        h1 {
            font-size: 40px;
            line-height: 50px;
            font-weight: 900;
            color: #3C5A7D;
        }
    }

    .bread-crumb {
        li {
            position: relative;
            display: inline-block;
            font-size: 18px;
            line-height: 26px;
            color: #54717a;
            padding-right: 10px;
            margin-right: 2px;

            &:last-child {
                padding: 0px !important;
                margin: 0px !important;

                &:before {
                    display: none;
                }
            }

            a {
                color: #3C5A7D;

                &:hover {
                    text-decoration: underline;
                }
            }

            &:before {
                position: absolute;
                content: "\f105";
                font-family: 'Font Awesome 5 Pro';
                font-size: 16px;
                top: 1px;
                right: 0px;
                color: #3C5A7D;
                font-weight: 400;
            }
        }
    }

    .pattern-layer {
        .pattern-1 {
            position: absolute;
            left: 100px;
            top: 0px;
            width: 281px;
            height: 111px;
            background-repeat: no-repeat;
        }

        .pattern-2 {
            position: absolute;
            right: 80px;
            bottom: 0px;
            width: 279px;
            height: 111px;
            background-repeat: no-repeat;
        }
    }

    .lower-content {
        padding: 13px 0px;
        padding: 13px 50px;
    }
}

.content_block_5 {
    .content-box {
        .sec-title {
            h3 {
                position: relative;
                display: block;
                font-size: 30px;
                line-height: 40px;
                font-weight: 700;
                margin-top: 45px;
            }
        }
    }
}

.faq-page-section {
    position: relative;
    padding-top: 50px;

    .content_block_5 {
        .content-box {
            .sec-title {
                margin-bottom: 30px;
            }
        }
    }

    .categories-list {
        position: relative;
        display: block;
        background: #fff;
        box-shadow: 0 10px 30px #e3eae9;
        border-radius: 10px;
        overflow: hidden;
        max-width: 370px;
        width: 100%;

        li {
            position: relative;
            display: block;
            background: #fff;
            font-size: 17px;
            line-height: 28px;
            font-weight: 600;
            padding: 0px 30px;

            &:before {
                position: absolute;
                content: '';
                width: 0%;
                height: 100%;
                left: 0px;
                top: 0px;
                transition: all 500ms ease;
            }

            &:hover {
                &:before {
                    width: 100%;
                }

                a {
                    border-bottom: 1px solid transparent;
                    color: #fff;

                    &:before {
                        color: #fff;
                    }
                }
            }

            a {
                position: relative;
                display: block;
                color: #3C5A7D;
                border-bottom: 1px solid #e5eded;
                padding: 16px 0px;

                &:before {
                    position: absolute;
                    content: "\f105";
                    font-family: 'Font Awesome 5 Pro';
                    font-size: 17px;
                    color: #3C5A7D;
                    top: 14px;
                    right: 0px;
                    transition: all 500ms ease;
                }
            }

            &:last-child {
                a {
                    border-bottom: none !important;
                }
            }
        }

        li.current {
            &:before {
                width: 100%;
            }

            a {
                border-bottom: 1px solid transparent;
                color: #fff;

                &:before {
                    color: #fff;
                }
            }
        }
    }

    .image-box {
        position: relative;
        margin: 61px 55px 0px -125px;

        .pattern {
            position: absolute;
            right: -20px;
            top: 90px;
            width: 452px;
            height: 366px;
            background-repeat: no-repeat;
            z-index: -1;
        }
    }
}

.questions-section {
    position: relative;

    .form-inner {
        form {
            .form-group {
                position: relative;
                margin-bottom: 30px;

                &:last-child {
                    margin-bottom: 0px;
                }

                input[type='text'] {
                    position: relative;
                    display: block;
                    width: 100%;
                    height: 60px;
                    background: #fff;
                    font-size: 15px;
                    color: #848484;
                    font-style: italic;
                    border: 1px solid #fff;
                    padding: 10px 30px;
                    border-radius: 40px;
                    box-shadow: 0 10px 30px #e3eae9;
                    transition: all 500ms ease;
                }

                textarea {
                    position: relative;
                    display: block;
                    width: 100%;
                    height: 60px;
                    background: #fff;
                    font-size: 15px;
                    color: #848484;
                    font-style: italic;
                    border: 1px solid #fff;
                    padding: 10px 30px;
                    border-radius: 40px;
                    box-shadow: 0 10px 30px #e3eae9;
                    transition: all 500ms ease;
                    height: 220px;
                    resize: none;
                    padding-top: 30px;
                }
            }
        }
    }

    .pattern-layer {
        .pattern-1 {
            position: absolute;
            left: 190px;
            top: 130px;
            width: 460px;
            height: 387px;
            background-repeat: no-repeat;
        }

        .pattern-2 {
            position: absolute;
            right: 120px;
            bottom: -50px;
            width: 460px;
            height: 387px;
            background-repeat: no-repeat;
        }

        .pattern-3 {
            position: absolute;
            top: 0px;
            right: 70px;
            width: 348px;
            height: 227px;
            background-repeat: no-repeat;
        }
    }
}

.pricing-section {
    position: relative;

    .tab-btn-box {
        .tab-btns {
            position: relative;
            display: inline-block;
            margin-bottom: 60px;

            &:before {
                position: absolute;
                left: 0;
                right: 0;
                margin: 0 auto;
                height: 40px;
                width: 90px;
                content: "";
                border-radius: 30px;
                box-shadow: 0 20px 40px 10px #c0e4e1;
            }

            li {
                position: relative;
                display: inline-block;
                min-width: 160px;
                text-align: right;
                font-size: 15px;
                padding: 7px 65px 3px 0px;
                line-height: 30px;
                font-weight: 600;
                color: #3C5A7D;
                cursor: pointer;
                transition: all 500ms ease;

                &:last-child {
                    padding-right: 0;
                    padding-left: 65px;
                    text-align: left;

                    &:before {
                        display: none;
                    }
                }

                &:before {
                    position: absolute;
                    top: 4px;
                    height: 32px;
                    width: 32px;
                    border-radius: 30px;
                    content: "";
                    right: -43px;
                    background: #fff;
                    -webkit-transition: all 300ms ease;
                    -o-transition: all 300ms ease;
                    transition: all 300ms ease;
                }
            }

            li.active-btn {
                &:before {
                    right: 7px;
                }
            }
        }
    }
}

.tabs-box {
    .tab {
        position: relative;
        display: none;
        transition: all 900ms ease;
        -moz-transition: all 900ms ease;
        -webkit-transition: all 900ms ease;
        -ms-transition: all 900ms ease;
        -o-transition: all 900ms ease;
        transform: scale(0.9, 0.9) translateY(0px);
    }

    .tab.active-tab {
        display: block;
        transform: scale(1) translateY(0px);
    }
}

.pricing-block-one {
    .pricing-table {
        position: relative;
        display: block;
        background: #fff;
        padding: 85px 40px 50px 40px;
        border-radius: 10px;
        overflow: hidden;
        transition: all 500ms ease;

        .table-header {
            h2 {
                font-size: 30px;
                line-height: 38px;
                font-weight: 700;
                margin-bottom: 5px;
            }

            h3 {
                position: relative;
                font-size: 22px;
                line-height: 32px;
                font-weight: 600;
            }

            position: relative;
            margin-bottom: 34px;
            border-bottom: 1px solid #efefef;
            padding-bottom: 23px;
        }

        .table-content {
            ul {
                li {
                    position: relative;
                    display: block;
                    font-size: 15px;
                    line-height: 26px;
                    color: #3C5A7D;
                    padding-left: 20px;
                    margin-bottom: 14px;

                    &:last-child {
                        margin-bottom: 0px;
                    }

                    &:before {
                        position: absolute;
                        content: "\f00c";
                        font-family: 'Font Awesome 5 Pro';
                        font-size: 10px;
                        left: 0px;
                        top: 0px;
                        font-weight: 700;
                    }
                }

                li.light {
                    color: #808080;

                    &:before {
                        color: #d2d4d9;
                    }
                }
            }

            position: relative;
            margin-bottom: 37px;
        }

        .table-footer {
            .link {
                a {
                    position: relative;
                    display: inline-block;
                    width: 50px;
                    height: 50px;
                    line-height: 54px;
                    text-align: center;
                    font-size: 16px;
                    background: #e6f5f3;
                    border-radius: 50%;
                    transform: scale(1, 1);
                }
            }

            .btn-box {
                position: absolute;
                left: 0px;
                bottom: 0px;
                opacity: 0;
                transition: all 900ms ease;

                .theme-btn-one {
                    padding: 12px 30px;
                }
            }

            position: relative;
        }

        &:hover {
            .table-footer {
                .link {
                    a {
                        transform: scale(0, 0);
                    }
                }

                .btn-box {
                    opacity: 1;
                }
            }
        }

        .pattern {
            .pattern-1 {
                position: absolute;
                left: 50%;
                top: 55%;
                transform: translate(-50%, -50%);
                width: 196px;
                height: 165px;
                background-repeat: no-repeat;
            }

            .pattern-2 {
                position: absolute;
                right: 0px;
                bottom: 0px;
                width: 217px;
                height: 104px;
                background-repeat: no-repeat;
            }

            .pattern-3 {
                position: absolute;
                left: 0px;
                top: 0px;
                width: 159px;
                height: 70px;
                background-repeat: no-repeat;
            }
        }
    }

    .pricing-table.active {
        box-shadow: 0 20px 40px 10px #cde4e1;

        .table-footer {
            .btn-box {
                opacity: 1;
            }
        }
    }
}

.error-section {
    position: relative;
    padding: 100px 0px 120px 0px;

    .inner-box {
        .theme-btn-one {
            i {
                margin-right: 10px;
            }
        }

        .error-image {
            position: relative;
            display: inline-block;
            margin-bottom: 60px;
        }

        h1 {
            font-size: 200px;
            line-height: 200px;
            font-weight: 900;
            margin-bottom: 10px;
        }

        h2 {
            position: relative;
            display: block;
            font-size: 30px;
            line-height: 42px;
            font-weight: 700;
            margin-bottom: 60px;
        }
    }
}

.select-field {
    position: relative;
    padding: 30px 0px;

    .form-inner {
        form {
            position: relative;
            float: right;
            max-width: 720px;
            width: 100%;
        }

        .select-box {
            float: left;
            margin-top: 15px;
        }
    }

    .content-box {
        form {
            .form-group {
                position: relative;
                background: #fff;
                //border-radius: 30px;
                margin-bottom: 0px;

                .wide {
                    position: relative;
                    float: left;
                    max-width: 240px;
                    width: 100%;

                    &:before {
                        position: absolute;
                        content: '';
                        background: #e5e5e5;
                        width: 1px;
                        height: 30px;
                        top: 15px;
                        right: 0px;
                    }
                }

                input {
                    position: relative;
                    display: block;
                    float: left;
                    width: 415px;
                    border: none;
                    //height: 60px;
                    font-size: 16px;
                    color: #848484;
                    padding: 10px 40px 10px 40px;
                }

                button {
                    position: absolute;
                    top: 5px;
                    right: 5px;
                    width: 50px;
                    height: 50px;
                    line-height: 50px;
                    text-align: center;
                    border-radius: 50%;
                    font-size: 18px;
                    color: #fff;
                    cursor: pointer;
                    transition: all 500ms ease;
                }

                .nice-select {
                    height: 60px;
                    padding: 12px 40px;

                    &:after {
                        top: 15px;
                    }
                }
            }
        }
    }
}

.clinic-section {
    position: relative;
    padding: 80px 0px 120px 0px;

    .item-shorting {
        position: relative;
        margin-bottom: 40px;

        .left-column {
            h3 {
                font-size: 26px;
                line-height: 50px;
                font-weight: 700;
            }
        }

        .right-column {
            .short-box {
                float: left;
                width: 190px;
                margin-right: 10px;
            }

            .menu-box {
                float: left;

                button {
                    position: relative;
                    display: inline-block;
                    float: left;
                    width: 50px;
                    height: 50px;
                    line-height: 50px;
                    font-size: 14px;
                    border: 1px solid #e3e6ea;
                    border-radius: 50%;
                    color: #93959e;
                    background: transparent;
                    cursor: pointer;
                    margin-right: 10px;
                    transition: all 500ms ease;

                    &:last-child {
                        margin-right: 0px !important;
                        font-size: 16px;
                    }
                }

                button.on {
                    color: #fff;
                }
            }

            .nice-select {
                height: 50px;
                border: 1px solid #e5e7ec !important;
                border-radius: 30px;
                padding: 6px 30px;
                font-size: 15px;
                font-weight: 500;

                &:after {
                    top: 10px;
                }
            }
        }
    }

    .pagination-wrapper {
        position: relative;
        padding-top: 30px;
    }

    .team-block-three {
        .inner-box {
            margin-bottom: 30px;
        }
    }

    .map-inner {
        position: relative;
        border-radius: 10px;

        #contact-google-map {
            position: relative;
            width: 100%;
            height: 500px;
        }
    }
}

.clinic-block-one {
    .inner-box {
        position: relative;
        display: block;
        overflow: hidden;
        background: #fff;
        border-radius: 10px;
        padding: 35px 40px 31px 170px;
        margin-bottom: 30px;
        box-shadow: 0 15px 40px 10px #edf1f1;

        .image-box {
            position: absolute;
            left: 40px;
            top: 40px;
            width: 100px;
            height: 100px;
            border-radius: 20px;
            box-shadow: 0 10px 40px rgba(0, 24, 73, 0.2);

            img {
                width: 100%;
                border-radius: 20px;
            }
        }

        .content-box {
            .like-box {
                position: absolute;
                top: 20px;
                right: 20px;

                a {
                    position: relative;
                    display: inline-block;
                    font-size: 15px;
                    width: 40px;
                    height: 40px;
                    line-height: 40px;
                    text-align: center;
                    background: #eaf8f6;
                    border-radius: 50%;
                    z-index: 1;

                    &:hover {
                        color: #fff;
                    }
                }
            }

            .name-box {
                li {
                    position: relative;
                    display: inline-block;
                    margin-right: 6px;
                    font-size: 20px;

                    &:nth-child(2) {
                        top: 2px;
                    }

                    &:last-child {
                        top: 2px;
                        margin: 0px !important;
                    }

                    h3 {
                        position: relative;
                        display: block;
                        font-size: 22px;
                        line-height: 26px;
                        font-weight: 700;

                        a {
                            display: inline-block;
                            color: #3C5A7D;
                        }
                    }
                }
            }

            .designation {
                position: relative;
                display: block;
                font-size: 15px;
                line-height: 24px;
                //margin-bottom: 8px;
            }

            .subdesignation {
                display: block;
            }

            .text {
                position: relative;
                margin-bottom: 9px;

                p {
                    line-height: 26px;
                }
            }

            .rating {
                li {
                    position: relative;
                    display: inline-block;
                    float: left;
                    font-size: 14px;
                    margin-right: 3px;

                    &:last-child {
                        margin-right: 0px !important;
                        margin-left: 5px !important;
                    }

                    a {
                        display: inline-block;
                        font-size: 15px;
                        position: relative;
                        color: #808080;
                    }
                }

                position: relative;
                display: inline-block;
                float: left;
            }

            .link {
                position: relative;
                display: inline-block;
                float: left;
                padding-left: 15px;
                margin-left: 15px;

                a {
                    font-size: 15px;

                    &:hover {
                        color: #3C5A7D !important;
                    }
                }

                &:before {
                    position: absolute;
                    content: '';
                    background: #e5e5e5;
                    width: 1px;
                    height: 18px;
                    left: 0px;
                    top: 4px;
                }
            }

            .rating-box {
                position: relative;
                margin-bottom: 5px;
            }

            .location-box {
                position: relative;
                display: block;

                p {
                    font-size: 15px;
                    color: #808080;

                    i {
                        margin-right: 8px;
                    }
                }
            }

            .btn-box {
                position: absolute;
                right: 40px;
                bottom: 40px;

                a {
                    position: relative;
                    display: inline-block;
                    font-size: 15px;
                    line-height: 26px;
                    font-weight: 600;
                    border: 2px solid #ebeef1;
                    border-radius: 30px;
                    padding: 7px 30px;

                    &:hover {
                        color: #fff;
                    }
                }
            }
        }

        .pattern {
            .pattern-1 {
                position: absolute;
                left: -30px;
                top: 0px;
                width: 254px;
                height: 115px;
                background-repeat: no-repeat;
                opacity: 0;
                transition: all 500ms ease;
            }

            .pattern-2 {
                position: absolute;
                right: 0px;
                bottom: 0px;
                width: 208px;
                height: 104px;
                background-repeat: no-repeat;
                opacity: 0;
                transition: all 500ms ease;
            }
        }

        &:hover {
            .pattern {
                .pattern-1 {
                    left: 0px;
                    opacity: 1;
                }

                .pattern-2 {
                    opacity: 1;
                }
            }
        }
    }
}

.wrapper.list {
    .clinic-list-content {
        display: block;
    }

    .clinic-grid-content {
        display: none;
    }
}

.wrapper.grid {
    .clinic-list-content {
        display: none;
    }

    .clinic-grid-content {
        display: block;
    }
}

.ml-10 {
    margin-left: 10px;
}

.clinic-half-map {
    position: relative;

    .left-inner {
        width: 45%;
        padding: 0px;
        position: fixed;
        left: 0px;
        overflow-x: hidden;
        overflow-y: auto;
        min-height: 500px;
        height: 100%;

        .form-inner {
            position: relative;
            padding: 12px 30px 25px 30px;

            .select-box {
                label {
                    color: #fff;
                }

                input[type="radio"] {
                    + {
                        label {
                            span {
                                border: 2px solid #fff;
                            }
                        }
                    }
                }

                margin-bottom: 6px;
                margin-top: 0px;
            }

            form {
                max-width: 100%;
            }
        }
    }

    .right-inner {
        width: 55%;
    }

    .clinic-section {
        padding: 30px 30px 150px 30px;

        .item-shorting {
            margin-bottom: 30px;
        }
    }

    .banner-section.style-five {
        .content-inner {
            margin-bottom: 0px;
        }

        padding: 260px 0px;
    }

    .outer-container {
        position: relative;
    }
}

.clinic-details {
    position: relative;
    padding: 80px 0px 120px 0px;
}

.clinic-details-content {
    position: relative;
    margin-right: 10px;
    margin-bottom: 30px;

    .clinic-block-one {
        .inner-box {
            .image-box {
                width: 171px;
                height: 171px;
            }

            padding: 34px 40px 37px 260px;
            box-shadow: none;
            margin-bottom: 40px;

            h2 {
                font-size: 30px;
                line-height: 38px;
                font-weight: 700;
            }

            .lower-box {
                .info {
                    position: relative;
                    float: left;

                    li {
                        position: relative;
                        display: block;
                        font-size: 15px;
                        padding-left: 20px;
                        margin-bottom: 3px;

                        &:last-child {
                            margin-bottom: 0px;
                        }

                        i {
                            position: absolute;
                            left: 0px;
                            top: 6px;
                        }

                        a {
                            color: #3C5A7D;
                        }
                    }
                }

                .view-map {
                    position: relative;
                    float: left;
                    margin-left: 15px;
                    padding-left: 15px;

                    a {
                        position: relative;
                        display: inline-block;
                        font-size: 15px;
                    }

                    &:before {
                        position: absolute;
                        content: '';
                        background: #e5e5e5;
                        width: 1px;
                        height: 18px;
                        left: 0px;
                        top: 4px;
                    }
                }
            }

            .content-box {
                .designation {
                    margin-bottom: 2px;
                }

                .rating-box {
                    margin-bottom: 12px;
                }

                .text {
                    margin-bottom: 14px;
                }

                .share-box {
                    position: absolute;
                    top: 40px;
                    right: 90px;

                    a.share-btn {
                        position: relative;
                        display: inline-block;
                        font-size: 15px;
                        width: 40px;
                        height: 40px;
                        line-height: 40px;
                        text-align: center;
                        background: #eaf8f6;
                        border-radius: 50%;
                        z-index: 1;

                        &:hover {
                            color: #fff;
                        }
                    }
                }
            }
        }
    }

    .tabs-box {
        position: relative;
        display: block;
        background: #fff;
        overflow: hidden;
        border-radius: 10px;

        .tabs-content {
            position: relative;
            padding: 43px 40px 50px 40px;

            h3 {
                position: relative;
                display: block;
                font-size: 22px;
                line-height: 30px;
                font-weight: 700;
                margin-bottom: 16px;
            }

            .inner-box {
                .text {
                    p {
                        margin-bottom: 35px;
                    }

                    .list {
                        li {
                            position: relative;
                            display: inline-block;
                            float: left;
                            width: 50%;
                            font-size: 16px;
                            color: #3C5A7D;
                            font-weight: 500;
                            margin-bottom: 6px;
                            padding-left: 23px;

                            &:before {
                                position: absolute;
                                content: '';
                                border: 3px solid #dad9e7;
                                width: 12px;
                                height: 12px;
                                border-radius: 50%;
                                left: 0px;
                                top: 7px;
                            }
                        }

                        position: relative;
                        margin-bottom: 28px;
                    }
                }

                .image-gallery {
                    h3 {
                        margin-bottom: 23px !important;
                    }
                }
            }
        }

        .tab-btn-box {
            position: relative;
            display: block;

            .tab-btns {
                li {
                    position: relative;
                    display: inline-block;
                    float: left;
                    //width: 33.333%;
                    text-align: center;
                    font-size: 16px;
                    line-height: 26px;
                    font-weight: 600;
                    color: rgba(255, 255, 255, 0.6);
                    padding: 15px 15px 15px 15px;
                    cursor: pointer;
                    border-right: 1px solid rgba(255, 255, 255, 0.1);
                    transition: all 500ms ease;

                    &:last-child {
                        border-right: none;
                    }
                }
            }
        }

        .accordion-box {
            .title-box {
                position: relative;
                background: #ebf7f6;
                padding: 12px 75px 12px 30px;
            }

            h6 {
                position: relative;
                display: block;
                font-size: 15px;
                line-height: 26px;
                font-family: 'Poppins', sans-serif;
                font-weight: 600;

                span {
                    float: right;
                }
            }

            .block {
                .acc-btn {
                    h6 {
                        font-weight: 500;
                    }

                    padding: 13px 80px 11px 30px;
                    border-bottom: 1px solid transparent;

                    .icon-outer {
                        border-left: 1px solid #e5e5e5;
                        right: 0px;
                        width: 50px;

                        &:after {
                            content: "\f067";
                            font-size: 20px;
                        }
                    }
                }

                box-shadow: none;
                margin: 0px;
                border-radius: 0px;
                border-bottom: 1px solid #e5e5e5;

                &:last-child {
                    border-bottom: none;
                }

                &:first-child {
                    border-top: 1px solid #e5e5e5;
                }

                .acc-btn.active {
                    border-bottom: 1px solid #e5e5e5;

                    .icon-outer {
                        &:before {
                            content: "\f068";
                            font-size: 20px;
                        }
                    }
                }

                .acc-content {
                    padding: 22px 30px 30px 30px;

                    p {
                        margin: 0px;
                    }
                }
            }

            .accordion-inner {
                position: relative;
                border: 1px solid #e5e5e5;
                border-top: none;
            }

            margin-bottom: 44px;

            h3 {
                margin-bottom: 24px !important;
            }
        }

        .award-box {
            .list {
                li {
                    position: relative;
                    display: block;
                    font-size: 15px;
                    color: #3C5A7D;
                    font-weight: 500;
                    margin-bottom: 6px;
                    padding-left: 23px;

                    span {
                        font-weight: 400;
                        color: #808080;
                    }

                    &:before {
                        position: absolute;
                        content: '';
                        border: 3px solid #dad9e7;
                        width: 12px;
                        height: 12px;
                        border-radius: 50%;
                        left: 0px;
                        top: 7px;
                    }
                }
            }

            margin-bottom: 35px;
        }

        .image-gallery {
            .image-list {
                position: relative;
                margin: 0px -5px;

                li {
                    position: relative;
                    float: left;
                    width: 33.333%;

                    .image {
                        position: relative;
                        margin: 0px 5px;
                        border-radius: 10px;

                        img {
                            width: 100%;
                            border-radius: 10px;
                        }
                    }
                }
            }
        }

        .onboard-doctors {
            position: relative;
        }

        .team-block-one {
            .btn-box {
                position: absolute;
                right: 0px;
                bottom: 0px;
                z-index: 1;

                .theme-btn-one {
                    padding: 9px 26px;
                }
            }
        }

        .location-box {
            .map-inner {
                position: relative;
                border-radius: 10px;
                border: 5px solid #dad9e7;
                height: 250px;
                margin-bottom: 20px;

                #contact-google-map {
                    position: relative;
                    width: 100%;
                    height: 250px;
                }
            }

            h4 {
                position: relative;
                display: block;
                font-size: 20px;
                line-height: 28px;
                font-weight: 700;
                margin-bottom: 6px;
            }

            .location-info {
                li {
                    position: relative;
                    display: block;
                    padding-left: 20px;
                    margin-bottom: 6px;

                    &:last-child {
                        margin-bottom: 0px;
                    }

                    a {
                        color: #3C5A7D;
                    }

                    i {
                        position: absolute;
                        left: 0px;
                        top: 7px;
                    }
                }
            }
        }
    }
}

.clinic-sidebar {
    .form-widget {
        position: relative;
        display: block;
        background: #fff;
        border-radius: 10px;
        overflow: hidden;
        margin-bottom: 40px;

        .form-title {
            position: relative;
            padding: 28px 40px 24px 40px;

            h3 {
                font-size: 26px;
                line-height: 30px;
                color: #fff;
                font-weight: 700;
            }

            p {
                color: rgba(255, 255, 255, 0.6);
            }
        }

        .form-inner {
            position: relative;
            padding: 40px 40px;

            .form-group {
                position: relative;
                margin-bottom: 10px;

                &:last-child {
                    margin-bottom: 0px;
                }

                input[type='text'] {
                    position: relative;
                    width: 100%;
                    height: 50px;
                    border: 1px solid #e5e7ec;
                    border-radius: 10px;
                    padding: 10px 20px;
                    font-size: 15px;
                    color: #808080;
                    transition: all 500ms ease;
                }

                input[type='email'] {
                    position: relative;
                    width: 100%;
                    height: 50px;
                    border: 1px solid #e5e7ec;
                    border-radius: 10px;
                    padding: 10px 20px;
                    font-size: 15px;
                    color: #808080;
                    transition: all 500ms ease;
                }

                textarea {
                    position: relative;
                    width: 100%;
                    height: 50px;
                    border: 1px solid #e5e7ec;
                    border-radius: 10px;
                    padding: 10px 20px;
                    font-size: 15px;
                    color: #808080;
                    transition: all 500ms ease;
                    height: 120px;
                    resize: none;
                }

                .nice-select {
                    height: 50px;
                    border: 1px solid #e5e7ec !important;
                    border-radius: 10px;
                    padding: 5px 20px;
                    font-size: 15px;
                    color: #808080;
                    z-index: 1;

                    &:after {
                        top: 10px;
                        color: #808080;
                    }
                }

                .theme-btn-one {
                    display: block;
                    width: 100%;
                }
            }

            .form-group.select-box {
                height: 50px;
            }
        }
    }

    .info-widget {
        position: relative;
        display: block;
        background: #fff;
        border-radius: 10px;
        overflow: hidden;

        .info-title {
            position: relative;
            padding: 28px 40px 24px 40px;

            h3 {
                font-size: 26px;
                line-height: 30px;
                color: #fff;
                font-weight: 700;
            }

            p {
                color: rgba(255, 255, 255, 0.6);
            }
        }

        .info-inner {
            position: relative;
            padding: 34px 40px 40px 40px;

            .info-list {
                li {
                    position: relative;
                    display: block;
                    padding-bottom: 23px;
                    margin-bottom: 24px;
                    border-bottom: 1px solid #e5e7ec;

                    &:last-child {
                        margin-bottom: 0px;
                    }

                    h4 {
                        position: relative;
                        display: block;
                        font-size: 20px;
                        line-height: 28px;
                        font-weight: 600;
                        margin-bottom: 5px;
                    }

                    p {
                        font-size: 15px;
                        line-height: 26px;
                        color: #808080;

                        a {
                            color: #808080;
                        }
                    }
                }

                margin-bottom: 26px;
            }
        }

        .social-box {
            h4 {
                position: relative;
                display: block;
                font-size: 20px;
                line-height: 28px;
                font-weight: 600;
                margin-bottom: 12px;
            }

            .social-links {
                li {
                    position: relative;
                    display: inline-block;
                    float: left;
                    margin-right: 10px;

                    &:last-child {
                        margin: 0px !important;
                    }

                    a {
                        position: relative;
                        display: inline-block;
                        width: 44px;
                        height: 44px;
                        line-height: 44px;
                        border: 1px solid #e6e7ec;
                        border-radius: 50%;
                        text-align: center;
                        font-size: 16px;
                        color: #8e9998;

                        &:hover {
                            color: #fff;
                        }
                    }
                }
            }
        }
    }
}

.patient-dashboard {
    position: relative;
    padding: 50px 50px;
}

.left-panel {
    position: absolute;
    left: 50px;
    top: 50px;
}

.profile-box {
    width: 375px;
    background: #fff;
    border-radius: 10px;
    overflow: hidden;
    border: 1px solid #e5eded;
    min-height: 980px;

    .upper-box {
        position: relative;
        margin-bottom: 70px;

        .profile-image {
            position: relative;
            display: block;
            text-align: center;

            img {
                width: 100%;
            }
        }

        .title-box {
            position: relative;
            margin: 0px 40px;

            .inner {
                position: relative;
                background: #fff;
                border-radius: 10px;
                padding: 37px 30px 35px 30px;
                margin-top: -65px;
                box-shadow: 0 20px 30px rgba(0, 24, 73, 0.08);

                h3 {
                    font-size: 22px;
                    line-height: 30px;
                    font-weight: 700;
                    margin-bottom: 0px;
                }

                p {
                    i {
                        font-size: 15px;
                        margin-right: 10px;
                    }
                }
            }
        }
    }

    .profile-info {
        position: relative;
        padding-bottom: 34px;

        .list {
            li {
                position: relative;
                display: block;
                padding: 15px 40px 12px 40px;
                border-top: 1px solid #e5eded;

                a {
                    font-size: 16px;
                    line-height: 26px;
                    color: #3C5A7D;
                    font-weight: 500;

                    i {
                        font-size: 15px;
                        margin-right: 20px;
                        color: #b3b3b3;
                        transition: all 500ms ease;
                    }
                }

                span {
                    position: absolute;
                    top: 20px;
                    right: 40px;
                    font-size: 10px;
                    line-height: 14px;
                    font-weight: 500;
                    display: inline-block;
                    color: #fff;
                    padding: 0px 9px;
                    border-radius: 15px;
                }
            }
        }
    }
}

.profile-box.patient-profile {
    min-height: 813px;
}

.right-panel {
    .content-container {
        margin-left: 420px;
    }

    .outer-container {
        max-width: 1390px;
        width: 100%;
        margin: 0 auto;
        position: relative;
    }

    .feature-content {
        position: relative;
        margin-bottom: 50px;
    }

    .message-box {
        position: relative;
        display: block;
        background: #fff;
        border: 1px solid #e5eded;
        border-radius: 10px;
        overflow: hidden;

        .chat-room {
            position: relative;
            padding: 40px 40px;

            .side-title {
                position: relative;
                display: block;
                padding: 15px 30px;

                h3 {
                    font-size: 22px;
                    line-height: 30px;
                    color: #fff;
                    font-weight: 600;
                    margin: 0px;
                }
            }

            #sidepanel {
                #search {
                    position: relative;
                    display: block;
                    padding: 30px 30px;
                }
            }
        }
    }
}

.feature-block-two {
    .inner-box {
        position: relative;
        display: block;
        background: #fff;
        overflow: hidden;
        border-radius: 10px;
        border: 1px solid #e5eded;
        padding: 67px 60px 62px 180px;

        .icon-box {
            position: absolute;
            left: 50px;
            top: 50px;
            width: 100px;
            height: 100px;
            line-height: 100px;
            background: #fff;
            font-size: 50px;
            text-align: center;
            border-radius: 50%;
            box-shadow: 0 10px 30px #e6efee;
        }

        h3 {
            font-size: 30px;
            line-height: 36px;
            font-weight: 900;
            margin-bottom: 0px;
        }

        h5 {
            font-size: 18px;
            line-height: 15px;
            color: #808080;
            font-weight: 500;
            margin-bottom: 6px;
        }

        .pattern {
            .pattern-1 {
                position: absolute;
                left: 0px;
                top: 0px;
                width: 293px;
                height: 121px;
                background-repeat: no-repeat;
            }

            .pattern-2 {
                position: absolute;
                right: 0px;
                bottom: 0px;
                width: 195px;
                height: 100px;
                background-repeat: no-repeat;
            }
        }
    }
}

.doctors-appointment {
    position: relative;
    display: block;
    background: #fff;
    border-radius: 10px;
    border: 1px solid #e5eded;

    .title-box {
        position: relative;
        padding: 33px 40px 40px 40px;

        h3 {
            font-size: 26px;
            line-height: 34px;
            font-weight: 700;
            margin-bottom: 33px;
        }

        .btn-box {
            .theme-btn-one {
                padding: 9px 28px;
                margin-right: 10px;
            }

            .theme-btn-two {
                padding: 7px 28px;
            }
        }
    }

    .table-outer {
        overflow-x: auto;
        position: relative;
        width: 100%;
    }

    .doctors-table {
        min-width: 1170px;
        width: 100%;

        .table-header {
            position: relative;
            background: #f4f7f7;

            th {
                font-size: 18px;
                line-height: 26px;
                color: #3C5A7D;
                font-weight: 600;
                padding: 13px 30px;

                &:first-child {
                    padding-left: 40px;
                }
            }
        }

        tr {
            border-top: 1px solid #e5eded;

            td {
                .name-box {
                    position: relative;
                    padding: 23px 0px 21px 100px;

                    .image {
                        position: absolute;
                        left: 40px;
                        top: 20px;
                        width: 50px;
                        height: 50px;
                        border-radius: 50%;

                        img {
                            width: 100%;
                            border-radius: 50%;
                        }
                    }

                    h5 {
                        font-size: 18px;
                        line-height: 24px;
                        font-weight: 500;
                        margin-bottom: 0px;
                    }

                    .designation {
                        position: relative;
                        display: block;
                        font-size: 15px;
                        line-height: 20px;
                    }
                }

                p {
                    font-size: 16px;
                    line-height: 18px;
                    color: #3C5A7D;
                    padding: 0px 30px;
                }

                .time {
                    position: relative;
                    display: block;
                    font-size: 15px;
                    line-height: 24px;
                    padding: 0px 30px;
                }

                .status {
                    position: relative;
                    display: inline-block;
                    font-size: 13px;
                    line-height: 16px;
                    font-weight: 600;
                    padding: 5px 15px;
                    border-radius: 15px;
                    background: #ebfbf3;
                    margin-left: 30px;
                }

                .status.cancel {
                    background: #f5e7e5;
                    position: relative;
                    display: inline-block;
                    font-size: 13px;
                    line-height: 16px;
                    font-weight: 600;
                    padding: 5px 15px;
                    border-radius: 15px;
                }

                .status.pending {
                    background: #fff6e5;
                }

                .print {
                    position: relative;
                    display: inline-block;
                    font-size: 15px;
                    font-weight: 500;
                    line-height: 20px;
                    padding: 9px 20px;
                    background: #ebfaf8;
                    border-radius: 25px;
                    margin-right: 10px;
                    float: right;

                    i {
                        margin-right: 5px;
                        font-size: 12px;
                    }
                }

                .view {
                    position: relative;
                    display: inline-block;
                    font-size: 15px;
                    font-weight: 500;
                    line-height: 20px;
                    padding: 9px 20px;
                    background: #e5f7fb;
                    border-radius: 25px;

                    i {
                        margin-right: 5px;
                        font-size: 12px;
                    }
                }

                .accept {
                    position: relative;
                    display: inline-block;
                    font-size: 15px;
                    font-weight: 500;
                    line-height: 20px;
                    padding: 9px 20px;
                    background: #ebfaf8;
                    border-radius: 25px;
                    float: right;
                    margin-right: 10px;

                    i {
                        font-size: 12px;
                        margin-right: 5px;
                    }
                }

                .cancel {
                    position: relative;
                    display: inline-block;
                    font-size: 15px;
                    font-weight: 500;
                    line-height: 20px;
                    padding: 9px 21.5px;
                    background: #ffeeec;
                    border-radius: 25px;
                    margin-right: 30px;

                    i {
                        font-size: 12px;
                        margin-right: 5px;
                    }
                }
            }
        }

        tr.cancel-block {
            background: #f4f7f7;
        }
    }
}

.pt-100 {
    padding-top: 100px;
}

.submit-review {
    position: relative;
    padding: 120px 0px;

    .review-box {
        position: relative;
        max-width: 570px;
        width: 100%;
        margin: 0 auto;

        .content-box {
            position: relative;
            display: block;
            background: #fff;
            border-radius: 10px;
            overflow: hidden;

            .title-inner {
                position: relative;
                padding: 27px 40px 25px 40px;

                h3 {
                    font-size: 26px;
                    line-height: 32px;
                    color: #fff;
                    font-weight: 700;
                    margin-bottom: 0px;
                }

                p {
                    font-size: 15px;
                    line-height: 26px;
                    color: rgba(255, 255, 255, 0.6);
                }
            }

            .content-inner {
                position: relative;
                padding: 36px 40px 40px 40px;
            }
        }

        .content-inner {
            .rating-box {
                h4 {
                    font-size: 20px;
                    line-height: 28px;
                    font-weight: 700;
                    margin-bottom: 12px;
                }

                .rating {
                    li {
                        position: relative;
                        display: inline-block;
                        float: left;
                        width: 45px;
                        height: 45px;
                        line-height: 45px;
                        border: 1px solid #e5e7ec;
                        border-radius: 5px;
                        font-size: 18px;
                        color: #d6dada;
                        margin-right: 5px;
                        text-align: center;

                        &:last-child {
                            margin: 0px !important;
                        }
                    }
                }

                padding-bottom: 30px;
                border-bottom: 1px solid #e5e7ec;
            }

            .form-inner {
                position: relative;
                padding-top: 23px;

                .form-group {
                    position: relative;
                    margin-bottom: 23px;

                    &:last-child {
                        margin-bottom: 0px;
                    }

                    label {
                        position: relative;
                        display: block;
                        font-size: 15px;
                        font-weight: 500;
                        margin-bottom: 6px;
                    }

                    input[type='text'] {
                        position: relative;
                        width: 100%;
                        height: 50px;
                        border: 1px solid #e5e7ec;
                        border-radius: 10px;
                        font-size: 15px;
                        color: #808080;
                        padding: 10px 20px;
                        transition: all 500ms ease;
                    }

                    input[type='email'] {
                        position: relative;
                        width: 100%;
                        height: 50px;
                        border: 1px solid #e5e7ec;
                        border-radius: 10px;
                        font-size: 15px;
                        color: #808080;
                        padding: 10px 20px;
                        transition: all 500ms ease;
                    }

                    textarea {
                        position: relative;
                        width: 100%;
                        height: 50px;
                        border: 1px solid #e5e7ec;
                        border-radius: 10px;
                        font-size: 15px;
                        color: #808080;
                        padding: 10px 20px;
                        transition: all 500ms ease;
                        height: 150px;
                        resize: none;
                    }

                    button {
                        display: block;
                        width: 100%;
                    }
                }
            }
        }
    }

    .custom-control.material-checkbox {
        --color: #ebebeb;
        padding: 0px;
        margin: 0px;
        margin-bottom: 0px !important;

        .material-control-input {
            display: none;

            &:checked {
                ~ {
                    .material-control-indicator {
                        -webkit-transform: rotateZ(45deg) translate(1px, -5px);
                        transform: rotateZ(45deg) translate(1px, -5px);
                        width: 8px;
                        border: 2px solid #848484;
                        border-top: 0px;
                        border-left: 0px;
                        background: transparent;
                        border-radius: 0px;
                    }
                }
            }
        }

        .material-control-indicator {
            display: inline-block;
            position: absolute;
            top: 5px;
            left: 0px;
            width: 20px;
            height: 20px;
            border: 1px solid #e5e7ec;
            cursor: pointer;
        }
    }

    .custom-controls-stacked {
        .description {
            position: relative;
            display: inline-block;
            padding: 0px;
            margin-left: 30px;
            font-size: 15px;
            color: #808080;
            cursor: pointer;
            font-weight: 400;

            a {
                display: inline-block;
                line-height: 16px;
            }
        }
    }

    .pattern {
        .pattern-1 {
            position: absolute;
            right: 550px;
            top: 50px;
            width: 460px;
            height: 387px;
            background-repeat: no-repeat;
        }

        .pattern-2 {
            position: absolute;
            left: 500px;
            bottom: 240px;
            width: 460px;
            height: 387px;
            background-repeat: no-repeat;
        }
    }
}

.appointment-section {
    position: relative;
    padding: 120px 0px;

    .appointment-information {
        position: relative;
        display: block;
        background: #fff;
        border-radius: 10px;
        overflow: hidden;
        margin-right: 10px;

        .inner-box {
            padding: 30px 40px 40px 40px;

            .single-box {
                margin-bottom: 39px;
            }
        }

        .custom-check-box {
            margin-bottom: 12px;

            &:last-child {
                margin-bottom: 0px;
            }
        }
    }

    .title-box {
        position: relative;
        display: block;
        padding: 30px 40px 28px 40px;

        h3 {
            font-size: 26px;
            line-height: 32px;
            color: #fff;
            font-weight: 700;
            margin: 0px;
        }
    }

    h3 {
        font-size: 22px;
        line-height: 30px;
        font-weight: 600;
        margin-bottom: 19px;
    }

    .information-form {
        .form-group {
            position: relative;
            margin-bottom: 23px;

            &:last-child {
                margin-bottom: 0px;
            }

            label {
                position: relative;
                display: block;
                font-size: 15px;
                color: #808080;
                font-weight: 500;
                margin-bottom: 6px;
            }

            input[type='text'] {
                position: relative;
                width: 100%;
                height: 50px;
                border: 1px solid #e5e7ec;
                border-radius: 10px;
                font-size: 15px;
                color: #808080;
                padding: 10px 20px;
                transition: all 500ms ease;
            }

            input[type='email'] {
                position: relative;
                width: 100%;
                height: 50px;
                border: 1px solid #e5e7ec;
                border-radius: 10px;
                font-size: 15px;
                color: #808080;
                padding: 10px 20px;
                transition: all 500ms ease;
            }

            textarea {
                position: relative;
                width: 100%;
                height: 50px;
                border: 1px solid #e5e7ec;
                border-radius: 10px;
                font-size: 15px;
                color: #808080;
                padding: 10px 20px;
                transition: all 500ms ease;
                height: 150px;
                resize: none;
            }

            .nice-select {
                width: 100%;
                height: 50px;
                border: 1px solid #e5e7ec !important;
                border-radius: 10px;
                font-size: 15px;
                color: #808080;
                padding: 5px 20px;

                &:after {
                    top: 10px;
                }
            }
        }

        margin-bottom: 34px;
    }

    .payment-information {
        .form-group {
            position: relative;
            margin-bottom: 23px;

            label {
                position: relative;
                display: block;
                font-size: 15px;
                font-weight: 500;
                margin-bottom: 6px;
                min-height: 28px;
            }

            input[type='text'] {
                position: relative;
                width: 100%;
                height: 50px;
                border: 1px solid #e5e7ec;
                border-radius: 10px;
                font-size: 15px;
                color: #808080;
                padding: 10px 20px;
                transition: all 500ms ease;
            }

            .card-list {
                li {
                    position: relative;
                    float: left;
                    line-height: 50px;
                    margin-right: 10px;

                    &:last-child {
                        margin: 0px !important;
                    }
                }
            }
        }

        .custom-check-box {
            padding-top: 6px;

            .description {
                color: #808080;
            }
        }
    }

    .booking-information {
        position: relative;
        display: block;
        background: #fff;
        border-radius: 10px;
        overflow: hidden;

        .inner-box {
            padding: 14px 40px 18px 40px;
            border-bottom: 1px solid #e5e7ec;
        }

        .single-box {
            ul {
                li {
                    position: relative;
                    display: block;
                    font-size: 15px;
                    font-weight: 500;
                    color: #3C5A7D;
                    margin-bottom: 10px;

                    span {
                        float: right;
                    }

                    &:last-child {
                        margin-bottom: 0px;
                    }
                }
            }

            padding-bottom: 16px;
            border-bottom: 1px solid #e5e7ec;
            padding-top: 19px;
        }

        .total-box {
            h5 {
                position: relative;
                display: block;
                font-size: 18px;
                line-height: 26px;
                font-family: 'Poppins', sans-serif;
                font-weight: 500;
                padding-top: 21px;

                span {
                    float: right;
                }
            }
        }

        .btn-box {
            padding: 30px 40px 50px 40px;

            .theme-btn-one {
                display: block;
                width: 100%;
            }
        }
    }
}

.custom-check-box {
    .custom-control.material-checkbox {
        .material-control-input {
            display: none;

            &:checked {
                ~ {
                    .material-control-indicator {
                        &:before {
                            opacity: 1;
                        }

                        display: inline-block;
                        position: absolute;
                        top: 4px;
                        left: 0px;
                        width: 20px;
                        height: 20px;
                        cursor: pointer;
                        border-radius: 3px;
                        border: 1px solid #e5e7ec;
                    }
                }
            }
        }

        .material-control-indicator {
            display: inline-block;
            position: absolute;
            top: 4px;
            left: 0px;
            width: 20px;
            height: 20px;
            border: 1px solid #e5e7ec;
            cursor: pointer;
            border-radius: 3px;

            &:before {
                position: absolute;
                content: "\f00c";
                font-family: 'Font Awesome 5 Pro';
                font-size: 12px;
                color: #fff;
                width: 20px;
                height: 20px;
                line-height: 20px;
                text-align: center;
                opacity: 0;
            }
        }
    }

    .custom-controls-stacked {
        .description {
            position: relative;
            display: inline-block;
            padding: 0px;
            margin-left: 10px;
            font-size: 15px;
            color: #3C5A7D;
            cursor: pointer;
            font-weight: 400;
        }
    }
}

.confirm-section {
    position: relative;
    padding: 120px 0px;

    .inner-box {
        position: relative;
        max-width: 570px;
        width: 100%;
        margin: 0 auto;

        .content-box {
            position: relative;
            display: block;
            background: #fff;
            padding: 100px 50px 90px 50px;
            border-radius: 10px;

            .icon-box {
                position: relative;
                display: inline-block;
                width: 120px;
                height: 120px;
                line-height: 120px;
                font-size: 40px;
                font-weight: 400;
                text-align: center;
                border-radius: 50%;
                margin-bottom: 25px;
            }

            h3 {
                position: relative;
                display: block;
                font-size: 30px;
                line-height: 38px;
                font-weight: 900;
                margin-bottom: 22px;
            }

            p {
                display: block;
                font-weight: 500;
            }

            a {
                display: inline-block;
                font-size: 15px;
                font-weight: 500;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    .pattern {
        .pattern-1 {
            position: absolute;
            right: 550px;
            top: 50px;
            width: 460px;
            height: 387px;
            background-repeat: no-repeat;
        }

        .pattern-2 {
            position: absolute;
            left: 500px;
            bottom: 0px;
            width: 460px;
            height: 387px;
            background-repeat: no-repeat;
        }
    }
}

.registration-section {
    position: relative;
    padding: 120px 0px;

    .inner-box {
        position: relative;
        max-width: 570px;
        width: 100%;
        margin: 0 auto;
    }

    .content-box {
        position: relative;
        display: block;
        background: #fff;
        border-radius: 10px;
        overflow: hidden;

        .title-box {
            position: relative;
            display: block;
            padding: 27px 40px 31px 40px;

            h3 {
                font-size: 26px;
                line-height: 32px;
                color: #fff;
                font-weight: 700;
                margin: 0px;
            }

            a {
                position: absolute;
                top: 33px;
                right: 40px;
                font-size: 18px;
                font-family: 'Jost', sans-serif;
                line-height: 26px;
                font-weight: 700;
                z-index: 1;

                &:hover {
                    color: #fff;
                }
            }
        }

        .inner {
            position: relative;
            padding: 33px 40px 35px 40px;

            .form-group {
                position: relative;
                margin-bottom: 23px;

                &:last-child {
                    margin-bottom: 0px;
                }

                label {
                    position: relative;
                    display: block;
                    font-size: 15px;
                    color: #808080;
                    font-weight: 500;
                    margin-bottom: 6px;
                }

                input[type='text'] {
                    position: relative;
                    width: 100%;
                    height: 50px;
                    border: 1px solid #e5e7ec;
                    border-radius: 10px;
                    font-size: 15px;
                    color: #808080;
                    padding: 10px 20px;
                    transition: all 500ms ease;
                }

                input[type='email'] {
                    position: relative;
                    width: 100%;
                    height: 50px;
                    border: 1px solid #e5e7ec;
                    border-radius: 10px;
                    font-size: 15px;
                    color: #808080;
                    padding: 10px 20px;
                    transition: all 500ms ease;
                }

                input[type='password'] {
                    position: relative;
                    width: 100%;
                    height: 50px;
                    border: 1px solid #e5e7ec;
                    border-radius: 10px;
                    font-size: 15px;
                    color: #808080;
                    padding: 10px 20px;
                    transition: all 500ms ease;
                }

                button {
                    display: block;
                    width: 100%;
                }

                .forgot-passowrd {
                    a {
                        position: relative;
                        float: right;
                        font-size: 15px;
                        color: #808080;
                        z-index: 1;
                    }
                }
            }

            .social-links {
                li {
                    position: relative;
                    display: inline-block;
                    float: left;
                    width: 50%;

                    a {
                        position: relative;
                        display: inline-block;
                        width: 240px;
                        font-size: 15px;
                        line-height: 26px;
                        font-weight: 600;
                        color: #fff;
                        padding: 12px 20px;
                        text-align: center;
                        z-index: 1;
                        border-radius: 30px;
                    }
                }

                position: relative;
                //margin-top: ;
            }

            .text {
                position: relative;
                display: block;
                text-align: center;
                margin-top: 20px;
                margin-bottom: 20px;

                &:before {
                    position: absolute;
                    content: '';
                    background: #e5e7ec;
                    width: 100%;
                    height: 1px;
                    left: 0px;
                    top: 19px;
                }

                span {
                    position: relative;
                    display: inline-block;
                    font-size: 26px;
                    line-height: 32px;
                    font-family: 'Jost', sans-serif;
                    color: #8a8a8a;
                    font-weight: 400;
                    width: 65px;
                    background: #fff;
                    text-align: center;
                }
            }
        }

        .login-now {
            p {
                a {
                    display: inline-block;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }

            position: relative;
            display: block;
            text-align: center;
            margin-top: 22px;
        }
    }

    .custom-check-box {
        .custom-controls-stacked {
            .description {
                color: #808080;
            }
        }
    }

    .pattern {
        .pattern-1 {
            position: absolute;
            right: 550px;
            top: 50px;
            width: 460px;
            height: 387px;
            background-repeat: no-repeat;
        }

        .pattern-2 {
            position: absolute;
            left: 500px;
            bottom: 240px;
            width: 460px;
            height: 387px;
            background-repeat: no-repeat;
        }
    }
}

.dashboard-section {
    position: relative;
}

.favourite-doctors {
    position: relative;
    padding: 50px 0px;

    .team-block-three {
        .inner-box {
            margin-bottom: 30px;
        }
    }

    .title-box {
        position: relative;
        padding-bottom: 20px;
        border-bottom: 1px solid #e5eded;
        margin-bottom: 50px;

        h3 {
            font-size: 26px;
            line-height: 32px;
            font-weight: 700;
            margin: 0px;
        }
    }

    .pagination-wrapper {
        padding-top: 30px;
    }
}

.doctor-details {
    position: relative;
    padding: 40px 0px 120px 0px;
}

.doctor-details-content {
    .tabs-box {
        .tab-btn-box {
            .tab-btns {
                li {
                    //width: 25%;
                }
            }
        }

        .tabs-content {
            .inner-box {
                .text {
                    .list {
                        li {
                            width: 100%;
                            float: none;

                            span {
                                font-weight: 400;
                                color: #808080;
                            }
                        }
                    }

                    .treatments-list {
                        li {
                            position: relative;
                            display: block;
                            font-size: 16px;
                            color: #3C5A7D;
                            margin-bottom: 6px;

                            &:last-child {
                                margin-bottom: 0px;
                            }

                            a {
                                color: #3C5A7D;
                            }
                        }

                        margin-bottom: 35px;
                    }
                }
            }

            .experience-box {
                .text {
                    h3 {
                        position: relative;
                        display: block;
                        font-size: 22px;
                        line-height: 30px;
                        font-weight: 700;
                        margin-bottom: 16px;
                    }

                    p {
                        margin-bottom: 13px;
                    }

                    .experience-list {
                        li {
                            position: relative;
                            display: block;
                            font-size: 17px;
                            line-height: 28px;
                            font-weight: 500;
                            color: #3C5A7D;
                            padding-left: 25px;
                            margin-bottom: 8px;

                            &:before {
                                position: absolute;
                                content: '';
                                border: 3px solid #dad9e7;
                                width: 12px;
                                height: 12px;
                                border-radius: 50%;
                                left: 0px;
                                top: 7px;
                            }

                            &:last-child {
                                margin-bottom: 0px;
                            }

                            p {
                                display: block;
                                font-size: 15px;
                                line-height: 30px;
                                color: #3C5A7D;
                                margin: 0px;

                                span {
                                    color: #808080;
                                }
                            }
                        }

                        margin-bottom: 33px;
                    }

                    .skills-list {
                        li {
                            position: relative;
                            display: block;
                            font-size: 16px;
                            line-height: 28px;
                            color: #3C5A7D;
                            padding-left: 25px;
                            margin-bottom: 6px;

                            &:before {
                                position: absolute;
                                content: '';
                                border: 3px solid #dad9e7;
                                width: 12px;
                                height: 12px;
                                border-radius: 50%;
                                left: 0px;
                                top: 7px;
                            }

                            &:last-child {
                                margin-bottom: 0px;
                            }
                        }
                    }
                }
            }

            .review-box {
                .rating-inner {
                    position: relative;
                    padding-left: 180px;
                    padding-bottom: 10px;
                    border-bottom: 1px solid #e5e7ec;
                    margin-bottom: 28px;

                    .rating-box {
                        position: absolute;
                        left: 0px;
                        top: 0px;
                        width: 150px;
                        border-radius: 10px;
                        padding: 18px 15px 22px 15px;
                        text-align: center;

                        h2 {
                            font-size: 40px;
                            line-height: 40px;
                            font-weight: 700;
                            margin-bottom: 4px;
                        }

                        .clearfix {
                            li {
                                position: relative;
                                display: inline-block;
                                font-size: 14px;
                                line-height: 20px;
                                margin: 0px;

                                &:last-child {
                                    color: #a29fc2;
                                }
                            }

                            margin-bottom: 4px;
                        }

                        span {
                            position: relative;
                            display: block;
                            font-size: 14px;
                            line-height: 17px;
                            color: #fff;
                            font-weight: 500;
                        }
                    }

                    .rating-progress {
                        position: relative;

                        .porgress-bar {
                            position: relative;
                            display: block;
                            width: 100%;
                            max-width: 390px;
                            height: 15px;
                            background: #ebf7f6;
                            border-radius: 5px;
                            overflow: hidden;

                            &:before {
                                position: absolute;
                                content: '';
                                height: 100%;
                                left: 0px;
                                top: 0px;
                            }
                        }

                        .single-progress {
                            &:first-child {
                                .porgress-bar {
                                    &:before {
                                        width: 80%;
                                    }
                                }
                            }

                            &:nth-child(2) {
                                .porgress-bar {
                                    &:before {
                                        width: 70%;
                                    }
                                }
                            }

                            &:nth-child(3) {
                                .porgress-bar {
                                    &:before {
                                        width: 60%;
                                    }
                                }
                            }

                            &:nth-child(4) {
                                .porgress-bar {
                                    &:before {
                                        width: 50%;
                                    }
                                }
                            }

                            &:last-child {
                                .porgress-bar {
                                    &:before {
                                        width: 40%;
                                    }
                                }
                            }

                            .text {
                                position: absolute;
                                top: -6px;
                                right: 5px;

                                p {
                                    color: #3C5A7D;

                                    i {
                                        margin-right: 10px;
                                    }
                                }
                            }

                            position: relative;
                            margin-bottom: 19px;
                        }
                    }
                }

                h3 {
                    margin-bottom: 23px;
                }

                .review-inner {
                    margin-bottom: 30px;
                }
            }
        }
    }

    .single-review-box {
        border-bottom: 1px solid #dfe2e7;
    }
}

.single-review-box {
    position: relative;
    padding: 0px 0px 36px 125px;
    margin-bottom: 30px;

    &:last-child {
        margin-bottom: 0px;
    }

    .image-box {
        position: absolute;
        left: 0px;
        top: 2px;
        width: 95px;
        height: 95px;
        border-radius: 10px;

        img {
            width: 100%;
            border-radius: 10px;
        }
    }

    .rating {
        position: relative;
        line-height: 14px;

        li {
            position: relative;
            display: inline-block;
            font-size: 14px;
            line-height: 20px;
            margin: 0px;
        }

        li.light {
            color: #dae6e5;
        }
    }

    h6 {
        position: relative;
        display: block;
        font-size: 15px;
        line-height: 22px;
        font-weight: 500;
        font-family: 'Poppins', sans-serif;
        margin-bottom: 7px;

        span {
            color: #808080;
        }
    }

    p {
        line-height: 26px;
        margin: 0px;
    }
}

#ui-datepicker-div.ui-widget-content {
    border: 1px solid #c5c5c5;
    background: #fff none repeat scroll 0 0;
    border: 1px solid #777;
    color: #252525;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    border-radius: 0px;
    width: 270px;
    padding: 5px;
}

#ui-datepicker-div.ui-datepicker {
    .ui-datepicker-header {
        background: #151515 none repeat scroll 0 0;
        border: 2px solid #252525;
        border-radius: 0;
        color: #fff;
        font-weight: 700;
        padding: 5px 0;
        position: relative;
    }
}

.ui-datepicker {
    td {
        a {
            color: #000000 !important;
            text-align: center;
            background-image: none !important;
            background: #f5f5f5 !important;
            border: 1px solid #f9f9f9 !important;
        }
    }

    .ui-datepicker-prev {
        left: 5px;
        border-radius: 30%;
        height: 20px;
        position: absolute;
        top: 7px;
        width: 20px;
        transition: all 500ms ease;
        cursor: pointer;

        &:hover {
            top: 7px;
            background: #fff;
            border: none;
        }
    }

    .ui-datepicker-next {
        border-radius: 30%;
        height: 20px;
        position: absolute;
        top: 7px;
        width: 20px;
        transition: all 500ms ease;
        cursor: pointer;
        right: 5px;

        &:hover {
            top: 7px;
            background: #fff;
            border: none;
        }
    }

    table {
        border-collapse: collapse;
        font-size: 13px;
        margin: 0 0 0.4em;
        width: 100%;
        z-index: 99999999;
    }

    th {
        border: medium none;
        font-weight: 600;
        padding: 2px 3px;
        text-align: center;
    }
}

.ui-state-highlight {
    border: 1px solid #333333 !important;
    background: #333333 !important;
    color: #ffffff !important;
}

.ui-widget-content {
    .ui-state-highlight {
        border: 1px solid #333333 !important;
        background: #333333 !important;
        color: #ffffff !important;
    }
}

.ui-widget-header {
    .ui-state-highlight {
        border: 1px solid #333333 !important;
        background: #333333 !important;
        color: #ffffff !important;
    }
}

.ui-datepicker-calendar {
    .ui-state-default {
        &:hover {
            background: #43c3ea;
            color: #fff;
            border-color: #43c3ea;
        }

        &:active {
            background: #43c3ea;
            color: #fff;
            border-color: #43c3ea;
        }
    }
}

.doctors-sidebar {
    .form-widget {
        position: relative;
        display: block;
        background: #fff;
        border-radius: 10px;
        overflow: hidden;

        .form-title {
            position: relative;
            padding: 20px 30px 20px 30px;

            h3 {
                font-size: 26px;
                line-height: 34px;
                color: #fff;
                font-weight: 700;
                margin-bottom: 0px;
            }

            p {
                font-size: 15px;
                line-height: 26px;
                color: rgba(255, 255, 255, 0.7);
            }
        }

        .appointment-time {
            position: relative;
            padding: 40px 40px;
            border-bottom: 1px solid #e5e7ec;

            .form-group {
                position: relative;
                margin-bottom: 10px;

                &:last-child {
                    margin-bottom: 0px;
                }

                input[type='text'] {
                    position: relative;
                    width: 100%;
                    height: 50px;
                    background: #ebf7f6;
                    padding: 10px 20px;
                    color: #808080;
                    font-size: 14px;
                    border-radius: 10px;
                }

                i {
                    position: absolute;
                    top: 17px;
                    right: 20px;
                    z-index: 1;
                }
            }
        }

        .choose-service {
            position: relative;
            padding: 33px 40px 40px 40px;

            h4 {
                font-size: 20px;
                line-height: 28px;
                font-weight: 600;
                margin-bottom: 12px;
            }

            .btn-box {
                position: relative;
                padding-top: 10px;

                .theme-btn-one {
                    display: block;
                    width: 100%;
                }
            }
        }

        .custom-check-box {
            .custom-controls-stacked {
                .description {
                    font-weight: 500;
                    display: block;
                    margin-left: 0px;

                    .price {
                        float: right;
                    }
                }
            }

            padding: 9px 15px 3px 15px;
            border: 1px solid #e5e7ec;
            border-radius: 10px;
            margin-bottom: 10px;

            &:last-child {
                margin-bottom: 0px;
            }

            .custom-control.material-checkbox {
                .material-control-indicator {
                    background: #e5e7ec;
                    width: 15px;
                    height: 15px;
                    top: 7px;

                    &:before {
                        width: 15px;
                        height: 15px;
                        line-height: 15px;
                        font-size: 10px;
                    }
                }
            }
        }
    }
}

.doctors-dashboard {
    position: relative;
    padding: 50px 50px;
    min-height: 1080px;

    .right-panel {
        .appointment-list {
            position: relative;
            display: block;
            background: #fff;
            border: 1px solid #e5eded;
            border-radius: 10px;
            overflow: hidden;
            margin-bottom: 30px;

            .upper-box {
                h3 {
                    position: relative;
                    font-size: 26px;
                    line-height: 44px;
                    font-weight: 700;
                }

                padding: 30px 40px;
                border-bottom: 1px solid #e5eded;

                .nice-select {
                    height: 44px;
                    border: 1px solid #e5eded !important;
                    border-radius: 30px;
                    padding: 3px 20px;
                    width: 175px;
                    color: #3C5A7D;
                    font-size: 14px;
                    font-weight: 500;

                    &:after {
                        top: 7px;
                        right: 20px;
                        color: #3C5A7D;
                    }
                }
            }

            .single-item {
                position: relative;
                padding: 16px 40px 13px 40px;
                border-bottom: 1px solid #e5eded;

                &:last-child {
                    border-bottom: none;
                }

                .image-box {
                    position: absolute;
                    left: 40px;
                    top: 20px;
                    width: 100px;
                    height: 100px;
                    border-radius: 20px;
                    box-shadow: 0 10px 40px rgba(0, 24, 73, 0.2);

                    img {
                        width: 100%;
                        border-radius: 20px;
                    }
                }

                .inner {
                    padding-left: 150px;

                    h4 {
                        position: relative;
                        display: block;
                        font-size: 20px;
                        line-height: 28px;
                        font-weight: 600;
                        margin-bottom: 5px;
                    }

                    .info-list {
                        li {
                            position: relative;
                            display: block;
                            font-size: 15px;
                            line-height: 26px;
                            color: #727272;
                            margin-bottom: 1px;

                            &:last-child {
                                margin-bottom: 0px;
                            }

                            a {
                                display: inline-block;
                                color: #727272;
                            }

                            i {
                                font-size: 13px;
                                margin-right: 15px;
                                color: #c7c7c7;
                            }
                        }
                    }

                    .confirm-list {
                        position: absolute;
                        top: 48px;
                        right: 40px;

                        li {
                            position: relative;
                            display: inline-block;
                            font-size: 15px;
                            font-weight: 500;
                            line-height: 20px;
                            padding: 9px 20px;
                            background: #e9f9f7;
                            border-radius: 25px;

                            i {
                                margin-right: 5px;
                                font-size: 12px;
                            }

                            &:last-child {
                                background: #ffeeec;
                            }
                        }
                    }
                }
            }
        }
    }

    .pagination-wrapper {
        margin-top: 30px;
    }

    .review-list {
        position: relative;
        background: #fff;
        border: 1px solid #e5eded;
        border-radius: 10px;
        overflow: hidden;

        .title-box {
            position: relative;
            padding: 30px 40px;
            border-bottom: 1px solid #e5eded;

            h3 {
                position: relative;
                font-size: 26px;
                line-height: 44px;
                font-weight: 700;
            }

            .nice-select {
                height: 44px;
                border: 1px solid #e5eded !important;
                border-radius: 30px;
                padding: 3px 20px;
                width: 175px;
                color: #3C5A7D;
                font-size: 14px;
                font-weight: 500;

                &:after {
                    margin: 0px;
                    top: 2px;
                    color: #3C5A7D;
                }
            }
        }

        .comment-inner {
            .single-comment-box {
                position: relative;
                display: block;
                padding: 26px 40px 30px 40px;
                border-bottom: 1px solid #e5eded;

                &:last-child {
                    border-bottom: none;
                }

                .comment {
                    position: relative;
                    padding-left: 110px;

                    .comment-thumb {
                        position: absolute;
                        left: 0px;
                        top: 4px;
                        width: 80px;
                        height: 80px;
                        border-radius: 50%;

                        img {
                            width: 100%;
                            border-radius: 50%;
                        }
                    }

                    h4 {
                        font-size: 20px;
                        line-height: 28px;
                        font-weight: 600;
                        margin-bottom: 1px;
                    }

                    .comment-time {
                        position: relative;
                        display: block;
                        font-size: 15px;
                        margin-bottom: 15px;

                        i {
                            font-size: 14px;
                            margin-right: 10px;
                        }
                    }

                    .rating {
                        position: absolute;
                        top: 0px;
                        right: 0px;

                        li {
                            position: relative;
                            display: inline-block;
                            font-size: 15px;
                        }

                        li.light {
                            color: #b3b3b3;
                        }
                    }

                    p {
                        margin-bottom: 22px;
                    }

                    .replay-btn {
                        position: relative;
                        display: inline-block;
                        font-size: 15px;
                        line-height: 26px;
                        color: #3C5A7D;
                        font-weight: 500;
                        padding: 4px 18px;
                        border: 2px solid #e5eded;
                        border-radius: 25px;

                        i {
                            font-size: 12px;
                            margin-right: 10px;
                        }

                        &:hover {
                            color: #fff;
                            box-shadow: 0 20px 30px #d5edea;
                        }
                    }
                }

                .comment.replay-comment {
                    margin-left: 110px;
                    margin-top: 50px;
                }
            }
        }
    }

    .message-box {
        .image-box {
            position: relative;
            display: block;
            border-radius: 10px;

            img {
                width: 100%;
                border-radius: 10px;
            }
        }
    }

    .my-profile {
        .single-box {
            .profile-title {
                position: relative;
                padding: 67px 0px 63px 230px;
                max-width: 640px;
                width: 100%;
                border: 1px solid #e5e7ec;
                border-radius: 10px;
                margin-bottom: 24px;

                .image-box {
                    position: absolute;
                    left: 40px;
                    top: 40px;
                    width: 150px;
                    height: 150px;
                    border-radius: 20px;

                    img {
                        width: 100%;
                        border-radius: 20px;
                    }
                }

                .upload-photo {
                    a {
                        position: relative;
                        display: inline-block;
                        font-size: 15px;
                        line-height: 26px;
                        font-weight: 600;
                        color: #fff;
                        text-align: center;
                        padding: 11.5px 30px;
                        border-radius: 30px;
                        box-shadow: 0 10px 20px #d5edea;
                        margin-bottom: 24px;
                        transition: all 500ms ease;

                        i {
                            font-size: 20px;
                            margin-right: 10px;
                            position: relative;
                            top: 3px;
                        }

                        &:hover {
                            background: #3C5A7D;
                        }
                    }

                    span {
                        position: relative;
                        display: block;
                        font-size: 14px;
                        line-height: 24px;
                    }
                }
            }

            .inner-box {
                .form-group {
                    i {
                        position: absolute;
                        bottom: 16px;
                        right: 30px;
                        z-index: 1;
                    }
                }

                .image-box {
                    .image {
                        position: relative;
                        float: left;
                        border-radius: 20px;
                        margin-right: 30px;

                        &:last-child {
                            margin: 0px !important;
                        }

                        img {
                            width: 100%;
                            border-radius: 20px;
                        }
                    }
                }
            }
        }
    }
}

.my-patients {
    .title-box {
        .search-form {
            position: relative;
            display: inline-block;
            width: 265px;
            margin: 0px;
            margin-right: 10px;

            .form-group {
                position: relative;
                margin: 0px;
            }

            input[type='search'] {
                position: relative;
                width: 100%;
                height: 44px;
                border: 1px solid #e5eded;
                border-radius: 30px;
                font-size: 14px;
                font-weight: 500;
                color: #808080;
                padding: 10px 20px 10px 20px;
                transition: all 500ms ease;
            }

            button {
                position: absolute;
                top: 10px;
                right: 20px;
                font-size: 16px;
                color: #808080;
                background: transparent;
                cursor: pointer;
                transition: all 500ms ease;
            }
        }

        h3 {
            margin-bottom: 0px;
            line-height: 44px;
        }

        padding: 30px 40px;
    }

    .doctors-table {
        tr {
            td {
                .name-box {
                    padding: 34px 0px 32px 100px;
                }
            }
        }
    }
}

.add-listing {
    .single-box {
        position: relative;
        display: block;
        background: #fff;
        border: 1px solid #e5eded;
        border-radius: 10px;
        margin-bottom: 50px;

        .title-box {
            position: relative;
            padding: 36px 40px;
            border-bottom: 1px solid #e5eded;

            h3 {
                font-size: 26px;
                line-height: 34px;
                font-weight: 700;
                margin: 0px;
            }

            a.menu {
                position: absolute;
                top: 30px;
                right: 40px;
                background: #fff;
                width: 44px;
                height: 44px;
                line-height: 44px;
                border: 1px solid #e6eeee;
                border-radius: 50%;
                text-align: center;
                font-size: 16px;
                color: #808080;
                z-index: 1;
                box-shadow: 0 20px 30px #d5edea;

                &:hover {
                    color: #fff;
                }
            }

            a.theme-btn-one {
                position: absolute;
                top: 30px;
                right: 104px;
                padding: 9px 30px;
                z-index: 1;
            }
        }

        .inner-box {
            position: relative;
            padding: 35px 40px 40px 40px;

            .form-group {
                position: relative;
                margin-bottom: 24px;

                &:last-child {
                    margin-bottom: 0px;
                }

                label {
                    position: relative;
                    display: block;
                    font-size: 15px;
                    line-height: 26px;
                    font-weight: 500;
                    color: #808080;
                    margin-bottom: 7px;
                }

                input[type='text'] {
                    position: relative;
                    width: 100%;
                    height: 50px;
                    border: 1px solid #e5e7ec;
                    border-radius: 10px;
                    font-size: 15px;
                    color: #808080;
                    padding: 10px 20px;
                    transition: all 500ms ease;
                }

                input[type='email'] {
                    position: relative;
                    width: 100%;
                    height: 50px;
                    border: 1px solid #e5e7ec;
                    border-radius: 10px;
                    font-size: 15px;
                    color: #808080;
                    padding: 10px 20px;
                    transition: all 500ms ease;
                }

                input[type='password'] {
                    position: relative;
                    width: 100%;
                    height: 50px;
                    border: 1px solid #e5e7ec;
                    border-radius: 10px;
                    font-size: 15px;
                    color: #808080;
                    padding: 10px 20px;
                    transition: all 500ms ease;
                }

                textarea {
                    position: relative;
                    width: 100%;
                    height: 50px;
                    border: 1px solid #e5e7ec;
                    border-radius: 10px;
                    font-size: 15px;
                    color: #808080;
                    padding: 10px 20px;
                    transition: all 500ms ease;
                    height: 120px;
                    resize: none;
                    display: block;
                }
            }
        }
    }

    .btn-box {
        .theme-btn-one {
            padding: 12px 30px;
        }

        .cancel-btn {
            position: relative;
            display: inline-block;
            font-size: 15px;
            line-height: 24px;
            font-weight: 600;
            background: #fff;
            color: #3C5A7D;
            border: 2px solid #e5eded;
            border-radius: 30px;
            padding: 11px 28px;
            margin-left: 6px;
            text-align: center;

            &:hover {
                background: #3C5A7D;
                border-color: #3C5A7D;
                color: #fff;
            }
        }
    }
}

.schedule-timing {
    position: relative;
    background: #fff;
    border: 1px solid #e5eded;
    border-radius: 10px;
    overflow: hidden;

    .title-box {
        position: relative;
        padding: 30px 40px;
        border-bottom: 1px solid #e5eded;

        h3 {
            font-size: 26px;
            line-height: 44px;
            font-weight: 700;
            margin: 0px;
        }

        .search-form {
            position: relative;
            display: inline-block;
            width: 265px;
            margin: 0px;
            margin-right: 10px;

            .form-group {
                position: relative;
                margin: 0px;
            }

            input[type='search'] {
                position: relative;
                width: 100%;
                height: 44px;
                border: 1px solid #e5eded;
                border-radius: 30px;
                font-size: 14px;
                font-weight: 500;
                color: #808080;
                padding: 10px 20px 10px 20px;
                transition: all 500ms ease;
            }

            button {
                position: absolute;
                top: 10px;
                right: 20px;
                font-size: 16px;
                color: #808080;
                background: transparent;
                cursor: pointer;
                transition: all 500ms ease;
            }
        }

        .right-column {
            span {
                position: relative;
                display: inline-block;
                font-size: 15px;
                line-height: 44px;
                font-weight: 500;
                color: #3C5A7D;
                margin-right: 10px;
            }
        }
    }

    .inner-box {
        position: relative;
        display: block;
        padding: 40px 40px;

        .inner {
            position: relative;
            display: block;
            overflow: hidden;
            border-radius: 10px;
            border: 1px solid #e5eded;
        }

        .image-box {
            position: relative;
            display: block;

            img {
                width: 100%;
            }
        }

        .calendar-title {
            position: relative;
            display: block;
            padding: 10px 30px;

            h3 {
                font-size: 22px;
                line-height: 40px;
                font-weight: 600;
                color: #fff;
                margin: 0px;
            }

            .theme-btn-one {
                padding: 7px 30px;
                box-shadow: none;

                &:before {
                    background: #3C5A7D;
                }
            }
        }
    }

    .calendar-inner {
        position: relative;

        .today-box {
            position: absolute;
            top: 30px;
            right: 286px;

            a {
                position: relative;
                display: inline-block;
                font-size: 14px;
                font-weight: 500;
                color: #808080;
                padding: 2px 20px;
                text-align: center;
                border: 1px solid #e5eded;
                border-radius: 30px;
                z-index: 1;
            }
        }

        .date-zone {
            position: absolute;
            right: 30px;
            top: 30px;
            border-radius: 25px;
            overflow: hidden;
            border: 1px solid #e0ebeb;
            z-index: 1;

            li {
                position: relative;
                display: inline-block;
                float: left;

                a {
                    position: relative;
                    display: inline-block;
                    font-size: 14px;
                    line-height: 24px;
                    font-weight: 500;
                    color: #3C5A7D;
                    padding: 4px 20px;
                    text-align: center;
                    border-right: 1px solid #e5eded;
                    z-index: 1;
                }

                &:last-child {
                    a {
                        border-right: none;
                    }
                }

                &:first-child {
                    a {
                        background: #ebf7f6;
                    }
                }
            }
        }
    }
}

.main-header.style-three {
    .header-lower {
        padding: 0px 50px;

        .outer-box {
            display: block;
        }

        .logo-box {
            float: left;
            margin-right: 130px;
            padding: 24.5px 0px;
        }

        .menu-area {
            float: left;
        }
    }

    .author-box {
        margin-top: 25px;

        .icon-box {
            position: relative;
            display: inline-block;
            float: left;
            padding-right: 30px;
            margin-right: 30px;

            &:before {
                position: absolute;
                content: '';
                background: #e4edeb;
                width: 1px;
                height: 50px;
                top: 0px;
                right: 0px;
            }

            a {
                position: relative;
                display: inline-block;
                width: 50px;
                height: 50px;
                line-height: 50px;
                background: #fff;
                border-radius: 50%;
                text-align: center;
                font-size: 24px;
                border: 1px solid #e5eded;
                color: #3C5A7D;
                box-shadow: 0 10px 30px #d5edea;

                &:hover {
                    color: #fff;
                }
            }
        }

        .author {
            position: relative;
            display: inline-block;
            float: left;
            padding: 0px 0px 0px 70px;

            .author-image {
                position: absolute;
                left: 0px;
                top: 0px;
                width: 50px;
                height: 50px;
                border-radius: 50%;

                img {
                    width: 100%;
                    border-radius: 50%;
                }
            }

            .nice-select {
                padding: 8px 0px;
                font-size: 18px;
                line-height: 26px;
                font-family: 'Jost', sans-serif;
                font-weight: 600;
                color: #3C5A7D;
                height: 50px;
                padding-right: 10px;

                .list {
                    width: 200px;
                    left: inherit !important;
                    right: 0px !important;
                }

                &:after {
                    top: 10px;
                    right: 0px;
                    margin: 0px;
                    font-size: 18px;
                    color: #3C5A7D;
                }
            }
        }
    }
}

.blog-grid {
    position: relative;
    padding: 120px 0px;

    .news-block-one {
        .inner-box {
            margin-bottom: 30px;
        }
    }

    .pagination-wrapper {
        position: relative;
        margin-top: 30px;
    }
}

.sidebar-page-container {
    position: relative;
    padding: 120px 0px;

    .pagination-wrapper {
        text-align: right;
        margin-top: 20px;
    }

    .news-block-one {
        .inner-box {
            box-shadow: none;

            .lower-content {
                padding-left: 0px;
                padding-right: 0px;

                .btn-box {
                    left: 0px;
                }
            }
        }
    }
}

.blog-sidebar {
    position: relative;
    margin-left: 10px;

    .sidebar-widget {
        position: relative;
        display: block;
        background: #fff;
        border-radius: 10px;
        padding: 34px 40px 40px 40px;
        box-shadow: 0 10px 40px #e5ebea;
        margin-bottom: 40px;

        &:last-child {
            margin-bottom: 0px;
        }

        .widget-title {
            position: relative;
            display: block;
            margin-bottom: 32px;

            h3 {
                font-size: 26px;
                line-height: 34px;
                font-weight: 700;
                margin: 0px;
            }
        }
    }

    .sidebar-search {
        .search-form {
            .form-group {
                position: relative;
                margin: 0px;

                input[type='search'] {
                    position: relative;
                    width: 100%;
                    height: 60px;
                    border: 1px solid #e5e5e5;
                    border-radius: 30px;
                    padding: 10px 80px 10px 30px;
                    font-size: 15px;
                    color: #808080;
                    transition: all 500ms ease;
                }

                button {
                    position: absolute;
                    top: 5px;
                    right: 5px;
                    font-size: 17px;
                    color: #fff;
                    width: 50px;
                    height: 50px;
                    line-height: 50px;
                    text-align: center;
                    border-radius: 50%;
                    cursor: pointer;
                    transition: all 500ms ease;
                }
            }
        }
    }

    .category-widget {
        .category-list {
            li {
                position: relative;
                display: block;
                margin-bottom: 12px;

                &:last-child {
                    margin-bottom: 0px;
                }

                a {
                    position: relative;
                    display: inline-block;
                    font-size: 16px;
                    color: #3C5A7D;
                    padding-left: 23px;

                    &:before {
                        position: absolute;
                        content: '';
                        border: 3px solid #dad9e7;
                        width: 12px;
                        height: 12px;
                        border-radius: 50%;
                        left: 0px;
                        top: 7px;
                        transition: all 500ms ease;
                    }
                }
            }
        }

        .widget-title {
            margin-bottom: 25px;
        }
    }

    .post-widget {
        .post-inner {
            .post {
                position: relative;
                padding: 0px 0px 19px 100px;
                border-bottom: 1px solid #e5e5e5;
                margin-bottom: 15px;
                min-height: 106px;

                &:last-child {
                    border-bottom: none;
                    margin-bottom: 0px;
                }

                &:hover {
                    .post-thumb {
                        img {
                            opacity: 0.5;
                        }
                    }
                }
            }

            .post-thumb {
                position: absolute;
                left: 0px;
                top: 5px;
                width: 80px;
                height: 80px;
                border-radius: 10px;

                img {
                    width: 100%;
                    border-radius: 10px;
                    transition: all 500ms ease;
                }
            }

            h5 {
                position: relative;
                display: block;
                font-size: 18px;
                line-height: 26px;
                font-weight: 700;
                margin-bottom: 6px;

                a {
                    display: inline-block;
                    color: #3C5A7D;
                }
            }

            p {
                position: relative;
            }
        }

        padding-bottom: 20px;
    }

    .archives-widget {
        .archives-list {
            li {
                position: relative;
                display: block;
                margin-bottom: 12px;

                &:last-child {
                    margin-bottom: 0px;
                }

                a {
                    position: relative;
                    display: block;
                    font-size: 16px;
                    color: #3C5A7D;
                    padding-left: 23px;

                    span {
                        float: right;
                    }

                    &:before {
                        position: absolute;
                        content: '';
                        border: 3px solid #dad9e7;
                        width: 12px;
                        height: 12px;
                        border-radius: 50%;
                        left: 0px;
                        top: 7px;
                        transition: all 500ms ease;
                    }
                }
            }
        }

        .widget-title {
            margin-bottom: 25px;
        }
    }

    .sidebar-tags {
        .tags-list {
            li {
                position: relative;
                display: inline-block;
                float: left;
                margin-right: 5px;
                margin-bottom: 15px;

                a {
                    position: relative;
                    display: inline-block;
                    font-size: 14px;
                    line-height: 26px;
                    color: #7f8180;
                    border: 1px solid #e5e5e5;
                    border-radius: 30px;
                    text-align: center;
                    padding: 6px 16px;

                    &:hover {
                        color: #fff;
                        box-shadow: 0 20px 30px #d5edea;
                    }
                }
            }
        }

        padding-bottom: 35px;
    }
}

.blog-standard-content {
    .news-block-one {
        .inner-box {
            .lower-content {
                h3 {
                    font-size: 30px;
                    line-height: 42px;
                }

                padding-top: 32px;
                padding-bottom: 50px;
            }
        }
    }
}

.blog-details-content {
    .news-block-one {
        .inner-box {
            .lower-content {
                h3 {
                    font-size: 30px;
                    line-height: 42px;
                }

                padding-top: 32px;
            }

            .image-box {
                &:before {
                    display: none;
                }
            }

            &:hover {
                .image-box {
                    img {
                        transform: scale(1);
                    }
                }
            }
        }
    }

    blockquote {
        position: relative;
        display: block;
        background: #ebf7f6;
        padding: 45px 100px 45px 65px;
        border-radius: 10px;
        margin: 6px 0px 70px 0px;

        i {
            position: absolute;
            left: -35px;
            top: 50%;
            transform: translateY(-50%);
            width: 70px;
            height: 70px;
            line-height: 70px;
            text-align: center;
            font-size: 30px;
            color: #fff;
            border-radius: 50%;
            box-shadow: 0 20px 30px #d5edea;
        }

        p {
            position: relative;
            font-size: 17px;
            line-height: 30px;
            color: #222222;
            font-weight: 600;
            margin: 0px;
        }
    }

    .text {
        h3 {
            position: relative;
            display: block;
            font-size: 26px;
            line-height: 26px;
            line-height: 34px;
            font-weight: 700;
            margin-bottom: 24px;
        }
    }

    .two-column {
        .text {
            p {
                margin-bottom: 28px;
            }

            .list {
                li {
                    position: relative;
                    display: block;
                    padding-left: 30px;
                    margin-bottom: 9px;

                    &:last-child {
                        margin-bottom: 0px;
                    }

                    &:before {
                        position: absolute;
                        content: "\f00c";
                        font-family: 'Font Awesome 5 Pro', sans-serif;
                        font-size: 10px;
                        color: #fff;
                        font-weight: 400;
                        width: 15px;
                        height: 15px;
                        line-height: 15px;
                        text-align: center;
                        border-radius: 2px;
                        left: 0px;
                        top: 7px;
                    }
                }
            }
        }

        margin-bottom: 65px;

        .image-box {
            position: relative;
            display: block;

            img {
                width: 100%;
            }
        }
    }

    .post-share-option {
        position: relative;
        display: block;
        background: #ebf7f6;
        padding: 30px 30px;
        margin: 70px 0px 91px 0px;
        border-radius: 10px;

        .text {
            h4 {
                position: relative;
                font-size: 22px;
                line-height: 50px;
                font-weight: 700;
            }
        }

        .social-links {
            li {
                position: relative;
                display: inline-block;
                float: left;
                margin-right: 10px;

                &:last-child {
                    margin: 0px !important;
                }

                a {
                    position: relative;
                    display: inline-block;
                    width: 50px;
                    height: 50px;
                    line-height: 50px;
                    background: #fff;
                    color: #acadb4;
                    font-size: 16px;
                    text-align: center;
                    border-radius: 50%;

                    &:hover {
                        color: #fff;
                        box-shadow: 0 20px 30px #d5edea;
                    }
                }
            }
        }
    }

    .group-title {
        position: relative;
        display: block;
        margin-bottom: 33px;

        h3 {
            font-size: 26px;
            line-height: 34px;
            font-weight: 700;
        }
    }

    .comment-box {
        .comment {
            position: relative;
            padding-left: 110px;
            margin-bottom: 30px;

            &:last-child {
                margin-bottom: 0px;

                .comment-inner {
                    border-bottom: none;
                }
            }

            .thumb-box {
                position: absolute;
                left: 0px;
                top: 0px;
                width: 80px;
                height: 80px;
                border-radius: 50%;

                img {
                    width: 100%;
                    border-radius: 50%;
                }
            }

            .comment-inner {
                position: relative;
                padding-bottom: 30px;
                border-bottom: 1px solid #e5e7ec;

                .comment-info {
                    h5 {
                        font-size: 20px;
                        line-height: 28px;
                        font-weight: 700;
                        margin-bottom: 2px;
                    }

                    .comment-time {
                        position: relative;
                        display: block;
                        font-size: 15px;
                        line-height: 24px;
                    }

                    margin-bottom: 15px;
                }

                p {
                    margin-bottom: 10px;
                }

                .reply-btn {
                    position: relative;
                    display: inline-block;
                    font-size: 15px;
                    line-height: 24px;
                    color: #222222;
                    font-weight: 500;
                }
            }
        }

        margin-bottom: 54px;
    }

    .comments-form-area {
        .comment-form {
            .form-group {
                position: relative;
                margin-bottom: 30px;

                &:last-child {
                    margin-bottom: 0px;
                }

                input[type='text'] {
                    position: relative;
                    width: 100%;
                    height: 50px;
                    border: 1px solid #e5e7ec;
                    border-radius: 10px;
                    font-size: 15px;
                    color: #848484;
                    padding: 10px 20px;
                    transition: all 500ms ease;
                }

                input[type='email'] {
                    position: relative;
                    width: 100%;
                    height: 50px;
                    border: 1px solid #e5e7ec;
                    border-radius: 10px;
                    font-size: 15px;
                    color: #848484;
                    padding: 10px 20px;
                    transition: all 500ms ease;
                }

                textarea {
                    position: relative;
                    width: 100%;
                    height: 50px;
                    border: 1px solid #e5e7ec;
                    border-radius: 10px;
                    font-size: 15px;
                    color: #848484;
                    padding: 10px 20px;
                    transition: all 500ms ease;
                    display: block;
                    resize: none;
                    height: 150px;
                }
            }
        }
    }
}

.information-section {
    position: relative;

    .sec-title {
        margin-bottom: 40px;
    }

    .pattern-layer {
        .pattern-1 {
            position: absolute;
            left: 200px;
            top: 75px;
            width: 460px;
            height: 387px;
            background-repeat: no-repeat;
        }

        .pattern-2 {
            position: absolute;
            right: 260px;
            bottom: 30px;
            width: 460px;
            height: 387px;
            background-repeat: no-repeat;
        }
    }
}

.single-information-block {
    .inner-box {
        position: relative;
        display: block;
        background: #fff;
        border-radius: 10px;
        overflow: hidden;
        padding: 76px 30px 69px 30px;

        .icon-box {
            position: relative;
            min-height: 93px;
            margin-bottom: 23px;
            transition: all 500ms ease;

            img {
                transition: all 500ms ease;
            }
        }

        &:hover {
            .icon-box {
                img {
                    transform: scale(1.1);
                }
            }
        }

        h3 {
            position: relative;
            display: block;
            font-size: 26px;
            line-height: 34px;
            font-weight: 700;
            margin-bottom: 23px;
        }

        p {
            position: relative;
            display: block;
            font-size: 15px;
            line-height: 26px;
            color: #808080;

            a {
                display: inline-block;
                color: #808080;
            }
        }

        .pattern {
            position: absolute;
            right: 0px;
            bottom: 0px;
            width: 166px;
            height: 83px;
            background-repeat: no-repeat;
        }
    }
}

.contact-section {
    position: relative;
    padding: 120px 0px;

    .default-form {
        .form-group {
            position: relative;
            margin-bottom: 30px;

            input[type='text'] {
                position: relative;
                width: 100%;
                height: 50px;
                border: 1px solid #e5e7ec;
                border-radius: 10px;
                font-size: 15px;
                color: #848484;
                padding: 10px 20px;
                transition: all 500ms ease;
            }

            input[type='email'] {
                position: relative;
                width: 100%;
                height: 50px;
                border: 1px solid #e5e7ec;
                border-radius: 10px;
                font-size: 15px;
                color: #848484;
                padding: 10px 20px;
                transition: all 500ms ease;
            }

            textarea {
                position: relative;
                width: 100%;
                height: 50px;
                border: 1px solid #e5e7ec;
                border-radius: 10px;
                font-size: 15px;
                color: #848484;
                padding: 10px 20px;
                transition: all 500ms ease;
                display: block;
                height: 120px;
                resize: none;
            }
        }
    }

    .form-inner {
        position: relative;
        margin-right: 20px;
    }

    .map-inner {
        position: relative;
        padding-left: 100px;

        #contact-google-map {
            position: relative;
            width: 100%;
            height: 570px;
            box-shadow: 0 10px 30px rgba(0, 24, 73, 0.3);
        }

        .pattern {
            position: absolute;
            left: 0px;
            bottom: -110px;
            width: 704px;
            height: 740px;
            background-repeat: no-repeat;
        }
    }
}

.mb-30 {
    margin-bottom: 30px;
}

.monthly-day-wrap {
    .monthly-week {
        &:nth-child(2) {
            .monthly-day-pick {
                &:last-child {
                    &:before {
                        position: absolute;
                        content: '09:30AM Meeting';
                        font-size: 12px;
                        font-weight: 500;
                        font-family: 'Poppins', sans-serif;
                        color: #fff;
                        width: calc(100% - 20px);
                        height: 24px;
                        line-height: 24px;
                        left: 10px;
                        top: 35px;
                        border-radius: 2px;
                        padding: 0px 10px;
                    }
                }
            }
        }

        &:nth-child(3) {
            .monthly-day-pick {
                &:nth-child(2) {
                    &:before {
                        position: absolute;
                        content: '11:30AM Meeting';
                        font-size: 12px;
                        font-weight: 500;
                        font-family: 'Poppins', sans-serif;
                        color: #fff;
                        width: calc(100% - 20px);
                        height: 24px;
                        line-height: 24px;
                        left: 10px;
                        top: 35px;
                        border-radius: 2px;
                        padding: 0px 10px;
                    }
                }
            }
        }

        &:nth-child(4) {
            .monthly-day-pick {
                &:nth-child(4) {
                    &:before {
                        position: absolute;
                        content: '02:30PM Meeting';
                        font-size: 12px;
                        font-weight: 500;
                        font-family: 'Poppins', sans-serif;
                        color: #fff;
                        width: calc(100% - 20px);
                        height: 24px;
                        line-height: 24px;
                        left: 10px;
                        top: 35px;
                        border-radius: 2px;
                        padding: 0px 10px;
                    }
                }
            }
        }
    }
}

.message-box {
    .title-box {
        position: relative;
        padding: 36px 40px;
        border-bottom: 1px solid #e5eded;

        h3 {
            font-size: 26px;
            line-height: 34px;
            font-weight: 700;
            margin: 0px;
        }

        a.menu {
            position: absolute;
            top: 30px;
            right: 40px;
            background: #fff;
            width: 44px;
            height: 44px;
            line-height: 44px;
            border: 1px solid #e6eeee;
            border-radius: 50%;
            text-align: center;
            font-size: 16px;
            color: #808080;
            z-index: 1;
            box-shadow: 0 20px 30px #d5edea;

            &:hover {
                color: #fff;
            }
        }
    }
}

@media (min-width:1200px) {
    .container {
        max-width: 1170px;
        padding: 0px 15px;
    }
}

.ng-dropdown-panel {
    .ng-optgroup {
        background-color: lightgray;
    }
}

/*** 

====================================================================
                        JIRA SERVICE MANAGEMENT
====================================================================

***/

  iframe[name='JSD widget'] {
   display: block;
   left: 0;
   bottom: 60px !important;
  }



  .blurred {
    filter: blur(7px); /* Apply blur effect */
    pointer-events: none; /* Optional: prevents interaction with the content */
    user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    -webkit-user-drag: none; /* Disable dragging */
  }