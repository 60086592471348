.main-provider-header-profile .main-img-user {
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 6px;
    width: 6px;
    height: 6px;
    background-color: #17b86a;
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.95);
    border-radius: 100%;
    bottom: 3px;
    display: block !important;
    top: 35px;
	
  }

  display: inline-block;
  position: relative;
  width: 45px;
  height: 45px;
  border-radius: 100%;
  text-align: center;
}

.main-profile-menu .profile-user img {
  width: 37px;
  height: 37px;
  border-radius: 50%;
  margin-left: 0;
  width: 37px;
  height: 37px;
  box-shadow: 0px 4px 15px 0px #dbe4f9;
}

.main-profile-overview .main-img-user::after {
  display: none !important;
}

/* ###### 7.8 Profile  ###### */

.main-provider-content-profile {
  flex: 1;
}

@media (max-width: 991.98px) {
  .main-provider-content-profile {
    .container, .container-fluid {
      display: block;
    }
  }
}

.main-provider-content-left-profile {
  padding-left: 0;
  padding-right: 0;
  display: block;
  border-bottom: 1px solid $border;
  padding-bottom: 25px;
  width: auto;
}

@media (min-width: 992px) {
  .main-provider-content-left-profile {
    width: 270px;
    padding-right: 20px;
    padding-bottom: 0;
    border-right: 1px solid $border;
    border-bottom: 0;
  }
}

@media (min-width: 1200px) {
  .main-provider-content-left-profile {
    padding-right: 25px;
  }
}

.main-profile-overview {
  .main-img-user {
    width: 120px;
    height: 120px;
    margin-bottom: 20px;
  }

  .btn-icon-list {
    .btn, .sp-container button {
      border-radius: 100%;
    }
  }
}

.sp-container .main-profile-overview .btn-icon-list button {
  border-radius: 100%;
}

.main-profile-name {
  color: $gray-900;
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 2px;
}

.main-profile-name-text {
  color: $gray-600;
  font-size: 13px;
  margin-bottom: 0;
}

.main-profile-bio {
  font-size: 13px;
  margin-bottom: 20px;
}

.main-profile-social-list {
  .media {
    align-items: center;

    + .media {
      margin-top: 20px;
    }
  }

  .media-icon {
    font-size: 34px;
    width: 30px;
    line-height: 0;
  }

  .media-body {
    margin-left: 20px;

    span {
      display: block;
      font-size: 12px;
    }

    a {
      font-size: 13px;
    }
  }
}

.main-provider-content-body-profile {
  .nav {
    flex-direction: column;
    padding: 20px 20px 20px 15px;
    border-bottom: 1px solid $border;
    margin-bottom:1.3rem;
  }

  .main-nav-line .nav-link {
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 500;
    color: $gray-800;

    &.active {
      font-weight: 700;
      color: $primary;

      &::before {
        background-color: $primary;
      }
    }
  }
}

@media (min-width: 576px) {
  .main-provider-content-body-profile .nav {
    flex-direction: row;
    align-items: center;
  }
  
   .main-provider-content-body-profile .nav {
	padding: 18px 14px 17px 30px;
  }
}

@media (min-width: 992px) {
  .main-provider-content-body-profile .nav {
    padding-left: 20px;
  }
}

@media (min-width: 1200px) {
  .main-provider-content-body-profile .nav {
    padding-left: 25px;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .main-provider-content-body-profile .main-nav-line .nav-link.active::before {
    width: auto;
    top: auto;
    left: 0;
    right: 0;
    height: 2px;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .main-provider-content-body-profile .main-nav-line .nav-link + .nav-link {
    margin-top: 0;
    margin-left: 20px;
  }
}

.main-profile-body {
  padding: 15px 0 0;
}

@media (min-width: 576px) {
  .main-profile-body {
    padding-top: 20px;
  }
}

@media (min-width: 992px) {
  .main-profile-body {
    padding: 25px 0 0 20px;
  }
}

@media (min-width: 1200px) {
  .main-profile-body {
    padding-left: 25px;
  }
}

.main-profile-view-chart {
  position: relative;
  width: calc(100% - 10px);
  height: 200px;
}

@media (min-width: 375px) {
  .main-profile-view-chart {
    width: 100%;
  }
}

@media (min-width: 576px) {
  .main-profile-view-chart {
    height: 250px;
  }
}

.main-profile-view-info {
  position: absolute;
  top: 0;
  left: 0;

  h6 {
    font-size: 32px;
    font-weight: 500;
    color: $gray-900;
    margin-bottom: 0;
  }

  span {
    font-size: 12px;
    color: $pink;
    margin-left: 5px;
  }

  p {
    font-size: 13px;
    margin-bottom: 0;
  }
}

.main-traffic-detail-item {
  > div:first-child {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    margin-bottom: 7px;

    > span {
      &:first-child {
        color: $gray-600;
      }

      &:last-child {
        font-size: 11px;
        font-weight: 700;
        color: $gray-900;

        span {
          color: $gray-600;
          font-weight: 400;
        }
      }
    }
  }

  + .main-traffic-detail-item {
    margin-top: 25px;
  }

  .progress {
    height: 8px;
  }
}

.main-profile-work-list {
  .media + .media {
    margin-top: 25px;
  }

  .media-logo {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 0;
    color: $white;
    font-size: 21px;
    position: relative;
    top: 2px;
    border-radius: 100%;
  }

  .media-body {
    margin-left: 20px;

    h6 {
      color: $gray-900;
      font-weight: 500;
      margin-bottom: 2px;
    }

    span {
      display: block;
      margin-bottom: 5px;
    }

    p {
      margin-bottom: 0;
      font-size: 12px;
      color: $gray-600;
    }
  }
}

.main-profile-contact-list {
  .media {
    align-items: center;

    + .media {
      margin-top: 25px;
    }
  }

  .media-icon {
    line-height: 0;
    font-size: 36px;
    width: 35px;
    text-align: center;
  }

  .media-body {
    margin-left: 25px;

    span {
      font-size: 12px;
      color: $gray-600;
      display: block;
      line-height: 1.3;
    }

    div {
      font-weight: 500;
      color: $gray-900;
    }
  }
}

@media (max-width: 1134px) {
  .main-profile-body .media.profile-footer {
    display: block !important;

    .media-body {
      padding: 3px 0 15px 0;
    }
  }
}

@media (max-width: 578px) {
  .main-profile-menu .profile-user img {
    margin-left: 0;
  }
}

.main-profile-work-list .media-logo, .main-profile-contact-list .media-icon, .main-profile-social-list .media-icon {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 0;
  color: $white;
  font-size: 21px;
  position: relative;
  top: 2px;
  border-radius: 100%;
}

.profile-user .profile-edit {
  position: absolute;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  line-height: 30px;
  right: 0;
  background: #d5d4e0;
  margin: 0 auto;
  text-align: center;
}

.main-profile-body .card-header {
  padding-top: 0;
  padding-left: 0;
}

.drop-down-profile {
  position: relative;
}

.profile-footer a {
  width: 34px;
  height: 34px;
  background: #efeff5;
  color: $black;
  margin: 1px auto;
  text-align: center;
  line-height: 34px;
  display: inline-block;
  border-radius: 50%;
  font-size: 12px;
}

.drop-down-profile span.assigned-task {
  position: absolute;
  font-size: 12px;
  width: 18px;
  height: 18px;
  line-height: 18px;
  color: $white;
  top: 0;
  margin: 0 auto;
  text-align: center;
  border-radius: 50%;
  right: 0;
}

@media (max-width: 575px) {
  .main-provider-content-body-profile .main-nav-line .nav-link.active::before {
    left: 0px;
  }
}

@media (max-width: 767.98px) {
  .main-profile-menu .profile-user img {
    margin-left: 0 !important;
  }
}

.profile-name {
  margin-top: 8px;
}

.profile.navtab-custom {
  padding: 11px 0 10px 0;

  li a {
    padding: 13px;
    color: $black;
    background: transparent;
    margin-right: 7px;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    border: 1px solid #e3e6f0;
    border-bottom: 0;
    font-size: 13px;
  }

  .active a, a.active {
    background: $gray-100;
    border-bottom: 0;
    color: $black;
  }
}

.ga-border {
  height: 3px;
  width: 40px;
  background-color: $primary;
  margin: 10px auto 5px auto;
}
.profile-cover__action {
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    padding: 216px 30px 10px 185px;
    border-radius: 5px 5px 0 0;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-pack: end;
    -webkit-box-pack: end;
    justify-content: flex-end;
    overflow: hidden;
    background: url(/assets/img/media/bg-img.jpg) no-repeat;
    background-size: cover;
}
.profile-cover__img {
    position: absolute;
    top: 230px !important;
    left: 50px;
    text-align: center;
    z-index: 1;
}
.profile-cover__img > img {
    max-width: 120px;
    border-radius: 50%;
}
.profile-cover__img > img + .h3 {
    margin-top: 6px;
}
.profile-cover__img > .h3 {
    color: #393939;
    font-size: 20px;
    line-height: 30px;
}
@media (min-width: 481px){
.profile-cover__action > .btn {
    min-width: 125px;
}
.profile-cover__action > .btn {
    margin-bottom: 10px !important;
}
}
@media (min-width: 601px){
.profile-cover__info .nav {
    padding: 15px 0 10px 170px;
    color: #999;
    font-size: 16px;
    line-height: 26px;
    font-weight: 300;
    text-align: center;
}
}
.profile-cover__info .nav li {
    font-size: 14px;
    color: #464461;
    margin-bottom: 13px;
}
.profile-cover__info , .profile-tab .main-nav-line .nav-link.active {
  color: $primary;
    padding: 15px;
}
.profile-cover__info .nav span {
    display: block;
    margin-bottom: 10px;
    font-size: 25px;
}
.profile-cover__info .nav li:not(:first-child) {
    margin-left: 30px;
    padding-left: 30px;
    border-left: 1px solid rgba(238, 238, 238, 0.2);
}
.profile-tab .main-nav-line .nav-link {
    font-weight: 500;
}
.main-nav-line .nav-link {
    color: #3c4858;
    position: relative;
    border: 0px solid #ededf5;
    padding: 7px;
}
.main-provider-content-body-profile .main-nav-line .nav-link {
    color: #3c4858;
    position: relative;
    padding: 8px 25px;
}
.profile-tab.tab-menu-heading .main-nav-line .nav-link{
    position: relative;
    padding: 4px 20px;


}
.profile-tab .main-nav-line .nav-link {
    font-weight: 500;
}
@media (min-width: 769px){
.main-nav-line .nav-link + .nav-link {
    margin-top: 0;
    margin-left: 5px;
    padding: 8px 25px;
}
}

.card-header:first-child {
    border-radius: 11px 11px 0 0;
}
.user-lock img {
    width: 70px;
    height: 70px;
    margin: 0 auto;
}
.main-img-user img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 100%;
}
@media (max-width: 768px){
  .main-nav-line .nav-link + .nav-link {
      margin-top: 10px !important;
      margin-left: 0 !important;
  }
  .profile-cover__img {
    top: 260px !important;
    left: 0;
    right: 0;
  }
}

.panel-tabs.main-nav-line .nav-link {
    border-radius: 0px;
}
.profile-image img{
  position: relative;
  width: 180px;
  height: 180px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.profile-online {
  // top: 6px;
  right: -10px;
  position: absolute;
  border: 3px solid #fff;
  padding: 6px;
  margin-top: 6px;
}
.profile-tab.tab-menu-heading {
  font-size: 14px !important;
}
.bg-white-transparent {
  background-color: rgba(255,255,255,0.4);
}
a.option-dots {
  display: inline-block;
  display: block;
  width: 2.5rem;
  height: 2.5rem;
  line-height: 2.5rem;
  text-align: center;
  border-radius: 100%;
  font-size: 18px;
  border-radius: 50px;
  border: 1px solid $border;
  vertical-align: middle;
  margin-right: 5px;
  padding: 0;
}
