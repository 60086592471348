:root {
  --primary-bg-color: #38cab3;
  --primary-bg-hover: #5cd3b9;
  --primary-bg-border: #38cab3;
  --primary02: rgba(28, 213, 174, 0.2);
  --primary05: rgba(28, 213, 174, 0.5);
  --primary-transparentcolor: rgba(28, 213, 174, 0.2);
  --transparentprimary-transparentcolor: rgba(255, 255, 255, 0.05);
  --transparent-body: #38cab3;
  --transparent-theme: rgba(0, 0, 0, 0.1);
  --blue: #38cab3;
  --indigo: #38cab3;
  --purple: #ad4ffa;
  --pink: #f754fa;
  --red: #f34343;
  --orange: #fd7e14;
  --yellow: #ffbd5a;
  --green: #016a59;
  --teal: #29ddee;
  --cyan: #4ec2f0;
  --white: #fff;
  --gray: #7987a1;
  --gray-dark: #383853;
  --secondary: #7987a1;
  --success: #016a59;
  --info: #4ec2f0;
  --warning: #ffbd5a;
  --danger: #f34343;
  --light: #f9f9fb;
  --dark: #383853;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

* {
  box-sizing: border-box;

  &::before,
  &::after {
    box-sizing: border-box;
  }
}

#global-loader {
  position: fixed;
  z-index: 50000;
  background: $white;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  text-align: center;
}

.loader-img {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
  transform: translateY(-50%);
}

.fade {
  transition: opacity 0.15s linear;

  &:not(.show) {
    opacity: 0;
  }
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }

  to {
    background-position: 0 0;
  }
}

.media {
  display: flex;
  align-items: center;
}

.media-body {
  flex: 1;
}

.close {
  font-size: 1.3125rem;
  font-weight: 400;
  line-height: 1;
  color: $black;
  text-shadow: 0 1px 0 $white;
  opacity: .5;
  float: right;

  &:not(:disabled):not(.disabled) {

    &:hover,
    &:focus {
      opacity: .75;
    }
  }
}

@media(max-width: 767px) {
  .close {
    font-size: 1.3125rem;
    font-weight: 400;
    line-height: 1;
    color: $black;
    text-shadow: 0 1px 0 $white;
    opacity: .5;
    position: absolute;
    right: 10px;
  }
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  appearance: none;
  color: inherit;
}

a.close.disabled {
  pointer-events: none;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border .75s linear infinite;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@-webkit-keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
    opacity: 0;
  }

  50% {
    -webkit-transform: scale(0.2);
    opacity: 1;
  }
}

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow .75s linear infinite;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;

  &::before {
    display: block;
    content: "";
  }

  .embed-responsive-item,
  iframe,
  embed,
  object,
  video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
}

.embed-responsive-21by9::before {
  padding-top: 42.85714%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable {

  &:active,
  &:focus {
    position: static;
    width: auto;
    height: auto;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.09) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

@media print {
  * {
    text-shadow: none !important;
    box-shadow: none !important;

    &::before,
    &::after {
      text-shadow: none !important;
      box-shadow: none !important;
    }
  }

  a:not(.btn) {
    text-decoration: underline;
  }

  abbr[title]::after {
    content: " ('attr(title) ')";
  }

  pre {
    white-space: pre-wrap !important;
    border: 1px solid $gray-500;
    page-break-inside: avoid;
  }

  blockquote {
    border: 1px solid $gray-500;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  tr,
  img {
    page-break-inside: avoid;
  }

  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }

  @page {
    size: a3;
  }

  body,
  .container {
    min-width: 992px !important;
  }
}

/* ########## BOOTSTRAP OVERRIDES ########## */

/* ############### PAGE LAYOUT STYLES ############### */

/* ###### 6.3 Global  ###### */

body {
  font-family: 'Infer', sans-serif;
}

.main-body,
.main-dashboard {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
}

.main-body::before {
  content: 'main';
  position: fixed;
  top: 45%;
  left: 70%;
  transform: translate3d(-50%, -58%, 0);
  font-size: 1300px;
  font-weight: 600;
  letter-spacing: -10px;
  line-height: .5;
  opacity: .02;
  z-index: -1;
  display: none;
}

.main-table-reference {
  background-color: $white;

  > {
    thead>tr> {

      th,
      td {
        padding: 8px 10px;
        border: 1px solid $border;
        font-size: 13px;
      }
    }

    tbody>tr> {

      th,
      td {
        padding: 8px 10px;
        border: 1px solid $border;
        font-size: 13px;
      }
    }

    thead>tr> {

      th,
      td {
        background-color: $gray-100;
        color: $gray-600;
        font-size: 11px;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: 1px;
        border-bottom: 0;
      }
    }
  }
}

/* ############### TEMPLATE STYLES ############### */

/* ###### 7.1 Demo  ###### */

.main-home-slider {
  position: relative;
  z-index: 4;
  width: 100%;
  height: 600px;
  max-height: 1800px;
  overflow: hidden;
  background-color: #1904be;

  .chart-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: -20px;
    z-index: 3;
    display: flex;
    align-items: flex-end;
    opacity: .2;

    .flot-chart {
      width: 100%;
      height: 400px;
    }
  }

  .card-columns {
    column-gap: 5px;
    column-count: 3;
    width: 500px;
    transform: rotate(37deg);
    position: absolute;
    top: 9%;
    left: 24%;
    z-index: 4;
    opacity: .25;

    .card {
      position: relative;
      border-width: 0;
      background-color: transparent;
      margin-bottom: 5px;
    }
  }
}

@media (min-width: 576px) {
  .main-home-slider {
    height: 600px;
  }
}

@media (min-width: 1200px) {
  .main-home-slider {
    height: 100vh;
    max-height: 780px;
  }
}

@media (min-width: 1440px) {
  .main-home-slider {
    max-height: 900px;
  }
}

@media (min-width: 1680px) {
  .main-home-slider {
    max-height: 1050px;
  }
}

@media (min-width: 1920px) {
  .main-home-slider {
    max-height: 1200px;
  }
}

@media (min-width: 2560px) {
  .main-home-slider {
    max-height: 1600px;
  }
}

@media (min-width: 2880px) {
  .main-home-slider {
    max-height: 1800px;
  }
}

@media (min-width: 768px) {
  .main-home-slider .chart-wrapper {
    opacity: 1;
  }
}

@media (min-width: 992px) {
  .main-home-slider .chart-wrapper .flot-chart {
    height: 500px;
  }

  .userlist-table .table {
    white-space: nowrap !important;
  }

  .nav-link#bs-example-navbar-collapse-1 {
    display: none;
  }
}

@media (min-width: 576px) {
  .main-home-slider .card-columns {
    width: 120%;
    left: 16%;
    transform: rotate(44deg);
  }
}

@media (min-width: 768px) {
  .main-home-slider .card-columns {
    transform: rotate(45deg);
    width: 100%;
    top: 0;
    left: auto;
    right: -45%;
    opacity: 1;
  }
}

@media (min-width: 992px) {
  .main-home-slider .card-columns {
    width: 80%;
    top: -10%;
    right: -30%;
    column-gap: 10px;
  }
}

@media (min-width: 1200px) {
  .main-home-slider .card-columns {
    width: 70%;
    right: -15%;
    top: -15%;
  }
}

@media (min-width: 1300px) {
  .main-home-slider .card-columns {
    right: -5%;
  }
}

@media (min-width: 992px) {
  .main-home-slider .card-columns .card {
    margin-bottom: 10px;
  }
}

.main-home-content {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 5;
  color: $white-7;
  right: 20px;

  .main-logo {
    font-size: 30px;
    font-weight: 700;
    display: flex;
    line-height: 1;
    color: $white;
    margin-bottom: 5px;
    text-indent: -1px;
    color: $white;
    letter-spacing: -.5px;
  }

  h5 {
    font-size: 12px;
    font-weight: 400;
    letter-spacing: .2px;
    margin-bottom: 25px;
  }

  .logo-group {
    margin-bottom: 60px;
    display: flex;

    i {
      font-weight: 400;
      font-size: 32px;
      line-height: .2;

      +i {
        margin-left: 10px;
      }
    }
  }

  h1 {
    color: $white;
    font-weight: 300;
    font-size: 24px;
    margin-bottom: 20px;
  }

  p {
    &:first-of-type {
      font-size: 14px;
      line-height: 1.7;
      margin-bottom: 25px;
    }

    &:last-of-type {
      display: flex;
      margin-bottom: 100px;

      .btn,
      .sp-container button {
        flex: 1;
        text-transform: uppercase;
        font-size: 11px;
        font-weight: 500;
        min-height: inherit;
        letter-spacing: 1px;
        padding: 10px 15px;
      }
    }
  }
}

@media (min-width: 576px) {
  .main-home-content {
    top: 40px;
    left: 40px;
    right: auto;
    width: 400px;
  }
}

@media (min-width: 768px) {
  .main-home-content {
    width: 410px;
  }
}

@media (min-width: 576px) {
  .main-home-content .main-logo {
    font-size: 36px;
    letter-spacing: -1px;
  }
}

@media (min-width: 576px) {
  .main-home-content h5 {
    font-size: 15px;
  }
}

@media (min-width: 576px) {
  .main-home-content h1 {
    font-size: 2.03125rem;
  }
}

.sp-container .main-home-content p:last-of-type button {
  flex: 1;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 500;
  min-height: inherit;
  letter-spacing: 1px;
  padding: 10px 15px;
}

@media (min-width: 768px) {
  .main-home-content p:last-of-type {

    .btn,
    .sp-container button {
      padding: 10px 25px;
      flex: none;
    }
  }

  .sp-container .main-home-content p:last-of-type button {
    padding: 10px 25px;
    flex: none;
  }
}

@media (min-width: 992px) {
  .main-home-content p:last-of-type {

    .btn,
    .sp-container button {
      flex: 1;
    }
  }

  .sp-container .main-home-content p:last-of-type button {
    flex: 1;
  }
}

.main-home-content p:last-of-type {

  .btn+.btn,
  .sp-container button+.btn {
    margin-left: 5px;
  }
}

.sp-container .main-home-content p:last-of-type button+.btn,
.main-home-content p:last-of-type .sp-container .btn+button,
.sp-container .main-home-content p:last-of-type .btn+button,
.main-home-content p:last-of-type .sp-container button+button,
.sp-container .main-home-content p:last-of-type button+button {
  margin-left: 5px;
}

.main-home-content nav:last-child {
  display: flex;

  a {
    font-size: 24px;
    color: $white-5;

    &:hover,
    &:focus {
      color: $white;
    }

    +a {
      margin-left: 8px;
    }
  }
}

.main-icon-group {
  text-align: center;
  padding: 20px;
  color: $gray-800;
}

.font-awesome.main-icon-group i {
  font-size: 14px;
}

.main-icon-group i {
  line-height: 3;
  font-size: 24px;
  font-size: 18px;
}

.main-page-header {
  position: relative;
  padding: 30px 0 40px;
  border-bottom: 1px solid #dee2ef;
  margin-bottom: 40px;
}

.main-page-title {
  font-weight: 700;
  font-size: 40px;
  font-family: "Raleway", sans-serif;
  margin-bottom: 15px;
  line-height: 1;
  letter-spacing: -1.5px;
}

.main-page-text {
  font-size: 20px;
  font-weight: 300;
  margin-bottom: 30px;
}

.main-page-content-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.brround {
  border-radius: 50%;
}

.main-page-content-list li {
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 500;

  +li {
    margin-top: 5px;
  }

  i {
    margin-right: 10px;
    font-size: 10px;
    line-height: 0;
    color: $gray-500;
    text-indent: 1px;
  }
}

.main-page-label {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 5px;
  letter-spacing: -.2px;
}

.main-syntax-wrapper {
  position: relative;

  .badge {
    padding: 4px 5px;
    position: absolute;
    top: 5px;
    right: 5px;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 9px;
    letter-spacing: normal;
    border-radius: 1px;
  }

  pre {
    border-color: $gray-300;
    background-color: $white;
    padding: 15px;
    font-size: 13px;
  }

  code[class*="language-"],
  pre[class*="language-"] {
    font-family: 'Roboto Mono', monospace;
  }
}

/* ############### UTILITIES/HELPER CLASSES ############### */

.horizontal-nav .mega-dropdown-menu {
  box-shadow: 0 25px 100px rgba(115, 77, 191, 0.2);
  border: 0;
  margin: -2px;
  width: 100%;

  &:before {
    content: '';
    position: absolute;
    top: -10px;
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent $white transparent;
    right: auto;
    left: 16px;
  }
}

.mega-dropdown-menu:after {
  right: auto;
  left: 16px;
}

.horizontal-nav .dropdown-menu {
  min-width: 12.5rem;
}

.drop-icon-wrap .drop-icon-item {
  display: inline-block;
  padding-bottom: 10px;
  padding-top: 10px;
  text-align: center;
  color: #020202;
  text-transform: capitalize;
  width: 33.3%;
  float: left;
}

.h-6 {
  height: 2rem !important;
}

.dropgroupicons .dropdown-menu {
  width: 260px ! important;
  padding: 5px ! important;
  top: 52px !important;
  border-radius: 6px;
  -webkit-box-shadow: 0px 0px 15px 1px rgba(69, 65, 78, 0.2);
  box-shadow: 0px 0px 15px 1px rgba(69, 65, 78, 0.2);
}

.flag {
  .dropdown-menu {
    top: 52px !important;
    border-radius: 6px;
    -webkit-box-shadow: 0px 0px 15px 1px rgba(69, 65, 78, 0.2);
    box-shadow: 0px 0px 15px 1px rgba(69, 65, 78, 0.2);
  }

  >a::before {
    content: '';
    position: absolute;
    top: 47px;
    left: 50%;
    margin-left: -7px;
    width: 14px;
    height: 14px;
    border: 2px solid transparent;
    border-top-color: $gray-300;
    border-left-color: $gray-300;
    transform: rotate(45deg);
    background-color: $white;
    z-index: 901;
    display: none;
  }
}

.dropdown-toggle-1::after {
  top: 10px;
  right: 9px;
  border-right: 0;
  border-bottom: 0;
  border-left: 0;
  content: '\f3d0';
  font-family: 'Ionicons';
  position: absolute;
  display: inline-block;
  color: #7a8eb1;
}

.dropdown-toggle-2::after {
  top: 13px;
  right: 0px;
  border-right: 0;
  border-bottom: 0;
  border-left: 0;
  content: '\f3d0';
  font-family: 'Ionicons';
  position: absolute;
  display: inline-block;
  color: #7a8eb1;
}

.dropgroupicons .nav-link.icon i {
  font-size: 21px;
}

.drop-icon-wrap {
  a:hover {
    background: #eff2f6;
  }

  .drop-icon-item .drop-font {
    font-size: 12px;
  }
}

.country-Flag img {
  width: 1.5rem;
  height: 1rem;
  line-height: 0.1rem;
  font-size: .75rem;
}

.dropdown.flag .dropdown-item span {
  font-size: 14px;
}

.btn.btn-default.nav-link {
  height: 38px;
  width: 38px;
  text-align: center;
  border-radius: 50%;
  line-height: 1;
  font-size: 17px;
  padding: 1px;
}

.d-md-block.nav.nav-item.nav-link {
  padding: 0.5rem 0.7rem;
}

.mega-dropdown-menu {
  width: 65rem;
  padding: 0.70rem 0.80rem 0.50rem 0.80rem;

  .dropdown-item {
    padding: 0.5rem 1rem;

    &:hover {
      color: $primary;
      background: transparent;
    }
  }
}

.btn-absolute {
  position: absolute;
  display: block;
  left: 0;
  right: 0;
  bottom: 12px;
  padding: 20px;
}

.arrow-ribbon {
  padding: 6px 8px;
  position: absolute;
  top: 10px;
  left: 0px;
  z-index: 999;
  font-size: 14px;
  line-height: 17px;
  background: $black;
  color: $white;

  &:before {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    content: "";
    right: -15px;
    border-top: 14px solid transparent;
    border-left: 15px solid $black;
    border-bottom: 15px solid transparent;
    width: 0;
  }
}

.header-megamenu-dropdown .nav-item .btn-link {
  color: $default-color;
}

.wordpress-project {
  border-radius: 5px;
  width: 55px;
  height: 55px;
}

.mega-dropdown-menu .media-body .progress-bar {
  height: 4px;
}

.header-megamenu-dropdown .dropdown-menu {
  top: 8px !important;
}

.mega-menu {
  &:before {
    content: '';
    position: absolute;
    top: -10px;
    left: 14px;
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent $white transparent;
  }

  &:after {
    content: '';
    position: absolute;
    top: -10px;
    left: 14px;
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent #c9cddb transparent;
    border-color: transparent transparent rgba(64, 82, 128, 0.9) transparent;
    z-index: -99;
  }
}

.drop-menu-header {
  padding: 1.5rem;
  color: $white;
}

.dropdown-menu .dropdown-header {
  text-transform: uppercase;
  font-size: 0.73333333rem;
  font-weight: 500;
  border-bottom: 1px solid $border;
}

.flag-text {
  color: $default-color;
  font-weight: 400;

  &:before {
    content: '\f3d0';
    font-family: 'Ionicons';
    font-weight: 400;
    font-size: 12px;
    position: absolute;
    top: 10px;
    right: 0;
    color: $default-color;
  }
}

.drop-flag {
  margin-right: 2.3rem !important;
}

.flag-notification {
  a {
    display: block;
    font-size: 22px;
    color: $gray-900;
    position: relative;
    line-height: 1.5;
    outline: none;
    padding: 0.5rem 0.5rem;
  }

}

.flag-img {
  width: 2rem;
  height: 1.3rem;
}

.drop-flag .dropdown-menu {
  width: 150px;
  position: absolute;
  top: 52px;
  left: auto;
  right: -10px;
  bottom: auto;
  padding: 10px 10px 10px;
  border-color: $gray-300;
  border-width: 2px;

  .dropdown-item {
    font-size: 14px;
  }
}

.setting-menu-footer li {
  list-style-type: none;
}

.setting-menu-btn {
  padding: 0.5rem 1rem;
}

.setting-menu-footer .divider {
  margin: 0.5rem 0;
  height: 1px;
  overflow: hidden;
  background: #dee2e6;
}

.setting-menu .dropdown-item {
  padding: 0.4rem 1.5rem;
}

.setting-scroll {
  max-height: 162px;
  position: relative;
}

.header-img {
  background: linear-gradient(rgba(109, 110, 243, 0.7), rgba(109, 110, 243, 0.7)), url(/assets/img/20.jpg);
  background-size: cover;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 20px 0 20px 0;
}

#dropdownMenuButton2 .badge-pill {
  padding: 5px 7px;
  font-size: 10.5px;
}

.bx-flip-horizontal {
  transform: scaleX(-1);
}

.bx-tada {
  -webkit-animation: tada 1.5s ease infinite;
  animation: tada 1.5s ease infinite;
}

.drop-img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: $white;
  position: relative;
}

.dropdown-item .content {
  margin-left: 15px;
  width: 200px;
  white-space: normal;
}

.avatar-status {
  content: '';
  position: absolute;
  bottom: 0;
  right: 5px;
  width: 6px;
  height: 6px;
  background-color: $gray-500;
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.95);
  border-radius: 100%;
  bottom: 4px;
}

.user-pro-body .profile-status {
  position: absolute;
  display: block;
  bottom: 0;
  right: 18px;
  width: 8px;
  height: 8px;
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.95);
  border-radius: 100%;
  background: $success;

  &:before {
    content: '';
    right: -6px;
    width: 20px;
    height: 20px;
    position: absolute;
    border-radius: 50%;
    background: rgba(34, 192, 60, 0.5);
    cursor: pointer;
    box-shadow: 0 0 0 rgba(34, 192, 60, 0.9);
    animation: pulse 1s linear infinite;
    top: -6px;
  }
}

.main-provider-header .nav-link .header-badge,
.main-provider-header .nav-item .header-badge {
  position: absolute;
  top: 0px;
  right: 0px;
  display: block !important;
  padding: 3px 5px !important;
  font-size: 10px;
  z-index: 9999;
  border-radius: 50px;
  line-height: 1.1;
}

.pulse {
  display: block;
  position: absolute;
  top: 6px;
  right: 8px;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: $success;

  &:before {
    content: '';
    top: -4px;
    right: -4px;
    width: 15px;
    height: 15px;
    position: absolute;
    border-radius: 50%;
    background: rgba(34, 192, 60, 0.6);
    cursor: pointer;
    box-shadow: 0 0 0 rgba(34, 192, 60, 0.9);
    animation: pulse 1s linear infinite;
  }
}


@keyframes pulse {
  0% {
    transform: scale(0);
    opacity: 0;
  }

  8% {
    transform: scale(0);
    opacity: 0;
  }

  15% {
    transform: scale(0.1);
    opacity: 1;
  }

  30% {
    transform: scale(0.5);
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: scale(1.1);
  }
}


@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(0);
    opacity: 0;
  }

  8% {
    -webkit-transform: scale(0);
    opacity: 0;
  }

  15% {
    -webkit-transform: scale(0.1);
    opacity: 1;
  }

  30% {
    -webkit-transform: scale(0.5);
    opacity: 1;
  }

  100% {
    opacity: 0;
    -webkit-transform: scale(1.1);
  }
}

@-moz-keyframes pulse {
  0% {
    -webkit-transform: scale(0);
    opacity: 0;
  }

  8% {
    -webkit-transform: scale(0);
    opacity: 0;
  }

  15% {
    -webkit-transform: scale(0.1);
    opacity: 1;
  }

  30% {
    -webkit-transform: scale(0.5);
    opacity: 1;
  }

  100% {
    opacity: 0;
    -webkit-transform: scale(1.1);
  }
}

.pulse-danger {
  display: block;
  position: absolute;
  top: 6px;
  right: 5px;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #ee335e;

  &:before {
    content: '';
    top: -4px;
    right: -4px;
    width: 15px;
    height: 15px;
    position: absolute;
    border-radius: 50%;
    background: rgba(238, 51, 94, 0.8);
    cursor: pointer;
    box-shadow: 0 0 0 rgba(238, 51, 94, 0.9);
    animation: pulse 1s linear infinite;
  }
}

.main-message-list a {
  .desc {
    font-size: 11px;
    color: $default-color;
    margin-left: 10px;
    white-space: break-spaces;
  }

  .name {
    font-size: .8rem;
    transition: all .2s ease-in-out;
    border-radius: 2px;
    color: $default-color;
    margin-left: 10px;
  }

  .time {
    font-size: 11px;
    color: #7987a1;
    font-weight: 400;
  }
}

.menu-header-content {
  padding: 1.2rem;
}

.chat-scroll,
.Notification-scroll,
.chat-scroll1,
.Notification-scroll1 {
  max-height: 280px;
  position: relative;
}

.cover-image {
  background-size: cover !important;
}

.notifyimg {
  i {
    color: $black;
    float: left;
    height: 40px;
    line-height: 40px;
    text-align: center;
    vertical-align: middle;
    width: 40px;
    font-size: 21px;
  }

  border-radius: 25%;
}

.notification-label {
  font-size: 13.5px;
  transition: all .2s ease-in-out;
  border-radius: 2px;
  color: $default-color;

  &:hover {
    color: $primary;
  }
}

.main-message-list .name:hover {
  color: $primary;
}

.notification-subtext {
  font-size: 12px;
  color: #7987a1;
}

/* ###### 4.8 Box-shadows ###### */

.box-shadow-primary {
  box-shadow: 0 5px 10px rgba(118, 109, 249, 0.25);
}

.box-shadow-success {
  box-shadow: 0 5px 10px rgba(26, 156, 134, 0.25);
}

.box-shadow-warning {
  box-shadow: 0 5px 10px rgba(255, 189, 90, 0.25);
}

.box-shadow-danger {
  box-shadow: 0 5px 10px rgba(220, 53, 69, 0.25);
}

.box-shadow-pink {
  box-shadow: 0 5px 10px rgba(241, 0, 117, 0.25);
}

.box-shadow-info {
  box-shadow: 0 5px 10px rgba(78, 194, 240, 0.25);
}

.main-notification-list {
  .las {
    font-size: 13px;
  }

  a:hover {
    background: $bg-hover;
    color: $default-color;
  }
}

.main-message-list a:hover {
  background: $bg-hover;
  color: $default-color;
}

.page-header-icon i {
  margin: 0 auto;
  color: $primary;
  text-shadow: -2px 2px 2px rgba(34, 5, 191, 0.2);
}

.nav-badge {
  margin-left: auto !important;
  z-index: 9;
  padding: 5px 7px;
  font-size: 10.5px;
  border-radius: 50%;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.09), 0 1px 3px rgba(0, 0, 0, 0.08);
  position: absolute;
  right: 12px;
}

.nav-text-badge {
  margin-left: auto !important;
  z-index: 9;
  position: absolute;
  right: 7px;
}

.widget-icons {
  height: 45px;
  width: 45px;
  padding: 7px;
  border-radius: 50%;
  font-size: 23px;
  text-align: center;
  line-height: 28px;

  i {
    margin: 0 auto;
    text-align: center;
    align-items: center;
  }
}

.line-list {
  li {
    &:before {
      content: "";
      position: absolute;
      border-radius: 100%;
      width: 12px;
      height: 12px;
      left: 0;
      border: 3px solid $primary;
      margin-right: 15px;
      z-index: 2;
      background: $white;
    }

    &:after {
      content: "";
      border: 1px solid #e6edf7;
      position: absolute;
      bottom: 0;
      left: 0;
      height: 100%;
    }
  }

  padding-left: 30px;
  margin-bottom: 0;
  position: relative;
  list-style-type: none;
}

.image-grouped .profile-img {
  width: 30px;
  height: 30px;
}

.increase i,
.decrease i {
  height: 35px;
  width: 35px;
  margin: 0 auto;
  text-align: center;
  line-height: 35px;
  font-size: 16px;
}

.sales-bar {
  min-height: 180px !important;
  position: relative;
  bottom: -21px;
}

.progress-sm {
  height: 4px;
}

.expansion-value strong {
  &:first-child {
    color: $gray-900;
  }

  &:last-child {
    color: $gray-600;
  }
}

.card-chart {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  margin-top: 3px;
}

.sales-details {
  position: absolute;
  width: 100%;
}

.table-dashboard-one {
  td {
    vertical-align: top;
    line-height: 1;
    padding: 9px 0;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      color: #8392a5;
    }
  }

  width: 100%;
  font-size: 13px;

  tr+tr td {
    border-top: 1px solid #e5e9f2;
  }
}

.browser-stats {
  i {
    height: 32px;
    width: 32px;
    text-align: center;
    border-radius: 50%;
    line-height: 32px;
  }

  .item {
    padding: 11px 1rem;
  }

  h6 {
    font-size: 13px;
    margin-bottom: 2px;
  }

  .sub-text {
    display: block;
    color: $gray-500;
    font-size: 10.6px;
  }
}

.sales-card {
  .badge {
    border-radius: 30px;
    background-color: #c6d8ff;
    padding: 4px 15px;
    color: #4680ff;
    font-weight: 600;
  }

  .sales-icon {
    color: $white;
    font-size: 18px;
    padding: 40px 40px 20px 20px;
    border-radius: 50%;
    position: absolute;
    top: -30px;
    right: -30px;
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
  }

  &:hover .sales-icon {
    font-size: 25px;
  }
}

.aligner-wrapper {
  .absolute.absolute-center {
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    position: absolute;
  }

  position: relative;
}

.media img {
  width: 35px;
  height: 35px;
}

.sales-dash-header {
  position: absolute;
  left: 20px;
  right: 20px;
  padding: 0;
  background-color: transparent;
  z-index: 5;
}

.sales-session {
  li {
    list-style-type: none;
    margin-bottom: 1.2rem;
  }

  margin-left: 0;
  padding-left: 0;
}

.stats .icon {
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 45px;
  border-radius: 3px;

  i {
    font-size: 20px;
  }
}

.ecommerce-card {
  .icons {
    height: 50px;
    width: 50px;
    line-height: 63px;
    border-radius: 50px;
    text-align: center;
    background: #e9eefe;

    &.primary {
      background: $black-2;

      i {
        color: $white-8 !important;
      }
    }

    i {
      color: $black-5;
      font-size: 30px;
    }

    &.pink {
      background: rgba(241, 0, 117, 0.08) !important;
      color: $pink;

      i {
        color: $pink;
        opacity: 0.8;
      }
    }
  }

  &:hover .icons.pink {
    i {
      color: $white;
      opacity: 1;
    }

    background: $pink !important;
  }

  .icons.success {
    background: rgba(34, 192, 60, 0.1);
    color: $success;

    i {
      color: $success;
      opacity: 0.8;
    }
  }

  &:hover .icons.success {
    i {
      color: $white;
      opacity: 1;
    }

    background: $success !important;
  }

  .icons.warning {
    background: rgba(255, 193, 7, 0.12);
    color: $warning;

    i {
      color: $warning;
      opacity: 0.8;
    }
  }

  &:hover .icons.warning {
    i {
      color: $white;
      opacity: 1;
    }

    background: $warning !important;
  }
}

.product-timeline {
  ul.timeline-1 {
    list-style-type: none;
    position: relative;
  }

  .name {
    color: $default-color;
    font-size: 15px;
  }

  ul.timeline-1 {
    &:before {
      content: ' ';
      margin: 20px 20px 0 22px;
      display: inline-block;
      position: absolute;
      left: -2px;
      height: 94%;
      z-index: 0;
      content: '';
      position: absolute;
      bottom: 0;
      border-left: 2px dotted #c0ccda;
    }

    >li {
      margin: 20.7px 0;
      padding-left: 1rem;

      .product-icon {
        width: 37px;
        height: 37px;
        text-align: center;
        border-radius: 50%;
        position: absolute;
        left: 3px;
        background-color: #f3f6f7;
        font-size: 20px;
        line-height: 37px;
        font-size: 17px;
      }
    }
  }
}

.font-weight-semibold {
  font-weight: 500 !important;
}

.bounceIn {
  -webkit-animation-duration: 0.75s;
  animation-duration: 0.75s;
  -webkit-animation-name: bounceIn;
  animation-name: bounceIn;
}

@keyframes bounceIn {

  from,
  20%,
  40%,
  60%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03);
  }

  80% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
    transform: scale3d(0.97, 0.97, 0.97);
  }

  to {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

.order-list {
  .list {
    padding-left: 0;

    .list-item:first-child {
      padding-top: 0;
    }

    &.list-noborders .list-item {
      border: none;
    }

    .list-item {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      padding: 0 0 19px 0;
    }
  }

  .list-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    padding: 20px 0;
    border-bottom: 1px solid #dee2e6;
  }
}

.top-selling-product {
  img {
    border-radius: 6px;
  }

  .table {

    th,
    td {
      padding: 7px 7px 7px 13px !important;
      vertical-align: baseline;
    }
  }

  .table-bordered thead {

    th,
    td {
      border-top-width: 1px;
      padding-top: 11px !important;
      padding-bottom: 11px !important;
    }
  }
}

.out-of-stock {
  position: relative;

  &:before {
    position: absolute;
    content: '';
    background: rgba(239, 242, 246, 0.8) !important;
    top: 0;
    left: 0;
    width: 43px;
    min-width: 43px;
    height: 43px;
    bottom: 0;
    background-size: cover;
    display: block;
  }
}

.top-selling-product .text-default {
  color: #cdd4e8 !important;
}

.order-list img {
  border-radius: 6px;
}

.traffic-resource {
  .table {

    th,
    td {
      padding: 15px 0px !important;
      border-top: 0;
      border-bottom: 1px solid $border;
    }
  }

  h6 {
    margin-bottom: 0;
  }

  .table-hover tbody tr:hover {
    color: $default-color;
    background-color: $bg-hover;
  }
}

@keyframes ani {
  0% {
    transform: scale(1);
  }

  25% {
    transform: scale(1);
  }

  30% {
    transform: scale(1.4);
  }

  50% {
    transform: scale(1.2);
  }

  70% {
    transform: scale(1.4);
  }

  90% {
    transform: scale(1);
  }

  100% {
    transform: scale(1);
  }
}

.row-cards> {

  .col,
  [class*='col-'] {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

.row-deck> {

  .col,
  [class*='col-'] {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: stretch;
    align-items: stretch;
  }

  .col .card,
  [class*='col-'] .card {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
  }
}

.semi-circle {
  background: rgba(234, 239, 254, 0.5);
  border-radius: 0 0 300px 300px;
}

.project-card {
  overflow: hidden;

  &:hover i {
    color: rgb(40, 92, 247);
    opacity: .16;
  }

  i {
    font-size: 65px;
    margin: 0 24px 0 -30px;
    position: relative;
    opacity: .07;
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
  }

  .project-content {
    width: 100%;

    ul {
      strong {
        color: $gray-500;
        font-size: 12px;
        font-weight: 400;
      }

      li {
        line-height: 20px;
      }

      list-style: none;
      margin: 0;
      padding: 0;

      span {
        color: #323232;
        float: right;
        font-weight: 600;
        text-align: right;
        margin-left: auto;
      }
    }
  }

  .primary {
    fill: $primary;
  }

  &:hover .primary {
    fill: $primary;
    opacity: 1;
  }

  .teal,
  &:hover .teal {
    fill: $teal;
  }

  .pink {
    fill: $pink;
  }

  &:hover .pink {
    fill: $pink;
    opacity: 1;
  }

  .purple {
    fill: $purple;
  }

  &:hover .purple {
    fill: $purple;
    opacity: 1;
  }
}

.area.chart-legend {
  top: 125px;
}

.recent-operations-card .operation-icon {
  width: 40px;
  height: 40px;
  text-align: center;
  padding: 0;
  font-size: 18px;
  color: $white;
  border-radius: 5px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.crypto-scroll {
  max-height: 291px;
  position: relative;
}

.transcation-scroll {
  max-height: 400px;
  position: relative;

  .list p a {
    color: $gray-700;
  }
}

.crypto {

  #flotChart3,
  #flotChart5,
  #flotChart1 {
    position: relative;
    margin: 0 -40px;
    margin-bottom: -40px;
  }

  .media {
    position: absolute;
  }

  .card-footer {
    .nav-link {

      &:hover,
      &:focus {
        background-color: $bg-hover;
      }

      span {
        display: block;

        &:first-child {
          color: $gray-500;
          font-weight: 700;
          font-size: 11px;
        }

        &:last-child {
          color: $default-color;
          font-weight: 500;
          font-size: 11px;
          font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
        }
      }

      +.nav-link {
        border-left: 1px solid $border;
      }
    }

    background-color: $white;
    padding: 0;
  }

  &.card-footer .nav-link.active {
    background-color: $bg-active;
  }

  .card-footer .nav-link {
    &.active::before {
      content: '';
      position: absolute;
      top: -1px;
      left: 0;
      right: 0;
      z-index: 5;
    }

    display: block;
    padding: 0.5rem 1.49rem;
  }

  .nav-link+.nav-link {
    border-left: 1px solid $border;
  }

  .card-footer .nav-link {
    flex: 1;
    display: block;
    text-align: center;
    background-color: rgba(227, 231, 237, 0.45);
    position: relative;
    padding-left: 10px;
    padding-right: 10px;
  }

  .coin-logo {
    width: 42px;
    height: 42px;
    color: $white;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    margin-right: 15px;
  }

  h6 {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 2px;
    color: $gray-900;
  }
}

.market-values .btn {
  min-height: initial;
}

.fs-name {
  font-size: .92rem;
  font-weight: 500;
  line-height: 1;
  margin-bottom: 3px;
}

.task-box {
  background-color: #eff2f6;
  margin-bottom: 15px;
  padding: 10px;

  &.primary p,
  &.pink p {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 10px;
    margin-bottom: 5px;
    letter-spacing: .5px;
  }

  &.primary {
    background-color: rgba(217, 232, 254, 0.6) !important;
    color: $primary !important;
    border: 1px dashed #7da7e4;
  }

  &.pink {
    background-color: rgba(254, 229, 241, 0.5) !important;
    color: $pink !important;
    border: 1px dashed #f196c3;
  }
}

.task-stat .tasks {
  color: $default-color;
  padding: 0.65rem 1.25rem;
  font-weight: 500;
  border-bottom: 1px solid #e7ebf3;
}

.svg-icons {
  fill: $default-color;
  height: 20px;
}

.eve-icon {
  height: 20px;
}

.ui-bottom-data {
  position: relative;
  bottom: -10px;
  border-radius: 5px;
  -webkit-box-shadow: 0 1px 10px rgba(24, 28, 33, 0.2);
  box-shadow: 0 1px 10px rgba(24, 28, 33, 0.2);
}

.project-list .list-unstyled li {
  margin-bottom: 20px;
  padding: 14px;
  border-left: 1px solid $black;

  .media-body {
    margin-left: 13px;
  }
}

.pricing-card {
  .list-unstyled {
    padding-left: 0;
    list-style: none;
  }

  .display-5 {
    font-size: 2.3rem;
    line-height: 1.2;
    padding: 25px 0 0 !important;
  }

  .list-unstyled li {
    display: flow-root;
    margin-bottom: 0;
    padding: 9px 0;
  }
}

/*------ Pricing Styles ---------*/

.panel.price {
  -moz-transition: all .3s ease;
  -o-transition: all .3s ease;
  -webkit-transition: all .3s ease;

  >.panel-heading {
    -moz-transition: all .3s ease;
    -o-transition: all .3s ease;
    -webkit-transition: all .3s ease;
  }

  h3 {
    margin-bottom: 0;
    padding: 20px 0;
  }
}

.panel-heading {
  padding: 10px 15px;
  border-bottom: 1px solid $white-2;
  background: $white;
}

.panel.price {
  >.panel-heading {
    color: $dark;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }

  .list-group-item {
    &:last-child {
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 0px;
    }

    &:first-child {
      border-top-right-radius: 0px;
      border-top-left-radius: 0px;
    }
  }

  margin-bottom: 1.5rem;
}

.price {
  .panel-footer {
    border-bottom: 0px;
    background-color: $white;
    border-left: 0;
    border-right: 0;
  }

  &.panel-color>.panel-body {
    background-color: $white;
  }
}

.panel-body {
  padding: 15px;
  border: 1px solid $border;

  .lead {
    strong {
      font-size: 40px;
      margin-bottom: 0;
    }

    font-size: 20px;
    margin-bottom: 0;
    padding: 10px 0;
  }
}

.panel-footer {
  padding: 10px 15px;
  background-color: $white;
  border-top: 1px solid $border;
  border-left: 1px solid $border;
  border-right: 1px solid $border;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.panel.price .btn {
  border: 0px;
}

.pricing .list-unstyled li {
  display: flow-root;
  margin-bottom: 0;
  padding: 15px 0;
}

.demo-gallery {
  >ul>li a {
    border-radius: 3px;
    display: block;
    overflow: hidden;
    position: relative;
    float: left;
    height: 100%;
    width: 100%;

    >img {
      opacity: 1;
      -webkit-transition: .3s ease-in-out;
      transition: .3s ease-in-out;
      width: 100% !important;
    }

    &:hover {

      >img,
      .demo-gallery-poster>img {
        opacity: .5;
      }
    }

    .demo-gallery-poster {
      background-color: $black-1;
      bottom: 0;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      -webkit-transition: background-color 0.15s ease 0s;
      -o-transition: background-color 0.15s ease 0s;
      transition: background-color 0.15s ease 0s;

      >img {
        left: 50%;
        margin-left: -10px;
        margin-top: -10px;
        opacity: 0;
        position: absolute;
        top: 50%;
        -webkit-transition: opacity 0.3s ease 0s;
        -o-transition: opacity 0.3s ease 0s;
        transition: opacity 0.3s ease 0s;
      }
    }

    &:hover .demo-gallery-poster {
      background-color: $black-5;
    }
  }

  .justified-gallery>a {
    >img {
      -webkit-transition: -webkit-transform 0.15s ease 0s;
      -moz-transition: -moz-transform 0.15s ease 0s;
      -o-transition: -o-transform 0.15s ease 0s;
      transition: transform 0.15s ease 0s;
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
      height: 100%;
      width: 100%;
    }

    &:hover {
      >img {
        -webkit-transform: scale3d(1.1, 1.1, 1.1);
        transform: scale3d(1.1, 1.1, 1.1);
      }

      .demo-gallery-poster>img {
        opacity: 1;
      }
    }

    .demo-gallery-poster {
      background-color: $black-1;
      bottom: 0;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      -webkit-transition: background-color 0.15s ease 0s;
      -o-transition: background-color 0.15s ease 0s;
      transition: background-color 0.15s ease 0s;

      >img {
        left: 50%;
        margin-left: -10px;
        margin-top: -10px;
        opacity: 0;
        position: absolute;
        top: 50%;
        -webkit-transition: opacity 0.3s ease 0s;
        -o-transition: opacity 0.3s ease 0s;
        transition: opacity 0.3s ease 0s;
      }
    }

    &:hover .demo-gallery-poster {
      background-color: $black-5;
    }
  }

  .video .demo-gallery-poster img {
    height: 48px;
    margin-left: -24px;
    margin-top: -24px;
    opacity: 0.8;
    width: 48px;
  }

  &.dark>ul>li a {
    border: 3px solid #04070a;
  }
}

.card-body+.card-body {
  border-top: 1px solid $border;
}

.left-side-product-box {
  img {
    width: 100%;
  }

  .sub-img img {
    margin-top: 5px;
    width: 83px;
    height: 100px;
  }
}

.right-side-pro-detail {
  span {
    font-size: 15px;
  }

  p {
    font-size: 25px;
    color: #a1a1a1;
  }

  .price-pro {
    color: #E45641;
  }

  .tag-section {
    font-size: 18px;
    color: #5D4C46;
  }
}

.pro-box-section .pro-box img {
  width: 100%;
  height: 200px;
}

@media (min-width: 360px) and (max-width: 640px) {
  .pro-box-section .pro-box img {
    height: auto;
  }
}

.product-details .media img {
  width: 60px;
  height: 60px;
  margin-right: 20px;
  border-radius: 5px;
  max-width: inherit;
  border: 1px solid #d4daea;
}

.preview-pic {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.preview-thumbnail.nav-tabs {
  border: none;
  margin-top: 15px;

  li {
    width: 18%;
    margin-right: 2.5%;

    img {
      max-width: 100%;
      display: block;
    }

    a {
      padding: 0;
      margin: 0;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }
}

.tab-content {
  overflow: hidden;

  img {
    width: 100%;
  }
}

.details {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.colors {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.product-title,
.price,
.sizes,
.colors {
  font-weight: 500;
}

.checked {
  color: #ff9f1a;
}

.price span {
  color: #031b4e;
}

.product-title,
.rating,
.product-description,
.price,
.vote,
.sizes {
  margin-bottom: 15px;
}

.product-title {
  margin-top: 0;
}

.size {
  margin-right: 10px;

  &:first-of-type {
    margin-left: 40px;
  }
}

.details .rdiobox span {
  &:after {
    top: 5px;
  }

  &:before {
    top: 0px;
  }
}

@-webkit-keyframes opacity {
  0% {
    opacity: 0;
    -webkit-transform: scale(3);
    transform: scale(3);
  }

  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes opacity {
  0% {
    opacity: 0;
    -webkit-transform: scale(3);
    transform: scale(3);
  }

  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.colorinput {
  margin: 0;
  position: relative;
  cursor: pointer;
}

.colorinput-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.colorinput-color {
  display: inline-block;
  width: 1.75rem;
  height: 1.75rem;
  border-radius: 3px;
  border: 1px solid #eaf0f7;
  color: $white;
  box-shadow: 0 1px 2px 0 $black-05;

  &:before {
    content: '';
    opacity: 0;
    position: absolute;
    top: .25rem;
    left: .25rem;
    height: 1.25rem;
    width: 1.25rem;
    transition: .3s opacity;
    background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E") no-repeat center (center / 50%) 50%;
  }
}

.colorinput-input:checked~.colorinput-color:before {
  opacity: 1;
}

.feature2 i {
  line-height: 3rem;
  font-size: 20px;
  margin: 0 0 15px 0;
}

.item-card {
  .cardtitle {
    span {
      display: block;
      font-size: .75rem;
    }

    a {
      color: #1f252d;
      font-weight: 500;
      text-decoration: none;
    }
  }

  .cardprice {
    position: absolute;
    top: 17px;
    right: 15px;

    span {
      &.type--strikethrough {
        opacity: .7;
        text-decoration: line-through;
      }

      display: block;
      color: #1f252d;
    }
  }

  .relative {
    position: relative;
  }
}

/*------icons-list---------*/

.main-icon-list {
  padding: 0;
}

.icons-list-item i {
  font-size: 1rem !important;
  line-height: 3;
}

.icons-list {
  list-style: none;
  margin: 0 -1px -1px 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}

.icons-list-item {
  text-align: center;
  height: 3rem;
  width: 3rem;
  line-height: 3.3;
  display: block;
  align-items: center;
  justify-content: center;
  border: 1px solid #e9ebfa;
  margin: 4px;
  border-radius: 5px;
  color: #6c757e;
  fill: #6c757e;
  flex: 1 0 3rem;
}

.icons-list-item .bs-tooltip-start {
  padding: 10px 0.4rem;
}

.flag {
  margin: 8px auto !important;
  width: 30px;
  height: 30px;
  margin: 0 auto;
  display: block;
  vertical-align: middle;
  box-shadow: none;
}

.payment {
  width: 30px;
  height: 30px;
  margin: 0 auto;
  margin-top: 9px;
  display: block;
  vertical-align: middle;
  box-shadow: none;
}

.icons-list .flag-icon {
  border-radius: 0;
}

/*------icons-list---------*/

.example {
  padding: 1rem;
  border: 1px solid $border;
  font-size: 0.8175rem;
  border-radius: 7px;

  +.highlight {
    border-top: none;
    margin-top: 0;
  }
}

.text-wrap> :last-child {
  margin-bottom: 0;
}

.highlight {
  margin: 0 0 2rem;
  border: 1px solid #23241f;
  border-radius: 0px;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  font-size: 0.9375rem;
  background: #edeff7;
  border-top-right-radius: 8px;
  position: relative !important;

  pre {
    margin-bottom: 0;
    background-color: #23241f;
    max-height: 20rem;
  }
}

pre {
  color: #3d3f4e;
  padding: 1rem;
  overflow: auto;
  font-size: 85%;
  line-height: 1.45;
  background-color: #edeff7;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
  border-top-right-radius: 7px;
}

/*------icons-list---------*/

.clip-widget {
  position: relative;
}

.clipboard-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 25px;
  background: $white;
  border: 1px solid $border;
  ;
  border-radius: 8px;
  cursor: pointer;
  height: 35px;
  width: 35px;
  text-align: center;
  line-height: 33px;

}

*::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  transition: .3s background;
}

::-webkit-scrollbar-thumb {
  background: $bg-active;
}

*:hover::-webkit-scrollbar-thumb {
  background: #adb5bd;
}

@media (max-width: 568px) {

  .alert-dismissible .close {
    padding: 0.55rem 0.20rem;
    color: inherit;
  }

  .main-nav .nav-link+.nav-link {
    border-top: 0px solid $border;
  }

  .main-nav .nav-link+.nav-link {
    border-left: 0px !important;
  }


}

@media (max-width: 768px) {
  .responsive-logo .header-logo {
    display: block;
    margin: 0 auto;
    left: 0;
    right: 0;
    text-align: center;
    position: absolute;
    top: 10px;
    ;
  }
}

.angle {
  color: #9ca2a9 !important;
}

.pro-img-box img {
  border: 1px solid $border !important;
}

.banner {
  background: rgba(109, 110, 243, 0.1);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 4px;
  margin: 1.5rem 0;
  padding: 14px 15px;
  border: 1px solid rgb(219, 219, 247);
}

.nav-sub-item .sub-slide-item {
  color: $white;
}

@media (max-width: 633px) {
  .right-content {
    margin-top: 20px !important;
  }
}

@media (max-width: 1199px) {
  .total-revenue {
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid $border !important;
  }
}

.sub-menu li a:before {
  margin-right: 8px;
  content: '';
  width: inherit;
  height: inherit;
  border-width: 0;
  position: absolute;
  left: 8px;
  top: 15px;
  z-index: 99;
  border-color: #bec5d0;
  content: "\e92f";
  font-family: 'feather' !important;
  position: absolute;
  top: 9px;
  left: 8px;
  font-size: 9px;
}

.horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>a:hover {
  color: #696af1;
}

.mega-menubg.hor-mega-menu li a:before {
  position: absolute;
  z-index: 99;
  border-color: #bec5d0;
  content: "\f22d";
  font-family: "Font Awesome 5 Free" !important;
  top: 11px;
  left: 10px;
  font-size: 12px;
  background: transparent;
  color: #7b7a7d !important;
}


.slide.active.is-expanded {
  background: rgba(238, 238, 247, 0.5);
}

@media (max-width: 1134px) {
  .p-text {
    display: none;
  }
}

@media (max-width: 992px) {
  .fullscreen-button {
    display: none;
  }
}

.horizontalMenucontainer.main-provider-header form[role="search"] {
  width: inherit;
}

.page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: center;
}

.prev-price {
  text-decoration: line-through;
}

.qunatity-list {
  margin-left: 0;
  padding-left: 6px;

  li {
    list-style-type: none;
  }
}

.chips .chip {
  margin: 0 .5rem .5rem 0;
}

.chip {
  display: inline-block;
  height: 2rem;
  line-height: 2rem;
  font-size: 0.875rem;
  font-weight: 500;
  color: #6e7687;
  padding: 0 .75rem;
  border-radius: 1rem;
  background-color: #f5f6fb;
  transition: .3s background;

  .avatar {
    float: left;
    margin: 0 .5rem 0 -.75rem;
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
  }
}

.team i {
  margin-left: 10px;
  float: right;
}

.feature .project {
  display: flex;
  width: 45px;
  height: 45px;
  line-height: 2em;
  vertical-align: middle;
  padding-top: 0px;
  color: $white;
  font-size: 20px;
  border-radius: 50px;
  position: relative;
  align-items: center;
  text-align: center;
  left: 0;
  right: 0;
  justify-content: center;
  font-weight: 500;
}

.plan-icon {
  font-size: 25px;
  width: 80px;
  height: 80px;
  line-height: 80px !important;
  overflow: hidden;
  border: 1px solid #ebeaf1;
  border-radius: 50%;
  background: #f5f6fb;
  transition: all .3s;
}

.country-table .table th,
.country-table .table td {
  padding: 10px 15px;
  line-height: 1.462;
}

/*-----Back to Top-----*/

#back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 99;
  display: none;
  text-align: center;
  border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -o-border-radius: 4px;
  z-index: 1000;
  height: 50px;
  width: 50px;
  background-repeat: no-repeat;
  background-position: center;
  transition: background-color 0.1s linear;
  -moz-transition: background-color 0.1s linear;
  -webkit-transition: background-color 0.1s linear;
  -o-transition: background-color 0.1s linear;

  i {
    padding-top: 0;
    font-size: 20px;
    line-height: 2.4;
  }

  background:$primary;
  color: $white;
  border-radius: 5px;
}

#compositeline,
#compositeline2 {
  position: relative;
  bottom: -1px;
}

@media (max-width: 480px) {
  .sales-bar {
    padding-top: 50px;
  }
}

@media (max-width: 578px) {
  .main-logo {
    display: none !important;
  }

  .total-revenue .media {
    display: none;
  }
}

.email-button {
  background: #009a3e;
  color: $white;
  position: fixed;
  bottom: 80px;
  line-height: 50px;
  right: 20px;
  z-index: 99;
  text-align: center;
  border-radius: 50%;
  z-index: 1000;
  height: 50px;
  width: 50px;
  background-repeat: no-repeat;
  background-position: center;
  transition: background-color 0.1s linear;
  -moz-transition: background-color 0.1s linear;
  -webkit-transition: background-color 0.1s linear;
  -o-transition: background-color 0.1s linear;

  &:hover {
    background: $bg-active;
    color: #009a3e;
    border: 2px solid #009a3e;
  }
}

.box-shadow {
  box-shadow: none;
}

@media (max-width: 480px) {
  .sales-bar {
    padding-top: 50px;
  }
}

@media (max-width: 575px) {
  .sales-bar {
    padding-top: 50px;
  }
}

.horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>a:hover:before {
  border-color: $primary;
  color: $primary;
}

.main-logo-pages {
  display: flex;
  align-items: center;
  position: relative;
  margin: 0 auto;
  height: 2.3rem;
}

@media (max-width: 578px) {
  .desktop-logo.logo-light .main-logo {
    display: block;
  }

}

@media (max-width: 767px) {
  .icons-list-item {
    margin: 4px 4px;
  }
}

/* ############### DASHBOARD STYLES ############### */

/* ###### 8.1 Dashboard One  ###### */

.main-provider-content-dashboard {
  padding-top: 20px;
}

@media (min-width: 992px) {
  .main-provider-content-dashboard {
    padding-top: 30px;
  }
}

.main-dashboard-one-title {
  margin-bottom: 20px;
}

@media (min-width: 576px) {
  .main-dashboard-one-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

@media (min-width: 992px) {
  .main-dashboard-one-title {
    margin-bottom: 30px;
  }
}

.main-dashboard-title {
  font-size: 22px;
  font-weight: 700;
  color: #1c273c;
  letter-spacing: -1px;
  margin-bottom: 3px;
}

.main-dashboard-text {
  font-size: 13px;
  margin-bottom: 0;
}

.main-dashboard-date {
  display: flex;
  align-items: center;
  margin-top: 20px;

  .date {
    display: flex;

    >div {
      &:first-child {
        font-size: 32px;
        font-family: Arial, sans-serif;
        font-weight: 500;
        color: #1c273c;
        line-height: .9;
      }

      &:last-child {
        margin-left: 5px;

        span {
          display: block;
          color: #1c273c;
          font-weight: 700;
          font-size: 12px;
          line-height: 1.2;
          letter-spacing: -.5px;

          &:last-child {
            color: #7987a1;
            font-weight: 400;
          }
        }
      }
    }
  }

  >i {
    font-size: 21px;
    line-height: 0;
    display: block;
    margin: 0 15px;
    color: #97a3b9;
  }

  .wallet {
    border-radius: 3px;

    i {
      line-height: 40px;
    }
  }
}

@media (min-width: 576px) {
  .main-dashboard-date {
    margin-top: 0;
  }
}

@media (min-width: 768px) {
  .main-dashboard-date .date>div:first-child {
    font-size: 36px;
  }
}

@media (min-width: 768px) {
  .main-dashboard-date .date>div:last-child span {
    font-size: 13px;
  }
}

@media (min-width: 768px) {
  .main-dashboard-date>i {
    margin: 0 20px;
    font-size: 24px;
  }
}

.main-dashboard-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $border;
  margin-bottom: 20px;

  .nav-link {
    font-size: 12px;
    padding: 5px 10px 8px;
    display: flex;
    align-items: center;
    line-height: 1;

    &:hover,
    &:focus {
      color: $primary;
    }

    +.nav-link {
      border-left: 1px solid $border;
    }
  }

  .nav {
    &:first-child .nav-link {
      color: #1c273c;
      display: none;

      &:hover,
      &:focus,
      &.active {
        color: $primary;
      }

      &:first-child {
        padding-left: 0;
        display: block;
      }

      &:last-child {
        display: block;
      }
    }

    &:last-child .nav-link {
      color: #596882;
      display: none;

      &:hover,
      &:focus {
        color: $primary;
      }

      i {
        font-size: 16px;
        margin-right: 7px;
        line-height: 0;
      }

      &:last-child {
        padding-right: 0;
        display: block;

        i {
          margin-right: 0;
        }
      }
    }
  }
}

@media (min-width: 576px) {
  .main-dashboard-nav .nav-link {
    font-size: 0.875rem;
  }
}

@media (min-width: 992px) {
  .main-dashboard-nav .nav-link {
    padding: 5px 15px 10px;
  }
}

@media (min-width: 576px) {
  .main-dashboard-nav .nav:first-child .nav-link {
    display: block;
  }
}

@media (min-width: 768px) {
  .main-dashboard-nav .nav:last-child .nav-link {
    display: block;
  }
}

@media (min-width: 768px) {
  .main-dashboard-nav .nav:last-child .nav-link:last-child {
    display: none;
  }
}

.card-dashboard-one {
  position: relative;
  border-color: $border;

  .card-header {
    padding: 20px;
    background-color: transparent;

    .card-title {
      color: #1c273c;
      font-weight: 700;
      font-size: 14px;
      line-height: 1;
      margin-bottom: 3px;
    }

    .card-text {
      margin-bottom: 0;
    }

    .btn-group {
      margin-top: 15px;

      .btn,
      .sp-container button {
        font-size: 12px;
        font-weight: 500;
        padding: 5px 10px;
        min-height: inherit;
        background-color: $white;
        color: #7987a1;
        border: 1px solid $border;
      }
    }
  }
}

@media (min-width: 576px) {
  .card-dashboard-one .card-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
}

@media (min-width: 576px) {
  .card-dashboard-one .card-header .btn-group {
    margin-top: 0;
  }
}

.sp-container .card-dashboard-one .card-header .btn-group button {
  font-size: 12px;
  font-weight: 500;
  padding: 5px 10px;
  min-height: inherit;
  background-color: $white;
  color: #7987a1;
  border: 1px solid $border;
}

.card-dashboard-one .card-header .btn-group {

  .btn:hover,
  .sp-container button:hover {
    background-color: #f4f5f8;
    border-color: $border;
  }
}

.sp-container .card-dashboard-one .card-header .btn-group button:hover {
  background-color: #f4f5f8;
  border-color: $border;
}

.card-dashboard-one .card-header .btn-group {

  .btn:focus,
  .sp-container button:focus {
    background-color: #f4f5f8;
    border-color: $border;
  }
}

.sp-container .card-dashboard-one .card-header .btn-group button:focus {
  background-color: #f4f5f8;
  border-color: $border;
}

.card-dashboard-one .card-header .btn-group {

  .btn+.btn,
  .sp-container button+.btn {
    margin-left: -1px;
  }
}

.sp-container .card-dashboard-one .card-header .btn-group button+.btn,
.card-dashboard-one .card-header .btn-group .sp-container .btn+button,
.sp-container .card-dashboard-one .card-header .btn-group .btn+button,
.card-dashboard-one .card-header .btn-group .sp-container button+button,
.sp-container .card-dashboard-one .card-header .btn-group button+button {
  margin-left: -1px;
}

.card-dashboard-one .card-header .btn-group {

  .btn.active,
  .sp-container button.active {
    color: #1c273c;
    background-color: #e3e7ed;
    border-color: $border;
  }
}

.sp-container .card-dashboard-one .card-header .btn-group button.active {
  color: #1c273c;
  background-color: #e3e7ed;
  border-color: $border;
}

.card-dashboard-one {
  .card-body {
    padding: 10px 0 20px;
    position: relative;
    overflow: hidden;

    .flot-chart-wrapper {
      position: relative;
      margin-left: -28px;
      margin-right: -20px;
    }

    .flot-chart {
      width: 100%;
      height: 150px;

      .flot-y-axis>div {
        transform: translateX(50px);
        text-shadow: 1px 1px rgba(255, 255, 255, 0.75);
        color: $default-color;
        font-weight: 700;
        font-size: 11px;
      }

      .flot-x-axis>div {
        color: #97a3b9;
        font-weight: 500;
        font-size: 11px;
      }
    }
  }

  .card-body-top {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    padding-left: 20px;

    >div {
      flex-basis: 50%;
    }

    label {
      font-size: 12px;
      margin-bottom: 3px;
    }

    h2 {
      font-size: 20px;
      font-weight: 600;
      font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      color: #1c273c;
    }
  }
}

@media (min-width: 576px) {
  .card-dashboard-one .card-body .flot-chart {
    height: 275px;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .card-dashboard-one .card-body .flot-chart {
    height: 252px;
  }
}

@media (min-width: 576px) {
  .card-dashboard-one .card-body-top {
    position: absolute;
    top: -5px;
    left: 20px;
    z-index: 5;
    padding-left: 0;
  }
}

@media (min-width: 576px) {
  .card-dashboard-one .card-body-top>div {
    flex-basis: auto;
  }
}

@media (min-width: 576px) {
  .card-dashboard-one .card-body-top>div+div {
    margin-left: 30px;
  }
}

@media (min-width: 576px) {
  .card-dashboard-one .card-body-top label {
    font-size: 0.875rem;
  }
}

@media (min-width: 576px) {
  .card-dashboard-one .card-body-top h2 {
    font-size: 24px;
    letter-spacing: -.5px;
  }
}

.card-dashboard-two {
  height: 100%;
  border-radius: 0;
  border-color: $border;
  background-color: $white-5;

  .card-header {
    background-color: transparent;
    padding: 20px 20px 0;

    h6 {
      font-size: 28px;
      font-weight: 600;
      font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      color: #1c273c;
      display: flex;
      align-items: baseline;
      margin-bottom: 2px;
      line-height: 1;
      letter-spacing: -1px;

      i {
        margin-left: 10px;
        font-size: 24px;
      }

      small {
        font-size: 12px;
        font-weight: 400;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
        margin-left: 5px;
        color: #7987a1;
        letter-spacing: normal;
      }
    }

    p {
      margin-bottom: 0;
      font-size: 13px;
    }
  }

  .card-body {
    padding: 0;
    overflow: hidden;
  }

  .chart-wrapper {
    position: relative;
    margin: 0 -10px -10px;
  }

  .flot-chart {
    width: 100%;
    height: 100px;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .card-dashboard-two .card-header h6 {
    font-size: 24px;
  }
}

.card-dashboard-three {
  height: 150px;
  border-radius: 0;
  border-color: $border;
  background-color: $white-5;

  .card-header {
    background-color: transparent;
    padding: 20px;
    position: absolute;
    max-width: 50%;

    h6 {
      font-size: 28px;
      font-weight: 600;
      font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      color: #1c273c;
      display: flex;
      align-items: baseline;
      margin-bottom: 15px;
      line-height: 1;
      letter-spacing: -1px;

      small {
        font-size: 12px;
        font-weight: 400;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
        margin-left: 5px;
        letter-spacing: normal;
      }
    }

    p {
      margin-bottom: 10px;
      font-weight: 700;
      text-transform: uppercase;
      color: #1c273c;
    }

    >small {
      font-size: 11px;
      display: none;
      color: #7987a1;
    }
  }

  .card-body {
    padding: 0 0 0 20px;
    display: flex;
    justify-content: flex-end;
  }

  .chart {
    width: 70%;
    height: 150px;
  }
}

@media (min-width: 768px) {
  .card-dashboard-three {
    height: 170px;
  }
}

@media (min-width: 992px) {
  .card-dashboard-three {
    height: 100%;
  }
}

@media (min-width: 1200px) {
  .card-dashboard-three .card-header h6 {
    font-size: 32px;
  }
}

@media (min-width: 768px) {
  .card-dashboard-three .card-header>small {
    display: block;
  }
}

@media (min-width: 375px) {
  .card-dashboard-three .chart {
    width: auto;
  }
}

@media (min-width: 768px) {
  .card-dashboard-three .chart {
    height: 170px;
  }
}

@media (min-width: 992px) {
  .card-dashboard-three .chart {
    height: 200px;
    width: 80%;
  }
}

.card-dashboard-pageviews {
  border-color: $border;
  border-radius: 0;
  padding: 20px;

  .card-header {
    background-color: transparent;
    padding: 0 0 10px;
  }

  .card-title {
    font-weight: 700;
    font-size: 14px;
    color: #1c273c;
    margin-bottom: 5px;
  }

  .card-text {
    font-size: 13px;
    margin-bottom: 0;
  }

  .card-body {
    padding: 0;
  }
}

.card-dashboard-four {
  border-radius: 0;
  border-color: $border;
  padding: 20px;
  height: 100%;

  .card-header {
    padding: 0 0 20px;
    background-color: transparent;
  }

  .card-title {
    font-weight: 700;
    font-size: 14px;
    color: #1c273c;
    margin-bottom: 0;
  }

  .card-body {
    padding: 0;
  }

  .chart {
    width: calc(100vw - 80px);
    height: 200px;
  }
}

@media (min-width: 992px) {
  .card-dashboard-four .card-body {
    padding: 0 20px;
  }
}

@media (min-width: 375px) {
  .card-dashboard-four .chart {
    width: auto;
  }
}

@media (min-width: 992px) {
  .card-dashboard-four .chart {
    height: 85%;
  }
}

.card-dashboard-five {
  border-color: $border;
  padding: 20px;

  .card-header {
    padding: 0;
    background-color: transparent;
  }

  .card-title {
    font-weight: 700;
    font-size: 14px;
    color: #1c273c;
    margin-bottom: 5px;
  }

  .card-text {
    display: block;
    font-size: 12px;
  }

  .card-body {
    padding: 20px 0 5px;

    label {
      display: block;
      margin-bottom: 2px;
      font-size: 12px;
    }

    h4 {
      color: #1c273c;
      font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-weight: 700;
      letter-spacing: -.5px;
      margin-bottom: 0;
    }
  }

  .card-chart {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
  }
}

@media (min-width: 576px) {
  .card-dashboard-five .card-chart {
    margin-bottom: 0;
    margin-right: 10px;
  }
}

.card-table-one {
  padding: 20px 20px 10px;
  border-color: $border;

  .card-title {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 5px;
  }

  .table-responsive {
    width: calc(100vw - 80px);
  }

  .table {
    margin-bottom: 0;

    thead tr> {

      th,
      td {
        vertical-align: middle;
        white-space: nowrap;
        padding-right: 2px;
      }
    }

    tbody tr> {

      th,
      td {
        vertical-align: middle;
        white-space: nowrap;
        padding-right: 2px;
      }
    }

    thead tr> {
      th {

        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5) {
          text-align: right;
        }
      }

      td {

        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5) {
          text-align: right;
        }
      }
    }

    tbody tr> {
      th {

        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5) {
          text-align: right;
        }
      }

      td {

        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5) {
          text-align: right;
        }
      }
    }

    thead tr> {

      th strong,
      td strong {
        color: #1c273c;
      }
    }

    tbody tr> {

      th strong,
      td strong {
        color: #1c273c;
      }
    }
  }

  .flag-icon {
    font-size: 20px;
    line-height: 1;
    border-radius: 100%;
  }
}

@media (min-width: 576px) {
  .card-table-one .table-responsive {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .card-table-one .table {
    thead tr> {

      th,
      td {
        padding-left: 0;
        padding-right: 0;
      }
    }

    tbody tr> {

      th,
      td {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}

/* ###### 8.2 Dashboard Two  ###### */

.main-provider-content-dashboard-two {
  background-color: #eff2f6;
  transition: all 0.4s;

  .main-provider-content-title {
    letter-spacing: -.5px;
  }

  .main-provider-content-body {
    padding: 0 20px;
  }

  .main-provider-header-menu-icon {
    margin-right: 0;
  }
}

@media (prefers-reduced-motion: reduce) {
  .main-provider-content-dashboard-two {
    transition: none;
  }
}

@media (min-width: 992px) {
  .main-provider-content-dashboard-two .main-provider-content-body {
    padding: 0 20px 20px;
  }
}

.main-dashboard-header-right {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;

  >div {
    label {
      display: block;
      margin-bottom: 0;
      color: #78829a;
    }

    h5 {
      font-size: 20px;
      font-weight: 700;
      font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      color: #1c273c;
      margin-bottom: 0;
      line-height: 1;
    }

    &:first-child {
      flex-basis: 100%;
    }

    +div {
      margin-top: 10px;
    }
  }
}

.left-content p {
  color: #78829a;
  font-weight: 400;
}

@media (min-width: 576px) {
  .main-dashboard-header-right {
    flex-wrap: nowrap;
  }
}

@media (min-width: 768px) {
  .main-dashboard-header-right {
    margin-top: 0;
  }
}

@media (min-width: 768px) {
  .main-dashboard-header-right>div {
    text-align: right;
  }
}

@media (max-width: 575.98px) {
  .main-dashboard-header-right>div+div {
    flex-basis: 50%;
  }
}

@media (min-width: 576px) {
  .main-dashboard-header-right>div+div {
    margin-left: 25px;
    margin-top: 0;
    flex-shrink: 0;
  }
}

@media (min-width: 992px) {
  .main-dashboard-header-right>div+div {
    margin-left: 50px;
  }
}

.main-star {
  display: flex;
  align-items: center;

  i {
    font-size: 20px;
    line-height: 0;
    color: #97a3b9;
    display: inline-block;

    &.typcn {
      line-height: .9;
    }

    &.active {
      color: $warning;
    }
  }

  span {
    font-size: 13px;
    display: inline-block;
    margin-left: 5px;
  }
}

.card-dashboard-seven {
  border-color: $border;
  margin-bottom: 20px;
  width: 100vw;
  border-left-width: 0;
  border-right-width: 0;
  position: relative;
  margin-left: -20px;
  margin-right: -20px;

  .card-header {
    background-color: transparent;
    padding: 20px;
    border-bottom: 1px solid $border;
    background-color: #f4f5f8;

    .media {
      align-items: center;

      >div:first-child {
        line-height: 0;
        font-size: 16px;
        margin-right: 5px;
        color: $white;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 34px;
        background-color: #97a3b9;
      }
    }

    .media-body {
      margin-top: 3px;
    }

    label {
      margin-bottom: 0;
      display: block;
      line-height: 1;
      font-size: 11px;
      color: #97a3b9;
    }

    .date {
      font-weight: 500;
      display: flex;
      align-items: center;

      span {
        display: block;
      }

      a {
        margin-left: 5px;
        font-size: 14px;
        color: #97a3b9;
        display: block;

        i {
          line-height: 0;
          position: relative;
          top: 1px;
        }
      }
    }
  }

  .card-body {
    padding: 20px;

    .row>div+div {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: -1px;
        bottom: 0;
        border-left: 1px dotted $border;
        display: none;
      }
    }

    .main-provider-content-label,
    .card-table-two .card-title {
      text-transform: none;
      color: $default-color;
      font-weight: 500;
      font-size: 0.875rem;
      margin-bottom: 10px;
    }
  }
}

@media (min-width: 576px) {
  .card-dashboard-seven {
    margin-bottom: 20px;
    margin-left: 0;
    margin-right: 0;
    width: auto;
    border-left-width: 1px;
    border-right-width: 1px;
  }
}

@media (min-width: 576px) {
  .card-dashboard-seven .card-header {
    padding: 20px;
  }
}

@media (min-width: 576px) {
  .card-dashboard-seven .card-header .media>div:first-child {
    width: 34px;
    font-size: 24px;
    margin-right: 8px;
  }
}

@media (min-width: 992px) {
  .card-dashboard-seven .card-body .row>div+div::before {
    display: block;
  }
}

.card-table-two .card-dashboard-seven .card-body .card-title,
.card-dashboard-seven .card-body .card-dashboard-eight .card-title,
.card-dashboard-eight .card-dashboard-seven .card-body .card-title {
  text-transform: none;
  color: $default-color;
  font-weight: 500;
  font-size: 0.875rem;
  margin-bottom: 10px;
}

.card-dashboard-seven .card-body {
  h2 {
    color: #1c273c;
    font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 600;
    font-size: 24px;
    letter-spacing: -1px;
    margin-bottom: 5px;
    line-height: 1;

    span {
      font-weight: 400;
      color: $default-color;
      letter-spacing: normal;
    }
  }

  .desc {
    display: flex;
    align-items: flex-end;
    margin-bottom: 15px;

    >i {
      line-height: 0;
      font-size: 18px;
    }

    span {
      display: block;
      line-height: 1;
      margin-left: 5px;

      strong {
        color: #1c273c;
      }
    }

    &.up>i {
      color: $success;
    }

    &.down>i {
      color: $danger;
    }
  }
}

@media (min-width: 576px) {
  .card-dashboard-seven .card-body h2 {
    font-size: 32px;
  }
}

.card-dashboard-six {
  border-color: $border;
  padding: 20px;

  .card-header {
    padding: 0;
    background-color: transparent;
    margin-bottom: 20px;
  }

  .main-provider-content-label,
  .card-table-two .card-title {
    margin-bottom: 5px;
    font-size: 0.875rem;
  }
}

@media (min-width: 992px) {
  .card-dashboard-six {
    height: 100%;
  }
}

@media (min-width: 576px) {
  .card-dashboard-six .card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

@media (min-width: 992px) {
  .card-dashboard-six .card-header {
    margin-bottom: 30px;
  }
}

.card-table-two .card-dashboard-six .card-title,
.card-dashboard-six .card-dashboard-eight .card-title,
.card-dashboard-eight .card-dashboard-six .card-title {
  margin-bottom: 5px;
  font-size: 0.875rem;
}

.card-dashboard-six .chart-legend {
  margin-top: 20px;
  display: flex;

  >div {
    display: flex;
    align-items: center;
    font-size: 11px;
    font-weight: 500;

    +div {
      margin-top: 2px;
    }

    span {
      &:last-child {
        width: 8px;
        height: 8px;
        margin-left: 5px;
      }

      &:first-child {
        width: 100px;
        text-align: right;
      }
    }
  }
}

@media (min-width: 576px) {
  .card-dashboard-six .chart-legend {
    display: block;
    margin-top: 0;
  }
}

@media (min-width: 576px) {
  .card-dashboard-six .chart-legend>div span:last-child {
    margin-left: 10px;
    width: 20px;
  }
}

.card-dashboard-map-one {
  border-color: $border;
  padding: 20px;

  .main-provider-content-label,
  .card-table-two .card-title {
    font-size: 0.875rem;
  }
}

.card-table-two .card-dashboard-map-one .card-title,
.card-dashboard-map-one .card-dashboard-eight .card-title,
.card-dashboard-eight .card-dashboard-map-one .card-title {
  font-size: 0.875rem;
}

.card-dashboard-map-one .vmap-wrapper {
  height: 200px;
}

@media (min-width: 576px) {
  .card-dashboard-map-one .vmap-wrapper {
    height: 300px;
  }
}

@media (min-width: 992px) {
  .card-dashboard-map-one .vmap-wrapper {
    height: 270px;
  }
}

.card-table-two {
  border-color: $border;
  padding: 20px;

  .card-title {
    font-size: 13px;
    margin-bottom: 5px;
  }

  .table-responsive {
    width: calc(100vw - 62px);
  }
}

@media (min-width: 576px) {
  .card-table-two .table-responsive {
    width: 100%;
  }
}

.table-dashboard-two {
  thead>tr> {

    th,
    td {
      white-space: nowrap;
    }
  }

  tbody>tr> {

    th,
    td {
      white-space: nowrap;
    }

    th:not(:first-child),
    td:not(:first-child) {
      font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-weight: 600;
      font-size: 13px;
    }
  }
}

.card-dashboard-eight {
  border-color: $border;
  background-color: $white-5;
  padding: 20px;

  .card-title {
    font-size: 13px;
    margin-bottom: 5px;
  }

  .list-group-item {
    padding: 10px 0;
    border-left-width: 0;
    border-right-width: 0;
    border-style: dotted;
    border-color: $border;
    background-color: transparent;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    &:first-child {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }

    &:last-child {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }

    p {
      margin-bottom: 0;
      margin-left: 10px;
    }

    span {
      display: block;
      margin-left: auto;
      font-weight: 600;
      font-family: 'Archivo', Arial, sans-serif;
      color: #1c273c;
    }
  }

  .flag-icon {
    font-size: 24px;
    line-height: 1;
    border-radius: 100%;
  }
}


/* ###### 8.3 Dashboard Three  ###### */

.main-provider-content-dashboard-three {
  position: relative;
  padding: 0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #fdfdfd;
}

@media (min-width: 992px) {
  .main-provider-content-dashboard-three {
    border-left: 1px solid $border;
    margin-left: 64px;
  }
}

.main-provider-header-dashboard-three {
  height: auto;

  .container-fluid {
    padding: 15px 20px;
  }

  .main-provider-header-center {
    margin-left: 0;
  }

  .main-provider-header-right {
    margin-left: auto;
  }
}

@media (min-width: 992px) {
  .main-provider-header-dashboard-three .main-provider-header-left {
    display: none;
  }

  .main-provider-header-left {
    .btn {
      position: absolute;
      top: 0;
      right: 11px;
      background-color: transparent;
      height: 40px;
      color: $white;
      transition: none;
      font-size: 16px;
      padding: 5px 15px;
    }
  }
}

.main-provider-content-body-dashboard-three {
  padding: 20px;
  position: relative;
  width: 100%;

  .main-provider-content-title {
    letter-spacing: -.5px;
  }
}

.card-dashboard-nine {
  border-color: $border;
  background-color: $white-5;
  position: relative;
  padding: 20px 20px 15px;

  .card-header {
    position: absolute;
    top: 20px;
    left: 20px;
    right: 20px;
    padding: 0;
    background-color: transparent;
    z-index: 5;

    h1 {
      font-size: 32px;
      font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      margin-bottom: 8px;
      line-height: 1;
      letter-spacing: -.5px;
      color: #1c273c;
    }
  }

  .main-provider-content-label,
  .card-table-two .card-title {
    font-size: 13px;
    margin-bottom: 10px;
    color: $default-color;
  }
}

.card-table-two .card-dashboard-nine .card-title,
.card-dashboard-nine .card-dashboard-eight .card-title,
.card-dashboard-eight .card-dashboard-nine .card-title {
  font-size: 13px;
  margin-bottom: 10px;
  color: $default-color;
}

.card-dashboard-nine {
  .card-title {
    font-weight: 600;
  }

  .main-provider-content-text {
    color: #7987a1;
  }

  .bar-chart {
    position: relative;
    width: auto;
    height: 200px;
  }
}

@media (min-width: 992px) {
  .card-dashboard-nine .bar-chart {
    height: 210px;
  }
}

.card-dashboard-ten {
  border-width: 0;
  padding: 20px;

  +.card-dashboard-ten {
    margin-top: 20px;
  }

  .main-provider-content-label,
  .card-table-two .card-title {
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 15px;
    line-height: 1.05;
    color: $white;
  }
}

@media (min-width: 576px) {
  .card-dashboard-ten {
    width: calc(50% - 11px);
    display: inline-block;
  }
}

@media (min-width: 992px) {
  .card-dashboard-ten {
    width: auto;
    display: block;
  }
}

@media (min-width: 576px) {
  .card-dashboard-ten+.card-dashboard-ten {
    margin-top: 0;
    margin-left: 18px;
  }
}

@media (min-width: 992px) {
  .card-dashboard-ten+.card-dashboard-ten {
    margin-left: 0;
    margin-top: 20px;
  }
}

.card-table-two .card-dashboard-ten .card-title,
.card-dashboard-ten .card-dashboard-eight .card-title,
.card-dashboard-eight .card-dashboard-ten .card-title {
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 15px;
  line-height: 1.05;
  color: $white;
}

.card-dashboard-ten .card-body {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  >div {
    flex: 1;

    label {
      font-size: 13px;
      margin-bottom: 0;
      color: rgba(255, 255, 255, 0.75);
    }

    h6 {
      margin-bottom: 2px;
      color: $white;
      font-size: 32px;
      font-weight: 600;
      line-height: .975;
      font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

      span {
        font-size: 13px;
      }

      .percent {
        font-size: 20px;
      }
    }
  }
}

@media (min-width: 992px) {
  .card-dashboard-ten .card-body>div {
    flex: none;
  }
}

@media (min-width: 1360px) {
  .card-dashboard-ten .card-body>div {
    &:first-child {
      flex-basis: 60%;
    }

    &:last-child {
      flex-basis: 40%;
    }
  }
}

@media (min-width: 992px) {
  .card-dashboard-ten .card-body>div h6 {
    font-size: 24px;
  }
}

.card-dashboard-eleven {
  border-color: $border;
  position: relative;
  overflow: hidden;

  .card-header {
    padding: 20px;
    background-color: transparent;
    position: relative;
    z-index: 200;
  }

  .main-provider-content-label,
  .card-table-two .card-title {
    display: block;
    font-size: 13px;
    margin-bottom: 5px;
    color: $dark;
    line-height: 1.4;
  }
}

.card-table-two .card-dashboard-eleven .card-title,
.card-dashboard-eleven .card-dashboard-eight .card-title,
.card-dashboard-eight .card-dashboard-eleven .card-title {
  display: block;
  font-size: 13px;
  margin-bottom: 5px;
  color: $dark;
  line-height: 1.4;
}

.card-dashboard-eleven {
  .card-text {
    color: #7987a1;
  }

  .card-body {
    padding: 0 0 5px;
    margin-left: 20px;
    position: relative;
    z-index: 200;
  }

  .flot-chart {
    width: 100%;
    height: 250px;
  }

  .card-footer {
    z-index: 200;
    background-color: transparent;
    padding: 0;
    border-width: 0;

    >div {
      flex: 1;
      padding: 15px 20px;
      border-top: 1px solid $border;
      position: relative;
    }

    label {
      font-weight: 700;
      text-transform: uppercase;
      margin-bottom: 10px;
      color: #596882;
      display: block;
    }

    h6 {
      font-size: 28px;
      font-weight: 600;
      font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      color: #1c273c;
      line-height: .7;
      letter-spacing: -1px;
      margin-bottom: 10px;
      display: flex;
      align-items: baseline;

      span {
        margin-left: 5px;
        font-size: 11px;
        font-weight: 500;
        display: block;
        padding: 4px 6px;
        color: $white;
        line-height: .8;
        position: relative;
        bottom: 3px;
        letter-spacing: normal;

        &.up {
          background-color: $success;
        }

        &.down {
          background-color: $danger;
        }
      }
    }

    small {
      display: block;
      font-size: 13px;
      color: #596882;
    }
  }

  .jqvmap {
    position: absolute;
    top: 10%;
    left: 0;
    width: 100%;
    height: 300px;
  }
}

@media (min-width: 768px) {
  .card-dashboard-eleven .card-footer {
    display: flex;
    align-items: stretch;
  }
}

@media (min-width: 768px) {
  .card-dashboard-eleven .card-footer>div+div {
    border-left: 1px solid $border;
  }
}

@media (min-width: 992px) {
  .card-dashboard-eleven .jqvmap {
    height: 80%;
  }
}

/* ###### 8.4 Dashboard Four  ###### */

.main-navbar-dashboard-four {
  box-shadow: 0 0 30px rgba(28, 39, 60, 0.08);
}

@media (min-width: 992px) {
  .main-navbar-dashboard-four .container-fluid {
    padding-left: 25px;
    padding-right: 25px;
  }
}

.main-provider-content-dashboard-four {
  padding: 20px;

  ~.main-provider-footer {

    .container,
    .container-fluid {
      padding-left: 25px;
      padding-right: 25px;
    }
  }

  >.media-dashboard {
    display: block;

    .media-aside {
      margin-top: 20px;
    }
  }

  .main-provider-content-header {
    height: auto;
    margin-bottom: 20px;
    align-items: flex-start;
    padding: 0;
  }
}

@media (min-width: 992px) {
  .main-provider-content-dashboard-four {
    flex: 1;
    padding: 30px 25px;
  }
}

@media (min-width: 1200px) {
  .main-provider-content-dashboard-four>.media-dashboard {
    display: flex;
    align-items: stretch;
    height: 100%;
  }
}

@media (min-width: 1200px) {
  .main-provider-content-dashboard-four>.media-dashboard .media-aside {
    margin-top: 0;
    display: block;
    width: 320px;
  }
}

@media (min-width: 1200px) {
  .main-provider-content-dashboard-four>.media-dashboard>.media-body {
    padding: 0 20px 0 0;
  }
}

@media (min-width: 768px) {
  .main-provider-content-dashboard-four .main-provider-content-header {
    display: flex;
  }
}

.main-provider-content-header-right {
  display: none;

  .media {
    label {
      margin-bottom: 2px;
      font-size: 10px;
      font-weight: 500;
      letter-spacing: .5px;
      text-transform: uppercase;
      color: #97a3b9;
    }

    h6 {
      color: #1c273c;
      margin-bottom: 0;
    }

    +.media {
      margin-left: 20px;
      padding-left: 20px;
      border-left: 1px solid $border;
    }
  }

  .btn:first-of-type,
  .sp-container button:first-of-type {
    margin-left: 30px;
  }
}

@media (min-width: 992px) {
  .main-provider-content-header-right {
    display: flex;
    align-items: center;
  }
}

.sp-container .main-provider-content-header-right button:first-of-type {
  margin-left: 30px;
}

.main-provider-content-header-right {

  .btn+.btn,
  .sp-container button+.btn {
    margin-left: 5px;
  }
}

.sp-container .main-provider-content-header-right button+.btn,
.main-provider-content-header-right .sp-container .btn+button,
.sp-container .main-provider-content-header-right .btn+button,
.main-provider-content-header-right .sp-container button+button,
.sp-container .main-provider-content-header-right button+button {
  margin-left: 5px;
}

.card-dashboard-calendar {
  border-color: $border;
  padding: 20px;
  box-shadow: 0 0 10px rgba(28, 39, 60, 0.1);

  .card-title {
    color: #1c273c;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 15px;
  }

  .card-body {
    padding: 0;
  }

  .ui-datepicker-inline {
    border-width: 0;
    max-width: none;
    padding: 0;
    margin: 0;
  }

  .ui-datepicker {
    .ui-datepicker-header {
      border-bottom: 1px solid $border;
      padding: 10px;
      margin-bottom: 5px;

      .ui-datepicker-next::before,
      .ui-datepicker-prev::before {
        top: 8px;
      }
    }

    .ui-datepicker-month {
      color: $primary;
    }

    .ui-datepicker-calendar {
      width: calc(100% - 20px);

      th {
        font-size: 10px;
        text-align: center;
      }

      td {
        text-align: center;

        a {
          display: block;
          width: 34px;
          height: 34px;
          font-size: 12px;
          font-family: 'Archivo', sans-serif;
          border-radius: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .ui-datepicker-today a {
        background-color: transparent;
        border: 2px solid $primary;
        color: $primary;
        font-weight: 700;
      }
    }
  }
}

@media (min-width: 576px) {
  .card-dashboard-calendar .ui-datepicker .ui-datepicker-calendar {
    width: 100%;
  }
}

.card-dashboard-twelve {
  border-color: $border;
  box-shadow: 0 0 10px rgba(28, 39, 60, 0.1);

  .card-header {
    background-color: transparent;
    border-bottom: 1px solid #e3e7ed;
    padding: 20px;
  }

  .card-title {
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 20px;

    span {
      font-weight: 400;
      color: $default-color;
      text-transform: capitalize;
    }
  }

  .sales-overview {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;

    .media {
      align-items: flex-end;
      flex-shrink: 0;
      flex-basis: calc(50% - 10px);

      +.media {
        margin-left: 20px;
      }

      &:last-child {
        display: none;
      }
    }

    .media-icon {
      width: 45px;
      height: 45px;
      color: $white;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      top: -5px;
      margin-right: 15px;
      border-radius: 100%;
      display: none;

      .typcn {
        line-height: .9;
        font-size: 24px;
      }
    }

    label {
      text-transform: uppercase;
      font-size: 9px;
      font-weight: 700;
      letter-spacing: .5px;
      color: $default-color;
      margin-bottom: 8px;
    }

    h4 {
      font-size: 20px;
      font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-weight: 600;
      color: #1c273c;
      line-height: 1;
      letter-spacing: -1px;
      margin-bottom: 3px;

      span {
        color: $default-color;
        font-weight: 400;
      }
    }

    .media-body>span {
      display: block;
      font-size: 10px;
      color: #97a3b9;

      strong {
        font-weight: 500;
        color: $default-color;
      }
    }

    p {
      font-size: 11px;
      margin-bottom: 0;
      color: #97a3b9;
    }
  }

  .card-body {
    position: relative;
    padding: 20px 5px;
  }
}

@media (min-width: 992px) {
  .card-dashboard-twelve .sales-overview {
    margin-bottom: 10px;
    flex-wrap: nowrap;
  }
}

@media (min-width: 576px) {
  .card-dashboard-twelve .sales-overview .media {
    flex-basis: auto;
  }
}

@media (min-width: 576px) {
  .card-dashboard-twelve .sales-overview .media+.media {
    margin-left: 40px;
  }
}

@media (min-width: 992px) {
  .card-dashboard-twelve .sales-overview .media+.media {
    margin-left: 50px;
  }
}

@media (max-width: 575.98px) {
  .card-dashboard-twelve .sales-overview .media:nth-child(3) {
    margin-left: 0;
    margin-top: 15px;
  }
}

@media (min-width: 768px) {
  .card-dashboard-twelve .sales-overview .media:last-child {
    flex: 1;
    display: block;
  }
}

@media (min-width: 576px) {
  .card-dashboard-twelve .sales-overview label {
    font-size: 10px;
    margin-bottom: 10px;
  }
}

@media (min-width: 576px) {
  .card-dashboard-twelve .sales-overview h4 {
    font-size: 28px;
  }
}

@media (min-width: 576px) {
  .card-dashboard-twelve .sales-overview .media-body>span {
    font-size: 11px;
  }
}

@media (min-width: 576px) {
  .card-dashboard-twelve .card-body {
    padding: 20px;
  }
}

.chart-legend {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 5px;
  left: 0;
  right: 0;
  z-index: 10;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;

  div {
    display: flex;
    align-items: center;

    +div {
      margin-left: 15px;
    }
  }

  span {
    width: 10px;
    height: 4px;
    display: block;
    margin-right: 5px;
  }
}

@media (min-width: 576px) {
  .chart-legend div+div {
    margin-left: 30px;
  }
}

@media (min-width: 576px) {
  .chart-legend span {
    width: 40px;
    margin-right: 10px;
  }
}

.chart-wrapper {
  position: relative;
  margin-left: -13px;
  margin-right: -13px;
}

@media (min-width: 576px) {
  .chart-wrapper {
    margin-left: -10px;
    margin-right: -15px;
  }
}

.card-dashboard-twelve .flot-chart {
  width: 100%;
  height: 200px;
  position: relative;

  .flot-x-axis>div span {
    &:first-child {
      display: block;
      text-transform: uppercase;
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-weight: 500;
      font-size: 10px;
      color: #7987a1;
    }

    &:last-child {
      display: block;
      font-size: 16px;
      font-weight: 700;
      font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      color: #1c273c;
      line-height: 1;
    }
  }
}

@media (min-width: 768px) {
  .card-dashboard-twelve .flot-chart {
    height: 250px;
  }
}

@media (min-width: 992px) {
  .card-dashboard-twelve .flot-chart {
    height: 300px;
  }
}

.main-media-date {
  h1 {
    font-size: 42px;
    font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    color: #1c273c;
    line-height: .95;
  }

  .media-body {
    margin-left: 5px;
  }

  p {
    color: #1c273c;
    font-weight: 700;
    margin-bottom: 0;
    text-transform: uppercase;
  }

  span {
    display: block;
    line-height: 1.2;
  }
}

.card-dashboard-thirteen {
  border-color: $border;
  padding: 20px;
  box-shadow: 0 0 10px rgba(28, 39, 60, 0.1);

  .main-provider-content-label,
  .card-table-two .card-title {
    font-size: 13px;
  }
}

.card-table-two .card-dashboard-thirteen .card-title,
.card-dashboard-thirteen .card-dashboard-eight .card-title,
.card-dashboard-eight .card-dashboard-thirteen .card-title {
  font-size: 13px;
}

.card-dashboard-thirteen {
  .media {
    margin-bottom: 15px;
  }

  .media-icon {
    font-size: 45px;
    line-height: 1;
    margin-right: 10px;
    display: flex;

    i {
      line-height: 0;
    }
  }

  .media-body {
    h6 {
      font-size: 22px;
      font-weight: 600;
      font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      color: #1c273c;
      letter-spacing: -.5px;
      margin-bottom: 2px;

      span {
        font-weight: 400;
        color: $default-color;
      }
    }

    label {
      font-weight: 700;
      font-size: 12px;
      text-transform: uppercase;
      margin-bottom: 0;
      display: block;
      letter-spacing: .5px;
    }
  }
}

.card-dashboard-events {
  .card-header {
    padding: 20px;
    background-color: transparent;
  }

  .card-subtitle {
    color: #1c273c;
    font-weight: 400;
    font-size: 18px;
  }

  .card-body {
    padding: 0;
  }

  .list-group-item {
    padding: 15px 20px 15px 40px;
    border-left-width: 0;
    border-right-width: 0;
    position: relative;

    &:first-child,
    &:last-child {
      border-radius: 0;
    }

    label {
      text-transform: uppercase;
      font-weight: 700;
      font-size: 10px;
      color: #596882;
      margin-bottom: 5px;
      letter-spacing: .5px;

      span {
        color: #97a3b9;
        font-weight: 500;
      }
    }

    h6 {
      font-size: 14px;
      font-weight: 700;
      color: #1c273c;
      margin-bottom: 5px;
      display: flex;
      align-items: center;
    }

    p {
      margin-bottom: 5px;

      strong {
        font-weight: 500;
      }
    }

    small {
      font-size: 11px;

      span {
        font-weight: 700;
        text-transform: uppercase;
      }
    }
  }

  .event-indicator {
    width: 10px;
    height: 10px;
    position: absolute;
    top: 21px;
    left: 20px;
    border-radius: 100%;
  }

  .event-user {
    margin-top: 10px;
    display: flex;
    align-items: center;

    .main-img-user {
      width: 28px;
      height: 28px;

      &::after {
        display: none;
      }
    }
  }
}

.main-img-user.online::after {
  background-color: $success;
}

.card-dashboard-events .event-user {
  .main-img-user+.main-img-user {
    margin-left: -6px;
  }

  a:last-child {
    margin-left: 10px;
    color: #7987a1;
    font-weight: 500;
    font-size: 11px;

    &:hover,
    &:focus {
      color: #596882;
    }
  }
}

.card-dashboard-fourteen {
  border-color: $border;
  box-shadow: 0 0 10px rgba(28, 39, 60, 0.1);
  padding: 20px;

  .main-provider-content-label,
  .card-table-two .card-title {
    font-size: 0.875rem;
    margin-bottom: 20px;
  }
}

@media (min-width: 768px) {
  .card-dashboard-fourteen {
    height: 100%;
  }
}

.card-table-two .card-dashboard-fourteen .card-title,
.card-dashboard-fourteen .card-dashboard-eight .card-title,
.card-dashboard-eight .card-dashboard-fourteen .card-title {
  font-size: 0.875rem;
  margin-bottom: 20px;
}

.card-dashboard-fourteen {

  .main-provider-content-label span,
  .card-table-two .card-title span {
    font-weight: 400;
    color: #97a3b9;
    text-transform: none;
  }
}

.card-table-two .card-dashboard-fourteen .card-title span,
.card-dashboard-fourteen .card-dashboard-eight .card-title span,
.card-dashboard-eight .card-dashboard-fourteen .card-title span {
  font-weight: 400;
  color: #97a3b9;
  text-transform: none;
}

.card-dashboard-fourteen {
  .card-body {
    padding: 0;
    min-height: 100px;

    h1 {
      color: #1c273c;
      font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-weight: 600;
      letter-spacing: -1px;
      margin-bottom: 0;

      span {
        color: #97a3b9;
        letter-spacing: normal;
        font-weight: 400;
        margin-right: 5px;
      }
    }

    h4 {
      color: #1c273c;
      font-weight: 700;
      font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      margin-bottom: 0;
      letter-spacing: -.5px;
    }

    .progress {
      margin-bottom: 2px;
    }

    small {
      font-size: 11px;
      color: #97a3b9;
    }
  }

  .sparkline-wrapper canvas {
    width: 100% !important;
  }

  .card-footer {
    background-color: transparent;
    padding: 0;
    margin-top: 15px;
    border-top-width: 0;

    h6 {
      color: #1c273c;
      font-size: 20px;
      font-weight: 700;
      font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      margin-bottom: 0;
      letter-spacing: -.5px;
    }

    label {
      display: block;
      margin-bottom: 0;
      color: #97a3b9;
      font-size: 11px;
    }
  }
}

@media (min-width: 576px) {
  .card-dashboard-fourteen .card-footer label {
    font-size: 0.875rem;
  }
}

/* ###### 8.5 Dashboard Five  ###### */

.main-body-sidebar.main-light .main-sidebar {
  background-color: #f4f5f8;
  border-right-color: $border;
  box-shadow: 2px 0 3px rgba(28, 39, 60, 0.04);
}

.main-provider-content-dashboard-five {
  background-color: #eeeff4;
  transition: all 0.4s;

  .main-provider-header {
    background-color: $bg-active;
    box-shadow: 0 2px 3px rgba(28, 39, 60, 0.05);
  }

  .main-provider-header-menu-icon {
    margin-right: 0;
  }

  .main-provider-header-center .form-control {
    border-color: $border;
  }

  .main-provider-content-header {
    padding: 20px 20px 0;
  }

  .main-provider-content-body {
    padding: 20px;
  }

  .card {
    background-color: $bg-active;
    border-color: $border;
    box-shadow: 0 0 10px rgba(28, 39, 60, 0.1);
  }

  .card-header {
    padding: 20px 20px 0;
    background-color: transparent;
  }

  .card-text {
    font-size: 12px;
    line-height: 1.4;
    color: #97a3b9;
    margin-bottom: 0;
  }
}

@media (prefers-reduced-motion: reduce) {
  .main-provider-content-dashboard-five {
    transition: none;
  }
}

@media (min-width: 1200px) {
  .main-provider-content-dashboard-five .main-provider-content-header {
    padding: 25px 25px 0;
  }
}

.card-dashboard-fifteen {
  padding: 20px 20px 0;
  overflow: hidden;

  h1 {
    font-size: 40px;
    font-weight: 500;
    font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    color: #1c273c;
    margin-bottom: 0;
    line-height: 1;

    span {
      display: inline;
      font-size: 18px;
      color: #7987a1;
    }
  }

  label {
    font-size: 14px;
    font-weight: 500;
    display: block;
  }

  span {
    color: #97a3b9;
    display: block;
    font-size: 12px;
  }

  .chart-wrapper {
    position: relative;
    margin-left: -32px;
    margin-right: -32px;
    margin-bottom: -10px;
  }

  .flot-chart {
    width: 100%;
    height: 127px;
    position: relative;
    margin-top: 20px;

    .flot-x-axis>div:nth-child(4) {
      color: #1c273c !important;
    }
  }
}

.table-talk-time {
  margin-bottom: 0;

  thead>tr {

    th,
    td {
      padding: 7px 10px;
    }
  }

  tbody>tr {

    th,
    td {
      padding: 7px 10px;
    }
  }

  thead>tr {

    th:first-child,
    td:first-child {
      padding-left: 20px;
    }
  }

  tbody>tr {

    th:first-child,
    td:first-child {
      padding-left: 20px;
    }
  }
}

.card-dashboard-sixteen {
  .main-img-user::after {
    display: none;
  }

  .table tbody>tr {
    background-color: transparent;

    th,
    td {
      padding: 10px 10px 10px 0;
      vertical-align: middle;
      white-space: nowrap;
    }

    th:first-child,
    td:first-child {
      width: 10%;
    }

    th:nth-child(2),
    td:nth-child(2) {
      width: 60%;
    }

    th:last-child,
    td:last-child {
      width: 30%;
    }
  }
}

.dashboard-five-stacked-chart {
  height: 200px;
}

@media (min-width: 768px) {
  .dashboard-five-stacked-chart {
    height: 240px;
  }
}

@media (min-width: 847px) {
  .dashboard-five-stacked-chart {
    height: 260px;
  }
}

@media (min-width: 992px) {
  .dashboard-five-stacked-chart {
    height: 240px;
  }
}

@media (min-width: 1067px) {
  .dashboard-five-stacked-chart {
    height: 260px;
  }
}

/* ###### 8.6 Dashboard Six  ###### */

.main-light {

  .main-iconbar,
  .main-iconbar-aside {
    background-color: #e3e7ed;
  }

  .main-iconbar-body {
    .nav {
      border-left-color: $border;
    }

    .nav-item+.nav-item,
    .nav-sub,
    .nav-sub-item+.nav-sub-item {
      border-top-color: #bdc6d6;
    }
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .main-light .main-iconbar-aside.show+.main-provider-content {
    box-shadow: none;
  }
}

.main-provider-content-dashboard-six {
  border-left-color: $border;
  box-shadow: 0 0 7px rgba(28, 39, 60, 0.08);
  position: relative;
  padding: 0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #f4f5f8;
}

@media (min-width: 992px) {
  .main-provider-content-dashboard-six {
    border-left: 1px solid $border;
    margin-left: 64px;
    z-index: 550;
  }
}

.main-provider-header-dashboard-six {
  background-color: $white;
  border-bottom: 1px solid $border;
  box-shadow: 0 0 10px rgba(28, 39, 60, 0.06);

  .main-provider-header-menu-icon {
    margin-right: 0;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .main-provider-header-dashboard-six .main-provider-header-center {
    margin-left: 5px;
  }
}

@media (min-width: 1200px) {
  .main-provider-header-dashboard-six .main-provider-header-center {
    margin-left: 0;
  }
}

@media (min-width: 992px) {
  .main-provider-header-dashboard-six .main-provider-header-menu-icon {
    display: none;
  }
}

.main-provider-content-body-dashboard-six {
  padding: 25px;

  .main-provider-content-title {
    letter-spacing: -.8px;
  }

  .card {
    border-color: $border;
    box-shadow: 0 0 10px rgba(28, 39, 60, 0.06);
  }

  .card-header {
    background-color: transparent;
    padding: 20px 20px 0;
  }

  .card-title {
    text-transform: uppercase;
    font-weight: 700;
  }
}

.card-dashboard-donut {
  .card-body {
    padding: 25px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .main-donut-chart {
    &.chart1 {
      width: 140px;
      height: 140px;
      background: $border;

      .slice {
        &.one {
          clip: rect(0 140px 70px 0);
          -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
          background: #6f42c1;
        }

        &.two {
          clip: rect(0 70px 140px 0);
          -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
          background: #6f42c1;
        }
      }

      .chart-center {
        top: 10px;
        left: 10px;
        width: 120px;
        height: 120px;
        background: $white;

        span {
          font-size: 40px;
          line-height: 120px;
          color: #6f42c1;

          &:after {
            content: "75%";
          }
        }
      }
    }

    &.chart2 {
      width: 140px;
      height: 140px;
      background: $border;

      .slice {
        &.one {
          clip: rect(0 140px 70px 0);
          -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
          background: $primary;
        }

        &.two {
          clip: rect(0 70px 140px 0);
          -webkit-transform: rotate(244.8deg);
          transform: rotate(244.8deg);
          background: $primary;
        }
      }

      .chart-center {
        top: 10px;
        left: 10px;
        width: 120px;
        height: 120px;
        background: $white;

        span {
          font-size: 40px;
          line-height: 120px;
          color: $primary;

          &:after {
            content: "68%";
          }
        }
      }
    }

    &.chart1 .chart-center span,
    &.chart2 .chart-center span {
      font-weight: 600;
      font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-size: 32px;
    }
  }
}

.card-dashboard-balance {
  position: relative;
  overflow: hidden;

  .card-body {
    padding: 20px;
    position: relative;
    z-index: 5;
  }

  .fab {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 48px;
    color: $primary;
    z-index: 5;
    background-color: $white;
  }

  .main-provider-content-label,
  .card-table-two .card-title {
    color: #7987a1;
    font-size: 10px;
    font-weight: 500;
    letter-spacing: 1px;
  }
}

@media (min-width: 576px) {
  .card-dashboard-balance {
    min-height: 260px;
    height: 100%;
  }
}

@media (min-width: 576px) {
  .card-dashboard-balance .card-body {
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.card-table-two .card-dashboard-balance .card-title,
.card-dashboard-balance .card-dashboard-eight .card-title,
.card-dashboard-eight .card-dashboard-balance .card-title {
  color: #7987a1;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 1px;
}

@media (min-width: 576px) {
  .card-dashboard-balance {

    .main-provider-content-label,
    .card-table-two .card-title {
      font-size: 11px;
    }
  }

  .card-table-two .card-dashboard-balance .card-title,
  .card-dashboard-balance .card-dashboard-eight .card-title,
  .card-dashboard-eight .card-dashboard-balance .card-title {
    font-size: 11px;
  }
}

.card-dashboard-balance {
  .balance-amount {
    color: #1c273c;
    font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 600;
    font-size: 28px;
    letter-spacing: -1px;
    margin-bottom: 20px;

    span {
      color: #97a3b9;
      font-weight: 400;
      letter-spacing: normal;
    }
  }

  .account-number {
    display: flex;
    align-items: center;
    font-size: 20px;
    font-weight: 700;
    font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    color: #1c273c;
    letter-spacing: -1px;
    margin-bottom: 20px;

    span {
      display: flex;
      align-items: center;
      line-height: 1;

      +span {
        margin-left: 20px;
      }

      i {
        width: 5px;
        height: 5px;
        background-color: #1c273c;
        border-radius: 100%;

        +i {
          margin-left: 5px;
        }
      }
    }
  }

  .account-name {
    color: #1c273c;
    font-weight: 400;
    margin-bottom: 0;
  }

  .chart-wrapper {
    position: absolute;
    top: 20px;
    bottom: 10px;
    left: -10px;
    right: -10px;
    opacity: .3;
    z-index: 4;
  }

  .flot-chart {
    width: 100%;
    height: 100%;
  }
}

@media (min-width: 576px) {
  .card-dashboard-balance .balance-amount {
    font-size: 36px;
  }
}

@media (min-width: 576px) {
  .card-dashboard-balance .account-number {
    font-size: 28px;
  }
}

@media (min-width: 576px) {
  .card-dashboard-balance .account-number span+span {
    margin-left: 30px;
  }
}

@media (min-width: 1200px) {
  .card-dashboard-balance .account-number span+span {
    margin-left: 50px;
  }
}

@media (min-width: 576px) {
  .card-dashboard-balance .account-number span i {
    width: 10px;
    height: 10px;
  }
}

@media (min-width: 1200px) {
  .card-dashboard-ratio {
    height: 350px;
  }
}

.card-dashboard-ratio {
  .card-body {
    display: flex;
    align-items: center;

    +.card-body {
      padding-top: 0px;
    }

    >div {
      &:first-child {
        width: 140px;
        display: none;
        align-items: center;
        justify-content: center;
      }

      &:last-child {
        flex: 1;
      }
    }

    h5 {
      font-size: 28px;
      font-weight: 600;
      font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      color: #1c273c;
      letter-spacing: -1px;
      margin-bottom: 2px;
    }
  }

  .card-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $border;
    font-size: 140px;

    .typcn {
      line-height: 1;

      &::before {
        width: auto;
      }
    }
  }
}

@media (min-width: 576px) {
  .card-dashboard-ratio .card-body>div:first-child {
    display: flex;
  }
}

@media (min-width: 576px) {
  .card-dashboard-ratio .card-body>div:last-child {
    margin-left: 30px;
  }
}

.card-dashboard-finance {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  .card-title {
    margin-bottom: 20px;
  }

  h2 {
    line-height: 1;
    color: #1c273c;
    font-weight: 600;
    font-size: 30px;
    font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    letter-spacing: -1px;
    margin-top: 10px;
    margin-bottom: 2px;

    span {
      letter-spacing: normal;
      color: #97a3b9;
      font-weight: 400;
      margin-right: 5px;
    }
  }
}

@media (min-width: 992px) {
  .card-dashboard-finance {
    min-height: 172px;
  }
}

@media (min-width: 1200px) {
  .card-dashboard-finance {
    min-height: 165px;
  }
}

@media (min-width: 992px) {
  .card-dashboard-finance .card-title {
    margin-bottom: auto;
  }
}

.card-dashboard-table-six {
  .card-title {
    padding: 15px 20px;
    margin-bottom: 0;
    border-bottom: 1px solid $border;
  }

  .table-responsive {
    width: calc(100vw - 42px);
  }

  .table {
    margin-bottom: 0;

    thead tr {
      th {
        white-space: nowrap;
      }

      &:first-child {
        background-color: #e3e7ed;

        th {
          padding: 10px 12px;
          line-height: 1;

          &:last-child {
            border-left: 1px solid $border;
          }
        }
      }

      &:last-child th {
        width: 13%;
        padding: 10px 12px;
        line-height: 1;
        text-align: right;

        &:first-child {
          width: 22%;
        }

        &:nth-child(2),
        &:nth-child(6) {
          border-left: 1px solid $border;
        }
      }
    }

    tbody tr {
      td {
        text-align: right;
        white-space: nowrap;

        &:first-child {
          text-align: left;
        }

        &:nth-child(2),
        &:nth-child(6) {
          border-left: 1px solid $border;
        }
      }

      &:last-child td {
        font-weight: 500;
        color: #1c273c;
      }
    }
  }
}

@media (min-width: 992px) {
  .card-dashboard-table-six .table-responsive {
    width: 100%;
  }
}

/* ###### 8.7 Dashboard Seven  ###### */

.main-minimal {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: $white;

  .main-navbar-three {
    .nav-item.active {
      &::before {
        bottom: -3px;
      }

      .nav-link {
        font-weight: 700;
      }
    }

    .nav-link {
      font-size: 13px;
    }
  }

  &.main-navbar-show .main-provider-header {
    z-index: auto;
  }
}

@media (min-width: 1200px) {
  .main-minimal {
    padding: 10px 0 0;
  }
}

.main-provider-content-title-minimal {
  color: #1c273c;
  font-weight: 500;
  font-size: 22px;
  letter-spacing: -1px;
  line-height: 1;
  margin-bottom: 8px;

  span {
    font-weight: 700;
  }
}

.main-provider-content-text-minimal {
  font-weight: 400;
  color: #596882;
  margin-bottom: 20px;
}

.media-dashboard-one {
  display: block;

  .media-right {
    width: 100%;
    flex-shrink: 0;
  }

  .media-body {
    margin-bottom: 20px;
  }
}

@media (min-width: 992px) {
  .media-dashboard-one {
    display: flex;
    align-items: stretch;
  }
}

@media (min-width: 992px) {
  .media-dashboard-one .media-right {
    width: 280px;
  }
}

@media (min-width: 992px) {
  .media-dashboard-one .media-body {
    margin-right: 20px;
    margin-bottom: 0;
  }
}

.card-minimal-one {
  border-color: $border;
  min-height: 320px;
  height: 100%;
  overflow: hidden;
  position: relative;

  .card-body {
    position: relative;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    z-index: 5;

    > {
      label {
        font-weight: 700;
        font-size: 14px;
        color: $primary;
        text-transform: uppercase;
        margin-bottom: 5px;
        display: block;
        line-height: 1;

        span {
          font-weight: 500;
          color: #596882;
        }
      }

      small {
        font-size: 12px;
        display: block;
        color: #97a3b9;
      }

      h6 {
        color: #1c273c;
        font-size: 36px;
        font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
        font-weight: 600;
        letter-spacing: -2px;
        display: flex;
        line-height: 1;
        align-items: flex-end;
        margin: 15px 0;

        span {
          font-weight: 400;
          margin-right: 3px;
          color: $default-color;
        }
      }
    }
  }

  .value-info {
    display: flex;
    align-items: center;

    +.value-info {
      margin-top: 5px;
    }

    p {
      margin-bottom: 0;
      color: #1c273c;
      font-weight: 700;
      font-family: 'Archivo', sans-serif;
      font-size: 14px;
      letter-spacing: -.35px;

      span {
        letter-spacing: normal;
        margin-right: 2px;
        color: $default-color;
      }
    }

    label {
      display: block;
      margin-bottom: 0;
      font-weight: 700;
      width: 40px;
      position: relative;
      top: -2px;
    }
  }

  .open-info {
    display: flex;
    align-items: flex-start;
    margin-top: auto;
    margin-bottom: 25px;

    >div+div {
      margin-left: 25px;
    }

    label {
      font-size: 12px;
      margin-bottom: 2px;
      color: #7f8da9;
    }

    h6 {
      margin-bottom: 0;
      color: #1c273c;
      font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-weight: 700;
      font-size: 13px;

      span {
        font-weight: 400;
      }
    }
  }

  .chart-wrapper {
    position: absolute;
    left: -9px;
    right: -8px;
    bottom: -13px;

    .flot-x-axis>div {
      transform: translate(20px, -23px);
    }
  }

  .flot-chart {
    width: 100%;
    height: 250px;
    position: relative;
  }
}

.card-minimal-two {
  height: 72px;
  border-color: $border;
  background-color: transparent;
  overflow: hidden;

  .card-header {
    background-color: transparent;
    padding: 15px;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $border;

    >.nav .nav-link {
      padding: 8px 18px;
      font-weight: 500;
      background-color: #e3e7ed;

      +.nav-link {
        margin-left: 2px;
      }

      &:hover,
      &:focus {
        background-color: $border;
      }

      &.active {
        background-color: $primary;
        color: $white;
      }
    }
  }

  .card-header-right {
    display: flex;
    align-items: center;
    margin-top: 20px;

    .nav {
      justify-content: flex-end;
      width: 100%;
    }

    .nav-link {
      padding: 5px 10px;
      text-transform: capitalize;

      +.nav-link {
        margin-left: 2px;
      }

      span {
        &:first-child {
          display: none;
        }

        &:last-child {
          text-transform: uppercase;
          font-size: 11px;
          font-weight: 500;
        }
      }

      &:hover,
      &:focus {
        background-color: #e3e7ed;
      }

      &.active {
        background-color: #97a3b9;
        color: $white;
      }
    }
  }

  .card-body {
    position: relative;
    overflow: hidden;
    padding: 0;
  }

  .flot-wrapper {
    position: relative;
    bottom: -14px;
    margin-right: -9px;
    margin-left: -31px;
    margin-top: -25px;

    .flot-x-axis>div {
      transform: translate(22px, -23px);
    }

    .flot-y-axis>div {
      transform: translateX(-10px);
    }
  }

  .flot-chart {
    width: 100%;
    height: 250px;
    position: relative;
  }

  .more-info {
    position: absolute;
    top: 10px;
    right: 10px;

    div {
      display: flex;
      align-items: flex-start;
      font-size: 13px;
    }

    span {
      &:first-child {
        width: 80px;
        text-align: right;
        color: #7987a1;
        display: block;
        line-height: 1.35;
      }

      &:last-child {
        margin-left: 15px;
        color: #1c273c;
        font-weight: 700;
        font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
        font-size: 14px;
      }
    }
  }

  .card-footer {
    padding: 20px;
    position: relative;
    z-index: 2;
    overflow-y: hidden;
    width: calc(100vw - 40px);
    margin-bottom: -8px;
  }
}

@media (min-width: 576px) {
  .card-minimal-two .card-header {
    display: flex;
    padding: 15px 20px;
  }
}

@media (min-width: 992px) {
  .card-minimal-two .card-header>.nav .nav-link {
    padding: 8px 20px;
  }
}

@media (min-width: 1200px) {
  .card-minimal-two .card-header>.nav .nav-link {
    padding: 8px 30px;
  }
}

@media (min-width: 576px) {
  .card-minimal-two .card-header-right {
    margin-top: 0;
  }
}

@media (min-width: 576px) {
  .card-minimal-two .card-header-right .nav-link {
    padding: 8px 10px;
  }
}

@media (min-width: 768px) {
  .card-minimal-two .card-header-right .nav-link span:first-child {
    display: inline;
  }
}

@media (min-width: 768px) {
  .card-minimal-two .card-header-right .nav-link span:last-child {
    display: none;
  }
}

@media (min-width: 992px) {
  .card-minimal-two .card-body {
    padding: 0 20px;
  }
}

@media (min-width: 992px) {
  .card-minimal-two .flot-wrapper {
    margin-right: -9px;
    margin-left: 10px;
    margin-top: 0;
  }
}

@media (min-width: 992px) {
  .card-minimal-two .flot-chart {
    height: 300px;
  }
}

@media (min-width: 576px) {
  .card-minimal-two .more-info {
    right: 20px;
    top: 30px;
  }
}

@media (min-width: 992px) {
  .card-minimal-two .more-info {
    right: 30px;
    top: 50px;
  }
}

@media (min-width: 992px) {
  .card-minimal-two .card-footer {
    width: auto;
  }
}

.card-bitcoin {
  .media-icon {
    color: #f7931a;
    font-size: 42px;
    line-height: 0;
  }

  .media-body {
    margin-left: 20px;
    margin-bottom: 10px;
  }

  .row {
    flex-wrap: nowrap;
    white-space: nowrap;
    overflow-x: auto;

    >div {
      flex-shrink: 0;
    }
  }

  label {
    margin-bottom: 2px;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 11px;
    letter-spacing: .5px;
    color: #7987a1;
  }

  p {
    margin-bottom: 0;
    color: #1c273c;
    font-weight: 700;
    font-size: 16px;
    font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  }
}

.card-minimal-three {
  border-color: $border;
  width: calc(100vw - 38px);

  .card-header {
    border-bottom: 1px solid $border;
    background-color: transparent;
    padding: 20px 20px 0;

    .main-provider-content-text {
      margin-bottom: 20px;
    }
  }

  .main-nav-tabs {
    padding: 0;
    background-color: transparent;
    position: relative;
    margin-left: -15px;
    margin-right: -15px;
    margin-bottom: 3px;

    .lSAction>a {
      background-color: $border;

      &::before {
        top: -4px;
      }

      &.lSPrev {
        left: -35px;
      }
    }

    .tab-item {
      min-width: 203px;
    }

    .tab-link {
      background-color: #e3e7ed;
      font-weight: 500;
      align-items: flex-start;
      padding-top: 8px;

      &:hover {
        background-color: $border;
      }

      &.active {
        background-color: #596882;
        color: $white;

        &:hover,
        &:focus {
          background-color: #596882;
          color: $white;
        }
      }
    }
  }

  .card-body {
    padding: 0;
  }

  .tab-pane-header {
    padding: 20px;
    background-color: $white;
    border-bottom: 1px solid $border;

    .main-provider-content-label,
    .card-table-two .card-title {
      margin-bottom: 3px;
    }
  }
}

@media (min-width: 992px) {
  .card-minimal-three {
    width: 634px;
  }
}

@media (min-width: 1200px) {
  .card-minimal-three {
    width: 753px;
  }
}

@media (min-width: 576px) {
  .card-minimal-three .main-nav-tabs .tab-item {
    min-width: 150px;
  }
}

@media (min-width: 576px) {
  .card-minimal-three .tab-pane-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.card-table-two .card-minimal-three .tab-pane-header .card-title,
.card-minimal-three .tab-pane-header .card-dashboard-eight .card-title,
.card-dashboard-eight .card-minimal-three .tab-pane-header .card-title {
  margin-bottom: 3px;
}

.card-minimal-three {
  .tab-pane-header>div:last-child {
    display: flex;
    margin-top: 15px;

    a {
      flex: 1;

      +a {
        margin-left: 10px;
      }
    }
  }

  .tab-pane-body {
    padding: 20px;

    .table {
      thead tr {

        th,
        td {
          white-space: nowrap;
        }
      }

      tbody tr {

        th,
        td {
          white-space: nowrap;
        }
      }
    }
  }

  .no-transactions-panel {
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .icon-wrapper {
      width: 80px;
      height: 80px;
      background-color: #e3e7ed;
      color: #97a3b9;
      font-size: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;
      border-radius: 100%;

      i {
        line-height: 0;
      }
    }

    h6 {
      text-transform: uppercase;
      font-weight: 700;
      font-size: 15px;
      color: #1c273c;
      letter-spacing: 1px;
      margin-bottom: 2px;
    }
  }

  .table tbody tr td {
    vertical-align: middle;

    span {
      display: block;
      width: 8px;
      height: 8px;
      border-radius: 100%;
    }

    &:last-child {
      font-weight: 500;
      color: #1c273c;
    }
  }
}

.card-crypto-portfolio {
  width: 100%;
  border-color: $border;
  margin-top: 20px;

  .card-title {
    text-transform: uppercase;
  }

  .table {
    margin-bottom: 0;

    thead tr {

      th,
      td {
        padding: 10px 0;
        text-align: right;
        white-space: nowrap;
      }
    }

    tbody tr {

      th,
      td {
        padding: 10px 0;
        text-align: right;
        white-space: nowrap;
      }
    }

    thead tr {

      th:first-child,
      td:first-child {
        text-align: left;
      }
    }

    tbody tr {

      th:first-child,
      td:first-child {
        text-align: left;
      }
    }

    thead tr th {
      font-size: 10px;
    }

    tbody tr td:first-child {
      font-weight: 500;
      color: #1c273c;
    }
  }
}

@media (min-width: 992px) {
  .card-crypto-portfolio {
    width: calc(33.33333% - 13px);
    margin-top: 0;
  }
}

.card-minimal-four {
  border-color: $border;

  .card-header {
    background-color: transparent;
    border-bottom: 1px solid $border;

    h6 {
      font-size: 14px;
      font-weight: 700;
      margin-bottom: 2px;
      color: #1c273c;
    }

    p {
      margin-bottom: 0;
      white-space: nowrap;

      span {
        color: $danger;
      }
    }

    .media {
      align-items: center;
    }

    .media-body {
      margin-left: 12px;
    }
  }

  .coin-logo {
    width: 42px;
    height: 42px;
    color: $white;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;

    i {
      line-height: 0;
    }
  }

  .card-body {
    padding: 0;
    overflow: hidden;
    position: relative;
    background-color: $bg-active;
  }

  .card-body-top {
    top: 15px;
    left: 10px;
    position: absolute;
    font-size: 10px;
    text-transform: uppercase;
    letter-spacing: 1px;
    z-index: 10;

    a {
      color: #1c273c;
      font-weight: 700;
      width: 20px;
      text-align: right;
      display: inline-block;

      &:hover,
      &:focus {
        color: $primary;
      }
    }

    div+div {
      margin-top: 5px;
    }
  }

  .chart-wrapper {
    position: relative;
    margin-left: -20px;
    margin-right: -9px;
    margin-bottom: -17px;
  }

  .flot-chart {
    width: 100%;
    height: 150px;
  }

  .card-footer {
    background-color: transparent;
    border-top-color: $border;
    padding: 0;

    .nav-link {
      flex: 1;
      display: block;
      text-align: center;
      background-color: #e3e7ed;
      position: relative;
      padding-left: 10px;
      padding-right: 10px;

      &:hover,
      &:focus {
        background-color: #eeeff4;
      }

      span {
        display: block;

        &:first-child {
          color: #97a3b9;
          font-weight: 700;
          font-size: 11px;
        }

        &:last-child {
          color: $default-color;
          font-weight: 500;
          font-size: 11px;
          font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
        }
      }

      +.nav-link {
        border-left: 1px solid $border;
      }

      &.active {
        background-color: $bg-active;

        &::before {
          content: '';
          position: absolute;
          top: -1px;
          left: 0;
          right: 0;
          border-top: 1px solid #f7f7f7;
          z-index: 5;
        }

        span:first-child {
          color: #1c273c;
        }
      }
    }
  }
}

/* ###### 8.8 Dashboard Eight  ###### */

.main-dashboard-eight {
  background-color: #dde2e9;
}

.main-provider-header-primary {
  background-color: #0040ff;

  .main-provider-header-center {
    .form-control {
      background-color: #0033cc;
      border-width: 0;
      height: 38px;
      border-radius: 2px;
      color: $white;

      &::placeholder {
        color: $white-4;
      }

      &:focus+.btn {
        color: $white;
      }
    }

    .sp-container .form-control:focus+button {
      color: $white;
    }
  }
}

.sp-container .main-provider-header-primary .main-provider-header-center .form-control:focus+button {
  color: $white;
}

.main-provider-header-primary .main-provider-header-center {

  .btn,
  .sp-container button {
    font-size: 14px;
  }
}

.sp-container .main-provider-header-primary .main-provider-header-center button {
  font-size: 14px;
}

.main-provider-header-primary .main-provider-header-center {

  .btn:hover,
  .sp-container button:hover {
    color: $white;
  }
}

.sp-container .main-provider-header-primary .main-provider-header-center button:hover {
  color: $white;
}

.main-provider-header-primary .main-provider-header-center {

  .btn:focus,
  .sp-container button:focus {
    color: $white;
  }
}

.sp-container .main-provider-header-primary .main-provider-header-center button:focus {
  color: $white;
}

.main-provider-header-primary {
  .main-logo {
    color: $white;
    font-weight: 600;
    letter-spacing: -1.2px;

    span {
      color: rgba(255, 255, 255, 0.65);
    }
  }

  .main-provider-header-menu-icon span {
    background-color: rgba(255, 255, 255, 0.65);

    &::before,
    &::after {
      background-color: rgba(255, 255, 255, 0.65);
    }
  }

  .main-provider-header-message>a {
    color: rgba(255, 255, 255, 0.75);
  }

  .main-provider-header-notification {
    >a {
      color: rgba(255, 255, 255, 0.75);

      &::after {
        display: none;
      }
    }

    .dropdown-menu {
      border-width: 0;
      box-shadow: 0 0 5px rgba(28, 39, 60, 0.1);
    }
  }

  .main-profile-menu {
    .dropdown-menu {
      border-width: 0;
      box-shadow: 0 0 5px rgba(28, 39, 60, 0.1);
    }

    &.show .main-img-user::before {
      display: none;
    }
  }
}

@media (min-width: 576px) {
  .main-provider-header-primary .main-provider-header-notification .dropdown-menu {
    top: 43px;
  }
}

@media (min-width: 992px) {
  .main-provider-header-primary .main-provider-header-notification .dropdown-menu {
    top: 45px;
  }
}

@media (min-width: 576px) {
  .main-provider-header-primary .main-profile-menu .dropdown-menu {
    top: 46px;
  }
}

@media (min-width: 992px) {
  .main-provider-header-primary .main-profile-menu .dropdown-menu {
    top: 48px;
  }
}

.main-navbar-dashboard-eight {
  background-color: #f9f9f9;
  border-bottom-width: 0;

  .main-navbar-header {
    background-color: #0040ff;
    border-bottom-width: 0;
    color: $white;

    .main-logo {
      color: $white;
    }
  }

  .nav-item.active {
    &::before {
      border-bottom-color: #1a53ff;
    }

    .nav-link {
      color: #1a53ff;
    }
  }

  .nav-link {
    text-transform: capitalize;
    font-weight: 500;
    font-size: 14px;
  }

  .nav-sub:not(.nav-sub-mega) {
    background-color: #f9f9f9;
    border-width: 0;

    &::before {
      content: '';
      position: absolute;
      top: -5px;
      left: -5px;
      right: -5px;
      height: 5px;
      background-color: #f9f9f9;
      z-index: 4;
    }
  }

  .nav-sub-item {
    >.nav-sub {
      left: 180px;

      &::before {
        left: -5px;
        top: -5px;
        bottom: -5px;
        right: auto;
        width: 5px;
        height: auto;
      }
    }

    &.active .nav-sub-link {
      color: $primary;
    }
  }

  .nav-sub-link:hover {
    color: $primary;
  }

  .nav-sub-mega {

    .container,
    .container-fluid {
      background-color: #f9f9f9;
      border-width: 0;
    }
  }
}

@media (min-width: 992px) {
  .main-navbar-dashboard-eight .nav-sub:not(.nav-sub-mega) {
    box-shadow: 0 0 5px rgba(28, 39, 60, 0.12);
  }
}

@media (min-width: 992px) {
  .main-navbar-dashboard-eight .nav-sub-mega {

    .container,
    .container-fluid {
      box-shadow: 0 5px 3px -3px rgba(28, 39, 60, 0.12);
    }
  }
}

.main-provider-content-dashboard-eight {
  padding-top: 25px;

  .main-provider-content-title {
    letter-spacing: -.8px;
  }

  .card {
    border-width: 0;
    border-radius: 2px;
    box-shadow: 0 0 5px rgba(28, 39, 60, 0.1);
  }

  .card-title {
    font-weight: 500;
  }
}

@media (max-width: 575px) {
  .main-provider-content-dashboard-eight .container {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.card-dashboard-seventeen {
  position: relative;

  .card-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 180px;
    position: relative;
    z-index: 10;

    h4 {
      margin-bottom: 0;
      font-weight: 700;
      font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      color: #1c273c;
    }

    span {
      font-size: 11px;
    }
  }

  .card-title {
    line-height: 1.3;
  }

  &.bg-primary-dark {
    background-color: #0033cc;
  }

  .chart-wrapper {
    position: absolute;
    right: -8px;
    bottom: -8px;
    left: -8px;
  }

  .flot-chart {
    width: 100%;
    height: 180px;
  }
}

.card-dashboard-eighteen {
  display: block;
  padding: 20px;

  .card-title {
    font-weight: 700;
    text-transform: uppercase;
  }

  .card-body {
    padding: 20px 0 34px;
    align-items: flex-start;

    h6 {
      color: #1c273c;
      font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-weight: 600;
      font-size: 21px;
      letter-spacing: -.8px;
      margin-bottom: 2px;
      position: relative;
      padding-left: 23px;

      span {
        font-weight: 400;
        color: #97a3b9;
        letter-spacing: normal;
      }

      &::before {
        content: '';
        position: absolute;
        display: block;
        top: calc(50% - 8px);
        left: 0;
        width: 16px;
        height: 16px;
        border-radius: 100%;
        border: 4px solid transparent;
      }

      &.dot-primary::before {
        border-color: $primary;
      }

      &.dot-purple::before {
        border-color: #6f42c1;
      }

      &.dot-teal::before {
        border-color: $teal;
      }

      &.dot-dark-blue::before {
        border-color: #0033cc;
      }
    }

    label {
      color: #7987a1;
      margin-bottom: 0;
      display: block;
    }
  }

  .chartjs-wrapper {
    height: 309px;
    position: relative;
    margin-left: -10px;
    margin-right: -5px;
  }
}

.card-dashboard-nineteen {
  overflow: hidden;
  position: relative;
  background-color: #f9f9f9;

  .card-header {
    padding: 20px 20px 0;
    background-color: transparent;

    .row {
      display: block;

      >div {
        max-width: none;

        +div {
          margin-top: 20px;
        }
      }
    }

    h4 {
      font-size: 24px;
      font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-weight: 600;
      letter-spacing: -1px;
      color: #1c273c;
      margin-bottom: 10px;

      span {
        font-weight: 400;
        letter-spacing: normal;
        color: #97a3b9;
      }
    }

    .main-provider-content-label,
    .card-table-two .card-title {
      text-transform: none;
      line-height: 1.3;
      margin-bottom: 5px;
      font-size: 0.875rem;
    }
  }
}

@media (min-width: 375px) {
  .card-dashboard-nineteen .card-header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
  }
}

@media (min-width: 375px) {
  .card-dashboard-nineteen .card-header .row {
    display: flex;
  }
}

@media (min-width: 375px) {
  .card-dashboard-nineteen .card-header .row>div+div {
    margin-top: 0;
  }
}

@media (min-width: 576px) {
  .card-dashboard-nineteen .card-header h4 {
    font-size: 30px;
  }
}

.card-table-two .card-dashboard-nineteen .card-header .card-title,
.card-dashboard-nineteen .card-header .card-dashboard-eight .card-title,
.card-dashboard-eight .card-dashboard-nineteen .card-header .card-title {
  text-transform: none;
  line-height: 1.3;
  margin-bottom: 5px;
  font-size: 0.875rem;
}

@media (min-width: 992px) {
  .card-dashboard-nineteen .card-header {

    .main-provider-content-label,
    .card-table-two .card-title {
      font-size: 14px;
    }
  }

  .card-table-two .card-dashboard-nineteen .card-header .card-title,
  .card-dashboard-nineteen .card-header .card-dashboard-eight .card-title,
  .card-dashboard-eight .card-dashboard-nineteen .card-header .card-title {
    font-size: 14px;
  }
}

.card-dashboard-nineteen .card-header {
  p {
    font-size: 11px;
    color: #7987a1;
    margin-bottom: 0;
  }

  .btn,
  .sp-container button {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 11px;
    letter-spacing: .5px;
    padding: 5px 20px;
    min-height: inherit;
    border-width: 2px;
  }
}

@media (min-width: 576px) {
  .card-dashboard-nineteen .card-header p {
    font-size: 12px;
  }
}

.sp-container .card-dashboard-nineteen .card-header button {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 11px;
  letter-spacing: .5px;
  padding: 5px 20px;
  min-height: inherit;
  border-width: 2px;
}

.card-dashboard-nineteen {
  .card-title {
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 15px;
    color: #0040ff;
  }

  .chart-legend {
    margin-top: 22px;
    display: flex;
    align-items: center;

    >div {
      position: relative;
      text-transform: uppercase;
      font-size: 10px;
      font-weight: 500;
      letter-spacing: .5px;

      &::before {
        content: '';
        position: relative;
        top: 1.5px;
        display: inline-block;
        width: 10px;
        height: 10px;
        margin-right: 5px;
      }

      &:first-child::before {
        background-color: #05478f;
      }

      &:nth-child(2)::before {
        background-color: #2f93fe;
      }

      &:last-child::before {
        background-color: #bbdafc;
      }

      +div {
        margin-left: 10px;
      }
    }
  }

  .card-body {
    padding: 0;
    position: relative;
  }

  .flot-chart-wrapper {
    position: relative;
    margin: -30px -8px -16px -47px;
  }

  .flot-chart {
    width: 100%;
    height: 250px;

    .flot-x-axis {
      transform: translate(18px, -25px);
      color: $white;
      font-size: 10px;
      text-transform: uppercase;
      letter-spacing: 1px;
      opacity: .5;
    }

    .flot-y-axis {
      transform: translate(66px, -13px);
      color: rgba(0, 0, 0, 0.65);
      font-weight: 400;
      font-size: 10px;
      letter-spacing: .5px;
    }
  }
}

@media (min-width: 768px) {
  .card-dashboard-nineteen .chart-legend {
    margin-left: 70px;
  }
}

@media (min-width: 375px) {
  .card-dashboard-nineteen .chart-legend>div {
    letter-spacing: 1px;
  }
}

@media (min-width: 375px) {
  .card-dashboard-nineteen .chart-legend>div+div {
    margin-left: 30px;
  }
}

@media (min-width: 375px) and (max-width: 575px) {
  .card-dashboard-nineteen .card-body {
    padding-top: 170px;
  }
}

@media (min-width: 576px) {
  .card-dashboard-nineteen .card-body {
    padding-top: 130px;
  }
}

@media (min-width: 768px) {
  .card-dashboard-nineteen .card-body {
    padding-top: 95px;
  }
}

@media (min-width: 375px) {
  .card-dashboard-nineteen .flot-chart-wrapper {
    margin-top: 0;
  }
}

@media (min-width: 576px) {
  .card-dashboard-nineteen .flot-chart {
    height: 314px;
  }
}

.card-dashboard-twenty {
  .card-body {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .chartjs-wrapper {
    width: 100%;
    height: 230px;
  }

  .expansion-value {
    display: flex;
    justify-content: space-between;
    font-size: 17px;
    font-weight: 700;
    font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    letter-spacing: -.5px;
    margin-bottom: 5px;
    margin-top: auto;

    strong {
      &:first-child {
        color: #1c273c;
      }

      &:last-child {
        color: #7987a1;
      }
    }
  }

  .progress {
    margin-bottom: 3px;
    height: 4px;
  }

  .expansion-label {
    display: flex;
    justify-content: space-between;

    span {
      font-weight: 400;
      font-size: 11px;
      color: #97a3b9;
    }
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .card-dashboard-twenty .chartjs-wrapper {
    margin-top: auto;
  }
}

.card-dashboard-progress {
  .progress-legend {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center;

    li {
      position: relative;
      text-transform: uppercase;
      font-size: 10px;
      font-weight: 500;
      letter-spacing: 1px;
      padding-left: 15px;
      line-height: .95;

      &::before {
        content: '';
        position: absolute;
        top: 1px;
        left: 0;
        width: 8px;
        height: 8px;
      }

      &:first-child::before {
        background-color: $primary;
      }

      &:last-child::before {
        background-color: $teal;
      }

      +li {
        margin-left: 20px;
      }
    }
  }

  .media {
    display: block;

    >label {
      width: 50px;
      display: block;
      margin-bottom: 0;
    }

    +.media {
      margin-top: 15px;
    }
  }

  .media-body {
    margin-top: 5px;
  }

  .progress {
    background-color: #e3e7ed;
  }

  .progress-bar {
    height: 15px;
    font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 600;

    +.progress-bar {
      margin-left: 1px;
    }
  }
}

@media (min-width: 576px) {
  .card-dashboard-progress .media {
    display: flex;
    align-items: center;
  }
}

@media (min-width: 576px) {
  .card-dashboard-progress .media-body {
    margin-top: 0;
    margin-left: 15px;
  }
}

/* ###### 8.9 Dashboard Nine  ###### */

.main-body-dashboard-nine {
  background-color: $border;
}

@media (min-width: 992px) {
  .main-body-dashboard-nine .main-provider-header .container-fluid {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media (min-width: 992px) {
  .main-body-dashboard-nine.main-sidebar-hide .main-provider-header-dashboard-nine {
    left: 0;
  }
}

.main-sidebar-indigo-dark {
  background-color: #4130c5;
  border-right-width: 0;

  .main-logo {
    color: $white;
    letter-spacing: -1.4px;
  }

  .main-sidebar-loggedin .media-body {
    h6 {
      color: $white;
      font-weight: 500;
    }

    span {
      color: $white-5;
    }
  }

  .main-img-user::after {
    box-shadow: none;
  }

  .main-sidebar-body {
    .nav-label {
      border-bottom-color: $white-2;
      color: rgba(255, 255, 255, 0.35);
      font-weight: 400;
    }

    .nav-item {
      +.nav-item {
        margin-top: -1px;

        &::before {
          border-top-color: rgba(255, 255, 255, 0.12);
        }
      }

      &.active {
        position: relative;
        z-index: 5;

        &::before {
          content: '';
          position: absolute;
          top: -1px;
          left: -20px;
          right: -20px;
          background-color: #33269c;
          height: 41px;
        }

        .nav-link {
          font-weight: 500;
          color: $white;

          &:hover,
          &:focus {
            font-weight: 500;
            color: $white;
          }

          i,
          &:hover i,
          &:focus i {
            color: $white;
          }
        }
      }
    }

    .nav-link {
      color: rgba(255, 255, 255, 0.45);
      font-weight: 400;

      i {
        color: rgba(255, 255, 255, 0.75);
      }

      &.with-sub::after {
        opacity: .3;
      }

      &:hover,
      &:focus {
        color: rgba(255, 255, 255, 0.75);
      }
    }

    .nav-sub {
      border-left-color: $white-1;
      padding-left: 0;
      padding-bottom: 0;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: -32px;
        right: -20px;
        bottom: 0;
        background-color: $black-1;
        z-index: 4;
      }
    }

    .nav-sub-item.active .nav-sub-link {
      color: $white;
    }

    .nav-sub-link {
      padding-left: 21px;
      color: $white-4;
      border-top-color: $white-1;
      position: relative;
      z-index: 5;

      &:hover,
      &:focus {
        color: $white;
      }
    }
  }
}

.main-provider-header-dashboard-nine {
  background-color: $white;

  .main-provider-header-menu-icon {
    margin-right: 0;
  }
}

@media (min-width: 992px) {
  .main-provider-header-dashboard-nine {
    position: fixed;
    top: 0;
    left: 220px;
    right: 0;
    box-shadow: 0 0 15px rgba(28, 39, 60, 0.1);
  }
}

@media (min-width: 1200px) {
  .main-provider-header-dashboard-nine {
    left: 240px;
  }
}

.main-provider-content-1 {
  position: relative;

  .main-provider-content-header {
    display: block;
    padding: 20px;
    background-color: $white;
    border: 0;
    border-bottom: 1px solid #e3e8f1;

    .nav {
      justify-content: flex-start;
    }

    .nav-link {
      color: #596882;
      font-weight: 500;

      &:hover,
      &:focus {
        color: #1c273c;
      }

      &.active {
        color: $primary;

        &::before {
          top: 34px;
          bottom: auto;
          background-color: $primary;
        }
      }

      +.nav-link {
        margin-top: 0;
        margin-left: 25px;
      }

      &:last-child {
        position: relative;

        &::after {
          content: '';
          position: relative;
          display: inline-block;
          width: 25px;
        }
      }
    }
  }

  .main-provider-content-header-top {
    margin-bottom: 20px;
  }

  .main-provider-content-title {
    letter-spacing: -.7px;
    font-size: 24px;
  }

  .main-provider-content-body {
    padding: 20px;
  }

  .card {
    border-width: 0;
    box-shadow: 0 0 10px rgba(28, 39, 60, 0.1);
  }
}

@media (min-width: 992px) {
  .main-provider-content-1 {
    margin-top: 64px;
  }
}

@media (min-width: 992px) {
  .main-provider-content-1 .main-provider-content-header {
    padding: 15.7px 21px;
    margin: 0 0 1.3rem 0;
  }
}

@media (max-width: 991.98px) {
  .main-provider-content-1 .main-provider-content-header .nav-wrapper {
    overflow: hidden;
    width: 100%;
    height: 20px;
  }
}

@media (max-width: 991.98px) {
  .main-provider-content-1 .main-provider-content-header .nav {
    flex-direction: row;
    flex-wrap: nowrap;
    overflow-x: auto;
    width: calc(100vw - 20px);
  }
}

@media (max-width: 991.98px) {
  .main-provider-content-1 .main-provider-content-header .nav-link {
    white-space: nowrap;
    padding-bottom: 10px;
  }
}

@media (min-width: 992px) {
  .main-provider-content-1 .main-provider-content-header .nav-link {

    &:nth-child(5),
    &:last-child {
      font-weight: 400;
    }
  }
}

@media (min-width: 992px) {
  .main-provider-content-1 .main-provider-content-header .nav-link:nth-child(5) {
    margin-left: auto;
  }
}

@media (min-width: 768px) {
  .main-provider-content-1 .main-provider-content-header .nav-link:last-child::after {
    display: none;
  }
}

@media (min-width: 576px) {
  .main-provider-content-1 .main-provider-content-header-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }
}

.card-dashboard-twentyone {
  position: relative;

  .card-body {
    background-color: #f4f5f8;
    padding: 20px;
    height: 100%;

    .main-provider-content-label,
    .card-table-two .card-title {
      margin-bottom: 8px;
      text-transform: capitalize;
      font-size: 14px;
    }
  }
}

.card-table-two .card-dashboard-twentyone .card-body .card-title,
.card-dashboard-twentyone .card-body .card-dashboard-eight .card-title,
.card-dashboard-eight .card-dashboard-twentyone .card-body .card-title {
  margin-bottom: 8px;
  text-transform: capitalize;
  font-size: 14px;
}

@media (min-width: 768px) {
  .card-dashboard-twentyone .list-group {
    margin-bottom: 10px;
  }
}

.card-dashboard-twentyone {
  .list-group-item {
    padding: 8px 0;
    border-width: 0;
    border-right-width: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    position: relative;
    background-color: transparent;

    span {
      flex-basis: 50%;
      max-width: 50%;

      &:last-of-type {
        text-align: right;
        color: #1c273c;
        font-size: 12px;
        font-weight: 700;
        font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      }
    }

    .progress {
      margin-top: 5px;
      margin-bottom: 0;
      flex-basis: 100%;
      max-width: 100%;
      height: 5px;
      background-color: #b4bdce;
    }

    &:first-child {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }

    &:last-child {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  .vmap-wrapper {
    width: 100%;
    height: 180px;

    .jqvmap-zoomin,
    .jqvmap-zoomout {
      width: 24px;
      height: 24px;
      left: 15px;
      border-radius: 100%;
      background-color: #1c273c;
      color: $white;
      font-size: 20px;
      font-weight: 500;
      opacity: .2;
    }

    .jqvmap-zoomin {

      &:hover,
      &:focus {
        opacity: .8;
      }
    }

    .jqvmap-zoomout {

      &:hover,
      &:focus {
        opacity: .8;
      }
    }

    .jqvmap-zoomin {
      top: 15px;
    }

    .jqvmap-zoomout {
      top: 45px;
    }
  }
}

@media (min-width: 576px) {
  .card-dashboard-twentyone .vmap-wrapper {
    height: 250px;
  }
}

@media (min-width: 768px) {
  .card-dashboard-twentyone .vmap-wrapper {
    height: 100%;
  }
}

.card-dashboard-twentytwo {
  background-color: $white;
  position: relative;
  height: 120px;

  .media {
    padding: 20px;
    position: relative;
    z-index: 5;
  }

  .media-icon {
    width: 45px;
    height: 45px;
    color: $white;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    i {
      font-size: 24px;
      line-height: 0;

      &.typcn {
        line-height: .9;
      }
    }
  }

  .media-body {
    margin-left: 15px;
    padding-top: 5px;

    h6 {
      margin-bottom: 5px;
      line-height: .7;
      color: #1c273c;
      font-size: 26px;
      font-weight: 700;
      font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      letter-spacing: -.5px;
      display: flex;
      align-items: flex-end;

      >small {
        color: $white;
        padding: 2px 3px;
        font-size: 9px;
        font-weight: 500;
        line-height: 1.1;
        margin-left: 5px;
        letter-spacing: normal;

        &.up {
          background-color: $success;
        }

        &.down {
          background-color: $danger;
        }
      }
    }

    > {
      span {
        display: block;
      }

      small {
        display: block;
        font-size: 11px;
        color: #97a3b9;

        strong {
          font-weight: 500;
        }
      }
    }
  }

  .chart-wrapper {
    position: absolute;
    right: -9px;
    left: -9px;
    opacity: .3;
  }

  .flot-chart {
    width: 100%;
    height: 120px;
  }
}

.card-dashboard-twentythree {
  background-color: #f4f5f8;

  .main-donut-chart.chart1 {
    width: 130px;
    height: 130px;
    background: #b4bdce;

    .slice {
      &.one {
        clip: rect(0 130px 65px 0);
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
        background: $primary;
      }

      &.two {
        clip: rect(0 65px 130px 0);
        -webkit-transform: rotate(234deg);
        transform: rotate(234deg);
        background: $primary;
      }
    }

    .chart-center {
      top: 15px;
      left: 15px;
      width: 100px;
      height: 100px;
      background: #f4f5f8;

      span {
        font-size: 40px;
        line-height: 100px;
        color: $primary;

        &:after {
          content: "65%";
        }

        font-weight: 700;
        font-family: "Archivo",
        -apple-system,
        BlinkMacSystemFont,
        "Segoe UI",
        Roboto,
        "Helvetica Neue",
        Arial,
        "Noto Sans",
        sans-serif,
        "Apple Color Emoji",
        "Segoe UI Emoji",
        "Segoe UI Symbol",
        "Noto Color Emoji";
        font-size: 32px;
      }
    }
  }

  label {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 10px;
    line-height: 1;
    letter-spacing: .5px;

    span {
      display: block;
      width: 8px;
      height: 8px;
      border-radius: 100%;
      margin-right: 5px;
    }
  }

  h5 {
    font-size: 24px;
    font-weight: 700;
    font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    color: #1c273c;
    letter-spacing: -1px;
  }
}

.card-dashboard-audience-metrics {
  background-color: #f4f5f8;
  position: relative;
  overflow: hidden;
  height: 250px;

  .card-header {
    padding: 20px 20px 0;
    background-color: $white;
    position: relative;
    z-index: 3;
  }

  .card-title {
    font-size: 14px;
    margin-bottom: 5px;
  }

  .chart-wrapper {
    position: absolute;
    right: 0;
    bottom: -20px;
    left: 0;
    padding: 5px 5px 0;
  }

  .flot-chart {
    width: 100%;
    height: 180px;

    .flot-x-axis>div {
      text-transform: uppercase;
      font-size: 10px;
      font-weight: 700;
      color: #7987a1;
      display: none;

      span:last-child {
        padding-left: 2px;
        font-weight: 700;
        color: #1c273c;
      }
    }
  }

  .card-body {
    background-color: $white;
    display: flex;
    padding: 0 20px 20px;
    flex: none;
    position: relative;
    z-index: 3;

    >div+div {
      margin-left: 10px;
      padding-left: 10px;
    }

    h4 {
      font-weight: 700;
      font-size: 17px;
      font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      color: #1c273c;
      letter-spacing: -.5px;
      margin-bottom: 3px;
    }

    label {
      margin-bottom: 0;
      display: flex;
      align-items: center;
      font-size: 11px;

      span {
        display: inline-block;
        width: 9px;
        height: 9px;
        margin-right: 5px;
        border-radius: 100%;
      }
    }
  }
}

@media (min-width: 576px) {
  .card-dashboard-audience-metrics {
    height: 270px;
  }
}

@media (min-width: 576px) {
  .card-dashboard-audience-metrics .chart-wrapper {
    padding-bottom: 10px;
    bottom: 10px;
  }
}

@media (min-width: 576px) {
  .card-dashboard-audience-metrics .flot-chart .flot-x-axis>div {
    display: block;
  }
}

@media (min-width: 576px) {
  .card-dashboard-audience-metrics .card-body>div+div {
    margin-left: 20px;
    padding-left: 20px;
  }
}

@media (min-width: 576px) {
  .card-dashboard-audience-metrics .card-body h4 {
    font-size: 21px;
  }
}

@media (min-width: 576px) {
  .card-dashboard-audience-metrics .card-body label {
    font-size: 0.875rem;
  }
}

/* ###### 8.10 Dashboard Ten  ###### */

.card-dashboard-twentyfour {
  .card-header {
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 20px 0;
  }

  .card-title {
    font-size: 14px;
    margin-bottom: 0;
  }

  .card-body {
    padding: 15px 20px 20px;
  }

  .card-body-top {
    display: flex;
    margin-bottom: 20px;

    h6 {
      color: #1c273c;
      font-size: 18px;
      font-weight: 600;
      font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      margin-bottom: 0;
      letter-spacing: -.75px;

      small {
        font-weight: 600;
      }

      span {
        color: #97a3b9;
        font-weight: 400;
        letter-spacing: normal;
      }
    }

    label {
      display: block;
      margin-bottom: 0;
      color: #7987a1;
      font-size: 11px;
    }

    >div+div {
      margin-left: 30px;
    }
  }

  .main-provider-content-label,
  .card-table-two .card-title {
    font-size: 10px;
    color: $default-color;
    letter-spacing: .5px;
    margin-bottom: 0;
  }
}

@media (min-width: 576px) {
  .card-dashboard-twentyfour .card-body-top h6 {
    font-size: 22px;
  }
}

@media (min-width: 576px) {
  .card-dashboard-twentyfour .card-body-top label {
    font-size: 0.875rem;
  }

  .card-dashboard-audience-metrics .flot-chart .flot-x-axis .flot-tick-label {
    display: block;
  }
}

.card-table-two .card-dashboard-twentyfour .card-title,
.card-dashboard-twentyfour .card-dashboard-eight .card-title,
.card-dashboard-eight .card-dashboard-twentyfour .card-title {
  font-size: 10px;
  color: $default-color;
  letter-spacing: .5px;
  margin-bottom: 0;
}

.card-dashboard-twentyfour {
  .chart-legend {
    display: flex;
    align-items: center;
    font-size: 10px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: .5px;
    line-height: .6;

    span {
      display: inline-block;
      width: 7px;
      height: 7px;
      margin-right: 5px;
    }

    >div {
      display: flex;
      align-items: center;

      +div {
        margin-left: 20px;
      }
    }
  }

  .chart-wrapper {
    position: relative;
    margin-right: -10px;
  }

  .flot-chart {
    width: 100%;
    height: 130px;
  }
}

.card-dashboard-twentyfive {
  .card-title {
    font-size: 14px;
    margin-bottom: 15px;
  }

  .col+.col {
    border-left: 1px solid $border;
  }

  .card-label {
    display: block;
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 500;
    letter-spacing: .5px;
    margin-bottom: 2px;
    color: #97a3b9;
    white-space: nowrap;
  }

  .card-value {
    font-size: 22px;
    font-weight: 600;
    font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    color: #1c273c;
    margin-bottom: 0;
    letter-spacing: -1px;
  }

  .chart-wrapper {
    position: relative;
    margin: 0 -8px -5px;
  }

  .flot-chart {
    width: 100%;
    height: 35px;
  }
}

#flotChart2 {
  width: 100%;
  height: 35px;
}

.card-dashboard-twentysix {
  .card-header {
    padding: 15px 15px 10px;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .card-title {
    margin-bottom: 0;
    text-transform: uppercase;
  }

  .chart-legend {
    display: flex;
    align-items: center;

    >div {
      font-size: 10px;
      font-weight: 500;
      text-transform: uppercase;
      letter-spacing: .5px;

      +div {
        margin-left: 15px;
      }
    }

    span {
      display: inline-block;
      width: 8px;
      height: 8px;
      margin-right: 2px;
    }
  }

  .card-body {
    padding: 0;
    position: relative;
    overflow: hidden;

    h6 {
      margin-bottom: 0;
      color: #1c273c;
      font-size: 22px;
      font-weight: 600;
      font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      letter-spacing: -.5px;
      line-height: 1;

      span {
        font-weight: 400;
        font-size: 18px;
        letter-spacing: -1px;
      }
    }

    label {
      font-size: 12px;
      margin-bottom: 0;
      color: #97a3b9;
    }
  }

  .chart-wrapper {
    position: relative;
    margin: -30px -18px -15px -18px;
  }

  .flot-chart {
    width: 100%;
    height: 120px;

    .flot-x-axis>div {
      font-size: 10px;
      letter-spacing: .5px;
      text-transform: uppercase;
      color: rgba(255, 255, 255, 0.75);
      transform: translateY(-20px);
    }

    .flot-y-axis>div {
      font-size: 10px;
      transform: translateX(33px);
      color: rgba(28, 39, 60, 0.5);
    }
  }

  &.card-dark-one .card-title,
  &.card-dark-two .card-title,
  &.card-dark-one .card-body h6,
  &.card-dark-two .card-body h6 {
    color: $white;
  }

  &.card-dark-one .card-body h6 span,
  &.card-dark-two .card-body h6 span {
    color: $white-5;
  }

  &.card-dark-one .card-body label,
  &.card-dark-two .card-body label {
    color: $white-7;
  }

  &.card-dark-one .flot-chart .flot-y-axis>div,
  &.card-dark-two .flot-chart .flot-y-axis>div {
    color: $white-2;
  }

  &.card-dark-one {
    background-color: #8485fb;
    background-image: linear-gradient(to bottom, #1f05f0 0%, #8485fb 100%);
    background-repeat: repeat-x;
  }

  &.card-dark-two {
    background-color: #0040ff;
    background-image: linear-gradient(to bottom, #0a47ff 0%, $primary 100%);
    background-repeat: repeat-x;
  }
}

.main-rating-value {
  font-size: 40px;
  font-weight: 400;
  font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  color: #1c273c;
  margin-bottom: 0;
  letter-spacing: -.5px;
  line-height: .7;
}

.main-rating-label {
  margin-bottom: 10px;
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: .5px;
}

.main-media-list-reviews {
  .media+.media {
    margin-top: 25px;
  }

  .main-img-user {
    width: 32px;
    height: 32px;

    &::after {
      display: none;
    }
  }

  .media-body {
    margin-left: 15px;

    h6 {
      margin-bottom: 2px;
      line-height: 1;
    }

    small {
      display: inline-block;
      font-size: 12px;
      color: #97a3b9;
      line-height: 1;
    }
  }
}

.main-star-group {
  display: flex;
  align-items: center;

  span:last-child {
    display: block;
    font-weight: 500;
    font-size: 11px;
    margin-left: 5px;
    color: #7987a1;
  }
}

.main-star-item {
  color: $primary;
  font-size: 14px;
  position: relative;

  +.main-star-item {
    margin-left: 3px;
  }
}

.main-media-list-activity {
  .media+.media {
    margin-top: 23px;
  }

  .media-icon {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white;
    border-radius: 100%;

    i {
      font-size: 21px;
      line-height: 0;

      &.typcn {
        line-height: .9;
      }
    }
  }

  .media-body {
    margin-left: 15px;

    h6 {
      margin-bottom: 2px;
      color: #1c273c;
    }

    span {
      display: block;
      font-size: 11px;
      color: #97a3b9;
    }
  }

  .media-right {
    font-size: 11px;
    color: #97a3b9;
  }
}

.dark-theme.app.sidebar-mini .desktop-logo.logo-dark {
  display: block;
}

.dropdown-menu-left {
  box-shadow: 0px 0px 15px 1px rgb(188, 181, 214);

  .dropdown-item {
    padding: 4px 19px;
  }

  img {
    width: 31px;
    height: 20px;
  }
}

.main-provider-header-profile {
  border-top-right-radius: 7px;
  border-top-left-radius: 7px;
}

.dropdown-menu-arrow:after {
  content: '';
  position: absolute;
  top: -8.5px;
  right: 16px;
  border-bottom: 9px solid $white;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
}

.dropdown-menu-left {
  right: 0 !important;
  left: auto !important;
  bottom: auto !important;
}

.right-content {

  .btn-sm,
  .btn-group-sm>.btn {
    padding: .4rem .8rem;
  }
}

.total-revenue {
  display: flex;
  padding: 0 10px 20px;
  flex: none;
  position: absolute;
  z-index: 9;

  h4 {
    font-size: 21px;
    font-weight: 700;
    font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    color: #1c273c;
    letter-spacing: -.5px;
    margin-bottom: 3px;
  }

  label {
    font-size: 0.875rem;

    span {
      display: inline-block;
      width: 9px;
      height: 9px;
      margin-right: 5px;
      border-radius: 100%;
    }
  }

  >div+div {
    margin-left: 20px;
    padding-left: 20px;
  }
}

.customers .list-group-item .media img {
  width: 35px !important;
  height: 35px !important;
}

.list-group-item-action {
  width: 100%;
  color: #1d2635;
  text-align: inherit;
}

.progress-style {
  .table {

    th,
    td {
      padding: 0.75rem;
      vertical-align: middle;
    }
  }

  &.progress {
    overflow: visible !important;

    .progress-bar:after {
      content: "";
      width: 15px;
      height: 15px;
      border-radius: 50%;
      box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
      background: $white;
      position: absolute;
      right: -5px;
      top: -5px;
    }
  }

  .bg-primary-gradient.progress-bar:after,
  .bg-primary.progress-bar:after {
    border: 4px solid $primary;
  }

  .bg-secondary.progress-bar:after {
    border: 4px solid $secondary;
  }

  .bg-danger.progress-bar:after {
    border: 4px solid $danger;
  }

  .bg-teal.progress-bar:after {
    border: 4px solid $teal;
  }

  .bg-pink.progress-bar:after {
    border: 4px solid $pink;
  }

  .bg-success.progress-bar:after {
    border: 4px solid $success;
  }

  .bg-danger-gradient.progress-bar:after {
    border: 4px solid $danger;
  }

  .bg-warning.progress-bar:after {
    border: 4px solid $warning;
  }

  .bg-info.progress-bar:after {
    border: 4px solid $info;
  }

  &.progress .progress-bar {
    box-shadow: none;
    border-radius: 0;
    position: relative;
    -webkit-animation: animate-positive 2s;
    animation: animate-positive 2s;
  }

  .progress-bar {
    float: left;
    height: 100%;
    font-size: 12px;
    border-radius: 20px !important;
    line-height: 20px;
    overflow: visible !important;
    color: $white;
    text-align: center;
    background-color: #337ab7;
    -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
    -webkit-transition: width .6s ease;
    -o-transition: width .6s ease;
    transition: width .6s ease;
  }
}

.customers {
  .list-group-item-action {

    &:hover,
    &:focus {
      color: $default-color;
      background-color: $white;
    }
  }

  .list-group-item {
    padding: 0.82rem 1.25rem;
  }
}

.sales-info h3,
.card-table h4 {
  font-size: 21px;
  font-weight: 700;
  font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  color: #1c273c;
}

.sales-infomation {
  position: absolute;
  bottom: 0;
}

#chart {
  margin-bottom: 4.5rem;
}

.br-br-7 {
  border-bottom-right-radius: 7px !important;
}

.br-bl-7 {
  border-bottom-left-radius: 7px !important;
}

.header-icon-svgs {
  width: 20px;
  height: 20px;
  color: #7b8191;
  fill: #7b8191;
}

.fullscreen-button .full-screen-link {
  line-height: 33px !important;
}

.country-flag1 strong {
  color: #7282a9;
  display: none;
}

@media (max-width: 767px) {
  .app-sidebar__toggle {
    text-align: center;
  }
}

@media (max-width: 920px) {
  .sidebar-mini {
    .main-dashboard-header-right {
      flex-wrap: nowrap;
      margin-top: 20px;
    }

    .breadcrumb-header {
      display: block !important;
    }

    .main-dashboard-header-right>div {
      label {
        text-align: left;
      }

      &:first-child {
        flex-basis: 100%;
      }
    }
  }
}

@media (max-width: 576px) {
  .sidebar-mini .main-dashboard-header-right {
    flex-wrap: wrap !important;
  }

  .country-flag1 {
    display: none !important;
  }

  .total-revenue {
    >div+div {
      margin-left: 20px !important;
      padding-left: 3px !important;
    }

    padding: 0 !important;
  }

  .square-box {
    display: none;
  }
}

.nav-link.html-code.active {
  border-color: transparent;
  background-color: #23241f;
  color: white;
  margin-top: 1rem;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  border-bottom: 0;
}

.html-source.nav-tabs .nav-item {
  margin-bottom: -2px;
}

.nav-link.html-code {
  background: $gray-100;
  display: inline-flex;
  -webkit-box-align: center;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  align-items: center;
}

.counter-icon {
  margin-bottom: 0;
  display: inline-flex;
  padding: 1.3rem 1.4rem;
  border-radius: 50%;
  text-align: center;
  background: rgba(21, 22, 23, 0.2);

  i {
    color: $white;
    font-size: 22px;
  }
}

.success-widget h3 {
  &:before {
    content: "";
    position: relative;
    display: inline-block;
    width: 26%;
    height: 1px;
    vertical-align: middle;
    background: #ccead1;
  }

  &:after {
    content: "";
    position: relative;
    display: inline-block;
    width: 26%;
    height: 1px;
    vertical-align: middle;
    background: #ccead1;
    left: .5em;
  }

  &:before {
    right: .5em;
  }

  text-align: center;
}

.danger-widget h3 {

  &:before,
  &:after {
    background: #cad8a9;
  }

  &:before {
    content: "";
    position: relative;
    display: inline-block;
    width: 26%;
    height: 1px;
    vertical-align: middle;
    background: #f5dce2;
  }

  &:after {
    content: "";
    position: relative;
    display: inline-block;
    width: 26%;
    height: 1px;
    vertical-align: middle;
    background: #f5dce2;
    left: .5em;
  }

  &:before {
    right: .5em;
  }

  text-align: center;
}

.warning-widget h3 {

  &:before,
  &:after {
    background: #cad8a9;
  }

  &:before {
    content: "";
    position: relative;
    display: inline-block;
    width: 26%;
    height: 1px;
    vertical-align: middle;
    background: #f3e7c6;
  }

  &:after {
    content: "";
    position: relative;
    display: inline-block;
    width: 26%;
    height: 1px;
    vertical-align: middle;
    background: #f3e7c6;
    left: .5em;
  }

  &:before {
    right: .5em;
  }

  text-align: center;
}

.card .box {
  .img {
    width: 120px;
    height: 120px;
    margin: 0 auto;
    border-radius: 50%;
    overflow: hidden;
  }

  h2 {
    font-size: 20px;
    margin: 20px auto;
  }

  ul li a {
    display: block;
    margin: 0 10px;
    font-size: 20px;
    transition: 0.5s;
    text-align: center;
  }

  h2 span {
    background: $primary;
    font-size: 14px;
    color: $white;
    display: inline-block;
    padding: 4px 10px;
    border-radius: 15px;
    margin-top: 10px;
  }

  position: relative;
  top: 50%;
  left: 0;
  text-align: center;
  padding: 30px;
  box-sizing: border-box;
  border: 1px solid #e9f1f1;
  border-radius: 4px;
  box-shadow: 0 0 25px $white-3;
  width: 100%;
}

.userlist-table {
  .table {

    th,
    td {
      padding: 0.75rem;
      vertical-align: middle;
      display: table-cell;
    }
  }

  .table-bordered thead {

    th,
    td {
      padding-top: 12px;
      padding-bottom: 12px;
    }
  }

  .btn-sm,
  .btn-group-sm>.btn {
    font-size: .875rem;
    padding: .35rem .55rem;
    line-height: 0;
    border-radius: 0.2rem;
  }

  .user-link {
    font-weight: 500;
    color: $black;
    margin-right: 5px;
  }

  .user-subhead {
    color: #8992a9;
    display: none;
  }
}

.dot-label {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  animation: ani 1s linear infinite;
  left: 7px;
  bottom: -12px;
  position: relative;
}

@keyframes ani {
  0% {
    transform: scale(1);
  }

  25% {
    transform: scale(1);
  }

  30% {
    transform: scale(1.4);
  }

  50% {
    transform: scale(1.2);
  }

  70% {
    transform: scale(1.4);
  }

  90% {
    transform: scale(1);
  }

  100% {
    transform: scale(1);
  }
}

.search .page-link {
  background-color: $white;
}

.main-card-signin,
.modal-content .card {
  box-shadow: none;
}

@media (max-width: 920px) {
  .right-content {
    margin-top: 1rem !important;
  }
}

@media (max-width: 576px) {
  .profile.navtab-custom {
    li {
      display: flex;
      width: 100%;
      border-radius: 0;

      a {
        border-radius: 0;
        width: 100%;
        margin-right: 0 !important;
        border-top-left-radius: 0 !important;
        border-top-right-radius: 0 !important;
      }
    }

    padding: 11px 0 0 0 !important;
    width: 100%;
  }
}

.table-vcenter {

  td,
  th {
    vertical-align: middle;
    border-top: 1px solid #f1f5fa;
  }
}

.table-center {

  td,
  th {
    text-align: center;
  }
}

.table {
  width: 100% !important;
  margin-bottom: 1rem;
  color: #22252f;
  border: 1px solid $border
}

.userlist-table {
  .dot-label {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    animation: ani 1s linear infinite;
    left: -6px;
    bottom: -7px;
    position: relative;
  }

  img {
    max-width: inherit;
  }
}

.product-pagination {
  .page-link {
    background-color: $white !important;
    border: 1px solid $border;
  }

  .page-item.active .page-link {
    background: $primary !important;
    border-color: $primary;
  }
}

.main-logo1 {
  font-family: poppins;
  font-weight: 600;
}

@media (min-width: 576px) {
  .dropdown.nav-itemd-none .dropdown-menu:after {
    border-bottom: 9px solid $white;
  }
}

@media (min-width: 992px) {
  .top-header .header-brand {
    display: none;
  }

  .top-header .main-provider-header-center {
    margin-left: 0 !important;
  }

  .top-header .header-brand.header-brand2 {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
    top: 17px;
  }
}

.btn-outline-danger:focus {
  color: $danger;
}


.featured_icon i {
  transform: rotate(-43deg);
  position: absolute;
  top: 3px;
  text-align: center;
  right: 0;
  left: 0;
  color: $white;
  font-size: 6px;
}

.fa-circle:before {
  content: "\f111";
}

.featured_icon.danger {
  border: 3px solid #ee6161;
  background-image: linear-gradient(-38deg, #f32828 0%, #f32828 100%) !important;
}

.featured_icon {
  width: 17px;
  height: 17px;
  line-height: 60px;
  margin-left: -35px !important;
  background-image: linear-gradient(-38deg, $primary 0%, #6922be 100%);
  color: $black;
  font-size: 10px;
  border: 3px solid #a36de3;
  position: relative;
  margin-top: -40px;
  border-radius: 18px;
  top: 20px;
}

ul.timeline:before {
  content: ' ';
  background: $border;
  display: inline-block;
  position: absolute;
  left: 25px;
  width: 1.5px;
  height: 76%;
  z-index: 0;
  top: 61px;
}

.latest-timeline {
  .timeline {
    li.activity {
      margin-bottom: 2.5rem;
      padding: 0;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.featured_icon.success {
  border: 3px solid #25de8c;
  background-image: linear-gradient(-38deg, #0ba360 0%, #3cba92 100%) !important;
}

.featured_icon.warning {
  border: 3px solid #f09819;
  background-image: linear-gradient(-38deg, #ff5858 0%, #f09819 100%) !important;
}

.featured_icon.teal {
  border: 3px solid $teal;
  background-image: linear-gradient(-38deg, #265b5b 0%, $teal 100%) !important;
}

.progress-style .bg-warning-gradient.progress-bar:after {
  border: 4px solid #f67536;
}

.img-card .img-card-circle {
  position: absolute;
  top: 68px;
  margin-right: -48px;
  text-align: right;
  right: 0;
  opacity: 0.6;
  z-index: 3;
  transform: rotate(25deg);
}

.fa-genderless:before {
  content: "\f22d";
}

.fs-12 {
  font-size: 12px;
}


.fs-15 {
  font-size: 15px;
}

.btn-icon {
  padding-left: .5rem;
  padding-right: .5rem;
  text-align: center;
}

.main-body #chartLine {
  height: 255px !important;
}

.country-card {
  padding: 20px;
}

@media (max-width:767px) {
  .responsive-navbar.navbar .navbar-collapse {
    padding: 0px;
    width: 100%;
    background: #fff;
    margin-top: 0;
    z-index: 9999;
    top: 63px;
    border-bottom: 1px solid #f0f0ff;
    border-top: 1px solid #f0f0ff;
    position: fixed;
    left: 0;
    right: 0;
  }

  .responsive-navbar.navbar {
    position: relative !important;
    display: -ms-flexbox;
    display: flex;
    top: -5px;
    margin: 0;
    margin-bottom: 0 !important;
    padding: 0;

    .new.nav-link {
      margin: 10px 6px !important;
    }
  }

  .mobile-main-provider-header .navbar {
    padding: 0 !important;
  }

  .responsive-logo {
    .mobile-logo {
      margin-top: 3px !important;
    }
  }

}

@media (min-width: 768px) {
  .mobile-header {
    display: none;
    height: 0;
  }

  .navbar-toggler.navresponsive-toggler {
    display: none;
  }
}

.feature-1 {
  position: absolute;
  right: 40px;
  top: 30px;
}

.feature .text-start .text-success {
  background: #c1ecab;
  padding: 10px;
  border-radius: 10px;
  margin-left: -104px;
}

.feature .text-danger {
  background: #fba5b9;
  padding: 10px;
  border-radius: 10px;
  margin-left: -104px;
}

.img-card-circle1 {
  position: absolute;
  top: -5px;
  margin-right: -5px;
  text-align: right;
  right: 0;
  opacity: 0.1;
}

.img-card-circle2 {
  position: absolute;
  top: -5px;
  margin-right: 1px;
  text-align: right;
  right: 0;
  opacity: 0.1;
}

.img-card-circle3 {
  position: absolute;
  top: -6px;
  margin-right: 4px;
  right: 0;
  opacity: 0.2;
}

.img-card-circle4 {
  position: absolute;
  top: -1px;
  margin-right: 0px;
  right: 0;
  opacity: 0.4;
}

.sidenav-toggled .responsive-navbar.navbar .navbar-collapse {
  z-index: 999 !important;
}

.product-grid6 .icons li a {
  color: $white;
}

.product-grid6 .icons {
  padding: 0;
  margin: 0;
  list-style: none;
  opacity: 0;
  left: 0;
  right: 0;
  text-align: center;
  position: absolute;
  bottom: -3px;
  z-index: 1;
  transition: all .5s ease 0s;
  display: block;
  margin: 0 auto;
}

.product-grid6 .icons li .primary-gradient {
  font-size: 13px;
  line-height: 35px;
  text-align: center;
  height: 35px;
  width: 35px;
  margin: 0 auto;
  border-radius: 4px;
  display: block;
  position: relative;
  transition: all .3s ease-in-out;
  background: $primary-gradient;
  color: $white;
}

.product-grid6 .icons li .secondary-gradient {
  font-size: 13px;
  line-height: 35px;
  text-align: center;
  height: 35px;
  width: 35px;
  margin: 0 auto;
  border-radius: 4px;
  display: block;
  position: relative;
  transition: all .3s ease-in-out;
  background: $secondary-gradient;
}

.product-grid6 .icons li .info-gradient {
  font-size: 13px;
  line-height: 35px;
  text-align: center;
  height: 35px;
  width: 35px;
  margin: 0 auto;
  border-radius: 4px;
  display: block;
  position: relative;
  transition: all .3s ease-in-out;
  background: $info-gradient
}

.product-grid6:hover .icons {
  opacity: 1;
  bottom: 12px;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
}

.product-grid6 .icons li a:hover:after,
.product-grid6 .icons li a:hover:before {
  opacity: 1;
}

.product-image .pic-1 {
  transition: all .3s ease;
}

.product-grid6 .product-image img {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.product-image .pic-2 {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.3s ease;
  bottom: 0;
  right: 0;
  margin: 0 auto;
}

.product-grid6 .product-link a:nth-child(2n) {
  border-right: none;
}

.product-grid6 .product-image a.image {
  display: block;
}

.product-grid6:hover .product-image .pic-2 {
  opacity: 0;

}

.product-grid6:hover .icons li {
  opacity: 1;
}

.product-grid6 .icons li {
  margin: 0 auto;
  display: inline-block;
  opacity: 0;
  transition: all 0.4s ease;
}

.handle-counter {
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.counter-minus.btn {
  padding: 9px 15px !important;
}

.handle-counter .btn {
  padding: 9px 10px !important;
}

.handle-counter input {
  float: left;
  text-align: center;
  height: 41px;
  border-radius: 0;
  width: 70px;
  border: 1px solid $border;
  outline: none;
  border-left: 0;
  border-right: 0;
}

.handle-counter {

  .counter-minus,
  .handle-counter .counter-plus {
    float: left;
    text-align: center;
    border-radius: 1px;
  }

  .counter-minus {
    border-radius: 3px 0px 0px 3px !important;
  }

  .counter-plus {
    border-radius: 0px 3px 3px 0px !important;
  }
}

.counter-minus {
  border-radius: 0px 0 0 0px !important;
}

.counter-plus {
  border-radius: 0 0px 0px 0 !important;
}

.file-image-1 {
  width: 100px;
  height: 100px;
  display: inline-table;
  margin: 20px;
  position: relative;
  border: 1px solid $border;
  border-radius: 5px;
}

.file-image-1 .product-image img {
  width: 100px;
  height: 100px;
  background-size: cover;
  background-repeat: no-repeat;
}

.file-image-md .product-image img {
  width: 150px;
  height: 150px;
  background-size: cover;
  background-repeat: no-repeat;
}

.file-image-lg .product-image img {
  width: 200px;
  height: 200px;
  background-size: cover;
  background-repeat: no-repeat;
}

.file-image-1 .icons {
  padding: 0;
  margin: 0;
  list-style: none;
  opacity: 0;
  right: 7px;
  top: 6px;
  text-align: center;
  position: absolute;
  bottom: 0;
  z-index: 1;
  transition: all 0.5s ease 0s;
  text-align: right;

}

.file-image-1:hover .icons {
  opacity: 1;
  bottom: 33px;
}

.file-image-1 .icons li a {
  font-size: 12px;
  line-height: 28px;
  text-align: center;
  height: 25px;
  width: 25px;
  margin: 4px 1px;
  padding: 0;
  border-radius: 50px;
  display: block;
  position: relative;
  transition: all 0.3s ease-in-out;
  color: $white;
}

.file-image-1 .file-name-1 {
  position: absolute;
  right: 0;
  left: 0;
  font-size: 12px;
  text-align: center;
  font-weight: 500;
  margin-bottom: 10px;
  margin-top: 10px;
}


//File image-2 styles

.file-image-md {
  width: 150px;
  height: 150px;
}

.file-image-md .icons li a {
  font-size: 13px;
  line-height: 30px;
  height: 28px;
  width: 28px;
}

//File image-2 styles

.file-image-lg {
  width: 200px;
  height: 200px;
}

.file-image-lg .icons li a {
  font-size: 15px;
  line-height: 35px;
  height: 35px;
  width: 35px;
}

.edit {
  display: inline-block;
  color: $primary;
  font-size: 18px;
  line-height: 1;
  text-decoration: none;
  margin-left: 30px;

}

.dlist-align {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.mobile-main-provider-header .main-provider-header-message .dropdown-menu {
  width: 100px;
  position: absolute;
  top: 52px;
  left: auto;
  right: -5px;
  bottom: auto;
  z-index: 9;
}

.card-options a:not(.btn) {
  margin-left: 0;
  color: #505069;
  display: inline-block;
  min-width: 1rem;
  padding: 0px 8px;
}

.card-options {
  margin-left: auto;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-order: 100;
  order: 100;
  margin-right: -.5rem;
  color: #a8afc7;
  -ms-flex-item-align: center;
  align-self: center;
}

.product-carousel .carousel-inner .carousel-item {
  border: 0px solid $border;
  padding: 35px;
  border-radius: 5px;
  background: transparent;
}

.carousel-inner .carousel-item .thumb {
  padding: .55rem;
  border: 1px solid $border;
  border-radius: 5px;
}

.carousel-inner .carousel-item .thumb.active {
  border: 1px solid $border;
  background: $bg-active;
}


/* ######  Loaders ###### */

/* ###### circleloader ###### */

.lds-circle {
  display: inline-block;
  transform: translateZ(1px);
}

.lds-circle>div {
  background: $primary;
}

.lds-circle>div {
  display: inline-block;
  width: 51px;
  height: 51px;
  margin: 6px;
  border-radius: 50%;
  animation: lds-circle 2.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

@keyframes lds-circle {

  0%,
  100% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
  }

  0% {
    transform: rotateY(0deg);
  }

  50% {
    transform: rotateY(1800deg);
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
  }

  100% {
    transform: rotateY(3600deg);
  }
}

/* ###### dual-ring loader ###### */

.lds-dual-ring {
  display: inline-block;
  width: 64px;
  height: 64px;
}

.lds-dual-ring:after {
  border: 5px solid $primary;
  border-color: $primary transparent $primary transparent;
}

.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 46px;
  height: 46px;
  margin: 1px;
  border-radius: 50%;
  animation: lds-dual-ring 1.2s linear infinite;
}

.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* ###### heart loader ###### */

.lds-heart {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
  transform: rotate(45deg);
  transform-origin: 32px 32px;
}

.lds-heart div {
  background: $primary;
}

.lds-heart div:after,
.lds-heart div:before,
.lds-heart div:after,
.lds-heart div:before {
  background: $primary;
}

.lds-heart div:before {
  content: " ";
  position: absolute;
  display: block;
  width: 26px;
  height: 26px;
  left: -17px;
  border-radius: 50% 0 0 50%;
}

.lds-heart div:after,
.lds-heart div:before,
.lds-heart div:after,
.lds-heart div:before {
  background: $primary;
}

.lds-heart div:after {
  top: -17px;
  border-radius: 50% 50% 0 0;
}

.lds-heart div:after {
  content: " ";
  position: absolute;
  display: block;
  width: 26px;
  height: 26px;
}

.lds-heart div:after {
  content: " ";
  position: absolute;
  display: block;
  width: 26px;
  height: 26px;
}

.lds-heart div {
  background: $primary;
}

.lds-heart div {
  top: 23px;
  left: 19px;
  position: absolute;
  width: 26px;
  height: 26px;
  animation: lds-heart 1.2s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
}


@keyframes lds-heart {
  0% {
    transform: scale(0.95);
  }

  5% {
    transform: scale(1.1);
  }

  39% {
    transform: scale(0.85);
  }

  45% {
    transform: scale(1);
  }

  60% {
    transform: scale(0.95);
  }

  100% {
    transform: scale(0.9);
  }
}

/* ###### ripple loader ###### */

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}

.lds-ripple div {
  border: 4px solid $primary;
}

.lds-ripple div {
  position: absolute;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div {
  position: absolute;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

@keyframes lds-ripple {
  0% {
    top: 28px;
    left: 28px;
    width: 0;
    height: 0;
    opacity: 1;
  }

  100% {
    top: -1px;
    left: -1px;
    width: 58px;
    height: 58px;
    opacity: 0;
  }
}

/* ###### Spinner loader ###### */

.lds-spinner {
  color: official;
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}

.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}

.lds-spinner div {
  transform-origin: 32px 32px;
  animation: lds-spinner 1.2s linear infinite;
}

.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}

.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}

.lds-spinner div:after {
  background: $primary;
}

.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 29px;
  width: 5px;
  height: 14px;
  border-radius: 20%;
}

.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}

.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}

.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}

.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}

.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}

.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}

.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}

.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}

.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}


@keyframes lds-spinner {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}


/* ######  lines loader  ###### */

.lds-facebook {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}

.lds-facebook div:nth-child(1) {
  left: 6px;
  animation-delay: -0.24s;
}

.lds-facebook div {
  background: $primary;
}

.lds-facebook div {
  display: inline-block;
  position: absolute;
  left: 6px;
  width: 13px;
  animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}

.lds-facebook div:nth-child(2) {
  left: 26px;
  animation-delay: -0.12s;
}

.lds-facebook div:nth-child(3) {
  left: 45px;
  animation-delay: -0.12s;
}

@keyframes lds-facebook {
  0% {
    top: 6px;
    height: 51px;
  }

  50%,
  100% {
    top: 19px;
    height: 26px;
  }
}

.Accordion-Style02 {
  border-radius: 0px;
}

@media (max-width: 767.98px) {

  .main-provider-header-message .dropdown-menu,
  .main-provider-header-notification .dropdown-menu,
  .main-profile-menu .dropdown-menu {
    width: 95% !important;
    left: 5px !important;
    right: 5px !important;
    top: 60px !important;
    margin: 0 auto;
  }

  .menu-header-content {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }

  .main-provider-header-message,
  .main-provider-header-notification,
  .main-profile-menu,
  .nav-link.icon {
    position: inherit !important;
  }

  .main-provider-header-profile {
    border-radius: 0px;
  }

}

.btn.Sub {
  padding: 5px 20px !important;
  transition: none;
}

.bxl-instagram.tx-prime,
.bxl-linkedin.tx-prime,
.bxl-twitter.tx-prime,
.bxl-facebook.tx-prime {
  color: $primary;
  display: block;
  font-size: 22px;
  position: relative;
  line-height: 35px !important;
  outline: none;
  height: 37px;
  width: 37px;
  background: $white;
  margin: auto;
  text-align: center;
  border: 1px solid #f1ecf7;
  box-shadow: none;
  border-radius: 35px;
}

@media (max-width: 568px) {
  .feature-1 {
    right: 4px;
  }

  .btn,
  .sp-container button {
    padding: .5rem 0.5rem;
    transition: none;
    margin-right: 0px;
    margin-top: 0px;
  }

  .main-contact-action {
    right: 0px !important;
  }
}

.main-sidemenu .slide.is-expanded i.angle {
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
  position: absolute;
  top: 13px;
  right: 20px;
  font-size: 15px;
}

.main-sidemenu i.angle {
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
  position: absolute;
  top: 13px;
  right: 20px;
  font-size: 15px;
}

@media (max-width: 600px) {
  .main-nav-line .nav-link+.nav-link {
    margin-top: 13px;
    margin-left: 0;
  }
}

@media (max-width: 768px) {
  .nav.main-nav-line {
    display: block;
  }

  .profile-cover__img {
    top: 200px !important;
  }

}

@media (min-width: 481px) {
  .profile-cover__action>.btn {
    min-width: 125px;
  }
}

@media (max-width: 991px) {
  .main-error-wrapper.wrapper-1 {
    margin-top: 0rem !important;
  }

  .profile-cover__info .nav li {
    width: 100% !important;
  }

  .profile-cover__info .nav li:not(:first-child) {
    margin-left: 0px !important;
  }
}

@media (max-width: 568px) {

  .btn.btn-rounded.plus,
  .btn.btn-rounded.comment {
    padding: 8px 12px;
    transition: none;
    margin-right: -25px;
    margin-top: 9px;
  }

  .main-error-wrapper h1 {
    line-height: 1;
    font-size: 95px !important;
  }
}

.mail-option .chk-all,
.mail-option .btn-group a.btn {
  border: 1px solid #ebecf1;
  border-radius: 3px !important;
  display: inline-block;
  min-height: 0;
  padding: 5px 10px;
}

ul.inbox-pagination {
  float: right;
  list-style-type: none;
  display: flex;
}

.mail-option .dropdown-menu>li>a {
  display: block;
  padding: 0.5rem 0.75rem;
  clear: both;
  font-weight: 400;
  line-height: 1.428571429;
  color: #7987a1;
  white-space: nowrap;
}

.inbox-pagination a.np-btn {
  border: 1px solid #ebecf1;
  border-radius: 3px !important;
  display: inline-block;
  padding: 5px 10px;
  margin-left: 5px;
  vertical-align: middle;
  min-height: 0;
}

.inbox-pagination li span {
  display: inline-block;
  margin-right: 5px;
  margin-top: 10px;
}

.remove-icons .remove-heart i {
  border: 1px solid $primary;
  border-radius: 35px;
  padding: 6px;
  color: $primary;
}

.remove-icons .remove-thumb i {
  border: 1px solid $secondary;
  border-radius: 35px;
  padding: 6px;
  color: $secondary;
}

.nav.panel-tabs .side-menu__icon {
  margin-right: 5px;
  width: 23px;
  height: 23px;
  color: #7987a1;
  fill: #7987a1;
}

.nav.panel-tabs .active .side-menu__icon {
  fill: #fff;
}

.user-profile {
  position: relative;
  width: 72px !important;
  height: 72px !important;
  margin: 0 auto;
}

.app.sidenav-toggled .user-profile {
  width: 55px !important;
  height: 55px !important;
  position: relative;
  margin: 0 auto;
}

.sidenav-toggled.sidenav-toggled-open .user-profile {
  position: relative;
  width: 72px !important;
  height: 72px !important;
  margin: 0 auto;
}

@media (max-width: 767px) {
  .app.sidenav-toggled .user-profile {
    position: relative;
    width: 72px !important;
    height: 72px !important;
    margin: 0 auto;
  }
}

hr:not([size]) {
  height: 0px;
}

ul.timeline li {
  list-style: none !important;
}

.btn-check:focus+.btn-info,
.btn-info:focus {
  color: #fff;
}

.light-layout {
  display: none;
}

@media (max-width: 767px) {
  .header-icons .new.nav-link {
    position: relative;
    margin: auto 6px !important;
  }

  .responsive-navbar.navbar .dropdown {
    position: initial;
  }
}

@media (max-width: 768px) {
  .responsive-navbar .collapse.show {
    .dropdown {
      position: inherit;

      .dropdown-menu {
        &.header-search {
          width: 100%;
          left: 0;
          right: 0;

          .form-control {
            border-radius: 5px;
          }
        }

        &:before,
        &:after {
          display: none;
        }
      }

      .show.dropdown-menu {
        top: 57px !important;
        left: 5px !important;
        right: 5px !important;
      }
    }
  }
}

@media (width:768px) {
  .main-provider-header-message .dropdown-menu {
    width: 16rem !important;
  }
}

.header-brand .desktop-dark {
  display: none;
}

.responsive-logo .mobile-logo.dark-logo-1 {
  display: none;

}

hr {
  border-top: 1px solid $border
}

.circle-icon.widget {
  i {
    line-height: 1.9 !important;
  }
}

.circle-icon {
  height: 40px;
  width: 40px;
  position: absolute;
  top: 40px;
  border-radius: 5px;
  right: 40px;

  i {
    line-height: 2.4 !important;
  }
}

.lh-lg {
  line-height: 2.2 !important;
}

.chart-dropshadow {
  -webkit-filter: drop-shadow(-6px 12px 4px rgba(0, 0, 0, 0.1));
  filter: drop-shadow(-6px 12px 4px rgba(0, 0, 0, 0.1));
}

@media (min-width: 992px) {
  .main-provider-content .main-provider-header .container-fluid {
    padding: 0px;
  }

}

.dot-label-1 {
  width: 10px;
  height: 10px;
  display: inline-block;
  border-radius: 100%;
  margin-right: 5px;
}

@media (max-width: 767px) {
  form[role="search"].active input {
    top: .09rem !important;
  }

  .navbar-form.active .input-group-btn {
    top: 1.0rem !important;
  }

  .demo-icon .nav-link {
    padding: 12px 4px 4px 10px !important;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .search-open {
    .main-provider-header {
      z-index: 9999 !important;
    }

    form[role="search"].active {
      position: fixed;
      top: 0;

      input {
        top: 0;
        transition: none;
        box-shadow: none;
      }

      .input-group-btn {
        top: .75rem;
      }
    }
  }

  .main-provider-header-right .input-group-btn .btn {
    height: 30px !important;
    width: 30px !important;
    line-height: 18px !important;
  }
}

.spinner1 {
  width: 40px;
  height: 40px;
  position: relative;
  margin: 100px auto;
}

@-webkit-keyframes sk-bounce {

  0%,
  100% {
    -webkit-transform: scale(0);
  }

  50% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bounce {

  0%,
  100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }

  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}

.row.user-social-detail {
  position: absolute;
}

.pos-absolute.bg-black-9 {
  border-radius: 0px 5px 5px 0px;
}

.main-mail-star .typcn-star:before {
  content: '\e109';
  font-size: 20px;
  line-height: 0.8;
}

.main-provider-content-label {
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1;
  margin-bottom: 10px;
  letter-spacing: .2px;
}

#morrisDonut1.morris-donut-wrapper-demo svg text {
  font-size: 12px !important;
  font-weight: 500 !important;
}

.form-label {
  display: block;
  margin-bottom: .375rem;
  font-weight: 500;
  font-size: 0.875rem;
  margin-top: 9px;
}

.form-group {
  margin-bottom: 1rem;
  display: block;
}

div.dt-button-info {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 400px;
  margin-top: -100px;
  margin-left: -200px;
  background-color: white;
  border: 1px solid #ededf5;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, .2);
  border-radius: 1px;
  text-align: center;
  z-index: 21;
}

div.dt-button-info h2 {
  padding: .5em;
  margin: 0;
  font-weight: normal;
  border-bottom: 1px solid #ddd;
  background-color: #f4f4fb;
  font-size: 22px;
}

.file-manger-icon img {
  width: 70px;
  height: 70px;
}

.file-manger-icon {
  position: relative;
}

@media (min-width: 768px) {
  .main-provider-footer {
    padding: 0.90rem 1rem 0.90rem 250px !important;
  }
}

@media (max-width: 768px) {
  .main-provider-footer {
    padding: 0.90rem 1rem 0.90rem 10px;
  }
}

@media (max-width: 767px) {
  .handle-counter input {
    height: 41;
    width: 48px;
  }

  .handle-counter .btn {
    padding: 5px 6px !important;
  }
}

.item7-card-img {
  border-radius: 5px !important;
}

@media (max-width: 320px) {
  .sweet-alert button {
    padding: .45rem 0.5rem;
  }
}

.custom-control-input:focus~.custom-control-label::before {
  box-shadow: none !important;
}

.custom-control-input:not(:disabled):active~.custom-control-label::before {
  color: #fff;
  background-color: $primary;
  border-color: $primary;
}

.bootstrap-tagsinput .badge [data-role="remove"]:after {
  content: "×";
  margin-left: 6px;
}

.btn-close {
  box-sizing: content-box;
  border: 0;
  border-radius: .25rem;
  opacity: .5;
  padding: 5px;
  line-height: 1;
  font-size: 24px;
  //background-image: none;
  color: #000;

  &:focus {
    box-shadow: none !important;
  }
}

@media (max-width:576px) {
  .alert {
    padding: 12px 30px;
  }

  .intl-tel-input input {
    width: 180px;
  }
}

.typcn:before {
  height: auto !important;
  line-height: 2 !important;
}

.img-card-circle1 {
  position: absolute;
  top: 0;
  margin-right: 0px;
  text-align: right;
  right: 0;
  opacity: 0.05;
}

.demo-icon {
  margin: auto 6px !important;
  margin-right: 20px !important;
}

.search-icon {
  margin: auto 6px !important;
}

.file-manager-icon {
  width: 28px;
  height: 28px;

}

.progress-dark {
  background-color: rgba(255, 255, 255, 0.5) !important;
  height: 8px;
}

.pricing-body {
  list-style-type: none;
}

.pricing-card {
  transition: box-shadow .3s;
}

.pricing-card:hover {
  box-shadow: 0px 16px 32px rgba(33, 33, 33, 0.15);
}

.border-primary-transparent {
  border: 1px solid #e8e7fe;
}

.pricing-tabs ul.nav-price {
  text-align: center;
  margin: 0 auto;
  display: inline-flex;
  margin-top: 30px;
  margin-bottom: 40px;
  border: 1px solid #e6ebf1;
  border-radius: 5px;
  background: #fff;
  overflow: hidden;
}

.pricing-tabs ul.nav-price li {
  display: block;
}

.pricing-tabs ul.nav-price li a.active {
  background: $primary;
}

.pricing-tabs ul.nav-price li a.active {
  color: #fff;
  transition: 0.3s;
}

.pricing-tabs ul.nav-price li a {
  padding: 12px 22px;
  display: block;
  background: #fff;
  font-size: 16px;
  border-radius: 0px;
  transition: 0.3s;
}

// userlist data table

#user-datatable {
  // border-left: 0px !important;

  thead th {
    background-image: none;
    background-color: #ffffff !important;
    position: relative;
    // border-right: 0px !important;
    // border-left: 0px !important;
  }

  td {
    padding: 0.8rem;
    // border-right: 0px !important;
    // border-left: 0px !important;
  }

  thead .sorting::after {
    content: '\f3d0' !important;
    top: 49%;
    display: none !important;
  }

  input.form-control {
    width: 25rem !important;
    border-radius: 4px !important;
    background-color: rgba(118, 109, 249, 0.13) !important;
  }
}

.demo-icon.nav-link {
  padding: 0rem 0rem 0rem 0.2rem !important;
}

.theme-layout {
  cursor: pointer;
}

.file-manger-icon1 img {
  width: 70px;
  height: 70px;
  border-radius: 10px;
}

.intl-tel-input .flag-dropdown .selected-flag {
  padding: 10px 18px 9px 7px;
}

.lg-outer .lg-thumb-item {
  border: 0px solid #FFF !important;
}

.form-control::placeholder {
  opacity: 0.6;
}

.productdesc {
  .tab-menu-heading {
    border-bottom: 0px !important;
  }

  .panel-body {
    border: 0px solid #ededf5;
  }
}

.background-image-blog {
  background-image: url(/assets/img/photos/error.jpg) !important;
  background-position: center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  height: 320px;
  position: relative;
  border-top-left-radius: calc(.25rem - 1px);
  border-top-right-radius: calc(.25rem - 1px);
  border-radius: 0px;
}

.custom-blog-content {
  position: absolute;
  font-weight: 600;
  color: $white;
  font-size: 19px;
  bottom: 0;
}

a.custom-blog-content:hover {
  color: $white !important;
}

.white-space-nowrap {
  white-space: nowrap;
}

//tag attachments css//

.attached-file-grid6 .icons li {
  margin: 0 auto;
  display: inline-block;
  opacity: 0;
  transition: all 0.4s ease;
}

.attached-file-grid6 .icons li a:hover {
  color: #fff;
}

.attached-file-grid6 .icons li a:hover {
  border: $primary;
  background: $primary;
}

.attached-file-grid6 .file-image img {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.attached-file-image .pic-1 {
  transition: all .3s ease;
}

.attached-file-image .pic-2 {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.3s ease;
  bottom: 0;
  right: 0;
  margin: 0 auto;
}

.attached-file-grid6:hover .attached-file-image .pic-2 {
  opacity: 0;
}

.attached-file-grid6:hover .icons {
  opacity: 1;
  bottom: 0px;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
  padding: 10px;
}

.attached-file-grid6:hover .icons li {
  opacity: 1;
}

.image-pic {
  position: absolute;
  right: 0;
  left: 0;
  top: 0px;
  color: #fff;
  font-size: 17px;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), transparent 100%);
  padding: 0px 0px 20px 10px;
  border-radius: 5px;
}

.tag.tag-attachments {
  padding: 4px 15px 2px 15px;
  font-size: 13px;
  background-color: #ffffff;
  border: 1px solid #e8e8f7;
}

.tag.tag-attachments-lg {
  padding: 5px 17px 3px 17px;
  font-size: 17px;
  background-color: #ffffff;
  border: 1px solid #e8e8f7;
}

.tag.tag-attachments-sm {
  padding: 3px 10px 1px 10px;
  font-size: 13px;
  background-color: #ffffff;
  border: 1px solid #e8e8f7;
}

.tags .tag-files span {
  position: absolute;
  top: 21px;
  margin-right: 104px;
  left: 51px;
  font-size: 12px;
  color: #a5b1d9;
}

.attached-file-grid6 .icons li a {
  font-size: 13px;
  line-height: 30px;
  text-align: center;
  height: 30px;
  width: 30px;
  margin: 0 auto;
  border-radius: 5px;
  display: block;
  position: relative;
  transition: all .3s ease-in-out;
  background: #fff;
}

.attached-file-grid6 .icons li a {
  color: $primary;
  border: 1px solid #fff;
}

.attached-file-grid6 .icons {
  padding: 0;
  margin: 0;
  list-style: none;
  opacity: 0;
  left: 0;
  right: 0;
  text-align: center;
  position: absolute;
  bottom: -3px;
  z-index: 1;
  transition: all .5s ease 0s;
  display: block;
  margin: 0 auto;
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5), transparent 100%);
}

.pro-img-box {
  position: relative;
}

.theme-container {
  button {
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    padding: 0.5rem 1rem;
    background-color: $white;
    border: 1px solid $border;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 5px;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
}

.theme-container1 {
  button {
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    padding: 0.5rem 1rem;
    background-color: $white;
    border: 1px solid $border;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 5px;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
}

.theme-container2 {
  button {
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    padding: 0.5rem 1rem;
    background-color: $white;
    border: 1px solid $border;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 5px;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
}

.pickr {
  text-align: center;
  margin-top: 10px;
}

.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next,
.owl-carousel button.owl-dot {
  background-color: $white;
  opacity: 7;
  color: inherit;
  border: none;
  padding: 0p !important;
  font: inherit;
}

.owl-nav .owl-next {
  position: absolute;
  top: 50%;
  right: -25px;
  margin-top: -1.65em;
}

.owl-nav .owl-prev {
  position: absolute;
  top: 50%;
  left: -25px;
  margin-top: -1.65em;
}

.owl-nav button {
  display: block;
  font-size: 1.3rem !important;
  line-height: 2em;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  text-align: center;
  background: $white !important;
  opacity: 0.5 !important;
  border: 1px solid #e8ebf3 !important;
  z-index: 99;
  box-shadow: 0 4px 15px rgba(67, 67, 67, 0.15);
}

@media (min-width: 992px) {
  .main-settings-menu .nav-link {
    font-size: 14px;

    i {
      font-size: 18px;
    }
  }
}

.settings-main-icon {
  display: flex;
  text-align: center;

  i {
    width: 3rem;
    font-size: 18px;
    line-height: 3rem;
    height: 3rem;
    background-color: $primary-02;
    color: $primary;
    border-radius: 50%;
  }
}

// about us //
.leading-normal {
  line-height: 1.5 !important;
}

.lead-1 {
  font-size: 1.6rem;
  font-weight: 500;
}

.aboutlist {
  list-style-type: none;
}

.about-icons {
  height: 60px;
  width: 60px;
}

.about-team {
  width: 80px;
  height: 80px;
  right: 0;
  left: 0;
  margin: 0 auto;
}

.about-main,
.about-motto {
  display: flex;
  align-items: center;
  justify-content: center;
}

.motto-icon {
  width: 30px;
  height: 30px;
}

//!about us//


.prime-card {
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    height: 160px;
    transform: scale(1.9) translate(3px, -12px);
  }
}

@media (max-width:1600px) {
  .prime-card {
    img {
      height: 250px;
      transform: scale(1);
    }
  }
}

.apexcharts-yaxis-texts-g text {
  fill: #adb5be !important;
}

.apexcharts-xaxis-texts-g text {
  fill: #adb5be !important;
}

#sales {
  justify-content: center;
  align-items: center;

  div {
    margin: 0 auto;
  }
}

#Viewers {
  .apexcharts-canvas {
    margin: 0 auto;
  }
}

.apexcharts-legend-marker {
  margin-right: 10px !important;
}

.apexcharts-legend-series {
  margin: 0px 20px !important;
}

.under-countdown .countdown {
  padding: 20px;
  border-radius: 5px;
}

.countdown span:first-child {
  font-size: 30px;
  font-weight: 500;
}

//Notifications//

.notification {
  list-style-type: none;
  padding: 0;
  position: relative
}

.notification:before {
  content: '';
  position: absolute;
  top: 40px;
  bottom: 5px;
  width: 4px;
  background-color: $primary-02;
  left: 20%;
  margin-left: -2.5px
}

.notification>li {
  position: relative;
  min-height: 50px;
  padding: 15px 0
}

.notification .notification-time {
  position: absolute;
  left: 0;
  width: 18%;
  text-align: right;
  top: 30px;
}

.notification .notification-time .date,
.notification .notification-time .time {
  display: block;
  font-weight: 500;
}

.notification .notification-time .date {
  line-height: 16px;
  font-size: 11px;
  margin-bottom: 4px;
  color: #7987a1;
}

.notification .notification-time .time {
  line-height: 24px;
  font-size: 18px;
  color: #7987a1;
}

.notification .notification-icon {
  left: 15%;
  position: absolute;
  width: 10%;
  text-align: center;
  top: 51px
}

.notification .notification-icon a {
  text-decoration: none;
  width: 20px;
  height: 20px;
  display: inline-block;
  border-radius: 50%;
  background: #fff;
  line-height: 10px;
  color: #fff;
  font-size: 14px;
  border: 3px solid $primary;
  transition: border-color .2s linear
}

.notification .notification-body {
  box-shadow: 0px 1px 16px rgba(0, 0, 0, 0.05);
  margin-left: 24%;
  margin-right: 18%;
  background: #fff;
  position: relative;
  padding: 14px 20px;
  border-radius: 6px
}

.notification .notification-body:before {
  content: '';
  display: block;
  position: absolute;
  border: 10px solid transparent;
  border-right-color: $white;
  left: -20px;
  top: 32px
}

.notification .notification-body>div+div {
  margin-top: 15px
}

.notification-badge {
  background-color: #eff1f5;
  color: #7987a1;
}

@media (max-width:576px) {
  .notification .notification-body:before {
    display: none;
  }

  .notification .notification-icon a {
    display: none;
  }

  .notification:before {
    display: none;
  }

  .notification-body {
    .media {
      flex-direction: column;

      .main-img-user {
        margin-bottom: 10px !important;
      }
    }
  }

  .notification .notification-time {
    z-index: 99;
    width: 100%;
    right: 5px !important;
    position: absolute;
    top: 20px !important;
  }

  .notification .notification-body {
    margin-left: 0px;
    margin-right: 0px;
    position: relative;
  }

  .notification-badge {
    position: absolute;
    left: 10px;
    top: 8px;
  }

  .notification .notification-time .date,
  .notification .notification-time .time {
    display: inline;
  }

  .notification .notification-time .time {
    line-height: 16px;
    font-size: 11px;
    margin-left: 5px;
    margin-right: 10px;
    color: #b6bfcf;
  }
}

//End-Notifications//

//Country selector modal//

.country-selector img {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

#country-selector {
  .modal-header {
    background-color: rgba($dark, 0.1);
    border-bottom: 1px solid #dae5e7 !important;
  }

  .modal-body {
    background-image: url(/assets/img/png/countrymap.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;

    &:before {
      width: 100%;
      height: 100%;
      content: '';
      position: absolute;
      background-color: rgba($dark, 0.1);
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }
  }
}

.btn-country {
  border: 1px solid rgba($primary, 0.2);
  box-shadow: none !important;
  text-align: justify !important;
  font-size: 13px !important;
  line-height: 1;
  border-radius: 12em;
  border: 1px solid transparent;
  padding: 0.45rem 0.45rem !important;
  transition: none !important;

  &:hover {
    border: 1px solid $primary !important;
  }

  .country-selector {
    img {
      box-shadow: 0px 1px 16px rgba(0, 0, 0, 0.2) !important;
    }
  }
}

.btn-check:checked+.btn-country,
.btn-country.active,
.btn-country:active {
  border: 1px solid $primary !important;
  font-weight: 600;
  color: $black;
  background-color: #fff;
}

//Country selector modal//

#login-otp {
  display: none;
}

.apexcharts-toolbar {
  z-index: 1 !important;
}

.app-sidebar.sidebar-scroll.open.ps--scrolling-y {
  .main-provider-content.app-content {
    position: fixed !important;
  }
}

.apexcharts-legend .apexcharts-legend-series .apexcharts-legend-text {
  font-size: 14px !important;
  font-weight: 500 !important;
}


//____________For Iphone

@supports (-webkit-touch-callout: none) {
  @media screen and (max-device-width: 991px) and (orientation: portrait) {
    .ps {
      &.ps--active-y {

        &:hover>.ps__rail-y,
        &:focus>.ps__rail-y {
          opacity: 0;
        }
      }
    }
  }

  @media screen and (max-device-width: 991px) and (orientation: landscape) {
    .ps {
      &.ps--active-y {

        &:hover>.ps__rail-y,
        &:focus>.ps__rail-y {
          opacity: 0;
        }
      }
    }
  }
}

.main-rocket {
  fill: $primary;
}

// Plugin primary colors//

.tree li i {
  color: $primary;
}

.bootstrap-tagsinput .badge {
  background-color: $primary;
  border: 1px solid $primary-border;
}

.sweet-alert button {
  background-color: $primary !important;
}


// Quill editor styles //

.ql-snow.ql-toolbar button:hover,
.ql-snow .ql-toolbar button:hover,
.ql-snow.ql-toolbar button:focus,
.ql-snow .ql-toolbar button:focus,
.ql-snow.ql-toolbar button.ql-active,
.ql-snow .ql-toolbar button.ql-active,
.ql-snow.ql-toolbar .ql-picker-label:hover,
.ql-snow .ql-toolbar .ql-picker-label:hover,
.ql-snow.ql-toolbar .ql-picker-label.ql-active,
.ql-snow .ql-toolbar .ql-picker-label.ql-active,
.ql-snow.ql-toolbar .ql-picker-item:hover,
.ql-snow .ql-toolbar .ql-picker-item:hover,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected {
  color: $primary;
}

.ql-snow.ql-toolbar button:hover .ql-fill,
.ql-snow .ql-toolbar button:hover .ql-fill,
.ql-snow.ql-toolbar button:focus .ql-fill,
.ql-snow .ql-toolbar button:focus .ql-fill,
.ql-snow.ql-toolbar button.ql-active .ql-fill,
.ql-snow .ql-toolbar button.ql-active .ql-fill,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-fill,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-fill,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar button:focus .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button:focus .ql-stroke.ql-fill,
.ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill {
  fill: $primary;
}

.ql-snow.ql-toolbar button:hover .ql-stroke,
.ql-snow .ql-toolbar button:hover .ql-stroke,
.ql-snow.ql-toolbar button:focus .ql-stroke,
.ql-snow .ql-toolbar button:focus .ql-stroke,
.ql-snow.ql-toolbar button.ql-active .ql-stroke,
.ql-snow .ql-toolbar button.ql-active .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-snow.ql-toolbar button:hover .ql-stroke-miter,
.ql-snow .ql-toolbar button:hover .ql-stroke-miter,
.ql-snow.ql-toolbar button:focus .ql-stroke-miter,
.ql-snow .ql-toolbar button:focus .ql-stroke-miter,
.ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
.ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
  stroke: $primary;
}

.ql-snow a {
  color: $primary;
}

// !Quill editor styles //

// gallery //

.lg-actions .lg-next,
.lg-actions .lg-prev {
  background-color: $primary-05;
  border: 1px solid $primary;
}

.lg-toolbar {
  background-color: $primary-05;
}

//  !gallery //

// Bootstrap datepicker //
.datepicker .datepicker-switch {
  color: $primary;
}

.datepicker .datepicker-switch:hover,
.datepicker .prev:hover,
.datepicker .next:hover,
.datepicker tfoot tr th:hover {
  color: $primary !important;
}

.datepicker table tr td.disabled,
.datepicker table tr td.disabled:hover {
  color: $primary;
}

.datepicker table tr td.active,
.datepicker table tr td.active:hover,
.datepicker table tr td.active.disabled,
.datepicker table tr td.active.disabled:hover {
  background-image: -moz-linear-gradient(to bottom, $primary, $primary);
  background-image: -ms-linear-gradient(to bottom, $primary, $primary);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from($primary), to($primary));
  background-image: -webkit-linear-gradient(to bottom, $primary, $primary);
  background-image: -o-linear-gradient(to bottom, $primary, $primary);
  background-image: linear-gradient(to bottom, $primary, $primary);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$primary', endColorstr='$primary', GradientType=0);
  border-color: $primary #002a80;
}

.datepicker table tr td span:hover,
.datepicker table tr td span.focused {
  background: $primary;
}

.datepicker table tr td span.disabled,
.datepicker table tr td span.disabled:hover {
  background: none;
  color: $primary;
}

.datepicker table tr td span.active,
.datepicker table tr td span.active:hover,
.datepicker table tr td span.active.disabled,
.datepicker table tr td span.active.disabled:hover {
  background-image: -moz-linear-gradient(to bottom, $primary, $primary);
  background-image: -ms-linear-gradient(to bottom, $primary, $primary);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from($primary), to($primary));
  background-image: -webkit-linear-gradient(to bottom, $primary, $primary);
  background-image: -o-linear-gradient(to bottom, $primary, $primary);
  background-image: linear-gradient(to bottom, $primary, $primary);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$primary', endColorstr='$primary', GradientType=0);
  border-color: $primary #002a80;
}

.datepicker table tr td span.old,
.datepicker table tr td span.new {
  color: $primary;
}

.datepicker table tr td span.old:hover,
.datepicker table tr td span.new:hover {
  color: $white !important;
}

// !Bootstrap datepicker //

// !Plugin primary colors//

@media (max-width:767.98px) {
  .error-page1 {
    .demo-icon {
      svg {
        position: absolute;
        left: auto;
        right: 60px;
        top: 60px;
        fill: $primary;
        width: 25px;
        height: 25px;
        z-index: 99999;
      }
    }
  }
}

.error-page1 {
  &.dark-theme .main-card-signin {
    box-shadow: none !important;
  }

  &.dark-theme .main-card-signin {
    background-color: transparent;
    border: 1px solid transparent;
  }

  .demo-icon {
    svg {
      position: absolute;
      left: auto;
      right: 60px;
      top: 60px;
      fill: $white;
      width: 25px;
      height: 25px;
    }
  }

  .tabs-menu1 ul li a.active {
    border-bottom: 3px solid $primary !important;
  }

  .tabs-menu1 ul li a {
    border-bottom: 3px solid #e6ebf1 !important;
  }
}

@media (max-width:991.98px) {
  .error-page1 .demo-icon svg {
    position: absolute;
    left: auto;
    right: 50px;
    top: 20px;
    width: 25px;
    z-index: 999;
    height: 25px;
  }
}

.carousel-inner.slider {
  position: relative;
  height: 400px;

  .carousel-item {
    position: absolute;
    left: 0;
    top: 0;
  }
}

.sidebar-mini {

  .slide-left,
  .slide-right {
    display: none !important;
  }
}

#videomodal,
#audiomodal {
  .modal-content {
    box-shadow: 0 1px 15px 1px rgba(156, 156, 168, 0.5);
  }
}

.main-provider-content-app {
  .option-dots {
    position: relative;
    right: 15px;
    z-index: 999;
    margin: 0 auto;
  }
}

// Gallery styles //

.masonry {
  .brick {
    width: auto;
    margin-bottom: 20px;
  }
}

.brick img {
  border-radius: 5px;
}

.smartphoto-img.active {
  border-radius: 5px;
}

.smartphoto-arrows li {
  padding: 8px;
  border-radius: 5px;
  background: $primary-02;
}

.smartphoto-arrows a {
  width: 50% !important;
  height: 50% !important;
  margin: 7px;
}

.smartphoto-dismiss {
  width: 15px !important;
  height: 15px !important;
  right: 22px !important;
  top: 18px !important;
}

.smartphoto {
  background-color: rgba(0, 0, 0, 0.8) !important;
}

.smartphoto-arrow-left {
  left: 15px !important;
}

.smartphoto-arrow-right {
  right: 15px !important;
}

.smartphoto-nav li {
  width: 70px !important;
  height: 70px !important;
  margin-left: 5px;
}

.smartphoto-nav {
  bottom: 10px !important;
}

.smartphoto-nav a {
  border-radius: 5px;
  opacity: 0.6 !important;
  border: $border;
}

.smartphoto-list li {
  display: table !important;
}

//  !Gallery styles //

.ck.ck-button,
a.ck.ck-button {
  border: 1px solid $border;
}

.theme-switch {
  .demo-icon {
    margin: 0 auto !important;
  }

  position: relative;
  padding: 10px;
  border-radius: 50px;
  background-color: $primary-02;
  height: 50px;
  width: 50px;
  display: flex !important;
  align-items: center;
  text-align: center;
  justify-content: center;

  a {
    position: absolute;

    svg {
      fill: $primary;
    }
  }
}

@media (max-width:576px) {

  //   .error-page1 {
  //     .demo-icon {
  //       display: none;
  //     }
  //   }
  .wizard>.steps .current a .title,
  .wizard>.steps .current a:hover .title,
  .wizard>.steps .current a:active .title {
    display: none !important;
  }
}

table.dataTable.dtr-inline.collapsed>tbody>tr>td.dtr-control:before,
table.dataTable.dtr-inline.collapsed>tbody>tr>th.dtr-control:before {
  background-color: $primary !important;
}

.twentytwenty-container {
  border-radius: 5px !important;
}

.pcr-app[data-theme='classic'] {
  border-radius: 5px !important;
}

.pcr-app[data-theme='monolith'] {
  border-radius: 5px !important;
}

.pcr-app[data-theme='nano'] {
  border-radius: 5px !important;
}

.file-detailimg {
  img {
    width: 1000%;
    height: 100%;
  }
}

#gallery {
  img {
    width: 360px;
  }
}

.chart-circle {
  display: block;
  height: 6rem;
  width: 6rem;
  position: relative;
}

.chart-circle canvas {
  margin: 0 auto;
  display: block;
  max-width: 100%;
  max-height: 100%;
}

.chart-circle .chart-circle-value.circle-style {
  position: absolute;
  top: 18px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  bottom: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-direction: column;
  flex-direction: column;
  line-height: 1;
  width: 60px;
  height: 60px;
  line-height: 60px;
  border: 2px dotted $primary;
  border-radius: 50%;
  background: transparent;
}

.chart-circle-value {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  bottom: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-direction: column;
  flex-direction: column;
  line-height: 1;
}

.chart-circle[data-color] {
  color: $primary;
}

@media screen and (max-width:1024px) {
  .cardbody {
    padding-left: 0;
  }

  .item-card .product-grid6 .cardprice {
    top: 20px;
    right: 0;
  }

  .rtl .cardbody {
    padding-left: inherit;
    padding-right: 0;
  }

  .rtl .item-card .product-grid6 .cardprice {
    top: 20px;
    left: 0;
    right: inherit;
  }
}

@media (orientation: landscape) and (max-width: 767px) {

  .smartphoto-img {
    width: 250px !important;
    left: 80%;
    display: block;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  .smartphoto-img-wrap {
    left: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 33%;
    right: 0;
    transform: none !important;
    justify-content: center;
    align-items: center;
  }
}

@media (orientation: landscape) and (min-width:768px) and (max-width: 991px) {

  .smartphoto-img {
    width: 350px !important;
    left: 75%;
    display: block;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  .smartphoto-img-wrap {
    left: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20%;
    right: 0;
    transform: none !important;
    justify-content: center;
    align-items: center;
  }
}

.SumoSelect.disabled>.CaptionCont {
  background-color: #f9fbfb;
}

.SumoSelect.disabled {

  .SlectBox,
  .testselect2 {
    display: none;
  }
}

.index1 {
  .circle-icon {
    height: 35px;
    width: 35px;
    position: initial;
    border-radius: 50%;

    i {
      line-height: 2.2 !important;
    }
  }
}

.transaction-icon {
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.featured_icon1.danger {
  border: 2px solid $danger;
}

.featured_icon1.success {
  border: 2px solid $success;
}

.featured_icon1.warning {
  border: 2px solid $warning;
}

.featured_icon1.teal {
  border: 2px solid $teal;
}

.featured_icon1.info {
  border: 2px solid $info;
}

.featured_icon1.secondary {
  border: 2px solid $secondary;
}

.featured_icon1 {
  width: 15px;
  height: 15px;
  line-height: 60px;
  margin-left: -34px !important;
  color: #000;
  font-size: 10px;
  border: 2px solid var(--primary-bg-color);
  position: relative;
  margin-top: -40px;
  border-radius: 18px;
  top: 20px;
}

.table-edit,
.table-delete {
  fill: $white;
}

.apexcharts-xaxistooltip.apexcharts-xaxistooltip-bottom.light.active {
  display: none !important;
}

.apexcharts-tooltip.light {
  border: 1px solid $primary-border !important;
  background: $primary !important;
  color: #fff !important;
}

.apexcharts-tooltip.light .apexcharts-tooltip-title {
  background: $primary !important;
  border-bottom: 1px solid $white-3 !important;
}

.apexcharts-tooltip-marker {
  background-color: $white !important;
}

.lh-maincard {
  line-height: 1.6 !important;
}

#country-selector .modal-body ul li {
  list-style-type: none;
}

#owl-demo2 {
  img {
    width: 100%;
  }
}

.main-provider-content {
  background-color: #eaf3f147;
  
  .btn-primary {
    color: #fff !important;
    background-color: var(--primary-bg-color);
    border-color: var(--primary-bg-border);
  }

  .card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background: $white !important;
    background-clip: border-box;
    border-radius: 5px;
    border: 0px solid transparent;
    margin-bottom: 1.3rem;
    box-shadow: $shadow;
  }
}