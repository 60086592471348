/* template-color */
.scroll-top {
	background: #65c7bb;
}
.header-top {
	.info {
		li {
			a {
				&:hover {
					color: #65c7bb !important;
				}
			}
		}
		li.language {
			.language-dropdown {
				background: #3C5A7D;
				border-top: 2px solid #65c7bb;
				li {
					a {
						&:hover {
							color: #65c7bb;
						}
					}
				}
			}
		}
	}
}
.bg-color-1 {
	background-color: #b7e9e1;
}
.theme-btn-one {
	background: #65c7bb;
	&:before {
		background: #3C5A7D;
	}
	&:hover {
		background: #3C5A7D;
	}
	&.disabled {
		background-color: lightgray;
	}
}
.main-header {
	.logo-box {
		.bg-color {
			background: #3C5A7D;
		}
	}
}
.main-menu {
	.navigation {
		>li.current {
			>a {
				color: #65c7bb;
			}
		}
		>li {
			&:hover {
				>a {
					color: #65c7bb;
				}
			}
			>ul {
				>li {
					>a {
						&:hover {
							color: #65c7bb;
						}
					}
					>ul {
						>li {
							>a {
								&:hover {
									color: #65c7bb;
								}
							}
						}
					}
				}
			}
			>.megamenu {
				li {
					>a {
						&:hover {
							color: #65c7bb;
						}
					}
				}
			}
			>a {
				&:before {
					background: #65c7bb;
				}
			}
		}
	}
}
.select-box {
	label {
		span {
			&:before {
				background-color: #65c7bb;
			}
		}
	}
	input[type="radio"] {
		&:checked {
			+ {
				label {
					span {
						border-color: #65c7bb;
					}
				}
			}
		}
	}
}
.banner-section {
	.content-box {
		form {
			.form-group {
				button {
					background: #65c7bb;
				}
			}
		}
	}
}
.bg-color-2 {
	background-color: #e6faf5;
}
.feature-block-one {
	.inner-box {
		h3 {
			color: #65c7bb;
		}
		.link {
			a {
				color: #65c7bb;
			}
		}
	}
}
.feature-block {
	&:first-child {
		.feature-block-one {
			.inner-box {
				h3 {
					color: #fe5948;
				}
			}
		}
	}
	&:nth-child(3) {
		.feature-block-one {
			.inner-box {
				h3 {
					color: #ffab01;
				}
			}
		}
	}
	&:nth-child(4) {
		.feature-block-one {
			.inner-box {
				h3 {
					color: #01b4d9;
				}
			}
		}
	}
}
.image_block_1 {
	.image-box {
		.pattern {
			.pattern-2 {
				background: #65c7bb;
			}
		}
	}
}
.sec-title {
	p {
		color: #65c7bb;
	}
}
.list-style-one {
	li {
		&:before {
			border: 3px solid #65c7bb;
		}
	}
}
.processing-block-one {
	.inner-box {
		h3 {
			a {
				&:hover {
					color: #65c7bb;
				}
			}
		}
		&:after {
			background: #65c7bb;
		}
	}
}
.processing-block {
	&:nth-child(2) {
		.processing-block-one {
			.inner-box {
				&:after {
					background: #fe5948;
				}
			}
		}
	}
}
.nav-style-one {
	.owl-prev {
		&:hover {
			background: #65c7bb;
		}
	}
	.owl-next {
		&:hover {
			background: #65c7bb;
		}
	}
}
.team-block-one {
	.inner-box {
		.content-box {
			position: relative;
			.like-box {
				a {
					color: #65c7bb;
					&:hover {
						background: #65c7bb;
					}
				}
			}
			.name-box {
				li {
					h3 {
						a {
							&:hover {
								color: #65c7bb;
							}
						}
					}
					&:nth-child(2) {
						color: #65c7bb;
					}
					&:last-child {
						color: #ffae0a;
					}
				}
			}
			.rating {
				li {
					color: #ffab01;
					a {
						&:hover {
							color: #65c7bb;
						}
					}
				}
			}
			.link {
				a {
					color: #65c7bb;
				}
			}
			.link.not-available {
				a {
					color: #fe5948;
				}
			}
		}
	}
}
.doctors-block-one {
	.inner-box {
		.doctors-list {
			li {
				a {
					&:hover {
						color: #65c7bb;
					}
				}
			}
		}
		.link {
			a {
				color: #65c7bb;
			}
		}
	}
}
.content_block_2 {
	.content-box {
		.btn-box {
			.download-btn.app-store {
				background: #65c7bb;
			}
			.download-btn.play-store {
				background: #fe5948;
			}
		}
	}
}
.news-block-one {
	.inner-box {
		.image-box {
			&:before {
				background: #65c7bb;
			}
			.category {
				background: #fe5948;
			}
		}
		.lower-content {
			h3 {
				a {
					&:hover {
						color: #65c7bb;
					}
				}
			}
			.post-info {
				li {
					a {
						&:hover {
							color: #65c7bb;
						}
					}
				}
			}
			.link {
				a {
					color: #65c7bb;
				}
			}
		}
	}
}
.news-block {
	&:nth-child(2) {
		.news-block-one {
			.inner-box {
				.image-box {
					.category {
						background: #01b4d9;
					}
				}
			}
		}
	}
	&:nth-child(3) {
		.news-block-one {
			.inner-box {
				.image-box {
					.category {
						background: #ffab01;
					}
				}
			}
		}
	}
	&:nth-child(4) {
		.news-block-one {
			.inner-box {
				.image-box {
					.category {
						background: #94c74e;
					}
				}
			}
		}
	}
	&:nth-child(5) {
		.news-block-one {
			.inner-box {
				.image-box {
					.category {
						background: #e48e65;
					}
				}
			}
		}
	}
	&:nth-child(6) {
		.news-block-one {
			.inner-box {
				.image-box {
					.category {
						background: #c14ad5;
					}
				}
			}
		}
	}
	&:nth-child(7) {
		.news-block-one {
			.inner-box {
				.image-box {
					.category {
						background: #6691f5;
					}
				}
			}
		}
	}
	&:nth-child(8) {
		.news-block-one {
			.inner-box {
				.image-box {
					.category {
						background: #88c58b;
					}
				}
			}
		}
	}
	&:nth-child(9) {
		.news-block-one {
			.inner-box {
				.image-box {
					.category {
						background: #e89c89;
					}
				}
			}
		}
	}
	&:nth-child(10) {
		.news-block-one {
			.inner-box {
				.image-box {
					.category {
						background: #fe5948;
					}
				}
			}
		}
	}
	&:nth-child(11) {
		.news-block-one {
			.inner-box {
				.image-box {
					.category {
						background: #01b4d9;
					}
				}
			}
		}
	}
	&:nth-child(12) {
		.news-block-one {
			.inner-box {
				.image-box {
					.category {
						background: #ffab01;
					}
				}
			}
		}
	}
}
.content_block_3 {
	.content-box {
		.support-box {
			.icon-box {
				color: #65c7bb;
				&:before {
					background: #65c7bb;
				}
			}
			h3 {
				a {
					color: #65c7bb;
				}
			}
		}
		&:before {
			background: #65c7bb;
		}
	}
}
.content_block_4 {
	.content-box {
		.subscribe-form {
			.form-group {
				input {
					&:focus {
						border-color: #65c7bb !important;
					}
				}
				button {
					&:hover {
						color: #65c7bb !important;
					}
				}
			}
		}
		&:before {
			background: #65c7bb;
		}
	}
}
.footer-top {
	.links-widget {
		.links {
			li {
				a {
					&:hover {
						color: #65c7bb;
					}
					&:before {
						background: #65c7bb;
					}
				}
			}
		}
	}
	.contact-widget {
		.widget-content {
			li {
				a {
					&:hover {
						color: #65c7bb;
					}
				}
				i {
					color: #65c7bb;
				}
			}
		}
	}
}
.footer-bottom {
	.copyright {
		p {
			a {
				&:hover {
					color: #65c7bb;
				}
			}
		}
	}
	.footer-nav {
		li {
			a {
				&:hover {
					color: #65c7bb;
				}
			}
		}
	}
}
.main-header.style-two {
	.header-top {
		.top-left {
			li {
				a {
					&:hover {
						color: #65c7bb;
					}
				}
				i {
					color: #65c7bb;
				}
			}
		}
	}
}
.banner-section.style-two {
	.bg-layer {
		&:before {
			background: #65c7bb;
		}
	}
}
.about-section {
	.image_block_2 {
		.image-box {
			.image-content {
				h4 {
					color: #fe5948;
				}
			}
		}
	}
}
.image_block_2 {
	.image-box {
		.pattern {
			.pattern-3 {
				background: #65c7bb;
			}
		}
		.image-content {
			h4 {
				color: #fe5948;
			}
		}
	}
}
.processing-block-two {
	.inner-box {
		h3 {
			a {
				&:hover {
					color: #65c7bb;
				}
			}
		}
	}
}
.team-block-two {
	.inner-box {
		.image-box {
			&:before {
				background: #65c7bb;
			}
			a {
				color: #65c7bb;
			}
		}
		.lower-content {
			h3 {
				a {
					&:hover {
						color: #65c7bb;
					}
				}
			}
			.rating {
				li {
					color: #ffab01;
					a {
						&:hover {
							color: #65c7bb;
						}
					}
				}
			}
		}
	}
}
.banner-section.style-three {
	&:before {
		background: #3a347a;
	}
}
.category-block-one {
	.inner-box {
		h3 {
			a {
				&:hover {
					color: #65c7bb;
				}
			}
		}
		.link {
			a {
				color: #65c7bb;
			}
		}
	}
}
.image_block_3 {
	.image-box {
		.image-content {
			h4 {
				color: #fe5948;
			}
		}
		.pattern {
			.pattern-3 {
				background: #38c8ba;
			}
		}
	}
}
.team-block-three {
	.inner-box {
		.image-box {
			a {
				color: #65c7bb;
			}
			&:before {
				background: #65c7bb;
			}
		}
		.lower-content {
			.name-box {
				li {
					&:nth-child(2) {
						color: #65c7bb;
					}
					&:last-child {
						color: #ffae0a;
					}
					color: #ffab01;
					h3 {
						a {
							&:hover {
								color: #65c7bb;
							}
						}
					}
				}
			}
			.rating {
				li {
					a {
						&:hover {
							color: #65c7bb;
						}
					}
					color: #ffab01;
				}
			}
			.lower-box {
				.text {
					color: #01b4d9;
				}
				a {
					color: #65c7bb;
					&:hover {
						background: #65c7bb;
						border-color: #65c7bb;
					}
				}
				.text.not-available {
					color: #fe5948;
				}
			}
		}
	}
}
.team-style-three {
	&:before {
		background: #3C5A7D;
	}
}
.testimonial-style-two {
	.owl-theme {
		.owl-dots {
			.owl-dot.active {
				span {
					border-color: #65c7bb;
					background: #65c7bb;
				}
			}
			.owl-dot {
				span {
					&:hover {
						border-color: #65c7bb;
						background: #65c7bb;
					}
				}
			}
		}
	}
}
.banner-section.style-four {
	.image-box {
		.anim-icon {
			.icon-4 {
				background: #65c7bb;
			}
		}
	}
}
.banner-section.style-five {
	.content-inner {
		.doctors-box {
			h3 {
				a {
					&:hover {
						color: #65c7bb;
					}
				}
			}
			.info {
				li {
					a {
						&:hover {
							color: #65c7bb;
						}
					}
					i {
						color: #65c7bb;
					}
				}
			}
		}
	}
	.content-box {
		background: #3a347a;
	}
}
.counter-block-one {
	.inner-box {
		.count-outer {
			color: #65c7bb;
		}
	}
}
.content_block_5 {
	.content-box {
		.sec-title {
			h3 {
				color: #01b4d9;
			}
		}
	}
}
.faq-page-section {
	.categories-list {
		li {
			&:before {
				background: #65c7bb;
			}
		}
	}
}
.questions-section {
	.form-inner {
		form {
			.form-group {
				input {
					&:focus {
						border-color: #65c7bb !important;
					}
				}
				textarea {
					&:focus {
						border-color: #65c7bb !important;
					}
				}
			}
		}
	}
}
.pricing-block-one {
	.pricing-table {
		.table-header {
			h3 {
				color: #65c7bb;
			}
		}
		.table-content {
			ul {
				li {
					&:before {
						color: #65c7bb;
					}
				}
			}
		}
		.table-footer {
			.link {
				a {
					color: #65c7bb;
				}
			}
		}
	}
}
.pricing-section {
	.tab-btn-box {
		.tab-btns {
			&:before {
				background: #65c7bb;
			}
			li.active-btn {
				color: #65c7bb;
			}
		}
	}
}
.error-section {
	.inner-box {
		h1 {
			color: #65c7bb;
		}
	}
}
.select-field {
	.content-box {
		form {
			.form-group {
				button {
					background: #65c7bb;
				}
			}
		}
	}
}
.clinic-section {
	.item-shorting {
		.right-column {
			.menu-box {
				button.on {
					background: #65c7bb;
					border-color: #65c7bb;
				}
			}
		}
	}
}
.clinic-block-one {
	.inner-box {
		.content-box {
			.like-box {
				a {
					color: #65c7bb;
					&:hover {
						background: #65c7bb;
					}
				}
			}
			.name-box {
				li {
					&:nth-child(2) {
						color: #65c7bb;
					}
					&:last-child {
						color: #ffab01;
					}
					h3 {
						a {
							&:hover {
								color: #65c7bb;
							}
						}
					}
				}
			}
			.rating {
				li {
					color: #ffab01;
					a {
						&:hover {
							color: #65c7bb;
						}
					}
				}
			}
			.link {
				a {
					color: #01b4d9;
				}
			}
			.btn-box {
				a {
					color: #65c7bb;
					&:hover {
						background: #65c7bb;
						border-color: #65c7bb;
					}
				}
			}
			.link.not-available {
				a {
					color: #fe5948;
				}
			}
		}
	}
}
.pagination {
	li {
		a {
			&:hover {
				background: #65c7bb;
				border-color: #65c7bb;
			}
		}
		a.current, a.active {
			background: #65c7bb;
			border-color: #65c7bb;
		}

		&.active {
			.page-link {
				background: #65c7bb;
				border-color: #65c7bb;
				color: #fff;
			}
		}
	}
}
.clinic-details-content {
	.clinic-block-one {
		.inner-box {
			.lower-box {
				.info {
					li {
						i {
							color: #65c7bb;
						}
						a {
							&:hover {
								color: #65c7bb;
							}
						}
					}
				}
				.view-map {
					a {
						color: #01b4d9;
					}
				}
			}
			.content-box {
				.share-box {
					a.share-btn {
						color: #65c7bb;
						&:hover {
							background: #65c7bb;
						}
					}
				}
			}
		}
	}
	.tabs-box {
		.tab-btn-box {
			background: #3C5A7D;
			.tab-btns {
				li.active-btn {
					color: white;
				}
			}
		}
		.location-box {
			.location-info {
				li {
					a {
						&:hover {
							color: #65c7bb;
						}
					}
					i {
						color: #65c7bb;
					}
				}
			}
		}
	}
}
.clinic-sidebar {
	.form-widget {
		.form-title {
			background: #3C5A7D;
		}
		.form-inner {
			.form-group {
				input {
					&:focus {
						border-color: #65c7bb !important;
					}
				}
				textarea {
					&:focus {
						border-color: #65c7bb !important;
					}
				}
			}
		}
	}
	.info-widget {
		.info-title {
			background: #3C5A7D;
		}
		.info-inner {
			.info-list {
				li {
					p {
						a {
							&:hover {
								color: #3C5A7D;
							}
						}
					}
				}
			}
		}
		.social-box {
			.social-links {
				li {
					a {
						&:hover {
							border-color: #65c7bb;
							background: #65c7bb;
						}
					}
				}
			}
		}
	}
}
.profile-box {
	.upper-box {
		.profile-image {
			background: #3C5A7D;
		}
	}
	.profile-info {
		.list {
			li {
				a.current {
					color: #65c7bb;
					i {
						color: #65c7bb;
					}
				}
				a {
					&:hover {
						color: #65c7bb;
						i {
							color: #65c7bb;
						}
					}
				}
				span {
					background: #65c7bb;
				}
			}
		}
	}
}
.feature-block-two {
	.inner-box {
		.icon-box {
			color: #65c7bb;
		}
	}
}
.theme-btn-two {
	color: #65c7bb;
	&:hover {
		background: #65c7bb;
		border-color: #65c7bb;
	}
}
.doctors-appointment {
	.doctors-table {
		tr {
			td {
				.time {
					color: #65c7bb;
				}
				.status {
					color: #39dc86;
				}
				.status.cancel {
					color: #fe5948;
				}
				.status.pending {
					color: #ffab01;
				}
				.print {
					color: #65c7bb;
				}
				.view {
					color: #01b4d9;
				}
				.accept {
					color: #65c7bb;
				}
				.cancel {
					color: #fe5948;
				}
			}
		}
	}
}
.submit-review {
	.review-box {
		.content-box {
			.title-inner {
				background: #3C5A7D;
			}
		}
		.content-inner {
			.form-inner {
				.form-group {
					input {
						&:focus {
							border-color: #65c7bb !important;
						}
					}
					textarea {
						&:focus {
							border-color: #65c7bb !important;
						}
					}
				}
			}
		}
	}
}
.custom-controls-stacked {
	.description {
		a {
			color: #65c7bb;
			border-bottom: 1px solid #65c7bb;
		}
	}
}
.custom-check-box {
	.custom-control.material-checkbox {
		.material-control-input {
			&:checked {
				~ {
					.material-control-indicator {
						background: #65c7bb !important;
						border: 1px solid #65c7bb !important;
					}
				}
			}
		}
	}
}
.appointment-section {
	.title-box {
		background: #3C5A7D;
	}
	.information-form {
		.form-group {
			input {
				&:focus {
					border-color: #65c7bb !important;
				}
			}
			textarea {
				&:focus {
					border-color: #65c7bb !important;
				}
			}
		}
	}
	.payment-information {
		.form-group {
			input {
				&:focus {
					border-color: #65c7bb !important;
				}
			}
		}
	}
}
.confirm-section {
	.inner-box {
		.content-box {
			.icon-box {
				border: 1px solid #65c7bb;
				color: #65c7bb;
			}
			a {
				color: #65c7bb;
			}
		}
	}
}
.registration-section {
	.content-box {
		.title-box {
			background: #3C5A7D;
			a {
				color: #65c7bb;
			}
		}
		.inner {
			.form-group {
				input {
					&:focus {
						border-color: #65c7bb !important;
					}
				}
				.forgot-passowrd {
					a {
						&:hover {
							color: #65c7bb;
						}
					}
				}
			}
			.social-links {
				li {
					&:first-child {
						a {
							background: #3a559f;
						}
					}
					&:last-child {
						a {
							background: #dd4b39;
						}
					}
				}
			}
		}
		.login-now {
			p {
				a {
					color: #65c7bb;
				}
			}
		}
	}
}
.doctor-details-content {
	.tabs-box {
		.tabs-content {
			.inner-box {
				.text {
					.treatments-list {
						li {
							a {
								&:hover {
									color: #65c7bb;
								}
							}
						}
					}
				}
			}
			.review-box {
				.rating-inner {
					.rating-box {
						background: #3C5A7D;
						h2 {
							color: #65c7bb;
						}
						.clearfix {
							li {
								color: #ffab01;
							}
						}
					}
					.rating-progress {
						.porgress-bar {
							&:before {
								background: #65c7bb;
							}
						}
						.single-progress {
							.text {
								p {
									i {
										color: #ffab01;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
.single-review-box {
	.rating {
		li {
			color: #ffab01;
		}
	}
}
.doctors-sidebar {
	.form-widget {
		.form-title {
			background: #3C5A7D;
		}
	}
}
.doctors-dashboard {
	.right-panel {
		.appointment-list {
			.single-item {
				.inner {
					.info-list {
						li {
							a {
								&:hover {
									color: #65c7bb;
								}
							}
						}
					}
					.confirm-list {
						li {
							color: #65c7bb;
							&:last-child {
								color: #fe5948;
							}
						}
					}
				}
			}
		}
	}
	.review-list {
		.comment-inner {
			.single-comment-box {
				.comment {
					.rating {
						li {
							color: #ffab01;
						}
					}
					.replay-btn {
						&:hover {
							background: #65c7bb;
							border-color: #65c7bb;
						}
					}
				}
			}
		}
	}
	.my-profile {
		.single-box {
			.profile-title {
				.upload-photo {
					a {
						background: #65c7bb;
					}
				}
			}
		}
	}
}
.my-patients {
	.title-box {
		.search-form {
			input {
				&:focus {
					border-color: #65c7bb !important;
					+ {
						button {
							color: #65c7bb;
						}
					}
				}
			}
			button {
				&:hover {
					color: #65c7bb;
				}
			}
		}
	}
}
.add-listing {
	.single-box {
		.inner-box {
			.form-group {
				input {
					&:focus {
						border-color: #65c7bb !important;
					}
				}
				textarea {
					&:focus {
						border-color: #65c7bb !important;
					}
				}
			}
		}
		.title-box {
			a.menu {
				&:hover {
					border-color: #65c7bb;
					background: #65c7bb;
				}
			}
		}
	}
}
.schedule-timing {
	.title-box {
		.search-form {
			input {
				&:focus {
					border-color: #65c7bb !important;
					+ {
						button {
							color: #65c7bb;
						}
					}
				}
			}
			button {
				&:hover {
					color: #65c7bb;
				}
			}
		}
	}
	.inner-box {
		.calendar-title {
			background: #3C5A7D;
		}
	}
}
.main-header.style-three {
	.author-box {
		.icon-box {
			a {
				&:hover {
					background: #65c7bb;
					border-color: #65c7bb;
				}
			}
		}
	}
}
.blog-sidebar {
	.sidebar-search {
		.search-form {
			.form-group {
				button {
					background: #65c7bb;
				}
				input {
					&:focus {
						border-color: #65c7bb !important;
					}
				}
			}
		}
	}
	.category-widget {
		.category-list {
			li {
				a {
					&:hover {
						color: #65c7bb;
						&:before {
							border-color: #65c7bb;
						}
					}
				}
			}
		}
	}
	.post-widget {
		.post-inner {
			h5 {
				a {
					&:hover {
						color: #65c7bb;
					}
				}
			}
		}
	}
	.archives-widget {
		.archives-list {
			li {
				a {
					&:hover {
						color: #65c7bb;
						&:before {
							border-color: #65c7bb;
						}
					}
				}
			}
		}
	}
	.sidebar-tags {
		.tags-list {
			li {
				a {
					&:hover {
						border-color: #65c7bb;
						background: #65c7bb;
					}
				}
			}
		}
	}
}
.blog-details-content {
	blockquote {
		i {
			background: #65c7bb;
		}
	}
	.two-column {
		.text {
			.list {
				li {
					&:before {
						background: #65c7bb;
					}
				}
			}
		}
	}
	.post-share-option {
		.social-links {
			li {
				a {
					&:hover {
						background: #65c7bb;
					}
				}
			}
		}
	}
	.comment-box {
		.comment {
			.comment-inner {
				.reply-btn {
					&:hover {
						color: #65c7bb;
					}
				}
			}
		}
	}
	.comments-form-area {
		.comment-form {
			.form-group {
				input {
					&:focus {
						border-color: #65c7bb !important;
					}
				}
				textarea {
					&:focus {
						border-color: #65c7bb !important;
					}
				}
			}
		}
	}
}
.single-information-block {
	.inner-box {
		p {
			a {
				&:hover {
					color: #65c7bb;
				}
			}
		}
	}
}
.contact-section {
	.default-form {
		.form-group {
			input {
				&:focus {
					border-color: #65c7bb !important;
				}
			}
			textarea {
				&:focus {
					border-color: #65c7bb !important;
				}
			}
		}
	}
}
.monthly-day-wrap {
	.monthly-week {
		&:nth-child(2) {
			.monthly-day-pick {
				&:last-child {
					&:before {
						background: #594ed0;
					}
				}
			}
		}
		&:nth-child(3) {
			.monthly-day-pick {
				&:nth-child(2) {
					&:before {
						background: #01b4d9;
					}
				}
			}
		}
		&:nth-child(4) {
			.monthly-day-pick {
				&:nth-child(4) {
					&:before {
						background: #fe5948;
					}
				}
			}
		}
	}
}
.message-box {
	.title-box {
		a.menu {
			&:hover {
				background: #65c7bb;
			}
		}
	}
}
.right-panel {
	.message-box {
		.chat-room {
			.side-title {
				background: #3C5A7D;
			}
		}
	}
}
#frame {
	#sidepanel {
		#search {
			input {
				&:focus {
					border-color: #65c7bb;
					+ {
						button {
							color: #65c7bb;
						}
					}
				}
			}
			button {
				&:hover {
					color: #65c7bb;
				}
			}
		}
		#contacts {
			ul {
				li.contact {
					.wrap {
						span.contact-status.online {
							background: #65c7bb;
						}
						span.contact-status.away {
							background: #ffab01;
						}
						span.contact-status.busy {
							background: #fe5948;
						}
						.meta {
							.hidden-chat {
								background: #65c7bb;
							}
						}
					}
				}
			}
		}
	}
	.content {
		.contact-profile {
			.chat-option {
				a {
					&:hover {
						background: #65c7bb;
						border-color: #65c7bb;
					}
				}
			}
		}
		.message-input {
			.wrap {
				button {
					background: #65c7bb;
				}
			}
		}
	}
}
