/*  Theme Responsive Css */
@media only screen and (max-width: 5000px) {
	.boxed_wrapper {
		overflow: hidden;
	}
}
@media only screen and (max-width: 1699px) {
	.select-field {
		.content-box {
			form {
				.form-group {
					input {
						width: 200px;
					}
					.wide {
						max-width: 220px;
					}
				}
			}
		}
	}
}
@media only screen and (max-width: 1449px) {
	.team-style-three {
		&:before {
			display: none;
		}
		.sec-title.light {
			h2 {
				color: #3C5A7D;
			}
		}
	}
	.banner-section.style-four {
		.image-box {
			right: 0px;
		}
	}
	.about-style-three {
		.image-box {
			.image {
				float: none !important;
			}
			.image-1 {
				max-width: 780px;
				margin: 0 auto;
				margin-bottom: 30px;
			}
			.image-2 {
				max-width: 400px;
				margin: 0 auto;
			}
			.pattern {
				display: none;
			}
		}
		padding-bottom: 0px;
	}
	.feature-block-two {
		.inner-box {
			padding-left: 30px;
			padding-right: 30px;
			.icon-box {
				position: relative;
				left: 0px;
				top: 0px;
				margin-bottom: 16px;
			}
		}
	}
	.main-header.style-three {
		.header-lower {
			.logo-box {
				float: none;
				display: block;
				text-align: center;
				margin: 0px;
			}
			.outer-box {
				.left-column {
					float: none;
				}
			}
		}
		.logo-box {
			.logo {
				margin: 0 auto;
			}
		}
	}
	.favourite-doctors {
		padding: 50px 30px;
	}
	.doctors-dashboard {
		.right-panel {
			.appointment-list {
				.single-item {
					.inner {
						.confirm-list {
							position: relative;
							top: 0px;
							right: 0px;
							margin-top: 15px;
						}
					}
				}
			}
		}
	}
	.schedule-timing {
		.calendar-inner {
			.today-box {
				display: none;
			}
			.date-zone {
				display: none;
			}
		}
	}
	.monthly-prev {
		right: 80px;
	}
	.monthly-next {
		right: 30px;
	}
	#frame {
		.content {
			width: 100%;
			margin-top: 30px;
			padding-left: 0px;
		}
	}
	.right-panel {
		.message-box {
			padding-bottom: 30px;
		}
	}
}
@media only screen and (max-width: 1200px) {
	.main-header.style-one {
		.logo-box {
			padding-right: 80px;
		}
	}
	.feature-block-two {
		.inner-box {
			.pattern {
				display: block !important;
			}
			margin-bottom: 30px;
		}
	}
	.main-header {
		.btn-box {
			display: none;
		}
	}
	.feature-block-one {
		.inner-box {
			padding-left: 20px;
			padding-right: 20px;
		}
	}
	.processing-block-one {
		.inner-box {
			padding-left: 30px;
			padding-right: 30px;
		}
	}
	.pattern-layer {
		display: none !important;
	}
	.team-block-one {
		.inner-box {
			padding-left: 150px;
			padding-right: 30px;
			.image-box {
				left: 20px;
			}
			.content-box {
				/*.like-box {
					top: -7px;
				}*/
			}
		}
	}
	.content_block_2 {
		.content-box {
			.btn-box {
				.download-btn {
					padding-right: 30px;
					padding-left: 70px;
					i {
						left: 30px;
						top: 23px;
						font-size: 30px;
					}
				}
				.download-btn.app-store {
					margin-right: 10px;
				}
			}
		}
	}
	.doctors-block-one {
		.inner-box {
			padding: 60px 30px;
		}
	}
	.pattern {
		//display: none !important;
	}
	.anim-icon {
		display: none !important;
	}
	.testimonial-section {
		.image-layer {
			display: none;
		}
	}
	.banner-section.style-three {
		padding-bottom: 120px;
	}
	.process-style-two {
		.inner-content {
			.arrow {
				display: none;
			}
		}
	}
	.team-block-three {
		.inner-box {
			.lower-content {
				.lower-box {
					a {
						float: none;
					}
					.text {
						float: none;
						display: block;
					}
				}
			}
		}
	}
	.banner-section.style-four {
		.image-box {
			display: none;
		}
		padding: 120px 0px;
	}
	.banner-section.style-five {
		.content-box {
			.image-box {
				display: none;
			}
			padding-right: 70px;
		}
	}
	.faq-section {
		.content_block_5 {
			.content-box {
				margin-left: 0px;
				margin-top: 0px;
			}
		}
	}
	.select-field {
		.form-inner {
			form {
				max-width: 680px;
			}
		}
	}
	.clinic-section {
		.item-shorting {
			.left-column {
				h3 {
					font-size: 22px;
				}
			}
		}
	}
	.clinic-half-map {
		.left-inner {
			position: relative;
			width: 100%;
		}
		.right-inner {
			position: relative;
			width: 100%;
		}
	}
	.clinic-details-content {
		.clinic-block-one {
			.inner-box {
				.content-box {
					.share-box {
						position: relative;
						display: inline-block;
						top: 0px;
						right: 0px;
					}
				}
			}
		}
		.tabs-box {
			.team-block-one {
				.btn-box {
					position: relative;
					margin-top: 10px;
				}
			}
		}
	}
	.clinic-block-one {
		.inner-box {
			.content-box {
				.like-box {
					position: absolute;
					display: inline-block;
					top: 20px;
					right: 20px;
				}
			}
		}
	}
	.clinic-sidebar {
		.form-widget {
			.form-inner {
				.form-group {
					.theme-btn-one {
						padding: 17px 30px;
					}
				}
			}
		}
	}
	.main-header.style-three {
		.header-lower {
			padding: 0px 15px;
		}
	}
	.doctor-details-content {
		.tabs-box {
			.tabs-content {
				.review-box {
					.rating-inner {
						.rating-progress {
							.single-progress {
								.text {
									//position: relative;
									//top: 0px;
									//right: 0px;
								}
							}
						}
					}
				}
			}
		}
	}
	.doctors-sidebar {
		.form-widget {
			.choose-service {
				.btn-box {
					.theme-btn-one {
						padding: 17px 20px;
					}
				}
			}
		}
	}
	.doctors-dashboard {
		.right-panel {
			.appointment-list {
				.upper-box {
					.select-box {
						float: left;
						margin-top: 15px;
					}
					.text {
						float: none;
						display: block;
					}
				}
			}
		}
		.my-profile {
			.single-box {
				.profile-title {
					padding: 30px 30px;
					.image-box {
						position: relative;
						left: 0px;
						top: 0px;
						margin-bottom: 15px;
					}
				}
			}
		}
	}
	.my-patients {
		.title-box {
			.search-form {
				input[type='search'] {
					margin-bottom: 15px;
				}
			}
		}
	}
	.add-listing {
		.single-box {
			.title-box {
				a.theme-btn-one {
					display: none;
				}
			}
		}
	}
	.schedule-timing {
		.title-box {
			.right-column {
				float: left;
			}
		}
	}
	.blog-sidebar {
		.sidebar-widget {
			padding-left: 30px;
			padding-right: 30px;
		}
	}
	.contact-section {
		.form-inner {
			margin-right: 0px;
		}
		.map-inner {
			padding-left: 0px;
		}
	}
}
@media only screen and (min-width: 768px) {
	.main-menu {
		.navigation {
			>li {
				>ul {
					display: block !important;
					visibility: hidden;
					opacity: 0;
					>li {
						>ul {
							display: block !important;
							visibility: hidden;
							opacity: 0;
						}
					}
				}
				>.megamenu {
					display: block !important;
					visibility: hidden;
					opacity: 0;
				}
			}
		}
	}
}
@media only screen and (max-width: 991px) {
	.main-menu {
		display: none !important;
	}
	.sticky-header {
		//display: none !important;
	}
	.main-header.style-one {
		.outer-container {
			&:before {
				display: none !important;
			}
		}
	}
	.menu-area {
		.mobile-nav-toggler {
			display: block;
			margin-top: 0px;
			padding: 10px;
		}
	}
	.megamenu {
		ul {
			li {
				&:first-child {
					display: none;
				}
			}
		}
	}
	.banner-section {
		.image-box {
			margin-top: 40px;
			.image {
				img {
					max-width: 100%;
					width: 100%;
					float: none;
				}
			}
		}
	}
	.feature-block-one {
		.inner-box {
			margin-bottom: 30px;
		}
	}
	.feature-section {
		padding-bottom: 90px;
	}
	.image_block_1 {
		.image-box {
			.image {
				img {
					float: none;
					max-width: 100%;
				}
			}
			margin-bottom: 50px;
		}
	}
	.team-block-one {
		.inner-box {
			padding-left: 30px;
			.image-box {
				position: relative;
				left: 0px;
				top: 0px;
				margin-bottom: 30px;
			}
			.content-box {
				.like-box {
					position: absolute;
					margin-bottom: 15px;
				}
			}
		}
	}
	.doctors-block-one {
		.inner-box {
			margin-bottom: 30px;
		}
	}
	.search-doctors {
		padding-bottom: 90px;
	}
	.cta-section {
		.image-box {
			.image {
				margin-bottom: 0px;
			}
		}
	}
	.news-block-one {
		.inner-box {
			margin-bottom: 30px;
		}
	}
	.news-section {
		padding: 115px 0px 90px 0px;
	}
	.content_block_3 {
		.content-box {
			margin-bottom: 30px;
		}
	}
	.footer-top {
		.footer-widget {
			margin: 0px 0px 30px 0px !important;
		}
		padding-bottom: 60px;
	}
	.banner-section.style-two {
		.bg-layer {
			display: none;
		}
	}
	.image_block_2 {
		.image-box {
			.image-2 {
				bottom: 80px;
			}
		}
	}
	.about-style-two {
		.image_block_2 {
			.image-box {
				margin-right: 0px;
			}
		}
	}
	.processing-block-two {
		.inner-box {
			margin-bottom: 30px;
		}
	}
	.process-style-two {
		padding-bottom: 90px;
	}
	.main-header.style-two {
		.header-top {
			.top-left {
				float: none;
				display: block;
				text-align: center;
			}
			.top-right {
				float: none;
				display: block;
				text-align: center;
			}
		}
	}
	.team-style-three {
		.more-btn {
			position: relative;
			top: 0px;
			margin-top: 15px;
		}
		padding-top: 0px;
	}
	.team-block-three {
		.inner-box {
			margin-bottom: 30px;
		}
	}
	.image_block_4 {
		.image-box {
			max-width: 540px;
			margin: 0px 0px 40px 0px;
		}
	}
	.banner-section.style-five {
		.content-box {
			padding-left: 30px;
			padding-right: 30px;
		}
	}
	.counter-block-one {
		.inner-box {
			margin: 15px 0px;
		}
	}
	.cta-section.alternat-2 {
		.image-box {
			margin-left: 0px;
		}
	}
	.faq-page-section {
		.categories-list {
			max-width: 100%;
			margin-bottom: 40px;
		}
		.image-box {
			margin: 0px 0px 40px 0px;
		}
	}
	.pricing-block-one {
		.pricing-table {
			margin-bottom: 30px;
		}
	}
	.pricing-section {
		padding-bottom: 90px;
	}
	.select-field {
		.form-inner {
			form {
				float: none;
				display: block;
				max-width: 100%;
				margin-bottom: 15px;
			}
			.select-box {
				float: none;
			}
		}
		.content-box {
			form {
				.form-group {
					input {
						width: 400px;
					}
				}
			}
		}
	}
	.clinic-section {
		.map-inner {
			margin-left: 0px;
			margin-top: 30px;
		}
	}
	.clinic-block-one {
		.inner-box {
			.content-box {
				.like-box {
					a {
						margin-bottom: 15px;
					}
				}
			}
		}
	}
	.left-panel {
		position: relative;
		left: 0px;
		top: 0px;
	}
	.profile-box {
		margin: 0 auto;
		margin-bottom: 30px;
		min-height: auto;
	}
	.right-panel {
		.content-container {
			margin-left: 0px;
		}
	}
	.main-header.style-three {
		.header-lower {
			.outer-box {
				padding-bottom: 25px;
			}
		}
		.author-box {
			margin-top: 0px;
		}
	}
	.appointment-section {
		.appointment-information {
			margin-bottom: 30px;
			margin-right: 0px;
		}
	}
	.clinic-half-map {
		.clinic-section {
			padding-bottom: 100px;
		}
		.left-inner {
			.form-inner {
				padding-top: 30px;
			}
		}
	}
	.dashboard-section {
		padding: 100px 0px;
	}
	.favourite-doctors {
		.team-block-three {
			.inner-box {
				margin-bottom: 30px;
			}
			max-width: 320px;
			margin: 0 auto;
		}
		.pagination-wrapper {
			text-align: center;
		}
	}
	.clinic-details-content {
		.tabs-box {
			margin-bottom: 30px;
		}
	}
	.my-patients {
		.title-box {
			.text {
				float: none;
				display: block;
			}
			.btn-box {
				float: none;
				display: block;
			}
		}
	}
	.blog-grid-content {
		margin-bottom: 40px;
	}
	.sidebar-page-container {
		.pagination-wrapper {
			text-align: left;
		}
	}
	.blog-standard-content {
		margin-bottom: 40px;
	}
	.blog-details-content {
		margin-bottom: 40px;
	}
	.blog-sidebar {
		margin-left: 0px;
	}
	.single-information-block {
		.inner-box {
			margin-bottom: 30px;
		}
	}
	.information-section {
		padding-bottom: 90px;
	}
	#frame {
		height: auto !important;
	}
}
@media only screen and (max-width: 767px) {
	.sec-pad {
		padding: 70px 0px !important;
	}
	.monthly-day-wrap {
		.monthly-week {
			&:nth-child(2) {
				.monthly-day-pick {
					&:last-child {
						&:before {
							display: none;
						}
					}
				}
			}
			&:nth-child(3) {
				.monthly-day-pick {
					&:nth-child(2) {
						&:before {
							display: none;
						}
					}
				}
			}
			&:nth-child(4) {
				.monthly-day-pick {
					&:nth-child(4) {
						&:before {
							display: none;
						}
					}
				}
			}
		}
	}
	.banner-section {
		.image-box {
			margin-left: 0px;
		}
		.content-box {
			h1 {
				font-size: 44px;
				line-height: 60px;
			}
			form {
				.form-group {
					button {
						top: inherit;
						bottom: 5px;
					}
				}
			}
		}
	}
	.main-header {
		.logo-box {
			.bg-color {
				height: 100%;
				top: 0px;
			}
		}
	}
	.main-header.style-one {
		.header-top {
			.top-right {
				float: none;
				text-align: center;
			}
		}
	}
	.header-top {
		padding: 15px 0px;
	}
	.feature-section {
		padding: 70px 0px 40px 0px;
	}
	.about-section {
		padding: 70px 0px;
	}
	.sec-title {
		h2 {
			font-size: 36px;
			line-height: 44px;
		}
	}
	.process-section {
		padding: 65px 0px 70px 0px;
	}
	.team-section {
		padding: 65px 0px 70px 0px;
	}
	.testimonial-section {
		padding: 70px 0px;
		.testimonial-inner {
			padding-left: 30px;
			padding-right: 30px;
		}
		.owl-nav {
			display: none;
		}
	}
	.search-doctors {
		padding: 65px 0px 40px 0px;
	}
	.news-section {
		padding: 65px 0px 40px 0px;
	}
	.cta-section {
		.content_block_2 {
			.content-box {
				padding-bottom: 70px;
			}
		}
	}
	.news-block-one {
		max-width: 400px;
		margin: 0 auto;
	}
	.footer-top {
		padding: 220px 0px 40px 0px;
	}
	.banner-section.style-two {
		padding: 100px 0px 220px 0px;
	}
	.about-style-two {
		padding: 70px 0px;
	}
	.clients-section {
		padding: 70px 0px;
	}
	.process-style-two {
		padding: 65px 0px 35px 0px;
	}
	.team-style-two {
		padding: 65px 0px 70px 0px;
	}
	.team-block-two {
		max-width: 300px;
		margin: 0 auto;
	}
	.banner-section.style-three {
		padding: 100px 0px;
	}
	.category-section {
		padding: 65px 0px 70px 0px;
	}
	.team-style-three {
		padding: 65px 0px 40px 0px;
	}
	.team-block-three {
		max-width: 400px;
		margin: 0 auto;
	}
	.faq-section {
		.content_block_5 {
			.content-box {
				padding-bottom: 70px;
			}
		}
	}
	.testimonial-style-two {
		.owl-dots {
			display: none;
		}
		padding: 65px 0px 70px 0px;
	}
	.banner-section.style-four {
		padding: 80px 0px;
	}
	.banner-section.style-five {
		.content-box {
			form {
				.form-group {
					.wide {
						max-width: 100%;
						&:before {
							display: none;
						}
					}
					input {
						width: 100%;
						float: none;
					}
				}
			}
		}
	}
	.process-style-two.alternat-2 {
		padding-top: 200px;
	}
	.team-style-four {
		padding: 65px 0px 40px 0px;
	}
	.funfact-section {
		padding: 55px 0px;
	}
	.cta-section.alternat-2 {
		.content_block_2 {
			.content-box {
				margin-top: 0px;
			}
		}
	}
	.pt-125 {
		padding-top: 70px;
	}
	.faq-page-section {
		padding-top: 70px;
	}
	.select-field {
		.content-box {
			form {
				.form-group {
					.wide {
						max-width: 100%;
						&:before {
							display: none;
						}
					}
					//border-bottom-left-radius: 30px;
					button {
						top: inherit;
						bottom: 5px;
					}
					input {
						//border-bottom-left-radius: 30px;
						width: 100%;
						//border-bottom-right-radius: 30px;
					}
				}
			}
		}
	}
	.clinic-section {
		.item-shorting {
			.left-column {
				float: none;
				display: block;
				margin-bottom: 10px;
			}
			.right-column {
				float: none;
				display: block;
			}
		}
		.pagination-wrapper {
			text-align: center;
		}
		padding-bottom: 70px;
	}
	.clinic-block-one {
		.inner-box {
			.content-box {
				.btn-box {
					position: relative;
					top: 0px;
					right: 0px;
					margin-top: 15px;
				}
			}
		}
	}
	.clinic-half-map {
		.clinic-section {
			padding-bottom: 70px;
		}
	}
	.submit-review {
		padding: 70px 0px;
	}
	.appointment-section {
		padding: 70px 0px;
	}
	.registration-section {
		padding: 70px 0px;
		.content-box {
			.inner {
				.social-links {
					li {
						width: 100%;
						margin-bottom: 10px;
						a {
							width: 100%;
						}
					}
				}
			}
		}
	}
	.favourite-doctors {
		padding-bottom: 0px;
	}
	.doctor-details {
		padding-bottom: 70px;
	}
	.footer-top.pt-100 {
		padding-top: 70px;
	}
	.doctors-dashboard {
		.review-list {
			.comment-inner {
				.single-comment-box {
					.comment.replay-comment {
						margin-left: 0px;
					}
				}
			}
		}
	}
	.blog-grid {
		padding: 70px 0px;
	}
	.sidebar-page-container {
		padding: 70px 0px;
		.news-block-one {
			max-width: 100%;
		}
	}
	.blog-details-content {
		.two-column {
			.image-box {
				margin-bottom: 30px;
			}
		}
	}
	.contact-section {
		padding: 70px 0px;
	}
	.image_block_1 {
		.image-box {
			&:before {
				display: none;
			}
		}
	}
	.rtl {
		.testimonial-section {
			.testimonial-inner {
				padding-left: 30px;
				padding-right: 30px;
			}
		}
	}
	#frame {
		.content {
			.message-input {
				.wrap {
					.attachment {
						display: none;
					}
					input {
						padding-left: 30px !important;
					}
				}
			}
		}
	}
}
@media only screen and (max-width: 599px) {
	.cta-section {
		.image-box {
			.image {
				img {
					width: 100%;
				}
			}
		}
	}
	.content_block_2 {
		.content-box {
			.btn-box {
				.download-btn.app-store {
					margin-bottom: 10px;
				}
			}
		}
	}
	.content_block_4 {
		.content-box {
			.subscribe-form {
				.form-group {
					button {
						position: relative;
						width: 100%;
					}
					padding-right: 0px;
					overflow: visible;
					input[type='email'] {
						border-radius: 0px;
						margin-bottom: 15px;
					}
				}
			}
		}
	}
	.footer-bottom {
		.copyright {
			float: none;
			display: block;
			text-align: center;
		}
		.footer-nav {
			float: none;
			display: block;
			text-align: center;
		}
	}
	.main-header {
		.outer-container {
			padding: 0px 30px;
		}
	}
	.clinic-block-one {
		.inner-box {
			.image-box {
				position: relative;
				left: 0px;
				top: 0px;
				margin-bottom: 30px;
			}
			padding-left: 30px;
		}
	}
	.clinic-details-content {
		.clinic-block-one {
			.inner-box {
				padding-left: 40px;
			}
		}
	}
	.doctors-dashboard {
		.right-panel {
			.appointment-list {
				.single-item {
					.image-box {
						position: relative;
						left: 0px;
						top: 0px;
						margin-bottom: 15px;
					}
					.inner {
						padding-left: 0px;
					}
				}
			}
		}
		padding: 50px 30px;
		.review-list {
			.comment-inner {
				.single-comment-box {
					.comment {
						padding-left: 0px;
						.comment-thumb {
							position: relative;
							left: 0px;
							top: 0px;
							margin-bottom: 15px;
						}
					}
				}
			}
		}
	}
	.about-section {
		.content_block_1 {
			.content-box {
				margin-left: 0px;
			}
		}
	}
	.banner-section {
		.content-box {
			form {
				.form-group {
					margin-right: 0px;
				}
			}
		}
	}
	.error-section {
		.inner-box {
			h1 {
				font-size: 100px;
				line-height: 100px;
			}
		}
	}
	.rtl {
		.banner-section {
			.content-box {
				form {
					.form-group {
						margin-left: 0px;
					}
				}
			}
		}
	}
	.monthly-header {
		padding: 30px 15px;
	}
	.monthly-header-title {
		font-size: 20px;
		line-height: 40px;
	}
	.schedule-timing {
		.inner-box {
			.calendar-title {
				padding: 10px 15px;
			}
		}
	}
	#frame {
		#sidepanel {
			width: 100%;
			width: 100% !important;
			min-width: 100% !important;
			height: auto !important;
			#contacts {
				ul {
					li.contact {
						padding-left: 15px !important;
						padding-right: 15px !important;
						.wrap {
							.meta {
								.preview {
									font-size: 10px !important;
								}
							}
						}
					}
				}
			}
		}
		min-width: 100%;
		width: 100% !important;
		min-width: 100% !important;
		.content {
			.contact-profile {
				.chat-option {
					position: relative !important;
					bottom: 0px !important;
					margin-top: 15px;
				}
			}
			min-width: 100% !important;
		}
	}
}
@media only screen and (max-width: 499px) {
	.agent-section {
		.inner-container {
			padding: 50px 30px;
		}
	}
	.content_block_3 {
		.content-box {
			padding-left: 30px;
			padding-right: 30px;
		}
	}
	.content_block_4 {
		.content-box {
			padding-left: 30px;
			padding-right: 30px;
		}
	}
	.banner-section.style-five {
		.content-inner {
			display: none;
		}
	}
	.clinic-details-content {
		.tabs-box {
			.tab-btn-box {
				.tab-btns {
					li {
						width: 100%;
						padding: 5px 0px;
					}
				}
				padding: 20px 0px;
			}
			.tabs-content {
				.inner-box {
					.text {
						.list {
							li {
								width: 100%;
							}
						}
					}
				}
				padding-left: 30px;
				padding-right: 30px;
			}
			.accordion-box {
				h6 {
					span {
						float: none;
						display: block;
					}
				}
			}
		}
	}
	.profile-box {
		width: 100%;
	}
	.patient-dashboard {
		padding-left: 30px;
		padding-right: 30px;
	}
	.image_block_2 {
		.image-box {
			.image-content {
				top: 60px;
			}
		}
	}
	.select-field {
		.content-box {
			form {
				.form-group {
					input {
						padding-right: 70px;
					}
				}
			}
		}
	}
	.clinic-half-map {
		.banner-section.style-five {
			display: none;
		}
	}
	.doctors-dashboard {
		.my-profile {
			.single-box {
				.inner-box {
					.image-box {
						.image {
							margin-bottom: 15px;
						}
					}
				}
			}
		}
	}
	.registration-section {
		.content-box {
			.title-box {
				a {
					position: relative;
					top: 0px;
					right: 0px;
				}
			}
		}
	}
	.blog-details-content {
		.post-share-option {
			.social-links {
				float: none;
				display: block;
			}
		}
	}
	.monthly-prev {
		display: none;
	}
	.monthly-next {
		display: none;
	}
	#frame {
		#sidepanel {
			#contacts {
				ul {
					li.contact {
						.wrap {
							.meta {
								.chat-time {
									display: none;
								}
								.hidden-chat {
									display: none;
								}
							}
						}
					}
				}
			}
		}
		.content {
			.message-input {
				.wrap {
					button {
						right: 15px !important;
					}
					padding-right: 75px !important;
				}
			}
		}
	}
	.right-panel {
		.message-box {
			.chat-room {
				padding-left: 30px;
				padding-right: 30px;
			}
		}
	}
}
@media only screen and (max-width: 399px) {
	.main-header.style-one {
		.logo-box {
			padding-right: 40px;
		}
	}
	.header-top {
		.info {
			li {
				margin: 0px 5px;
			}
		}
	}
	.team-block-one {
		.inner-box {
			.content-box {
				.link {
					padding-left: 0px;
					margin-left: 0px;
					display: block;
					&:before {
						display: none;
					}
				}
			}
		}
	}
	.content_block_3 {
		.content-box {
			.support-box {
				padding-left: 0px;
				.icon-box {
					position: relative;
					top: 0px;
					margin-bottom: 15px;
					&:before {
						display: none;
					}
				}
				h3 {
					font-size: 16px;
				}
			}
			h3 {
				font-size: 22px;
			}
		}
	}
	.content_block_4 {
		.content-box {
			h3 {
				font-size: 22px;
			}
			.subscribe-form {
				.form-group {
					button {
						padding: 12px 20px;
					}
				}
			}
		}
	}
	.banner-section.style-five {
		.content-box {
			padding-left: 15px;
			padding-right: 15px;
		}
	}
	.sec-title {
		h2 {
			font-size: 30px;
			line-height: 38px;
		}
	}
	.main-header {
		.outer-container {
			padding: 0px 15px;
		}
	}
	.banner-section {
		.content-box {
			h1 {
				font-size: 40px;
				line-height: 48px;
			}
		}
	}
	.rtl {
		.content_block_3 {
			.content-box {
				.support-box {
					padding-right: 0px;
				}
			}
		}
	}
	.pricing-section {
		.tab-btn-box {
			.tab-btns {
				li {
					min-width: 120px;
				}
			}
		}
	}
	.clinic-section {
		.item-shorting {
			.right-column {
				.short-box {
					margin-bottom: 10px;
				}
			}
		}
	}
	.clinic-block-one {
		.inner-box {
			.content-box {
				.link {
					padding-left: 0px;
					margin-left: 0px;
					display: block;
					&:before {
						display: none;
					}
				}
			}
		}
	}
	.team-block-three {
		.inner-box {
			.lower-content {
				.location-box {
					margin-bottom: 0px;
				}
			}
		}
	}
	.clinic-details-content {
		.clinic-block-one {
			.inner-box {
				padding-left: 20px;
				padding-right: 20px;
				.lower-box {
					.view-map {
						padding-left: 0px;
						margin-left: 0px;
						display: block;
						&:before {
							display: none;
						}
					}
				}
			}
		}
	}
	.profile-box {
		.upper-box {
			.title-box {
				margin-left: 15px;
				margin-right: 15px;
			}
		}
		.profile-info {
			.list {
				li {
					padding-right: 30px;
				}
			}
		}
	}
	.main-header.style-three {
		.author-box {
			.icon-box {
				display: none;
			}
		}
		.header-lower {
			padding-right: 30px;
		}
		.menu-area {
			.mobile-nav-toggler {
				margin-top: 10px;
			}
		}
	}
	.doctors-dashboard {
		.right-panel {
			.appointment-list {
				.single-item {
					padding-left: 20px;
					padding-right: 20px;
					.inner {
						.confirm-list {
							li {
								padding: 9px 15px;
							}
						}
					}
				}
			}
		}
		.review-list {
			.comment-inner {
				.single-comment-box {
					padding-left: 20px;
					padding-right: 20px;
					.comment {
						.rating {
							position: relative;
						}
					}
				}
			}
		}
	}
	.my-patients {
		.title-box {
			.search-form {
				width: 100%;
			}
			padding-left: 20px;
			padding-right: 20px;
		}
	}
	.add-listing {
		.single-box {
			.inner-box {
				padding-left: 20px;
				padding-right: 20px;
			}
			.title-box {
				padding-left: 20px;
				padding-right: 20px;
				a.menu {
					position: relative;
					top: 0px;
					right: 0px;
					margin-top: 5px;
				}
			}
		}
		.btn-box {
			.theme-btn-one {
				margin-bottom: 10px;
			}
		}
	}
	.schedule-timing {
		.title-box {
			padding-left: 20px;
			padding-right: 20px;
			.search-form {
				width: 100%;
			}
		}
		.inner-box {
			padding-left: 20px;
			padding-right: 20px;
			.calendar-title {
				.theme-btn-one {
					padding: 7px 25px;
				}
				.btn-box {
					float: none;
				}
			}
		}
	}
	.news-block-one {
		.inner-box {
			.lower-content {
				padding-left: 20px;
				padding-right: 20px;
			}
		}
	}
	.blog-details-content {
		blockquote {
			padding-left: 30px;
			padding-right: 30px;
			i {
				position: relative;
				left: 0px;
				top: 0px;
				transform: translateY(0%);
				margin-bottom: 15px;
			}
		}
	}
	#frame {
		#sidepanel {
			#contacts {
				ul {
					li.contact {
						padding-left: 15px;
						padding-right: 15px;
					}
				}
			}
		}
	}
}
